/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consent.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./consent.component";
import * as i4 from "@angular/router";
import * as i5 from "../services/analytics.service";
import * as i6 from "../services/user.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "@angular/common/http";
import * as i10 from "../services/rest.service";
var styles_ConsentComponent = [i0.styles];
var RenderType_ConsentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsentComponent, data: {} });
export { RenderType_ConsentComponent as RenderType_ConsentComponent };
export function View_ConsentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Vendor Onboarding ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "small", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "font-weight": 0 }), (_l()(), i1.ɵted(-1, null, [" Please confirm that you agree to the following terms and conditions."])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, "bold"); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_ConsentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consent", [], null, null, null, View_ConsentComponent_0, RenderType_ConsentComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConsentComponent, [i4.Router, i5.GoogleAnalyticsService, i6.UserService, i7.MatDialog, i8.MatSnackBar, i9.HttpClient, i10.RestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsentComponentNgFactory = i1.ɵccf("app-consent", i3.ConsentComponent, View_ConsentComponent_Host_0, {}, {}, []);
export { ConsentComponentNgFactory as ConsentComponentNgFactory };
