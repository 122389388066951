import { of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressService } from './../services/address.service';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';
import * as i0 from "@angular/core";
import * as i1 from "../services/address.service";
var ValidationService = /** @class */ (function () {
    function ValidationService(addr) {
        this.addr = addr;
        this.allStates = states.default;
        this.allCountries = countries.default;
        this.isValidRTV = false;
        this.RTVselectionCount = 0;
    }
    // rule functions
    // future year
    ValidationService.prototype.isFutureYear = function (input) {
        var thisYear = new Date().getFullYear();
        // console.log(thisYear, input);
        if (+input > thisYear) {
            return true;
        }
        else {
            return false;
        }
    };
    ValidationService.prototype.radioReturnedGoods = function (form, key, value) {
        var _this = this;
        var fieldList = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE']; // 01-09 except 03
        var applyToAllSkus = form.controls['RETURNS_APPLY_TO_ALL'] ? form.controls['RETURNS_APPLY_TO_ALL'] : form.controls['ONLINE_RETURN_EXCEPTIONS'];
        var selectedField = fieldList.find(function (x) { return x === key; }); // check that current field is in the list
        console.log('filter returned goods', applyToAllSkus, selectedField);
        // if apply to all skus = Y, allow only one 01-09 to be Y
        if (applyToAllSkus && applyToAllSkus.value === 'Y' && selectedField) {
            // set all others to N
            for (var _i = 0, fieldList_1 = fieldList; _i < fieldList_1.length; _i++) {
                var f = fieldList_1[_i];
                if (f === key) {
                    console.log('select clicked', f);
                    form.controls[f].setValue(value, { emitEvent: false });
                    form.controls[f].markAsDirty();
                }
                else {
                    console.log('update magically', f);
                    if (form.controls[f]) {
                        form.controls[f].setValue('N', { emitEvent: false });
                        form.controls[f].markAsDirty();
                    }
                }
            }
        }
        else {
            // if clicked on applyToAllSkus
            if ((key === 'RETURNS_APPLY_TO_ALL' || key === 'ONLINE_RETURN_EXCEPTIONS') && value === 'Y') {
                // set all to N
                var firstOneSet = false;
                for (var _a = 0, fieldList_2 = fieldList; _a < fieldList_2.length; _a++) {
                    var f = fieldList_2[_a];
                    var currControl = form.controls[f];
                    console.log(f, currControl);
                    if (currControl) {
                        if (currControl.value === 'Y' && !firstOneSet) {
                            // don't change the value, but update firstOneSet
                            firstOneSet = true;
                        }
                        else {
                            currControl.setValue('N', { emitEvent: false });
                            currControl.markAsDirty();
                        }
                    }
                }
            }
            else if (selectedField) {
                // auto select all N, make N read-only?
                // if all are N, change first to Y
                // applyToAllSkus is N or not present
                console.log('apply to all?', applyToAllSkus.value, applyToAllSkus);
                // require more than 1 Y, do not auto edit
                // set validation for at least 2 Y in list
                var selectedRTV = [];
                for (var _b = 0, fieldList_3 = fieldList; _b < fieldList_3.length; _b++) {
                    var r = fieldList_3[_b];
                    var fieldValue = form.get(r).value;
                    // if(form.controls[r] && form.controls[r].value) {selectedRTV[r] = form.controls[r].value;}
                    if (fieldValue) {
                        selectedRTV[r] = fieldValue;
                    }
                }
                var rtvSelections = selectedRTV.filter(function (f) { return f === 'Y'; });
                console.log('selected RTV', rtvSelections);
                if (rtvSelections && rtvSelections.length > 1) {
                    // valid
                    // this.isValidRTV = true;
                }
                else {
                    // invalid
                    console.log('must select at least 2 RTV options', selectedRTV);
                    // this.isValidRTV = false;
                }
            }
        }
        // validation
        form.valueChanges.subscribe(function (val) {
            _this.validateRTV(form, val);
        });
    };
    ValidationService.prototype.validateRTV = function (form, changes) {
        var fieldList = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE'];
        var val = form.controls;
        if (changes) {
            val = changes;
        }
        this.isValidRTV = false;
        this.returnsApplyToAll = val['RETURNS_APPLY_TO_ALL'] ? val['RETURNS_APPLY_TO_ALL'] : val['ONLINE_RETURN_EXCEPTIONS'];
        if (!changes) {
            this.returnsApplyToAll = val['RETURNS_APPLY_TO_ALL'].value ? val['RETURNS_APPLY_TO_ALL'].value : val['ONLINE_RETURN_EXCEPTIONS'].value;
        }
        var rtv = [];
        for (var _i = 0, fieldList_4 = fieldList; _i < fieldList_4.length; _i++) {
            var x = fieldList_4[_i];
            if (form.controls[x] && form.controls[x].value) {
                rtv[x] = form.get(x).value;
            }
        }
        console.log(this.returnsApplyToAll == 'Y' ? '1 and only 1 Y is required and allowed' : 'at least 2 Y are required');
        var notAllN = Object.keys(rtv).filter(function (key) { return rtv[key] == 'Y'; });
        this.RTVselectionCount = notAllN.length;
        if (!notAllN) {
            this.isValidRTV = false;
        }
        if (this.returnsApplyToAll == 'Y' && notAllN.length == 1) {
            this.isValidRTV = true;
        }
        if (this.returnsApplyToAll == 'N' && notAllN.length > 1) {
            this.isValidRTV = true;
        }
        console.log('Valid?', this.isValidRTV, rtv);
    };
    ValidationService.prototype.isRTV = function (key) {
        var rtv = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE'];
        if (rtv.indexOf(key) == -1) {
            return false;
        }
        return true;
    };
    ValidationService.prototype.radioDeductionLocation = function (form, section, key, value) {
        // group deduction location fields on each tab they appear
        // allow only one Y, set the rest to N
        // console.log(tabs);
        var fieldList = [];
        //const currentTab = tabs.find(t => t.fields.find(f => f.key === key));
        var currentTab = section;
        console.log('current tab', currentTab);
        for (var _i = 0, _a = currentTab.fields; _i < _a.length; _i++) {
            var field = _a[_i];
            if (form.get(field.key) && field.subtitle === 'Deduction Location') {
                fieldList.push(field.key);
            }
        }
        // console.log('deduction fields', fieldList);
        if (fieldList && fieldList.length > 0) {
            for (var _b = 0, fieldList_5 = fieldList; _b < fieldList_5.length; _b++) {
                var f = fieldList_5[_b];
                if (f === key) {
                    console.log('select clicked', f);
                    form.controls[f].setValue(value, { emitEvent: false });
                    form.controls[f].markAsDirty();
                }
                else {
                    // console.log('update magically', f);
                    if (form.controls[f]) {
                        form.controls[f].setValue('N', { emitEvent: false });
                        form.controls[f].markAsDirty();
                    }
                }
            }
        }
    };
    ValidationService.prototype.radioAccountsPayable = function (form, key, value) {
        // only one must be Y
        var fieldList = ['IS_OPENACCOUNT', 'IS_LINE_OF_CREDIT'];
        var selectedField = fieldList.find(function (x) { return x === key; }); // check that current field is in the list
        if (selectedField) {
            // set all others to N
            for (var _i = 0, fieldList_6 = fieldList; _i < fieldList_6.length; _i++) {
                var f = fieldList_6[_i];
                if (f === key) {
                    console.log('select clicked', f);
                    form.controls[f].setValue(value, { emitEvent: false });
                    form.controls[f].markAsDirty();
                }
                else {
                    // console.log('update magically', f);
                    if (form.controls[f]) {
                        if (value === 'Y') {
                            form.controls[f].setValue('N', { emitEvent: false });
                            form.controls[f].markAsDirty();
                        }
                        else {
                            form.controls[f].setValue('Y', { emitEvent: false });
                            form.controls[f].markAsDirty();
                        }
                    }
                }
            }
        }
    };
    ValidationService.prototype.updateFreightFormFields = function (form, key, list) {
        // console.log('key is ', key, key.substring(0,3));
        var abbr = key.substring(0, 3);
        if (abbr.includes('FOB')) {
            if (form.controls['TSC_RECEIVING'].value != 2) {
                console.log("Before changing TSC_RECEIVING value in FOB", form.controls['TSC_RECEIVING'].value);
                form.controls['TSC_RECEIVING'].setValue(2);
                form.controls['TSC_RECEIVING'].markAsDirty();
                form.controls['TSC_RECEIVING'].disable();
            }
            if (form.controls['TIME_TO_DESTINATION'].value != 45) {
                console.log("Before changing TIME_TO_DESTINATION value in FOB", form.controls['TIME_TO_DESTINATION'].value);
                form.controls['TIME_TO_DESTINATION'].setValue(45);
                form.controls['TIME_TO_DESTINATION'].markAsDirty();
                form.controls['TIME_TO_DESTINATION'].disable();
            }
            /*
            form.controls['TSC_RECEIVING'].setValue(2);
            form.controls['TIME_TO_DESTINATION'].setValue(45);
            form.controls['TSC_RECEIVING'].markAsDirty();
            form.controls['TIME_TO_DESTINATION'].markAsDirty();
            form.controls['TSC_RECEIVING'].disable();
            form.controls['TIME_TO_DESTINATION'].disable();
            */
        }
        else if (abbr.includes('FPV')) {
            /*
            form.controls['TSC_RECEIVING'].setValue(2);
            form.controls['TSC_RECEIVING'].markAsDirty();
            form.controls['TSC_RECEIVING'].disable();
            form.controls['LEAD_TIME_SHIP'].enable();
            form.controls['TIME_TO_DESTINATION'].enable();
            */
            if (form.controls['TSC_RECEIVING'].value != 2) {
                console.log("Before changing TSC_RECEIVING value in FPV", form.controls['TSC_RECEIVING'].value);
                form.controls['TSC_RECEIVING'].setValue(2);
                form.controls['TSC_RECEIVING'].markAsDirty();
                form.controls['TSC_RECEIVING'].disable();
            }
            form.controls['LEAD_TIME_SHIP'].enable();
            form.controls['TIME_TO_DESTINATION'].enable();
        }
        else if (abbr.includes('FCT')) {
            /*
            form.controls['TSC_RECEIVING'].setValue(2);
            form.controls['TIME_TO_DESTINATION'].setValue(2);
            form.controls['TSC_RECEIVING'].markAsDirty();
            form.controls['TIME_TO_DESTINATION'].markAsDirty();
            form.controls['TIME_TO_DESTINATION'].disable();
            form.controls['TSC_RECEIVING'].disable();
            */
            if (form.controls['TSC_RECEIVING'].value != 2) {
                console.log("Before changing TSC_RECEIVING value in FCT", form.controls['TSC_RECEIVING'].value);
                form.controls['TSC_RECEIVING'].setValue(2);
                form.controls['TSC_RECEIVING'].markAsDirty();
                form.controls['TSC_RECEIVING'].disable();
            }
            if (form.controls['TIME_TO_DESTINATION'].value != 2) {
                console.log("Before changing TIME_TO_DESTINATION value in FCT", form.controls['TIME_TO_DESTINATION'].value);
                form.controls['TIME_TO_DESTINATION'].setValue(2);
                form.controls['TIME_TO_DESTINATION'].markAsDirty();
                form.controls['TIME_TO_DESTINATION'].disable();
            }
        }
        if (list) {
            this.validateFreightTermsAndCalculatedDeliveryTime(form, list);
        }
        else {
            this.validateFreightTermsAndCalculatedDeliveryTime(form);
        }
        form.controls['TOTAL_DELIVERY_TIME'].disable();
    };
    ValidationService.prototype.validateFreightTermsAndCalculatedDeliveryTime = function (form, list) {
        var isInputValidNumber = true;
        if (Number.isNaN(form.controls['LEAD_TIME_SHIP'].value)) {
            form.controls['LEAD_TIME_SHIP'].setErrors({ 'incorrect': true });
            isInputValidNumber = false;
        }
        else if (Number.isNaN(form.controls['TIME_TO_DESTINATION'].value)) {
            form.controls['TIME_TO_DESTINATION'].setErrors({ 'incorrect': true });
            isInputValidNumber = false;
        }
        else if (Number.isNaN(form.controls['TSC_RECEIVING'].value)) {
            form.controls['TSC_RECEIVING'].setErrors({ 'incorrect': true });
            isInputValidNumber = false;
        }
        if (isInputValidNumber) {
            var calculatedDeliveryTime = parseInt(form.controls['LEAD_TIME_SHIP'].value) + parseInt(form.controls['TIME_TO_DESTINATION'].value) + parseInt(form.controls['TSC_RECEIVING'].value);
            if (form.controls['TOTAL_DELIVERY_TIME'].value != calculatedDeliveryTime) {
                form.controls['TOTAL_DELIVERY_TIME'].setValue(calculatedDeliveryTime);
                form.controls['TOTAL_DELIVERY_TIME'].markAsDirty();
            }
            //form.controls['TOTAL_DELIVERY_TIME'].setValue(calculatedDeliveryTime);
            //form.controls['TOTAL_DELIVERY_TIME'].markAsDirty();
            if (list) {
                // find the fields and update
                var fieldList = ['LEAD_TIME_SHIP', 'TIME_TO_DESTINATION', 'TSC_RECEIVING', 'TOTAL_DELIVERY_TIME'];
                var _loop_1 = function (field) {
                    var found = list.fields.find(function (f) { return f.key === field; });
                    if (found) {
                        found.value = form.controls[field].value;
                        console.log('updated ' + field + ' to ' + found.value);
                    }
                };
                for (var _i = 0, fieldList_7 = fieldList; _i < fieldList_7.length; _i++) {
                    var field = fieldList_7[_i];
                    _loop_1(field);
                }
            }
        }
    };
    ValidationService.prototype.calculateFreightTermsDeliveryTime = function (form, key, list) {
        // console.log(key);
        if (key === 'LEAD_TIME_SHIP' || key === 'LEAD_TIME_STORE' || key === 'TSC_RECEIVING'
            || key === 'TOTAL_DELIVERY_TIME' || key === 'TIME_TO_DESTINATION') {
            var freightFormFields = form.get('FRT_TERMS_LEAD_TIME');
            if (freightFormFields != null) {
                // console.log('freightFormFields', freightFormFields);
                this.updateFreightFormFields(form, freightFormFields.value, list);
            }
        }
        else if (key === "VA_ADDRESS_LINE1" || key === "VA_ADDRESS_LINE2" || key === "VA_CITY" || key === "VA_STATE" || key === "VA_COUNTRY" || key === "VA_ZIP") {
            form.get('VA_ADDRESS_LINE1').setErrors(null);
            form.get('VA_ADDRESS_LINE2').setErrors(null);
            form.get('VA_CITY').setErrors(null);
            form.get('VA_STATE').setErrors(null);
            form.get('VA_COUNTRY').setErrors(null);
            form.get('VA_ZIP').setErrors(null);
        }
        else if (key === "SA_ADDRESS_LINE1" || key === 'SA_ADDRESS_LINE2' || key === 'SA_CITY' || key === 'SA_STATE' || key === 'SA_COUNTRY' || key === 'SA_ZIP') {
            form.get('SA_ADDRESS_LINE1').setErrors(null);
            form.get('SA_ADDRESS_LINE2').setErrors(null);
            form.get('SA_CITY').setErrors(null);
            form.get('SA_STATE').setErrors(null);
            form.get('SA_COUNTRY').setErrors(null);
            form.get('SA_ZIP').setErrors(null);
        }
        else if (key === "RA_ADDRESS_LINE1" || key === "RA_ADDRESS_LINE2" || key === "RA_CITY" || key === "RA_STATE" || key === "RA_COUNTRY" || key === "RA_ZIP") {
            form.get('RA_ADDRESS_LINE1').setErrors(null);
            form.get('RA_ADDRESS_LINE2').setErrors(null);
            form.get('RA_CITY').setErrors(null);
            form.get('RA_STATE').setErrors(null);
            form.get('RA_COUNTRY').setErrors(null);
            form.get('RA_ZIP').setErrors(null);
        }
        //this.form.setErrors(null);
        //console.log("form state " + this.form.valid);
    };
    // TIN validation
    // validations for Dashboard/Maintenance
    // 
    // address validation
    ValidationService.prototype.validateAddresses = function (addresses, form) {
        var addressList = [];
        // get unique address types
        var roles = [];
        var _loop_2 = function (a) {
            var found = roles.find(function (r) { return r === a.subtitle; });
            if (!found) {
                roles.push(a.subtitle);
            }
        };
        for (var _i = 0, addresses_1 = addresses; _i < addresses_1.length; _i++) {
            var a = addresses_1[_i];
            _loop_2(a);
        }
        var _loop_3 = function (r) {
            var currentAddress = addresses.filter(function (c) { return (c.subtitle && c.subtitle === r); });
            if (currentAddress && currentAddress.length > 0) {
                var address = {};
                for (var _i = 0, currentAddress_1 = currentAddress; _i < currentAddress_1.length; _i++) {
                    var field = currentAddress_1[_i];
                    // this.vendorAddressesFields.push(field);
                    address.title = field.subtitle;
                    // console.log(field, field.label);
                    switch (field.label) {
                        case 'Address1':
                            address.address1 = field.value;
                            break;
                        case 'Address2':
                            address.address2 = field.value;
                            break;
                        case 'State':
                            address.state = field.value;
                            break;
                        case 'City':
                            address.city = field.value;
                            break;
                        case 'Country':
                            address.country = field.value;
                            break;
                        case 'ZIP Code':
                            address.zipcode = field.value;
                            break;
                        case 'ZIP':
                            address.zipcode = field.value;
                            break;
                        case 'Phone No':
                            address.phoneNo = field.value;
                            break;
                        case 'Fax':
                            address.fax = field.value;
                            break;
                        case 'Website':
                            address.website = field.value;
                            break;
                        default:
                            address[field.label] = field.value;
                    }
                }
                addressList.push(address);
            }
        };
        // now, format the addressList request to validation service
        for (var _a = 0, roles_1 = roles; _a < roles_1.length; _a++) {
            var r = roles_1[_a];
            _loop_3(r);
        }
        console.log('send to validation', addressList);
        var validateList = [];
        // look for changes
        for (var _b = 0, addressList_1 = addressList; _b < addressList_1.length; _b++) {
            var add = addressList_1[_b];
            // console.log('address:', add);
            // only validate US addresses
            if (add && (add.country === 'US' || add.country === 'USA')) {
                validateList.push(this.addr.verifyAddress(add).catch(function (e) { return of(e); }));
                console.log(add, validateList);
            }
            else {
                validateList.push(of('skip'));
            }
        }
        // make all requests, then return results to modal
        var modalDisplay = [];
        if (validateList && validateList.length > 0) {
            return forkJoin(validateList).pipe(map(function (data) {
                console.log(data);
                if (data && data.length > 0) {
                    var _loop_4 = function (i) {
                        var valid = true;
                        console.log(addressList[i], validateList[i], data[i]);
                        if (data[i] !== 'skip') {
                            Object.keys(addressList[i]).forEach(function (k) {
                                console.log(k, addressList[i][k]);
                                //  Object.keys(validateList[i]).forEach(k => {
                                //  console.log(k, validateList[i][k]);
                                if (data[i] && k !== 'title') {
                                    console.log(k, data[i][k], addressList[i][k]);
                                    if (addressList[i][k] !== data[i][k]) {
                                        valid = false;
                                    }
                                }
                                else if (!data[i] || data[i] === null || (data[i].value && data[i].value.status)) {
                                    // address rejected!
                                    console.log(i + ': data is null');
                                    var nullAddr = {};
                                    nullAddr.address1 = 'No match found.';
                                    data[i] = nullAddr;
                                    valid = false;
                                }
                            });
                        }
                        else {
                            // skip this one
                            var skipAddr = {};
                            skipAddr.address1 = 'skipped validation';
                            data[i] = skipAddr;
                            valid = true;
                        }
                        console.log(i, valid, data[i]);
                        // if (!valid) {
                        var row = [addressList[i], data[i]];
                        // let row = [validateList[i], data[i]];
                        modalDisplay.push(row);
                        // }
                    };
                    for (var i = 0; i < data.length; i++) {
                        _loop_4(i);
                    }
                }
                // return array for modal to address section, pass to vendor-dashboard
                return modalDisplay;
            }));
        }
    };
    ValidationService.prototype.formatState = function (st, co) {
        // retrieve full state name from abbreviation
        // assume and validate country abbreviation
        var abbr = this.allCountries.find(function (c) { return c.ABBR === co; });
        if (!abbr) {
            // try by name
            abbr = this.allCountries.find(function (c) { return c.NAME === co; });
        }
        if (abbr && abbr.ABBR) {
            co = abbr.ABBR;
        }
        var state = this.allStates.find(function (s) { return s.COUNTRY === co && s.ABBR == st; });
        if (state) {
            st = state.NAME; // full state name
        }
        return st;
    };
    ValidationService.prototype.formatCountry = function (abbr) {
        // retrieve name from abbreviation
        var country = this.allCountries.find(function (c) { return c.ABBR === abbr; });
        if (!country) {
            country = this.allCountries.find(function (c) { return c.NAME === abbr; });
        }
        if (country && country.NAME) {
            abbr = country.NAME;
        }
        return abbr;
    };
    ValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(i0.inject(i1.AddressService)); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
