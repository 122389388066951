import { Injectable } from '@angular/core';
import { UserService } from './user.service';

declare var gtag: Function;
declare var Buffer;

@Injectable()
export class GoogleAnalyticsService {

    public GAID = 'G-2DE6K4VVBT'; // GA4 tag; previous Universal Analytics tag 'UA-114630687-1';
    public userConsent = false;

    constructor(
        private users: UserService,
    ) {}

    public track(url) {
        // gtag('config', this.GAID, {'page_path': url });
        gtag('config', this.GAID, {'user_id': this.users.getUniqueID(), 'page_path': url});
    }


    public sendEvent(   eventCategory: string,
                        eventAction: string,
                        eventLabel: string,
                        customVars: {} = {}  
                    ) {
        // include necessary custom dimensions, ignore others
        let event_vars = {
            'event_category': eventCategory, 
            'event_label': eventLabel,
            'time_stamp': Math.floor(Date.now() / 1000) // number of seconds
        } 
            // get custom variables
        if (customVars && Object.keys(customVars).length > 0) {
            Object.assign(event_vars, customVars);
            // console.log('custom variables', customVars, event_vars);
            
        }
        console.log('GA4 event', eventAction, event_vars);
        gtag('event', eventAction, event_vars);
    }

    public setUser(user_id) {
        gtag('config', this.GAID, {'user_id': user_id,'send_page_view': false});
        console.log('ga set user_id: ', user_id);
    }

    
    public sendEvent_deprecated(   eventCategory: string,
                        eventAction: string,
                        eventLabel: string,
                        customVars: {} = {}
                       ) {

                        let uname = Buffer.from(this.users.loggedInUser.userName).toString('base64');
                        let event_vars = {
                            'event_category': eventCategory, 
                            'event_label': eventLabel,
                            'session_id': this.users.getSessionId(),
                            'user_name_enc': uname,
                            'req_url': location.href,
                            'time_stamp': Math.floor(Date.now() / 1000)
                        };
                        // get custom variables
                        if (customVars && Object.keys(customVars).length > 0) {
                            Object.assign(event_vars, customVars);
                            // console.log(event_vars);
                        }
        gtag('event', eventAction, event_vars);
        console.log('sent event:', eventAction, event_vars);
    }



    public setUser_deprecated(user_id) {
        gtag('config', this.GAID, {'user_id': user_id,'send_page_view': false,
                                    'custom_map': {
                                        'dimension1': 'user_name_enc',
                                        'dimension2': 'record_id', // usually sku
                                        'dimension3': 'session_id',
                                        'dimension4': 'client_id',
                                        'dimension5': 'req_json', // request contents
                                        'dimension6': 'req_url', // requested from this page
                                        'metric1': 'load_time',
                                        'metric2': 'error_count',
                                        'metric3': 'error_code',
                                        'metric4': 'time_stamp'
                                        }
                                    });
        console.log('ga set user_id: ', user_id);
    }

    public setConsent(bool) {
        if (bool) {
            this.userConsent = true;
        } else {
            this.userConsent = false;
        }
        console.log('set user consent', this.userConsent);
    }

    public getConsent() {
        return this.userConsent;
    }

}


