<div class="card" >
<!--
  <div *ngIf="showMsg!=''" class="myDiv">
    <div class="row">
      <div class="col-xs-12">
        <p class="alert alert-success">
          <strong>{{showMsg}}</strong> Do you want to go Home page ?<button class="btn btn-success" (click)="clearMessage()">Yes</button><button class="btn btn-light" (click)="clearValues()">No</button>
        </p>
      </div>
    </div>
  </div>
-->
  <div class="card-header"><div class="myDiv" *ngIf="buyerComp=='TSC'">
    <div class="row">
      <div class="col-sm-9">
        <div class="intro">
          <p>Complete the form below to begin the process of creating a TSC Additional Vendor</p>
        </div>
      </div>
      <div class="col-sm-3 text-right">
          <img src="'../../assets/TSC JPG.jpg" alt="TSC"/>
      </div>
    </div>
  </div>


    <div class="myDiv" *ngIf="buyerComp=='PETSENSE'">
      <div class="row">
        <div class="col-sm-9">
          <div class="intro">
            <p>Complete the form below to begin the process of creating a PetSense Additional Vendor</p>
          </div>
        </div>
        <div class="col-sm-3 text-right">
            <img src="'../../assets/petsense_logo.png" alt="petSense"/>
        </div>
      </div>
    </div></div>

<div style="width:95%;margin:0 auto"> <!-- (ngSubmit)="save()" -->
  <!-- <form novalidate class="add-vendor-form"
        (ngSubmit)="validateAddress()" 
        [formGroup]="vendorForm"> -->
        <form novalidate class="add-vendor-form"
        [formGroup]="vendorForm">

        <div class="row">
            <div class="col-sm-5">
              <label for="parentVendorNumber">Reference Vendor Number
                  <span class="material-icons info-help"
                  [matTooltip]="'Enter vendor number to copy all vendor terms for the new vendor number.'"
                  [matTooltipPosition]="'right'"
                  [matTooltipShowDelay]="333"
                  [matTooltipHideDelay]="333"
                  [matTooltipClass]="{'help-tooltip': true}">help_outline</span> 
              </label>
              <input *ngIf="vendorForm.get('buyerCompany').value ==='TSC'" id="parentVendorNumber" name="parentVendorNumber" class="form-control"
                     formControlName="parentVendorNumber"  [ngClass]="{'is-invalid': (vendorForm.get('parentVendorNumber').touched || vendorForm.get('parentVendorNumber').dirty) && !vendorForm.get('parentVendorNumber').valid }" (change)="setAddVendorTypeTSC()"/>
              <input *ngIf="vendorForm.get('buyerCompany').value ==='PETSENSE'" id="parentVendorNumberPTS" name="parentVendorNumber" class="form-control"
                     formControlName="parentVendorNumber"  [ngClass]="{'is-invalid': (vendorForm.get('parentVendorNumber').touched || vendorForm.get('parentVendorNumber').dirty) && !vendorForm.get('parentVendorNumber').valid }" (change)="setAddVendorTypePTS()"/>
              <span class="invalid-feedback">
                  <span *ngIf="vendorForm.get('parentVendorNumber').errors?.required">
                    Please enter Vendor Number.
                  </span>
                  <span *ngIf="vendorForm.get('parentVendorNumber').errors?.minlength">
                    Vendor Number must be 6 digits.
                  </span>
                <span *ngIf="vendorForm.get('parentVendorNumber').errors?.maxlength">
                    Vendor Number must be 6 digits.
                  </span>
                  <span *ngIf="vendorForm.get('parentVendorNumber').errors?.incorrect">
                      Reference Vendor Number must be a current active vendor.
                    </span>
                </span>
            </div>
          </div>

        <div *ngIf="vendorForm.get('buyerCompany').value ==='TSC'">
            <div class="row">
              <div class="col-sm-5">
                <label for="vendorType">Additional Vendor Number Type</label>
                <select class="form-control" name="vendorType" required id="vendorType"
                        formControlName="vendorType">
                  <option *ngIf="this.domesticVendorType">Domestic</option>
                  <option *ngIf="this.dropshipVendorType">Dropship</option>
                  <option *ngIf="this.dropshipVendorType">OnlineSpecialBuy</option>
                  <option *ngIf="this.importVendorType">Import</option>
                  <option *ngIf="this.importVendorType">LiveGoods</option>
                  <option *ngIf="this.importVendorType">Hay</option>
                </select>
              </div>
            </div>
        </div>
    
        <div *ngIf="vendorForm.get('buyerCompany').value ==='PETSENSE'">
          <div class="row">
            <div class="col-sm-6">
              <label for="vendorType1">Additional Vendor Number Type</label>
              <select class="form-control" name="vendorType"  id="vendorType1"
                      formControlName="vendorType">
                <option *ngIf="this.manuVendorType" value="PTSManufacturer">Manufacturer</option>
                <option *ngIf="this.distributorVendorType" value="PTSDistributor">Distributor</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <label>Buyer Id</label>
            <ng-container *ngIf="vendorForm.get('parentVendorNumber').pristine || vendorForm.get('parentVendorNumber').dirty && countBuyers(vendorForm.get('vendorType').value) > 0">
              <select class="form-control" id="buyer" formControlName="buyer" name="buyer" required>
                  <ng-container *ngFor="let buyerinfo of buyerData; let i = index">
                      <option *ngIf="buyerinfo.VendorType && buyerinfo.VendorType === vendorForm.get('vendorType').value"
                              value={{i}}>{{buyerinfo.BuyerID}}&nbsp;{{buyerinfo.FirstName}}&nbsp;{{buyerinfo.LastName}}
                      </option>
                  </ng-container>
              <!-- <option [ngValue]="buyerinfo.BuyerID" *ngFor="let buyerinfo of buyerData">{{buyerinfo.BuyerID}}</option> -->
              </select>
            </ng-container>

            <ng-container *ngIf="vendorForm.get('parentVendorNumber').dirty && countBuyers(vendorForm.get('vendorType').value) === 0">
             <select class="form-control" disabled required></select>
              <span class="text-danger">
                    No buyers are found for {{ vendorForm.get('vendorType').value }}.
              </span>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <label for="company">Additional Vendor Name</label>
            <input type="text" id="company"  name="company" class="form-control" readonly
                   formControlName="company" />
          </div>
        </div>


      
    <!--
      <div class="row">
      <div class="col-sm-5">
        <label for="company">Additional Vendor Name</label>
        <input type="text" id="company"  name="company" class="form-control"
               formControlName="company" [ngClass]="{'is-invalid': (vendorForm.get('company').touched || vendorForm.get('company').dirty) && !vendorForm.get('company').valid }" />
        <span class="invalid-feedback">
            <span *ngIf="vendorForm.get('company').errors?.required">
              Please enter your Vendor Name.
            </span>
            <span *ngIf="vendorForm.get('company').errors?.minlength">
              Vendor Name must be 6 Characters.
            </span>
          </span>
      </div>
    </div>
     
    <div class="row">
      <div class="col-sm-5">
        <label for="userName">User Name/Email Address</label>
        <input type="email" id="userName" class="form-control" name="userName"
               formControlName="email" [ngClass]="{'is-invalid': (vendorForm.get('email').touched || vendorForm.get('email').dirty) && !vendorForm.get('email').valid }" />

        <span class="invalid-feedback">
            <span *ngIf="vendorForm.get('email').errors?.required">
              Please enter your User Name/Email Address.
            </span>
            <span *ngIf="vendorForm.get('email').errors?.minlength">
              User Name must be 6 Characters.
            </span>
          </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5">
        <label for="firstName">First Name</label>
        <input id="firstName" name="firstName" class="form-control"
               formControlName="firstName" pattern="[A-Za-z]{0,45}" [ngClass]="{'is-invalid': (vendorForm.get('firstName').touched || vendorForm.get('firstName').dirty) && !vendorForm.get('email').valid }" />
        <span class="invalid-feedback">
            <span *ngIf="vendorForm.get('firstName').errors?.required">
              Please enter your First Name.
            </span>
            <span *ngIf="vendorForm.get('firstName').errors?.minlength">
              First Name must be 6 Characters.
            </span>
          </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5">
        <label for="lastName">Last Name</label>
        <input id="lastName" name="lastName" class="form-control"
               formControlName="lastName" pattern="[A-Za-z]{0,45}" [ngClass]="{'is-invalid': (vendorForm.get('lastName').touched || vendorForm.get('lastName').dirty) && !vendorForm.get('lastName').valid }" />
        <span class="invalid-feedback">
            <span *ngIf="vendorForm.get('lastName').errors?.required">
              Please enter your Last Name.
            </span>
            <span *ngIf="vendorForm.get('lastName').errors?.minlength">
              Last Name must be 6 Characters.
            </span>
          </span>
      </div>
    </div>
-->
    
    <div class="row">
        <div class="col-sm-5">
          <label for="AdditionalVendorReason">Reason for Additional Vendor Number</label>
          <input type="text" id="AdditionalVendorReason"  name="AdditionalVendorReason" class="form-control" maxlength="128"
                 formControlName="AdditionalVendorReason" pattern="[0-9A-Za-z ]{0,128}" [ngClass]="{'is-invalid': (vendorForm.get('AdditionalVendorReason').touched || vendorForm.get('AdditionalVendorReason').dirty) && !vendorForm.get('AdditionalVendorReason').valid }" />
          <span class="invalid-feedback">
          <span *ngIf="vendorForm.get('parentVendorNumber').errors?.required">
            Please enter the reason for additional vendor.
          </span>
        <span *ngIf="vendorForm.get('parentVendorNumber').errors?.maxlength">
            Maximum 128 characters are allowed.
          </span>
        </span>
        </div>
      </div>
      

        <div class="row label-row">
          <div class="col-sm-8">
            <label>Shipping Address for Additional Vendor Number:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-9">
            <div class="form-row input">
              <div class="col">
                <input class="form-control" type="text" id="addressLine1" name="addressLine1" (input)="fieldCheck()" placeholder="Street Address" formControlName="addressLine1">
              </div>
              <div class="col">
                <input class="form-control"  type="text" id="addressLine2" name="addressLine2" (input)="fieldCheck()" placeholder="Apt, Suite, Bldg, Gate Code. (optional)" formControlName="addressLine2">
              </div>
            </div>
          </div>
        </div>

      <div class="row">
          <div class="col-sm-9">
            <div class="form-row input">
             <div class="col">
                <label class="placeholder" *ngIf="!vendorForm.get('country').value || vendorForm.get('country').value==''">Country</label>
                <select  class="form-control" name="country" formControlName="country" (change)="setCountry(vendorForm.get('country').value)">
                  <option *ngFor="let c of countryList" [value]="c.NAME">{{c.ABBR}} {{c.NAME}}</option>
                </select>
              </div>
              <div class="col">
                <label class="placeholder" *ngIf="!vendorForm.get('state').value || vendorForm.get('state').value==''"> State</label>
                <select  class="form-control" [disabled]="!stateList || stateList.length < 2" name="state" (input)="fieldCheck()" class="form-control" formControlName="state" >
                  <option *ngFor="let s of stateList" [value]="s.NAME">{{s.NAME}}</option>
                </select>
              </div>

              <div class="col">
                <input   name="city" class="form-control" (input)="fieldCheck()" placeholder="City" formControlName="city" maxlength="40" pattern="[A-Za-z ]{1,40}"
                         oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                         oninput="setCustomValidity('')">
              </div>

              <div class="col">
                <input  type="text"  class="form-control" name="ZipCode" (input)="fieldCheck()" placeholder="Zip Code"
                        formControlName="zipCode" pattern="[A-Z0-9 -]{5,10}"
                        oninvalid="setCustomValidity('must be a 5-digit number')"
                        oninput="setCustomValidity('')"> <!-- [required]="vendorForm.get('addressLine1').value && vendorForm.get('addressLine1').value!==''" -->
              </div>
            </div>
          </div>
        </div>

<!-- remove remittance address  -->
    <div class="row label-row">
      <div class="col-sm-12">
        <label for="addressLine1">Additional Vendor Remittance Address:</label> <input [checked]="chkboxChecked" class="form-check-input" type="checkbox" (click)="copyShipperAddress();setChecked()">Same as Shipping Address
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <div class="form-row input">
          <div class="col">
            <input class="form-control" type="text" id="remitAddressLine1" name="remitAddressLine1" (input)="fieldCheckRemit()" placeholder="Street Address" formControlName="remitAddressLine1">
          </div>
          <div class="col">
            <input class="form-control"  type="text" id="remitAddressLine2" name="remitAddressLine2" (input)="fieldCheckRemit()" placeholder="Apt, Suite, Bldg, Gate Code. (optional)" formControlName="remitAddressLine2">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-9">
        <div class="form-row input">
          <div class="col">
            <label class="placeholder" *ngIf="!vendorForm.get('remitCountry').value || vendorForm.get('remitCountry').value==''">Country</label>
            <select  class="form-control" name="remitCountry" formControlName="remitCountry" (change)="setCountry(vendorForm.get('remitCountry').value, true)">
              <option *ngFor="let c of countryList" [value]="c.NAME">{{c.ABBR}} {{c.NAME}}</option>
            </select>
          </div>
          <div class="col">
            <label class="placeholder" *ngIf="!vendorForm.get('remitState').value || vendorForm.get('remitState').value==''"> State</label>
            <select  class="form-control" [disabled]="!remitStates || remitStates.length < 2"  name="remitState" class="form-control" (input)="fieldCheckRemit()" formControlName="remitState" > <!-- [required]="stateList && stateList.length > 1" -->
              <option *ngFor="let s of remitStates" [value]="s.NAME">{{s.NAME}}</option>
            </select>
          </div>

          <div class="col">
            <input   name="city" class="form-control" (input)="fieldCheckRemit()" placeholder="City" formControlName="remitCity" maxlength="40" pattern="[A-Za-z ]{1,40}"
                     oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                     oninput="setCustomValidity('')">
          </div>

          <div class="col">
            <input  type="text"  class="form-control" name="remitZipCode" class="form-control" (input)="fieldCheckRemit()" placeholder="Zip Code"
                    formControlName="remitZipCode" pattern="[A-Z0-9 -]{5,10}"
                    oninvalid="setCustomValidity('must be a 5-digit number')"
                    oninput="setCustomValidity('')">
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="vendorForm.get('vendorType').value ==='Dropship'">
        <div class="col-sm-8">
          <label class="form-check-label">Does the vendor have online RTV exceptions?</label>
        </div>
        <div class="col-sm-4">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="isRTVExceptions" name="isRTVExceptions" id="rtvExceptionYes" value="Y" required>
            <label class="form-check-label" for="rtvExceptionYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="isRTVExceptions" name="isRTVExceptions" id="rtvExceptionNo" value="N" required>
            <label class="form-check-label" for="rtvExceptionNo">No</label>
          </div>
        </div>
   
    </div>



    <div class="row">
        <div class="col-sm-8">
            <label class="form-check-label">
              Should the Additional Vendor Number have the same terms as the Reference Vendor Number?
                    <span class="material-icons info-help"
                    [matTooltip]="'Indicate if the additional vendor number should have the same freight terms, vendor funding, payment terms, etc. as the reference vendor'"
                    [matTooltipPosition]="'right'"
                    [matTooltipShowDelay]="333"
                    [matTooltipHideDelay]="333"
                    [matTooltipClass]="{'help-tooltip': true}">help_outline</span> 
              </label>
          </div>
          <div class="col-sm-4">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="isTermsRemainSame" id="isTermsRemainSameYes" value="Y" required
                      formControlName="isTermsRemainSame">
                <label class="form-check-label" for="isTermsRemainSameYes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="isTermsRemainSame" id="isTermsRemainSameNo" value="N" required
                      formControlName="isTermsRemainSame">
                <label class="form-check-label" for="isTermsRemainSameNo">No</label>
            </div>
          </div>
    </div>

    <div  *ngIf="vendorForm.get('isTermsRemainSame').value =='N'">
      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Same Vendor funding?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameVendorFunding" id="sameVendorFundingYes" value="Y" required
                                       formControlName="sameVendorFunding">
                  <label class="form-check-label" for="sameVendorFundingYes">Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameVendorFunding" id="sameVendorFundingNo" value="N" required
                                       formControlName="sameVendorFunding">
                  <label class="form-check-label" for="sameVendorFundingNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div *ngIf="vendorForm.get('sameVendorFunding').value=='N'">
        <div class="row">
          <div class="col-sm-5">
            <label for="VendorFundingClarify">If not, please clarify:</label>
            <input type="text" id="VendorFundingClarify"  name="VendorFundingClarify" class="form-control"  maxlength="45" required
                   formControlName="VendorFundingClarify">
          </div>
        </div></div>



      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Same Payment Terms?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="samePaymentTerms" id="samePaymentTermsYes" value="Y" required
                                       formControlName="samePaymentTerms">
                  <label class="form-check-label" for="samePaymentTermsYes">Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="samePaymentTerms" id="samePaymentTermsNo" value="N" required
                                       formControlName="samePaymentTerms">
                  <label class="form-check-label" for="samePaymentTermsNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Same Freight Terms and Lead Times?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameFreightTerms" id="sameFreightTermsYes" value="Y" required
                                       formControlName="sameFreightTerms">
                  <label class="form-check-label" for="sameFreightTermsYes">Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameFreightTerms" id="sameFreightTermsNo" value="N" required
                                       formControlName="sameFreightTerms">
                  <label class="form-check-label" for="sameFreightTermsNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <!-- <div class="row">
        <div class="col-sm-7">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Same Lead Time ?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameLeadTime" id="sameLeadTimeYes" value="Y" required
                                       formControlName="sameLeadTime">
                  <label class="form-check-label" for="sameLeadTimeYes">Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="sameLeadTime" id="sameLeadTimeNo" value="N" required
                                       formControlName="sameLeadTime">
                  <label class="form-check-label" for="sameLeadTimeNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Any Contact Changes?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio"  name="contactchanges" value="Y" required
                                       formControlName="contactchanges">
                  <label class="form-check-label" >Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio"   name="contactchanges" value="N" required
                                       formControlName="contactchanges">
                  <label class="form-check-label" >No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Any RTV code changes?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio"  name="isRTVChanges" value="Y" required
                                       formControlName="isRTVChanges">
                  <label class="form-check-label" >Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio"  name="isRTVChanges" value="N" required
                                       formControlName="isRTVChanges">
                  <label class="form-check-label" >No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <table width="80%">
              <tr>
                <td width="40%"><label class="form-check-label">Is this vendor Scan-based?
                </label></td>
                <td width="20%"><input class="form-check-input" type="radio" name="isVendorScanBased" value="Y" required
                                       formControlName="isVendorScanBased">
                  <label class="form-check-label" >Yes</label></td>
                <td width="20%"><input class="form-check-input" type="radio"  name="isVendorScanBased" value="N" required
                                       formControlName="isVendorScanBased">
                  <label class="form-check-label" >No</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8">
        <div class="intro">
          <table width="80%">
            <tr>
              <td width="40%"><label class="form-check-label">Is Vendor setup for EDI?
              </label></td>
              <td width="20%"><input class="form-check-input" type="radio" name="isEDISetup"  value="Y" required
                                     formControlName="isEDISetup">
                <label class="form-check-label">Yes</label></td>
              <td width="20%"><input class="form-check-input" type="radio" name="isEDISetup"  value="N" required
                                     formControlName="isEDISetup">
                <label class="form-check-label">No</label></td>
            </tr>
          </table>
        </div></div></div>


    <!--</div> -->

    <div class="form-group row mb-2">
      <div class="col-sm-12 text-center">
        <button class="btn btn-primary"
                type="submit"
                style="width:80px"
                [disabled]="!vendorForm.valid" (click)="ValidateAdditionalVendorForms()">Save
        </button>
        <!--
        <button class="btn btn-primary"
                type="submit"
                style="width:80px"
                [disabled]="!vendorForm.valid">Save
        </button>
        -->
      </div>
    </div>
  </form>
</div>
</div>

<app-modal #modalMessage>
  <div class="app-modal-header">
      {{ modalContent.header }}
  </div>
  <div class="app-modal-body">
          {{ modalContent.body }}
  </div>
  <div class='app-modal-footer'>
          {{ modalContent.footer }}
          <button *ngIf="!isSuccess"class="btn btn-primary modal-btn" (click)="modalMessage.hide()">OK</button>
          <button *ngIf="isSuccess" class="btn btn-primary modal-btn" (click)="goToPage('/')">OK</button>
  </div>
</app-modal>

<app-modal #modalValidateAddress class="address-validation">
  <div class="app-modal-header">
    <h4 class="lead">Verify Address</h4>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-sm">
        <p class="text-secondary">Entered Address</p>
      </div>
      <div class="col-sm">
        <p class="text-secondary">Suggested Address</p>
      </div>
    </div>
      <ng-container *ngFor="let address of addressData; let y = index">
         <div *ngIf="address[1].address1 !== 'skipped validation'" style="margin-bottom:20px;" class="btn-group no-gutters row" data-toggle="buttons">  
          <mat-radio-group class="col-sm-12">
            <div class="row">
              <div class="col-sm">
                <mat-radio-button color="primary" [value]="address[0].title"
                                  (click)="selectAddressOption(y,0)">
                  <b>{{address[0]?.title}}</b>
                </mat-radio-button>
                <div>
                  {{address[0]?.address1}}
                  {{address[0]?.address2}}
                  {{address[0]?.city}}
                  {{address[0]?.state}}
                  {{address[0]?.zipcode}}
                </div>
              </div>
              <div class="col-sm">
                <mat-radio-button color="primary" [value]="'Suggested ' + address[0].title"
                                  (click)="selectAddressOption(y,1)" [disabled]="address[1].address1 === 'No match found.'">
                  <b>{{address[0]?.title}}</b>
                </mat-radio-button>
                <div>
                  {{address[1]?.address1}}
                  {{address[1]?.address2}}
                  {{address[1]?.city}}
                  {{address[1]?.state}}
                  {{address[1]?.zipcode}}
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </ng-container>
  </div>
  <div class='app-modal-footer'>
      <button class="btn btn-outline-secondary" (click)="submitted=false;modalValidateAddress.hide()">Cancel</button>&nbsp;<button class="btn btn-primary" [disabled]="selectedAddressList?.length !== addressData?.length" (click)="selectAddress()">OK</button>
    </div>
</app-modal>

<div id="myModal" class="modal">
  <div class="modal-content">
    <div class="app-modal-header">
      <h4 class="lead">Vendor already exists</h4>
    </div>
    <div class="app-modal-body">
      <br>
      <h2>
        {{tableData}}
      </h2>
                  <thead>
                  <tr>
                    <th>Vendor ID</th>
                    <th>Vendor Name</th>
                    <th>Vendor Type</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>ZipCode</th>
                    <th>Vendor Status</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of responseData">
                      <td>{{data.VendorID}}</td>
                      <td>{{data.CompanyName}}</td>
                      <td>{{data.VendorType}}</td>
                      <td>{{data.SACity}}</td>
                      <td>{{data.SAState}}</td>
                      <td>{{data.SACountry}}</td>
                      <td>{{data.SAZip}}</td>
                      <td>{{data.IsVendorActive}}</td>
                    </tr>
                  </tbody>
                <mat-form-field style="min-width: 350px;"> 
                  <mat-label>Reason (required)</mat-label>
                  <textarea #reasonTextRef matInput [(ngModel)]="reasonText" placeholder="Enter reason text to continue if you choose yes (minimum 10 characters)"
                        cdkAutosizeMinRows ="2"
                       cdkAutosizeMaxRows="5"
                       pattern="[0-9A-Za-z ]{0,128}"
                       maxlength="128" (ngModelChange)="checkReasonTextLength($event)"></textarea>
                 </mat-form-field>
    </div>
    <div class='app-modal-footer'>
      <div style="text-align: center">
        <br>
        <button class="btn btn-outline-secondary" (click)="cancel()">No</button>&nbsp;
        <button class="btn btn-primary" [disabled]="reason" (click)="proceed()">Yes</button>
      </div>
    </div>
  </div>
</div>