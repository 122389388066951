<div class="container">
  <form #vacform="ngForm">
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-9">
          <p *ngIf="!vac.getRevisionStatus()">Complete the form below to begin the process of creating a Vendor Allowance Confirmation (VAC).</p>
          <p *ngIf="vac.getRevisionStatus()">Complete the form below to modify a Vendor Allowance Confirmation (VAC).</p>
        </div>    
        <div class="col-sm-3">
          <div *ngIf="buyerCompany=='PETSENSE'" class="logo pts-logo">
            <img src="'../../assets/petsense_logo.png" alt="petSense"/>
          </div>
          <div *ngIf="buyerCompany=='TSC'" class="logo tsc-logo">
            <img src="'../../assets/TSC JPG.jpg" alt="TSC"/>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="buyerId" class="col-sm-2">Buyer</label>
        <div class="col-sm-6">
          <select class="form-control" name="buyerId" required [(ngModel)]="selectedBuyerID">
            <option value="" disabled [selected]="!selectedBuyerID || selectedBuyerID === ''">Select a Buyer</option>
            <ng-container *ngFor="let buyer of vac.vacBuyer"> <!-- *ngIf="buyer.CompanyName === buyerCompany || buyerCompany === 'PETSENSE' && buyer.CompanyName === 'PTS'" -->
              <option *ngIf="buyer.CompanyName === buyerCompany || buyerCompany === 'PETSENSE' && buyer.CompanyName === 'PTS'" [value]="buyer.BuyerID + '|' + buyer.Email">{{ buyer.BuyerID }} - {{ buyer.FirstName }} {{ buyer.LastName }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="vendorId" class="col-sm-2">Vendor</label>
        <div class="col-sm-3">
          <input class="form-control" required name="vendorId" [(ngModel)]="selectedVendorID" [ngClass]="{'cell-invalid':!vendorIsValid}" type="text" maxlength="6" pattern="[0-9]{6}" (change)="checkVendor($event.target.value)">
        </div>
        <div class="col-sm-5">
          <span class="vendor-name" [ngClass]="{'invalid':!vendorIsValid}"> {{ vendorMsg }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="vendorEmail" class="col-sm-2" title="You may enter a new contact person if necessary">Vendor Contact</label>
        <div class="col-sm-3">
          <input class="form-control" name="vendorEmail" type="email" pattern="^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$" [(ngModel)]="selectedVendor.Email" (change)="changeVendorContact($event.target.value)">
      </div>
      <div class="col-sm-5">
        <span class="vendor-name"> {{ selectedVendor?.FirstName }} {{ selectedVendor?.LastName }}</span>
      </div>
    </div> 
      <div class="form-group row" *ngIf="buyerCompany=='PETSENSE'">
        <label for="manufacturer" class="col-sm-2">Manufacturer</label>
        <div class="col-sm-6">
          <input class="form-control" name="manufacturer" [(ngModel)]="VAC.MfrName">
      </div>
    </div> 
    <div class="form-group row" *ngIf="buyerCompany=='PETSENSE' && VAC.MfrName && VAC.MfrName !== ''">
      <label for="manufacturerEmail" class="col-sm-2">Manufacturer Email*</label>
      <div class="col-sm-6">
        <input type="email" pattern="^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$" class="form-control" [required]="VAC.MfrName && VAC.MfrName !== ''" name="manufacturerEmail" [(ngModel)]="VAC.MfrEmail">
    </div>
  </div>   
    </div>
  </div>

  <div class="row vac-section promo">
    <div class="col-sm-12"><fieldset [disabled]="!selectedBuyerID || !selectedVendorID || !vendorIsValid">
      <h4>Promotion</h4>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="promoType">Promotion Type</label>
            <div class="col-sm-8">
              <select class="form-control" name="promoType" required [(ngModel)]="VAC.PromoType" (change)="setPromoDates(true)">
                <option value="" disabled selected>Select a Promotion Type</option>
                <option *ngFor="let type of promoTypes" [selected]="VAC.PromoType === type.PromotionType">{{ type.PromotionType }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="startDate">Begin Date</label>
            <div class="col-sm-8">
              <input class="form-control" name="startDate" required type="date" [(ngModel)]="BeginDate">
            </div>
          </div>
        </div>
        <div class="col-sm-6">
        <!--   <div class="form-group row" *ngIf="!selectedVendor || selectedVendor.VendorType !=='Domestic'"> -->
          <div class="form-group row" *ngIf="!showDropDown()">
            <label class="col-sm-4" for="promoDescription">Promotion Name</label>
            <div class="col-sm-8">
              <input class="form-control" type="text" name="promoDescription" required [(ngModel)]="VAC.PromoDescription">
            </div>
          </div>
          <!-- <div class="form-group row" *ngIf="selectedVendor && selectedVendor.VendorType ==='Domestic'"> -->
          <div class="form-group row" *ngIf="showDropDown()">
            <label class="col-sm-4" for="promoName">Promotion Name</label>
            <div class="col-sm-8">
              <select class="form-control" name="promoName" required [(ngModel)]="VAC.PromoDescription" (change)="setPromoDates()">
                <option value="" disabled [selected]="!VAC.PromoDescription || VAC.PromoDescription === ''">Select a Promotion</option>
                <option *ngFor="let promo of promosByType" [selected]="VAC.PromoDescription === promo.PromotionName">{{ promo.PromotionName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="endDate">End Date</label>
            <div class="col-sm-8">
              <input class="form-control" [ngClass]="{'invalid':dateInvalid()}" name="endDate" required type="date" [(ngModel)]="EndDate" min="{{BeginDate | date:'yyyy-MM-dd'}}" (change)="setPaymentDate()">
              <div *ngIf="dateInvalid()" class="small text-danger">The End Date must be after the Begin Date.</div>
            </div>
          </div>
        </div>
      </div>
    </fieldset></div>
  </div>
  <div class="row vac-section promo-details">
    <div class="col-sm-12">
      <h4>Promotion Details</h4>

      <div class="form-group row">
        <label class="col-sm-3">Choose Accounting Type: </label>
        <div class="col-sm-6">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="promounits" id="promoUnitsEst" value="Scandown" [checked]="showGrid" (change)="selectType(0)">
            <label class="form-check-label" for="promounitsEst">Scandown</label>
            <input class="form-check-input" *ngIf="enableScanDownPercentOption" type="radio" name="promounits" id="promoUnitsEstPercent" value="ScandownPercent" [checked]="showGridSDPercent" (change)="selectType(2)">
            <label class="form-check-label" *ngIf="enableScanDownPercentOption" for="promoUnitsEstPercent">Scandown %</label>
            <input class="form-check-input" type="radio" name="promounits" id="promounitsFlat" value="Flat Amount" [checked]="!showGrid && !showGridSDPercent" (change)="selectType(1)">
            <label class="form-check-label" for="promounitsFlat">Flat Amount</label>
          </div>
        </div>
      </div>
      <div class="section-est" [ngClass]="{'hidden':!showGrid && !showGridSDPercent}">
 
      <div class="row">
        <div class="col-sm-8">
          <div class="grid-wrapper">

            <hot-table #vacgrid
              [options]="showGridSDPercent === true ?gridOptionsPercentScanDown: gridOptions">
            </hot-table>
          
          </div>
        </div>
        <div class="col-sm-4">
          <div class="grid-btns">
              <div class="row">
                <div class="col-sm-12">
                  <p class="small-text">Use the form provided to add products to the promotion; you may directly edit the data in the form, copy/paste data from a spreadsheet, 
                    or download the provided template to format your data and upload it back to the form.
                  </p>
                </div>
                <div class="col-sm-12">
                  <button class="btn btn-success download-btn" (click)="downloadTemplate()">1. Download Template</button>
                </div>
                <div class="col-sm-12">
                  <div class="custom-file">
                    <input type="file" #sheetupload name="uploadedFile" class="custom-file-input" id="customFile" (change)="uploadTemplate($event)">
                    <label class="custom-file-label" for="customFile">{{ uploadFilename || '2. Upload Spreadsheet' }}</label>
                  </div>
                  <div *ngIf="sheetError && sheetError !== ''" class="tiny text-danger">{{ sheetError }}</div>
                </div>
              </div>
          </div>
        </div> 
      </div>

      <div class="form-group row">
        <label class="col-sm-2" for=""> {{showGridSDPercent === true?'Total Sales Dollars':'Estimated # of Units'}}</label>
        <div class="col-sm-2">
          <div class="underline-field">{{showGridSDPercent === true?(VAC.PromoDetails.EstimatedUnits| currency : 'USD' : 'symbol' : '1.2-2') :  (VAC.PromoDetails.EstimatedUnits | number:'1.0':'en-US') }}</div>

        </div>
        <label class="col-sm-2" for="">Estimated Amount</label>
        <div class="col-sm-2">
         <div class="underline-field">{{ VAC.PromoDetails.EstimatedAmt | currency : 'USD' : 'symbol' : '1.2-2' }}</div>

        </div>
        <label class="col-sm-2" for="" *ngIf="showRainChecks()">Rain Checks</label>
        <div class="col-sm-2" *ngIf="showRainChecks()">
          <div class="form-check form-check-inline">
            <input class="form-check-input" [required]="(showGrid||showGridSDPercent)" type="radio" name="rainchecks" id="rainchecksYes" value="Y" [(ngModel)]="rainChecks">
            <label class="form-check-label" for="rainchecksYes">Yes</label>
            <input class="form-check-input" [required]="(showGrid||showGridSDPercent)" type="radio" name="rainchecks" id="rainchecksNo" value="N" [(ngModel)]="rainChecks">
            <label class="form-check-label" for="raincheksNo">No</label>
          </div>
        </div>
      </div>

    </div>
    <div class="section-flat" [ngClass]="{'hidden':showGrid||showGridSDPercent}">
      <div class="form-group row">
        <label class="col-sm-2" for="flatDollarAmount">Flat $ Amount</label>
        <div class="col-sm-4"> <!-- (ngModelChange)="VAC.PromoDetails.FlatDolAmt=$event.toLocaleString()"  | currency : 'USD' : 'symbol' : '1.2-2' -->
          <input class="form-control" name="flatDollarAmount" [required]="!showGrid && !showGridSDPercent" type="text" pattern="[$]*[0-9,]+(\.\d{2})?" (change)="formatFlatAmt()" [(ngModel)]="formattedAmount">
        </div>
        <div class="col-sm-6">
          <p>per event or promotion</p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2" for="skuList">SKU List (comma-separated)</label>
        <div class="col-sm-10">
          <textarea class="form-control" name="skuList" rows="4" [(ngModel)]="VAC.PromoDetails.FlatDollarSkuList"></textarea>
        </div>
      </div>
    </div>
      <div class="form-group row instructions">
        <label class="col-sm-2" for="specialInstructions">Special Instructions</label>
        <div class="col-sm-10">
          <textarea class="form-control" name="specialInstructions" maxlength="256" rows="4" [(ngModel)]="VAC.PromoDetails.SpecialInstructions"></textarea>
          <div *ngIf="VAC && VAC.PromoDetails && VAC.PromoDetails.SpecialInstructions && VAC.PromoDetails.SpecialInstructions.length > 200" class="text-tiny text-danger text-right">{{ VAC?.PromoDetails?.SpecialInstructions.length }}/256 characters allowed.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row vac-section payment">
    <div class="col-sm-12">
      <h4>Payment</h4>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="paymentType">Payment Instructions</label>
            <div class="col-sm-8">
              <select class="form-control" name="paymentType" required [(ngModel)]="VAC.PromoDetails.PaymentType">
                <option>Debit</option>
                <option>Check</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="paymentDate">Initial Payment Date</label>
            <div class="col-sm-8">
              <input class="form-control" name="paymentDate" type="date" required [(ngModel)]="PaymentDate">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-4" for="paymentFrequency">Payment Frequency</label>
            <div class="col-sm-8">
              <select class="form-control" name="paymentFrequency" required [(ngModel)]="PaymentFrequency">
                <option>One-Time</option>
                <option>Monthly</option>
                <option>Quarterly</option>
                <option>Half-Yearly</option>
                <option>Yearly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="PaymentFrequency !== 'One-Time'">
          <div class="form-group row">
            <label class="col-sm-4" for="numberOfPayments">Number of Payments (includes initial)</label>
            <div class="col-sm-8">
              <input class="form-control" name="numberOfPayments" type="number" min="2" [required]="PaymentFrequency !== 'One-Time'" [(ngModel)]="PaymentNumber">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row vac-section submit">
    <div class="col-sm-12 text-center">
       <button [disabled]="!canSubmit(vacform)" (click)="submitVACform(modalVAC,$event)" class="btn btn-primary">Submit</button>&nbsp; <mat-spinner *ngIf="requestInProgress" [diameter]="18"></mat-spinner>
    </div>
  </div>
</form>
</div>

<app-modal #modalVAC>
  <div class="app-modal-header">
    <div [class]="'alert alert-' + modalContent.class">
      {{ modalContent.header }}
    </div>
  </div>
  <div class="app-modal-body" [innerHTML]="modalContent.body"></div>
  <div class='app-modal-footer'>
    {{ modalContent.footer }} <button class="btn btn-primary" [ngStyle]="{'display': isSuccess ? 'none' : 'block'}" (click)="modalVAC.hide()">OK</button>
    <button *ngIf="isSuccess" class="btn btn-primary" (click)="resetForm(modalVAC,vacform)">OK</button>
  </div>
</app-modal>