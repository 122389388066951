import { Component, OnInit } from '@angular/core';
import { RestService } from './../services/rest.service';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsService } from './../services/analytics.service';
import * as faq from './../../assets/faq.json';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public faqList:any; 

  constructor(
    public rest: RestService,
    public http: Http,
    public ga: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    /*
    this.http.get(environment.appurl + '/assets/faq.json').subscribe(
      data => {
        if(data && data.json()){
          for (let q of data.json()){
            this.faqList.push(q);
          }
        }
        console.log(data);
      }
    );
    */
   // import faq json instead of http get
    this.faqList = (faq as any).default;
    console.log(this.faqList);



  }

  toggleCollapse(id, e) {
    // toggle answer
    let q = document.getElementById(id);
    if (q.classList.contains('open')) {
      q.classList.remove('open');
      q.classList.add('closed');
    } else {
      q.classList.remove('closed');
      q.classList.add('open');
    }

    // toggle question state
    console.log(e.target);
    if(e.target.classList.contains('selected')){
      e.target.classList.remove('selected');
    } else {
      e.target.classList.add('selected');
      this.ga.track('/VendorPortal/faq/expand/' + id);
    }

  }



}
