import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { UserService } from "./services/user.service";
import { GoogleAnalyticsService } from "./services/analytics.service";
import { distinctUntilChanged } from "rxjs/operators";

import { VendorApiService } from './vendor-services/vendor-api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  showNav = true;
  showCow = false;
  guestURL = 'consent';
  pendingOnboarding = false;
  currentYear = Date.now();
  showVolRebate = false;
  showAddVendor = false;
  showDashboard = false;

  constructor(
    public users: UserService,
    public ga: GoogleAnalyticsService,
    public router: Router,
    public api: VendorApiService,private cookieService: CookieService
  ) {
    // this.users.setUser('2');
    let user = this.users.getUser(); // keycloak
    this.ga.setUser(this.users.getSessionId());
  }

  ngOnInit() {
    this.cookieService.deleteAll();
    this.router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((x: any) => {
        console.log('route subscribe', x);
        // refresh onboarding data
        if (x.urlAfterRedirects == '/') {
          console.log('refreshing onboarding data');
          this.getOnboardingData();
        }
        this.ga.track("/VendorPortal" + x.url);
      });

    // show maintenance page?
    if (this.showCow) {
      // make it moo
      setTimeout(this.showit, 1000);
    }

    if (this.isGuest()) {
      console.log("send guests to the agreement page");
      this.router.navigate([this.guestURL]);
    } else {
      this.detectGuest();
    }

    // check onboarding
    if(this.isVendorAdmin() || this.isVendor()) {
      // make call to the API with vendorinfo=N
      const json = {'Email':this.users.loggedInUser.userName,'VendorInfo':'N'};

      this.getOnboardingData(json);

      this.getVendorTypeForUser().subscribe(
        (data:any) => {
          console.log('setting vendor nav permissions', data);
          // show additional vendor, volume rebate
          if(data.ResponseData){ // data.VendorData in previous getVendorInfo
            let vendorTypeList = [];
            for (let vendor of data.ResponseData) {
                let vType = vendor.VendorType || '';
                // also add to user object
                vendorTypeList.push(vType);

              if (vType.toLowerCase().indexOf('placeholder') < 0) {
                  this.showDashboard = true;
                  this.showAddVendor = true;
                  this.showVolRebate = true;


              }
            }
            this.users.loggedInUser.vendorType = vendorTypeList.join(',');
          }
        }, error => {
          console.log(error);
        }
      );
    }

  }

  getOnboardingData(json?) {
    if (!json) {
      json = {'Email':this.users.loggedInUser.userName,'VendorInfo':'N'};
      this.pendingOnboarding = false;
    }
    this.api.requestData(json).subscribe(
      data => {
        if (data) {
          console.log(data);
          let vendorList = JSON.parse(JSON.stringify(data));
          // for each vendor id
          if(vendorList && vendorList.Data) {
            for (let v of vendorList.Data) {

              let tabList = v;

              if (tabList['OnboardingStatus'] === 'Invite Sent' ||
                  tabList['OnboardingStatus'] === 'Invite Accepted' ||
                  tabList['OnboardingStatus'] === 'Saved') {
                  this.pendingOnboarding = true;
              }

              console.log('show onboarding link', this.pendingOnboarding);

            }
          } else {
            console.log('api not updated', data);
          }
        }
      }
    ); 
  }

  toggleNav() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  showit() {
    const el = document.getElementById("moo");
    if (el.classList) {
      el.classList.add("visible");
    } else {
      el.className += " " + "visible";
    }
  }

  isVendor() {
    let v = true;
    if (
        this.isAdmin() ||
        this.isVendorAdmin() ||
        this.isGuest() ||
        this.isTSCBuyer() ||
        this.isPTSBuyer() ||
        this.isVMD()
      ){
        v = false;
      }
    
    return v;
  }

  isVendorAdmin() {
    return this.users.getUserRole() === "VendorAdmin";
  }

  isAdmin() {
    if  ( this.users.getUserRole() === "TSCAdmin" || this.users.getUserRole() == "VMD") {
          return true;
        }
    return false;
  }

  isTSCBuyer() {
    return this.users.getUserRole() === "TSC_Buyer";
  }

  isPTSBuyer() {
    return this.users.getUserRole() === "PTS_Buyer";
  }

  isGuest() {
    return this.users.getUserRole() === "Guest";
  }

  isProductContent() {
    return this.users.getUserRole() === 'Product Content';
  }

  isVMD() {
    return this.users.getUserRole() == 'VMD';
  }

  detectGuest() {
    if (this.users.loggedInUser.roleName) {
      if (this.users.loggedInUser.rolename === "Guest") {
        this.router.navigate([this.guestURL]);
      }
      // otherwise, if rolename isn't Guest, load the page as normal
    } else {
      // no role detected, try to get the role
      this.users.requestUserRoles(this.users.loggedInUser).subscribe(data => {
        if (data.json()) {
          const resp = data.json();
          if (resp.roleDetails && resp.roleDetails.toString() === "Guest") {
            console.log("send guests to the agreement page");
            // don't show nav menu, send guests directly to agreement URL
            this.router.navigate([this.guestURL], { skipLocationChange:true });
          }
        }
      });
    }
  }

  isOnboarding(){
    return this.pendingOnboarding;
  }

  showButton(button){
    switch(button) {
      case 'addvendor':
        return this.showAddVendor;
      case 'volrebate':
        return this.showVolRebate;
      case 'dashboard':
        return this.showDashboard;
      case 'onboarding':
        return this.pendingOnboarding;
    }
  }

  getVendorTypeForUser(){
    const json = {
      'email': this.users.loggedInUser.userName,
    }
    // return this.api.requestAddVendor(json);
    return this.api.requestAddVendor(json,'queryvendorinfoedi'); // return edi data
  }

}
