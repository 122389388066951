
<button mat-icon-button class="close-button" [mat-dialog-close]="onCloseDialogResponse">
  <mat-icon class="close-icon" color="secondary">close</mat-icon>
</button>
 <h1 mat-dialog-title> {{data.title}}</h1> 
<!-- <span mat-dialog-title  >{{data.title}}</span> -->

<div mat-dialog-content>
  <div>
    <p [innerHTML]="data.message"></p>
  </div>
  <div *ngIf="data.title === 'Continue?' ">
    <p *ngIf="errMsg.length>0" class="err-msg">{{errMsg}}</p>
     <mat-form-field *ngIf="data.showReasonText" style="min-width: 350px;"> 
      <mat-label>Reason</mat-label>
      <textarea #reasonTextRef matInput [(ngModel)]="reasonText" placeholder="Enter reason text to continue if you choose yes"
            cdkAutosizeMinRows ="2"
           cdkAutosizeMaxRows="5"
           pattern="[0-9A-Za-z ]{0,128}"
           maxlength="128" (ngModelChange)="checkReasonTextLength($event)"></textarea>
     </mat-form-field> 

  </div>
</div>
 <div mat-dialog-actions align='end'> 
  
   <div *ngIf="data.title === 'Continue?' ">
    <button mat-raised-button (click)="onYesBtnClick()"  class="yes-btn" cdkFocusInitial>YES</button>
    <button mat-raised-button (click)="onNoBtnClick()" class="No-btn">NO</button>
   </div>
   <div *ngIf= "data.title === 'Notification' ">
    <button mat-raised-button    (click)="notificationOk()"  class="yes-btn" cdkFocusInitial>Ok</button> 

   </div>
   
 </div> 