import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../model/dialog-data.model';

@Component({
  selector: 'app-volume-rebate-search',
  templateUrl: './volume-rebate-search.component.html',
  styleUrls: ['./volume-rebate-search.component.css']
})
export class VolumeRebateSearchComponent implements OnInit {
  public searchQuery = '';
  public isSearching = false;
  constructor(public dialogRef: MatDialogRef<VolumeRebateSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, ) {
      dialogRef.disableClose = true;
     }

  ngOnInit() {
    console.log("INPUT data from parent:",this.data);
  }

  searchVAC(){
    
  }

  onClose(){
    this.dialogRef.close();
  }

}
