import { HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http, Headers, ResponseContentType, Response } from '@angular/http';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

// import 'rxjs/add/operator/map';
import { map, filter, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare var gtag: Function;


@Injectable()
export class RestService {

  // private api = 'http://t1rabuy101:8080/VendorPortal/admin/';
  private api = environment.api;
  private vacapi = environment.vacapi;
  private vrapi = environment.vrapi;
  public GAID = 'UA-114630687-1';  // same as analytics service
  private aiAPI = environment.aiapi;

  constructor(
    private http: Http,
    // private http: HttpClient

  ) { }



  getReq(url,auth?){
    const service_name = url;
   /* if(url.includes("getAccessToken")){
      url = this.api + url;
    } else {
      url = this.api + url;
    }*/
    url = this.api + url;
    let headers = new Headers();
    // let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    if (auth){
       headers.append('Authorization', auth);
       // replace with auth interceptor
    }
    return this.http.get(url, {headers : headers}).pipe(map(
      data => {
        return data;
      }, error => {
        this.gaTrack('/VendorPortal/Error/' + service_name);
        return error;
      }
    ));
  }

  postReq(url,json, auth?){
    const service_name = url;
    /*(url.includes("AddressVerification")){
       url = this.api + url;
      } else if (url.indexOf('http') === -1) {
        url = this.api + url;
      } // test for absolute url */
    url = this.api + url;
      console.log('service name', service_name);
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('Accept','application/json');
    
      if (auth){
        //console.log(url,json,JSON.stringify(auth));
        headers.append('Authorization', auth);
       }
      console.log("headers are " ,headers, json);
      return this.http.post(url, json, {headers : headers}).pipe(map(
        data => {
          console.log("full data is ", data) ;
          return data;

        }, error => {
        console.log("error is ", error) ;
          this.gaTrack('/VendorPortal/Error/' + service_name);
          return error;
        }
      ));
    
  }

  vacReq(url,json,auth?) {
    let api = this.vacapi + url;
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    if (auth){
      //console.log(url,json,JSON.stringify(auth));
      headers.append('Authorization', auth);
    }
    return this.http.post(api, json, {headers : headers}).pipe(map(
      data => {
        console.log("data from vac req", data) ;
        return data;

      }, error => {
        console.log("error is ", error) ;
        this.gaTrack('/VendorPortal/Error/' + url);
        return error;
      }
    ));
  }


  postInviteReq(url,json, auth?){
    const service_name = url;
    /*(url.includes("AddressVerification")){
       url = this.api + url;
      } else if (url.indexOf('http') === -1) {
        url = this.api + url;
      } // test for absolute url */
    url = url;
    console.log('service name', service_name);
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    if (auth){
      //console.log(url,json,JSON.stringify(auth));
      headers.append('Authorization', auth);
    }
    console.log("headers are " ,headers, json);
    return this.http.post(url, json, {headers : headers}).pipe(map(
      data => {
        console.log("full data is ", data) ;
        return data;

      }, error => {
        console.log("error is ", error) ;
        this.gaTrack('/VendorPortal/Error/' + service_name);
        return error;
      }
    ));
  }


  bwReq(url, json) {
    const server = window.location.hostname;
    if (server.indexOf('localhost') === -1) {
      if(url.indexOf('http') === -1) {
        url = this.api + url;
      }
    } else {
      // url = '/bw/' + url;
      url = this.api + url;
    }
    console.log('accessing BW services at ', url);
    return this.http.post(url, json).pipe(map(
      data => {
        return data;
      }, error => {
        this.gaTrack('/VendorPortal/Error/' + url);
        return error;
      }
    ));
  }
  bwReqVendorInfo(url, json, auth?){
    const server = window.location.hostname;
    if (server.indexOf('localhost') === -1) {
      if(url.indexOf('http') === -1) {
        url = this.vrapi + url;
      }
    } else {
      // url = '/bw/' + url;
      url = this.vrapi + url;
    }
    let headers = new Headers();
    headers.append('Content-Type','application/json;charset=utf-8');
    headers.append('Accept', 'application/json');
    headers.append('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers.append('Pragma','no-cache');
    headers.append('Expires', '0');
    if (auth){
      //console.log(url,json,JSON.stringify(auth));
      headers.append('Authorization', auth);
    }
   // headers.append('Authorization', )

  
    console.log('accessing BW services at ', url);
    return this.http.post(url, json, {headers:headers}).pipe(map(
      data => {
        return data.json();
      }, error => {
        this.gaTrack('/VendorPortal/Error/' + url);
        return error;
      }
    ));

  }
  bwReqVRSubmit(url, json, auth?){
    const server = window.location.hostname;
    if (server.indexOf('localhost') === -1) {
      if(url.indexOf('http') === -1) {
        url = this.vrapi + url;
      }
    } else {
      // url = '/bw/' + url;
      url = this.vrapi + url;
    }
    let headers = new Headers();
    headers.append('Content-Type','application/json;charset=utf-8');
    headers.append('Accept', 'application/json');
    headers.append('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers.append('Pragma','no-cache');
    headers.append('Expires', '0');
    if (auth){
      //console.log(url,json,JSON.stringify(auth));
      headers.append('Authorization', auth);
    }
   // headers.append('Authorization', )

  
    console.log('accessing BW services at ', url);
    return this.http.post(url, json, {headers:headers}).pipe(map(
      data => {
        return data.json();
      }, error => {
        this.gaTrack('/VendorPortal/Error/' + url);
        return error;
      }
    ));

  }
  bwGet(url, auth?) {
    let headers = new Headers();
    headers.append('Content-Type','application/json;charset=UTF-8');
    headers.append('Accept','application/json')
  if (auth){
    headers.append('Authorization', auth);
  }
  const server = window.location.hostname;
    if (server.indexOf('localhost') === -1) {
      if(url.indexOf('http') === -1) {
        url = this.api + url;
      }
    } else {
      // url = '/bw/' + url;
      url = this.api + url;
    }
  return this.http.get(url,{headers: headers}).pipe(map(
      (resp:Response) => {
        let output = resp.json();
        console.log('get data from ' + url, output);
        return output;
      }, error => {
        console.log(error);
        this.gaTrack('/VendorPortal/Error/GET');
        return error;
      }
    ));
  }

  delReq(url, json, auth){
    const service_name = url;
    let headers = new Headers();
    headers = new Headers({'Authorization': auth, 'Content-Type':'application/json'});
    url = this.api + url;
    return this.http.delete(url, {headers:headers,body:json}).pipe(map(
      data => {
        return data;
      }, error => {
        this.gaTrack('/VendorPortal/Error/' + service_name);
        return error;
      }
    ));

  }

  fileDownload(url, json, auth?){

    let headers = new Headers();
    if (auth){
      headers.append('Authorization', auth);
    }
    // get mime type from file extension
    let mime = '';
    const file = json.value.split('.');
    const ext = file[file.length-1];
    // console.log(ext);
    switch(ext) {
      case 'doc':
      mime = 'application/msword';
      break;
      case 'docx':
      mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
      case 'xlsx':
      mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
      case 'xls':
      mime = 'application/vnd.ms-excel';
      break;
      case 'csv':
      mime = 'text/csv';
      break;
      case 'txt':
      mime = 'text/plain';
      break;
      case 'rtf':
      mime = 'application/rtf';
      break;
      case 'ppt':
      mime = 'application/vnd.ms-powerpoint';
      break;
      case 'pptx':
      mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      break;
      default:
      mime = 'application/pdf';
    }
    url = this.api + url;
    return this.http.post(url, json, {responseType: ResponseContentType.Blob}).pipe(map(
      (res) => {
        // console.log(res);
        return new Blob([res.blob()], {type: mime});
      },
      error => {
        console.log(error);
        return error;
      }
    ));
  }

  postForm(url,obj,auth?) {
    const service_name = url;
    let postReq = new FormData();

    let attachment = null;
    if(obj.file && obj.file.length > 0){
      attachment = obj.file.item(0);
    }

    let headers = new Headers();
    if (auth){
      headers.append('Authorization', auth);
    }

    // build formData req
    if (obj.id) {
      postReq.append('id', obj.id); // modify only, new will have no id
    }

    // user data
    if(obj.groupName && obj.groupName !== '') {
      postReq.append('groupName', obj.groupName);
    } else {
      if (obj.roleName && obj.roleName.indexOf('TSCAdmin') > -1){
        obj.groupName = 'TSCAdmin';
      } else if(obj.roleName && obj.roleName.indexOf('VendorAdmin') > -1){
        obj.groupName = 'VendorAdmin';
      }else if(obj.roleName && obj.roleName.indexOf('PTS_Buyer') > -1){
        obj.groupName = 'PTS_Buyer';
      }
      else if(obj.roleName && obj.roleName.indexOf('TSC_Buyer') > -1){
        obj.groupName = 'TSC_Buyer';
      }else {
        obj.groupName = 'Vendors';
      }
      postReq.append('groupName', obj.groupName);
    }
    if (obj.roleName && obj.roleName !== '') {
      postReq.append('roleName', obj.roleName);
    }

    if (obj.buyerId && obj.buyerId !=='') {
      postReq.append('buyerId', obj.buyerId);
    }
    if (obj.lob && obj.lob !=='') {
      postReq.append('lob', obj.lob);
    }

    if (obj.vendor && obj.vendor !== '') {
      postReq.append('vendor', obj.vendor);
    }

    if (obj.vendorType && obj.vendorType !== '') {
      postReq.append('vendorType', obj.vendorType);
    }

    if (obj.isEmailOnly) {
      postReq.append('isEmailOnly', 'true');
    }

    if(obj.vendorAttr && obj.vendorAttr !=='') {
      postReq.append('vendorAttr', obj.vendorAttr);
    }

    if(obj.authorName && obj.authorName !== ''){
      postReq.append('authorName', obj.authorName);
    }

    if(obj.lastModifiedBy && obj.lastModifiedBy !== ''){
      postReq.append('lastModifiedBy', obj.lastModifiedBy);
    } else {
      postReq.append('lastModifiedBy', '');
    }
    if (obj.expiryDate && obj.expiryDate !=='' && obj.expiryDate.toUpperCase().indexOf('INVALID') < 0) {
      postReq.append('expiryDate', obj.expiryDate);
    }

    // announcement data
    if(obj.title && obj.title !== '') {
      postReq.append('title', obj.title);
    }
    if(obj.bodyText && obj.bodyText !== '') {
      postReq.append('bodyText', obj.bodyText);
    }
    if(obj.publishDate && obj.publishDate !== '') {
      postReq.append('publishDate', obj.publishDate);
    }

    if(obj.announceLink && obj.announceLink !== '') {
      postReq.append('announceLink', obj.announceLink);
    }

    // event data
    if(obj.eventName && obj.eventName !== '') {
      postReq.append('eventName', obj.eventName);
    }
    if(obj.eventDesc && obj.eventDesc !== '') {
      postReq.append('eventDesc', obj.eventDesc);
    }
    if(obj.startDate && obj.startDate !== '') {
      postReq.append('startDate', obj.startDate);
    }
    if(obj.endDate && obj.endDate !== '') {
      postReq.append('endDate', obj.endDate);
    }
    if(obj.eventLink && obj.eventLink !== '') {
      postReq.append('eventLink', obj.eventLink);
    }
    if(obj.eventLocation && obj.eventLocation !== '') {
      postReq.append('eventLocation', obj.eventLocation);
    }

    if(obj.deleteInd && obj.deleteInd !== '') {
      postReq.append('deleteInd', obj.deleteInd);
    }
    if(obj.modifyDate && obj.modifyDate !== '') {
      postReq.append('modifyDate', obj.modifyDate);
    }

    if(obj.createDate && obj.createDate !== '') {
      postReq.append('createDate', obj.createDate);
    } else {
      postReq.append('createDate', '');
    }

    // attachment
    if (obj.file) {
      postReq.append('file', attachment, obj.file.name);
    }
    if(obj.fileName && obj.fileName !== '') {
      postReq.append('fileName', obj.fileName);
    }
    if(obj.docPath && obj.docPath !== '') {
      postReq.append('docPath', obj.docPath);
    }

    // send request
    url = this.api + url;

    return this.http.post(url, postReq, {headers: headers}).pipe(map(
      data => {
        console.log(data);
        return data;

      }, error => {
        console.log(error);
        this.gaTrack('/VendorPortal/Error/' + service_name);
        return error;
      }
    ));


  }

  postDoc(url, doc, auth?){
    const service_name = url;
    let docReq = new FormData();

    // console.log('FileList', doc.file);

    let uploadedFile = null;
    if(doc.file && doc.file.length > 0){
      uploadedFile = doc.file.item(0);
    }

    let headers = new Headers();
    if (auth){
      headers.append('Authorization', auth);
    }

    // build formData req
    if (doc.id) {
      docReq.append('id', doc.id); // modify only, new will have no id
    }
      docReq.append('name', doc.name);
      if(doc.groupName && doc.groupName !== '') {
        docReq.append('groupName', doc.groupName);
      }
      if (doc.roleName && doc.roleName !== '') {
        docReq.append('roleName', doc.roleName);
      }
      docReq.append('authorName', doc.authorName);
      docReq.append('createDate', doc.createDate);
      docReq.append('lastModifiedBy', doc.lastModifiedBy);
      docReq.append('modifyDate', doc.modifyDate);
      if (doc.categoryName && doc.categoryName !== '') {
        docReq.append('folderName', doc.categoryName);
      } else {
        docReq.append('folderName', 'All');
      }
      if (doc.file) {
        docReq.append('file', uploadedFile, doc.file.name);
      }

    url = this.api + url;

      return this.http.post(url, docReq, {headers: headers}).pipe(map(
        data => {
          console.log(data);
          return data;

        }, error => {
          console.log(error);
          this.gaTrack('/VendorPortal/Error/' + service_name);
          return error;
        }
      ));
    }

    gaTrack(url){
      gtag('config', this.GAID, {'page_path': url });
    }


}
