import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AddVendorBuyer } from '../modal/AddVendorBuyer';
import { VendorApiService } from '../vendor-services/vendor-api.service';
import { ValidationService } from '../vendor-services/validation.service';
import { GoogleAnalyticsService } from '../services/analytics.service';
import { newUserRequest, User, UserService } from '../services/user.service';
import { InviteService } from '../services/invite.service';
import { Router } from '@angular/router';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { PopupDialogService } from '../services/popup-dialog.service';
var AddVendorComponent = /** @class */ (function () {
    function AddVendorComponent(fb, api, users, inviteService, router, vs, ga, poupDialogService) {
        this.fb = fb;
        this.api = api;
        this.users = users;
        this.inviteService = inviteService;
        this.router = router;
        this.vs = vs;
        this.ga = ga;
        this.poupDialogService = poupDialogService;
        this.vendor = new AddVendorBuyer();
        this.selectedUser = new User();
        this.showMsg = "";
        this.domesticVendorType = true;
        this.dropshipVendorType = true;
        this.importVendorType = true;
        this.manuVendorType = true;
        this.distributorVendorType = true;
        this.tableData = '';
        this.reasonText = '';
        this.reason = true;
        this.modalContent = {
            'header': 'Success',
            'body': 'User added successfully.',
            'footer': ''
        };
        this.userValue = "";
        this.isShipperAddressCopied = false;
        this.isSuccess = true;
        this.disableType = true;
        this.addressList = [];
        this.selectedAddressList = [];
        this.submitted = false;
        //NSC-64
        this.additionalVendorDuplicateReasonText = '';
        this.isBuyerOverride = 'N';
        this.chkboxChecked = false;
    }
    AddVendorComponent.prototype.ngOnInit = function () {
        this.users.getUser();
        // console.log("Testing" +this.users.loggedInUser.userName);
        this.userEmail = this.users.loggedInUser.userName;
        this.fname = this.users.loggedInUser.firstName;
        this.lname = this.users.loggedInUser.lastName;
        this.companyName = this.users.loggedInUser.vendorName;
        // console.log("Company Name" +this.users.loggedInUser.vendorName);
        this.allStates = states.default;
        this.countryList = countries.default;
        this.setAddVendorTypeForUser();
        /*
        this.vendorForm = this.fb.group({
          firstName: this.fname,
          lastName: this.lname,
          email: this.userEmail,
          parentVendorNumber: ['',[Validators.required, Validators.maxLength(6),Validators.minLength(6)]],
          AdditionalVendorReason: ['',[Validators.required, Validators.maxLength(45)]],
          vendorType:[{value: '', disabled: this.isTypeDisabled()}],
          addressLine1:['',[Validators.required, Validators.maxLength(45)]],
          addressLine2:'',
          state: ['',[Validators.required]],
          country:['',[Validators.required]],
          city:['',[Validators.required]],
          zipCode:['',[Validators.required]],
          // remitName:'',
          remitAddressLine1:['',[Validators.required, Validators.maxLength(45)]],
          remitAddressLine2:'',
          //remitState: '',
          //remitCountry:'',
          //remitCity:'',
          //remitZipCode:'',
          remitState: ['',[Validators.required]],
          remitCountry:['',[Validators.required]],
          remitCity:['',[Validators.required]],
          remitZipCode:['',[Validators.required]],
          sameVendorFunding:'Y',
          samePaymentTerms:'Y',
          sameFreightTerms:'Y',
          // sameLeadTime:'Y',
          contactchanges:'N',
          isRTVChanges:'N',
          isVendorScanBased:'N',
          isTermsRemainSame:'Y',
          isAdditionalVendor:'Y',
          isEDISetup:'Y',
          isBuyerInitiated: 'N' // mark vendor initiated invite
        });
         */
        this.initializeFormControls();
        // sort country list TIBCO-3702
        // sort alphabetically by Name
        this.countryList.sort(function (a, b) { return a.NAME.localeCompare(b.NAME); });
        // move usa to front
        var usIdx = this.countryList.findIndex(function (x) { return x.NAME === 'USA'; });
        if (usIdx > -1) {
            var usa = this.countryList.splice(usIdx, 1);
            if (usa[0]) {
                this.countryList.unshift(usa[0]);
            }
        }
    };
    AddVendorComponent.prototype.initializeFormControls = function () {
        this.vendorForm = this.fb.group({
            firstName: this.fname,
            lastName: this.lname,
            email: this.userEmail,
            parentVendorNumber: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
            AdditionalVendorReason: ['', [Validators.required, Validators.maxLength(45)]],
            vendorType: [{ value: '', disabled: this.isTypeDisabled() }],
            addressLine1: ['', [Validators.required, Validators.maxLength(45)]],
            addressLine2: '',
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            zipCode: ['', [Validators.required]],
            // remitName:'',
            remitAddressLine1: ['', [Validators.required, Validators.maxLength(45)]],
            remitAddressLine2: '',
            //remitState: '',
            //remitCountry:'',
            //remitCity:'',
            //remitZipCode:'',
            remitState: ['', [Validators.required]],
            remitCountry: ['', [Validators.required]],
            remitCity: ['', [Validators.required]],
            remitZipCode: ['', [Validators.required]],
            sameVendorFunding: 'Y',
            samePaymentTerms: 'Y',
            sameFreightTerms: 'Y',
            // sameLeadTime:'Y',
            contactchanges: 'N',
            isRTVChanges: 'N',
            isVendorScanBased: 'N',
            isTermsRemainSame: 'Y',
            isAdditionalVendor: 'Y',
            isEDISetup: 'Y',
            isBuyerInitiated: 'N',
            isBuyerOverride: 'N',
            buyerOverrideReason: ''
        });
        this.chkboxChecked = false;
    };
    AddVendorComponent.prototype.isTypeDisabled = function () {
        return this.disableType;
    };
    AddVendorComponent.prototype.populateTestData = function () {
        this.vendorForm.patchValue({
            firstName: 'Jack',
            lastName: 'Harkness',
            sendCatalog: false
        });
    };
    AddVendorComponent.prototype.validateAddress = function () {
        var _this = this;
        // format address list
        this.addressList = [];
        if (this.vendorForm.get('country').value === 'USA') {
            this.addressList.push({ subtitle: 'Shipping Address', label: 'Address1', key: 'addressLine1', value: this.vendorForm.get('addressLine1').value });
            this.addressList.push({ subtitle: 'Shipping Address', label: 'Address2', key: 'addressLine2', value: this.vendorForm.get('addressLine2').value });
            this.addressList.push({ subtitle: 'Shipping Address', label: 'City', key: 'city', value: this.vendorForm.get('city').value });
            this.addressList.push({ subtitle: 'Shipping Address', label: 'Country', key: 'country', value: this.vendorForm.get('country').value });
            this.addressList.push({ subtitle: 'Shipping Address', label: 'State', key: 'state', value: this.vendorForm.get('state').value });
            this.addressList.push({ subtitle: 'Shipping Address', label: 'ZIP', key: 'zipCode', value: this.vendorForm.get('zipCode').value });
        }
        if (this.vendorForm.get('remitCountry').value === 'USA' && !this.isShipperAddressCopied) {
            this.addressList.push({ subtitle: 'Remittance Address', label: 'Address1', key: 'remitAddressLine1', value: this.vendorForm.get('remitAddressLine1').value });
            this.addressList.push({ subtitle: 'Remittance Address', label: 'Address2', key: 'remitAddressLine2', value: this.vendorForm.get('remitAddressLine2').value });
            this.addressList.push({ subtitle: 'Remittance Address', label: 'City', key: 'remitCity', value: this.vendorForm.get('remitCity').value });
            this.addressList.push({ subtitle: 'Remittance Address', label: 'Country', key: 'remitCountry', value: this.vendorForm.get('remitCountry').value });
            this.addressList.push({ subtitle: 'Remittance Address', label: 'State', key: 'remitState', value: this.vendorForm.get('remitState').value });
            this.addressList.push({ subtitle: 'Remittance Address', label: 'ZIP', key: 'remitZipCode', value: this.vendorForm.get('remitZipCode').value });
        }
        if (this.addressList && this.addressList.length > 0) {
            // call validation service
            this.vs.validateAddresses(this.addressList).subscribe(function (data) {
                // handle response
                _this.addressData = data;
                _this.selectedAddressList = [];
                var invalidForms = [];
                for (var _i = 0, _a = _this.addressData; _i < _a.length; _i++) {
                    var addr = _a[_i];
                    if (addr[1].address1 === 'No match found.') {
                        invalidForms.push(addr[0].title);
                    }
                    else if (addr[1].address1.startsWith('%xav')) {
                        // error from service, just use original address
                        addr[1] = addr[0];
                    }
                    // format state name from abbreviation
                    if (addr[1].state && addr[1].state !== '') {
                        var stateName = _this.vs.formatState(addr[1].state, addr[1].country);
                        if (stateName) {
                            addr[1].state = stateName;
                        }
                    }
                    // format country name from abbreviation
                    if (addr[0].country && addr[0].country !== '') {
                        var cnm = _this.vs.formatCountry(addr[0].country);
                        addr[0].country = cnm;
                    }
                    if (addr[1].country && addr[1].country !== '') {
                        var cname = _this.vs.formatCountry(addr[1].country);
                        addr[1].country = cname;
                    }
                }
                if (invalidForms.length > 0) {
                    var _loop_1 = function (title) {
                        var bad = _this.addressList.filter(function (f) { return f.subtitle === title && f.label !== 'Address2' && f.label !== 'Remittance Name'; });
                        if (bad) {
                            for (var _i = 0, bad_1 = bad; _i < bad_1.length; _i++) {
                                var field = bad_1[_i];
                                if (_this.vendorForm.controls[field.key]) {
                                    _this.vendorForm.controls[field.key].setErrors({ 'incorrect': true });
                                    _this.vendorForm.controls[field.key].markAsTouched();
                                }
                            }
                        }
                    };
                    // mark fields invalid
                    for (var _b = 0, invalidForms_1 = invalidForms; _b < invalidForms_1.length; _b++) {
                        var title = invalidForms_1[_b];
                        _loop_1(title);
                    }
                    _this.submitted = false;
                    _this.isSuccess = false;
                    _this.modalContent.header = 'Invalid Address';
                    _this.modalContent.body = 'Please correct the invalid fields in ' + invalidForms.join(', ');
                    _this.modal.show();
                    _this.ga.sendEvent('Additional Vendor', 'Address Validation - Invalid Fields', invalidForms.join(', '), { 'time_stamp': Date.now() });
                }
                else {
                    // display the address validation modal
                    _this.addressModal.show();
                    _this.ga.sendEvent('Additional Vendor', 'Address Validation Success', JSON.stringify(_this.addressData), { 'time_stamp': Date.now() });
                }
            }, function (error) {
                // unable to validate addresses
                console.log('address validation failed, submitting anyway');
                _this.ga.sendEvent('Additional Vendor', 'Address Validation Error', error.message, { 'time_stamp': Date.now(), 'error_msg': error.message });
                //this.save();
                _this.checkInprogressAdditionalVendor(); // NSC-64
            });
        }
        else {
            // only validate USA addresses
            //this.save();
            console.log("Inside else loop of address validation...");
            this.checkInprogressAdditionalVendor(); // NSC-64
        }
    };
    AddVendorComponent.prototype.selectAddress = function () {
        console.log('saving the addresses', this.selectedAddressList, this.addressData);
        var _loop_2 = function (addr) {
            // console.log('Addr', addressTab, addr);
            var addressType = this_1.addressList.filter(function (f) { return f.subtitle === addr.title; });
            //  console.log('address type', addressType, addr);
            for (var _i = 0, addressType_1 = addressType; _i < addressType_1.length; _i++) {
                var field = addressType_1[_i];
                // console.log('field', field);
                var label = field.label.toLowerCase().replace(' ', ''); // match format
                if (addr[label] && addr[label] !== '') {
                    field.value = addr[label];
                    // console.log('update field ' + label + ' to ' + field.value);
                }
            }
        };
        var this_1 = this;
        // copy selected values to the tab
        // console.log('allTabs', this.allTabs);
        for (var _i = 0, _a = this.selectedAddressList; _i < _a.length; _i++) {
            var addr = _a[_i];
            _loop_2(addr);
        }
        // update display
        for (var _b = 0, _c = this.addressList; _b < _c.length; _b++) {
            var field = _c[_b];
            this.vendorForm.controls[field.key].setValue(field.value);
        }
        // trigger submit only if valid
        //this.save(); // commented as part of NSC-64
        this.checkInprogressAdditionalVendor(); // Added as part of NSC-64
        this.addressModal.hide();
    };
    AddVendorComponent.prototype.selectAddressOption = function (index, add) {
        // console.log('address index', index, this.addressData[index]);
        // console.log('selected address', add, this.addressData[index][add]);
        // see form-group.component for functionality
        var selectedAdd = this.addressData[index][add] || [];
        selectedAdd.title = this.addressData[index][0].title || '';
        // update the selected values
        var found = this.selectedAddressList.find(function (a) { return a.title === selectedAdd.title; });
        if (found) {
            // already in list, update
            found = selectedAdd;
        }
        else {
            // not in list, add
            this.selectedAddressList.push(selectedAdd);
            console.log('selected addresses', this.selectedAddressList, this.addressData);
        }
    };
    AddVendorComponent.prototype.fieldCheck = function () {
        this.vendorForm.controls['addressLine1'].setErrors(null);
        this.vendorForm.controls['addressLine2'].setErrors(null);
        this.vendorForm.controls['city'].setErrors(null);
        this.vendorForm.controls['state'].setErrors(null);
        this.vendorForm.controls['country'].setErrors(null);
        this.vendorForm.controls['zipCode'].setErrors(null);
    };
    AddVendorComponent.prototype.fieldCheckRemit = function () {
        this.vendorForm.controls['remitAddressLine1'].setErrors(null);
        this.vendorForm.controls['remitAddressLine2'].setErrors(null);
        this.vendorForm.controls['remitCity'].setErrors(null);
        this.vendorForm.controls['remitState'].setErrors(null);
        this.vendorForm.controls['remitCountry'].setErrors(null);
        this.vendorForm.controls['remitZipCode'].setErrors(null);
    };
    AddVendorComponent.prototype.save = function () {
        var _this = this;
        console.log(this.vendorForm);
        // console.log('Saved: ' + JSON.stringify(this.vendorForm.value));
        this.selectedUser.firstName = this.vendorForm.get('firstName').value;
        this.selectedUser.lastName = this.vendorForm.get('lastName').value;
        this.selectedUser.groupName = "Guest";
        this.selectedUser.roleName = "Guest";
        this.selectedUser.createDate = new Date() + "";
        // this.selectedUser.vendorName = this.vendorForm.get('company').value;
        this.selectedUser.vendorNumber = this.vendorForm.get('parentVendorNumber').value + "";
        this.selectedUser.userName = this.vendorForm.get('email').value.toLowerCase();
        this.selectedUser.vendorType = this.vendorForm.get('vendorType').value;
        //this.submitUser(this.selectedUser);
        // NSC-64 changes 
        if (this.isBuyerOverride === 'Y') {
            this.vendorForm.controls['isBuyerOverride'].setValue('Y');
            this.vendorForm.controls['buyerOverrideReason'].setValue(this.additionalVendorDuplicateReasonText);
        }
        this.inviteService.postAdditionalVendorForm(JSON.stringify(this.vendorForm.value)).subscribe(function (data) {
            console.log(JSON.stringify(data));
            var value = JSON.stringify(data);
            value = value.replace("_body", "body");
            /*
            // scroll to top
            window.scrollTo({ top: 0, behavior: 'smooth' });
            console.log('should scroll to top now...');
            */
            var parsed = JSON.parse(value);
            if (parsed["body"] === 'Success') {
                // this.showMsg="User has been added successfully";
                _this.modalContent.header = 'Success';
                _this.modalContent.body = 'You\'ve successfully submitted your request, please click “OK” to be redirected to the onboarding screen. Some information has already been filled in, but you will need to fill in everything else. If you have questions please reach out to your merchandising team.<br/><br/>If your submission was identified as a duplicate , you will receive an email once it has been approved for set up.  Please follow the link from the email to complete your request.';
                _this.isSuccess = true;
                _this.modal.show();
                _this.ga.sendEvent('Additional Vendor', 'Invite Success', _this.selectedUser.vendorNumber, { 'time_stamp': Date.now(), 'record_id': _this.selectedUser.vendorNumber });
            }
            else {
                // this.showMsg = parsed["body"];
                _this.modalContent.header = 'Failed';
                var msg = 'You have a change request in progress. Please complete your active request before submitting a new request. If you feel this is an error or issue please reach out to vendormasterdata@tractorsupply.com';
                // handle other messages
                console.log('Error message from service', parsed['body']);
                _this.modalContent.body = msg;
                _this.isSuccess = false;
                _this.modal.show();
                _this.ga.sendEvent('Additional Vendor', 'Error: Request in progress for ' + _this.selectedUser.vendorNumber, parsed['body'], { 'time_stamp': Date.now(), 'error_msg': parsed['body'], 'record_id': _this.selectedUser.vendorNumber });
            }
        }, function (error) {
            // this.showMsg = "Failed";
            // service call failure response, 4xx or 5xx
            console.log('Server Error', error);
            _this.modalContent.header = 'Failed';
            _this.modalContent.body = 'There was a problem with your entry. Try submitting again and if you continue to experience problems please contact vendormasterdata@tractorsupply.com';
            _this.isSuccess = false;
            _this.modal.show();
            _this.ga.sendEvent('Additional Vendor', 'Invite Error for ' + _this.selectedUser.vendorNumber, error.message, { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': _this.selectedUser.vendorNumber });
            /*
            // scroll to top
            window.scrollTo({ top: 0, behavior: 'smooth' });
            console.log('should scroll to top now...');
            */
        });
    };
    AddVendorComponent.prototype.setCountry = function (abbr, isRemit) {
        // console.log(abbr);
        // try full name
        var currCountry = this.countryList.find(function (c) { return c.NAME === abbr; });
        if (currCountry) {
            abbr = currCountry.ABBR;
        }
        // get the states
        if (isRemit) {
            this.remitStates = this.allStates.filter(function (s) { return s.COUNTRY === abbr; });
            this.remitStates = this.allStates.filter(function (s) { return s.COUNTRY === abbr; });
            this.vendorForm.controls['remitAddressLine1'].setErrors(null);
            this.vendorForm.controls['remitAddressLine2'].setErrors(null);
            this.vendorForm.controls['remitCity'].setErrors(null);
            this.vendorForm.controls['remitState'].setErrors(null);
            this.vendorForm.controls['remitCountry'].setErrors(null);
            this.vendorForm.controls['remitZipCode'].setErrors(null);
        }
        else {
            this.stateList = this.allStates.filter(function (s) { return s.COUNTRY === abbr; });
            this.vendorForm.controls['addressLine1'].setErrors(null);
            this.vendorForm.controls['addressLine2'].setErrors(null);
            this.vendorForm.controls['city'].setErrors(null);
            this.vendorForm.controls['state'].setErrors(null);
            this.vendorForm.controls['country'].setErrors(null);
            this.vendorForm.controls['zipCode'].setErrors(null);
        }
        this.vendor.state = ''; // clear state
        // console.log(this.stateList, abbr);
    };
    AddVendorComponent.prototype.copyShipperAddress = function () {
        this.remitStates = this.stateList;
        var shipperAddressLine1 = this.vendorForm.get('addressLine1').value;
        var shipperAddressLine2 = this.vendorForm.get('addressLine2').value;
        var shipperAddressCity = this.vendorForm.get('city').value;
        var shipperAddressState = this.vendorForm.get('state').value;
        var shipperAddressCountry = this.vendorForm.get('country').value;
        var shipperAddressZip = this.vendorForm.get('zipCode').value;
        this.vendorForm.controls['remitAddressLine1'].setValue(shipperAddressLine1);
        this.vendorForm.controls['remitAddressLine2'].setValue(shipperAddressLine2);
        this.vendorForm.controls['remitCity'].setValue(shipperAddressCity);
        this.vendorForm.controls['remitState'].setValue(shipperAddressState);
        this.vendorForm.controls['remitCountry'].setValue(shipperAddressCountry);
        this.vendorForm.controls['remitZipCode'].setValue(shipperAddressZip);
    };
    AddVendorComponent.prototype.setAddVendorType = function () {
        var _this = this;
        this.vendorData = [];
        this.domesticVendorType = false;
        this.dropshipVendorType = false;
        this.importVendorType = false;
        this.manuVendorType = false;
        this.distributorVendorType = false;
        // this.vendorForm.controls['remitName'].setValue('');
        if (this.vendorForm.get('parentVendorNumber').value.length === 6) {
            this.getParentVendorType(this.vendorForm.get('parentVendorNumber').value).subscribe(function (data) {
                console.log(data);
                _this.vendorData = data.VendorData;
                _this.disableType = false;
                _this.vendorForm.controls['vendorType'].enable();
                if (!_this.vendorData || !_this.vendorData.length) {
                    _this.vendorForm.controls['parentVendorNumber'].setErrors({ 'incorrect': true });
                }
                else if (_this.vendorData || _this.vendorData.length) {
                    _this.vendorForm.controls['parentVendorNumber'].setErrors({ 'incorrect': true });
                    // console.log('set error by default',this.vendorForm.controls['parentVendorNumber'].errors);
                    if (_this.vendorData.length > 0) {
                        var matchedRecord = _this.vendorData.filter(function (data) { return data.VendorID === _this.vendorForm.get('parentVendorNumber').value; });
                        _this.companyName = matchedRecord[0].CompanyName;
                    }
                    for (var _i = 0, _a = _this.vendorData; _i < _a.length; _i++) {
                        var vendor = _a[_i];
                        console.log('get type for ', vendor);
                        if (vendor.VendorType === 'Domestic') {
                            _this.vendorForm.controls['parentVendorNumber'].setErrors(null);
                            _this.vendorForm.controls['vendorType'].setValue('Domestic');
                            // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
                            _this.domesticVendorType = true;
                        }
                        else if (vendor.VendorType === 'Dropship') {
                            _this.vendorForm.controls['parentVendorNumber'].setErrors(null);
                            _this.vendorForm.controls['vendorType'].setValue('Dropship');
                            // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
                            _this.dropshipVendorType = true;
                            // console.log('clear error',this.vendorForm.controls['parentVendorNumber'].errors);
                        }
                        else if (vendor.VendorType === 'Import') {
                            _this.vendorForm.controls['parentVendorNumber'].setErrors(null);
                            _this.vendorForm.controls['vendorType'].setValue('Import');
                            // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
                            _this.importVendorType = true;
                        }
                        else if (vendor.VendorType === 'PTSManufacturer') {
                            _this.vendorForm.controls['parentVendorNumber'].setErrors(null);
                            _this.vendorForm.controls['vendorType'].setValue('PTSManufacturer');
                            //  this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
                            _this.manuVendorType = true;
                        }
                        else if (vendor.VendorType === 'PTSDistributor') {
                            _this.vendorForm.controls['parentVendorNumber'].setErrors(null);
                            _this.vendorForm.controls['vendorType'].setValue('PTSDistributor');
                            // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
                            _this.distributorVendorType = true;
                        } /*else{
                          this.vendorForm.controls['parentVendorNumber'].setErrors({'incorrect': true});
                        }*/
                    }
                }
            });
        }
    };
    AddVendorComponent.prototype.setAddVendorTypeForUser = function () {
        var _this = this;
        this.vendorNumberData = [];
        this.getVendorTypeForUser().subscribe(function (data) {
            // console.log(data);
            _this.vendorNumberData = data.VendorData;
        });
    };
    AddVendorComponent.prototype.addNewUser = function () {
        this.selectedUser = new User();
    };
    AddVendorComponent.prototype.getParentVendorType = function (vendorNumber) {
        // console.log('request info for ', vendorNumber);
        var vendorId = vendorNumber;
        var json = {
            'VendorID': vendorId
        };
        return this.api.requestAddVendor(json);
    };
    AddVendorComponent.prototype.setChecked = function () {
        if (this.chkboxChecked) {
            this.chkboxChecked = false;
        }
        else {
            this.chkboxChecked = true;
        }
    };
    AddVendorComponent.prototype.ValidateAdditionalVendorForms = function () {
        // First validate addresses
        this.validateAddress();
    };
    AddVendorComponent.prototype.checkInprogressAdditionalVendor = function () {
        var _this = this;
        this.responseData = [];
        this.tableData = '';
        //const companyName = this.vendorForm.get('company').value;
        var companyName = this.companyName;
        var shipperAddressCity = this.vendorForm.get('city').value;
        var shipperAddressState = this.vendorForm.get('state').value;
        var shipperAddressCountry = this.vendorForm.get('country').value;
        var shipperAddressZip = this.vendorForm.get('zipCode').value;
        var additionalVendorCheckRequest = {
            CompanyName: companyName,
            SACity: shipperAddressCity,
            SAState: shipperAddressState,
            SACountry: shipperAddressCountry,
            SAZip: shipperAddressZip
        };
        this.additionalVendorCheckApiCall(additionalVendorCheckRequest).subscribe(function (response) {
            console.log("RESPONSE:", response);
            if (response['AdditionalVendorCheck'][0].IsApprovalNeeded === 'N') {
                // go ahead and submit the form without any popup message or reason text
                _this.save();
            }
            else {
                _this.responseData = response['AdditionalVendorCheck'];
                for (var i in _this.responseData) {
                    _this.responseData[i].SAZip = _this.responseData[i].SAZip.substring(0, 5);
                    if (_this.responseData[i].IsVendorActive.toLowerCase() == 'y') {
                        _this.responseData[i].IsVendorActive = 'Active';
                    }
                    if (_this.responseData[i].IsVendorActive.toLowerCase() == 'n') {
                        _this.responseData[i].IsVendorActive = 'Inactive';
                    }
                }
                var displayMessage = "The below vendor(s) already exists with name of " + companyName + " and \
        Shipping information ( " + shipperAddressCountry + ", " + shipperAddressState + ", " + shipperAddressCity + ", " + shipperAddressZip.substring(0, 5) + " ). Are you sure you want to proceed (YES/NO)?";
                _this.tableData = displayMessage;
                var modal = document.getElementById("myModal");
                modal.style.display = "block";
                // get the reason text and show popup modal
                //   let displayMessage="<p>A vendor already exists with name of <b>"+response.CompanyName+"</b> and \
                //   Shipping information ( "+response.SACountry+", "+response.SAState+", "+response.SACity+", "+response.SAZip.substring(0,5)+" ). are you sure you want to proceed (YES/NO)?</p>";
                //   let popupData ={
                //    title: "Continue?",
                //    message: displayMessage,
                //    showReasonText:true  
                //  }
                //  let dialogServiceRef = this.poupDialogService.openDialog(popupData);
                //  dialogServiceRef.afterClosed().subscribe((result:PopupDialogResponse)=>{
                //   const continueOnborading = result.continueOnborading;
                //   if(continueOnborading){
                //     // If YES
                //     this.additionalVendorDuplicateReasonText = result.reasonText;
                //     this.isBuyerOverride = 'Y';
                //     this.save();
                //   }else{
                //     // If NO clear the vendor number
                //     this.additionalVendorDuplicateReasonText ='';
                //     this.isBuyerOverride = 'N';
                //     this.initializeFormControls();
                //   }
                //  })
            }
        }, function (error) {
            console.log("Error in Additional vendor check API..", error);
        });
    };
    AddVendorComponent.prototype.proceed = function () {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        var continueOnboarding = true;
        if (continueOnboarding) {
            this.additionalVendorDuplicateReasonText = this.reasonText;
            this.isBuyerOverride = 'Y';
            this.save();
        }
    };
    AddVendorComponent.prototype.cancel = function () {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        this.additionalVendorDuplicateReasonText = '';
        this.isBuyerOverride = 'N';
        this.initializeFormControls();
    };
    AddVendorComponent.prototype.checkReasonTextLength = function (event) {
        this.reason = true;
        if (event.length != 0 && event.length >= 10) {
            this.reason = false;
        }
    };
    AddVendorComponent.prototype.additionalVendorCheckApiCall = function (request) {
        var apiEndPoint = 'additionalvendorcheck';
        return this.api.duplicateVendorCheckCall(apiEndPoint, request).pipe(map(function (data) {
            return data;
        }));
    };
    AddVendorComponent.prototype.submitUser = function (user) {
        var _this = this;
        console.log('adding user', user);
        var req;
        // handle multiple roles
        if (user.roleName && Array.isArray(user.roleName)) {
            user.roleName = user.roleName.join(',');
        }
        if (user.roleName.indexOf('VendorAdmin') > -1) {
            user.roleName = 'VendorAdmin'; // remove unnecessary roles
            user.groupName = 'VENDOR_ADMIN';
        }
        else if (user.roleName.indexOf('Guest') > -1) {
            user.roleName = 'Guest'; // remove unnecessary roles
            user.groupName = 'GUEST';
        }
        else {
            // not vendor admin
            user.groupName = 'Vendors';
        }
        // new user
        // console.log('creating new user');
        this.modalContent.body = 'User ' + user.userName + ' has been added.';
        user.createDate = moment().utc().format('M/DD/YYYY, h:mm:ss A');
        // parse to new user request object
        this.users.getUserDetails(user).subscribe(function (data) {
            console.log(data);
            if (data && data.json() && data.json().length > 0) {
                // console.log('User already exists');
                _this.showMsg = "User already exists";
                _this.ga.sendEvent('Additional Vendor', 'User Create Error', 'User ' + user.firstName + ' ' + user.lastName + ' already exists', { 'time_stamp': Date.now() });
            }
            else {
                req = new newUserRequest();
                req.loggedUser = _this.users.loggedInUser.userName;
                req.userName = user.userName.toLowerCase();
                req.email = user.userName.toLowerCase();
                req.firstName = user.firstName;
                req.lastName = user.lastName;
                req.roleName = user.roleName;
                req.vendorName = user.vendorName;
                req.vendorNumber = _this.users.loggedInUser.vendorNumber;
                req.createDate = user.createDate;
                req.createBy = _this.users.loggedInUser.userName.toLowerCase();
                req.groupName = user.groupName;
                req.roleName = user.roleName;
                req.vendorType = user.vendorType;
                console.log('user request', req);
                _this.users.editVendorUser(req).subscribe(function (data) {
                    console.log(data);
                    _this.addNewUser();
                    _this.ga.sendEvent('Additional Vendor', 'User Create', user.firstName + ' ' + user.lastName + ' created successfully', { 'time_stamp': Date.now() });
                }, function (error) {
                    console.log(error);
                    _this.showMsg = "User has been added successfully";
                    _this.ga.sendEvent('Additional Vendor', 'User ' + user.firstName + ' ' + user.lastName + ' added with error', error.message, { 'time_stamp': Date.now(), 'error_msg': error.message });
                });
            }
        });
    };
    AddVendorComponent.prototype.clearMessage = function () {
        this.showMsg = "";
        this.userValue = "";
        this.router.navigateByUrl('/');
    };
    AddVendorComponent.prototype.clearValues = function () {
        this.showMsg = "";
        this.userValue = "";
    };
    AddVendorComponent.prototype.getBuyerInfo = function () {
        var _this = this;
        this.api.getBuyerDetails().subscribe(function (data) {
            _this.buyerData = data.Data;
        });
    };
    AddVendorComponent.prototype.getVendorTypeForUser = function () {
        var json = {
            "Email": this.userEmail
        };
        return this.api.requestAddVendor(json);
    };
    AddVendorComponent.prototype.goToPage = function (path) {
        this.router.navigate([path]);
    };
    return AddVendorComponent;
}());
export { AddVendorComponent };
