import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, User, newUserRequest } from './../services/user.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
@ViewChild('modalMessage') modalMessage;
@ViewChild('vuForm') vuForm;

  selectedUser = new User();
 // userList:User[] = [];
 userList;
 rolesList = [];
 isEdit = false;

  searchQuery = '';
  searchCriteria = 'username';
  sortField = '';
  sortDir = 'asc';

  modalContent = {
    'header':'Success',
    'body':'User added successfully.',
    'footer':''
  }


  saveMsg = '';
  showMsg = false;
  constructor(
    public users: UserService,
    private router: Router,
  ) { }

  ngOnInit() {

    if (!this.users.isLoggedIn || this.users.getUserRole() !== 'VendorAdmin'){
      console.log('not authorized');
      // redirect to home/login
      this.router.navigate(['']);
    }


    this.refreshUserList();
    this.listAvailableRoles();
    this.users.getUser();
  }


  addNewUser(){
    this.selectedUser = new User();
    this.isEdit = false;
    this.vuForm.reset();
  }

  editUser(id){
    this.selectedUser = this.userList.find(u => u.id === id);
    this.isEdit = true;

    // parse roles to rolesArr
    let roles = [];
      if (this.selectedUser.roleName.indexOf(',') > -1) {
        // multiple roles
        roles = this.selectedUser.roleName.split(',');
      } else {
        // single role
        roles.push(this.selectedUser.roleName);
      }
      this.selectedUser.rolesArr = roles;

  }

  refreshUserList(){
    console.log('refreshing user list');
    this.userList = [];
    this.users.getUsersForVendor().subscribe(
      data => {
        console.log(data);
        if(data && data.json()){
        for (let u of data.json()){


          // don't show TSCAdmin
          if(u.roleName !== 'TSCAdmin' && u.groupName !== 'TSCAdmin'){
            this.userList.push(u);
          }

          // sort user list by username
          this.userList = this.users.sortBy(this.userList, 'userName');
        }
      }
      }, error => {
        console.log(error);
      }
    );
  }

  sortUsers(fieldname){
    let sortedList = this.users.sortBy(this.userList, fieldname);
    if (this.sortField === fieldname){
      if (this.sortDir === 'desc') {
        sortedList.reverse();
      }
      this.toggleDirection();
    } else {
      this.sortDir = 'desc';
    }
    this.userList = sortedList;
    this.sortField = fieldname;    
  }

  toggleDirection() {
    if (this.sortDir === 'asc') {
      this.sortDir = 'desc';
    } else {
      this.sortDir = 'asc';
    }
  }


  listAvailableRoles(){
    this.rolesList = [];

    for (let role of this.users.roles){
      if(role !== 'TSCAdmin' && role !== 'VendorAdmin'){
        this.rolesList.push(role);
      }
    }
  }

  submitUser(user, modal){
    let req;
    // handle multiple roles

    // parse rolesArr to roleName
    if(user.rolesArr && user.rolesArr.length>0){
      user.roleName = user.rolesArr.join(',');
    }

    if (user.roleName && Array.isArray(user.roleName)) {
      user.roleName = user.roleName.join(',');
    }
    if (user.roleName.indexOf('VendorAdmin') > -1) {
      user.roleName = 'VendorAdmin'; // remove unnecessary roles
      user.groupName = 'VENDOR_ADMIN';
    } else {
      // not vendor admin
      user.groupName = 'Vendors';
    }

    if(user.id && user.id !== null){
      console.log('modifying user ' + user.id);
      this.modalContent.body = 'User ' + user.userName + ' has been modified.';
      user.lastModifiedBy = this.users.loggedInUser.userName;
      user.modifyDate = moment().utc().format('M/DD/YYYY, h:mm:ss A');
      req = user;
      this.vendorModifyUser(req,modal);
      
      } else {
      
      // new user
      console.log('adding user', user);
      this.modalContent.body = 'User ' + user.userName + ' has been added.';
      user.createDate = moment().utc().format('M/DD/YYYY, h:mm:ss A');
      // parse to new user request object

      this.users.getUserDetails(user).subscribe(
        data => {
          console.log(data);
          if(data && data.json() && data.json().length > 0) {
            console.log('User already exists');
            this.modalContent.header = 'Error';
            this.modalContent.body = 'User ' + user.userName + ' already exists. If they are not in your user list, they are assigned to a different vendor.';
            modal.show();
            return;
          } else {

          req = new newUserRequest();
          req.loggedUser = this.users.loggedInUser.userName;
          req.userName = user.userName.toLowerCase();
          req.email = user.userName.toLowerCase();
          req.firstName = user.firstName;
          req.lastName = user.lastName;
          req.roleName = user.roleName;
          req.vendorName = this.users.loggedInUser.vendorName;
          req.vendorNumber = this.users.loggedInUser.vendorNumber;
          req.createDate = user.createDate;
          req.createBy = this.users.loggedInUser.userName;

          req.groupName = user.groupName;
          req.roleName = user.roleName;

          console.log('user request', req);
          this.vendorModifyUser(req,modal);
          }
        }, error => {
          console.log('Error', error);
          this.modalContent.header = 'Error';
          this.modalContent.body = 'Could not create ' + user.userName + '. An unexpected error occurred.';
          modal.show();
        });
        }
    }

    vendorModifyUser(req,modal){
      this.users.editVendorUser(req).subscribe(
        data => {
          console.log(data);
          this.modalContent.header = 'Success';
          // this.modalContent.body = 'User ' + req.userName + ' ' + action + ' successfully.';
          modal.show();
          this.refreshUserList();
          this.addNewUser();
        }, error => {
          console.log(error);
          this.modalContent.header = 'Error';
          this.modalContent.body = 'There was a problem modifying the user ' + req.userName + '.';
          modal.show();
        }
      );
    }
  
    vendorDeleteUser(user,modal) {
      if(this.users.getUserRole() && this.users.getUserRole().toUpperCase() === 'VENDORADMIN'){
        this.selectedUser = user;
        modal.show();
      } else {
        // not permitted
        this.modalContent.header = 'Forbidden';
        this.modalContent.body = 'You do not have the necessary permissions for this action.';
        this.modalMessage.show();
      }
    }

    deleteUser(user, modal){
      this.users.deleteUser(user.userName).subscribe(
        data => {
          console.log('deleted ' + user.userName);
          this.modalContent.header = 'Success';
          this.modalContent.body = user.userName + ' has been deleted.';
          modal.show();
          this.refreshUserList();
          this.addNewUser();
        }, error => {
          console.log(error);
          this.modalContent.header = 'Error';
          this.modalContent.body = 'There was a problem deleting ' + user.userName + '.';
          modal.show();
        }
      );
    }

}
