<div class="container">
   <!-- <form novalidate [formGroup]="volRebateForm" >  -->
  <mat-card>
    <mat-card-header class="accent">
      <mat-card-title>Volume Rebate Request</mat-card-title>
    </mat-card-header>
    
    <mat-card-content>
       <form novalidate [formGroup]="volRebateForm" class="vol-rebate-form" > 
        <div class="row">
          <!--Left side column to display form fields-->
          <div class="col-sm-8 col-md-8">

            <div class="form-group row">
              <!-- <label for="buyerId" class="col-sm-2">Buyer</label> -->
              <div class="col-sm-8">
                <label for="buyerId">Buyer</label>
                <select class="form-control" #buyerSelection name="buyerId" formControlName="BUYERID" required (change)="setSelectedBuyer($event)" >
                  <option value="" disabled [selected]="!selectedBuyerID || selectedBuyerID === ''">Select a Buyer</option>
                  <ng-container *ngFor="let buyer of vacBuyers"> <!-- *ngIf="buyer.CompanyName === buyerCompany || buyerCompany === 'PETSENSE' && buyer.CompanyName === 'PTS'" -->
                    <option *ngIf="buyer.CompanyName === buyerCompany || buyerCompany === 'PETSENSE' && buyer.CompanyName === 'PTS'" [value]="buyer.BuyerID + '|' + buyer.FirstName + ':' + buyer.LastName">{{ buyer.BuyerID }} - {{ buyer.FirstName }} {{ buyer.LastName }}</option>
                  </ng-container>
                </select>
                <div>
                  <small class="text-danger" *ngIf="(volRebateForm.get('BUYERID').touched || volRebateForm.get('BUYERID').dirty) && !volRebateForm.get('BUYERID').valid" >Please make a buyer selection</small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <label for="vendorId">Vendor ID</label>
                <input class="form-control" required name="vendorId" formControlName="VENDOR_ID" [disabled]="isVendorIdDisabled"
                  [ngClass]="{'cell-invalid':!vendorIsValid}" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6"
                  pattern="[0-9]{6}" (focusout)="checkVendor($event.target.value)" max-width="3em">
              </div>
              <div class="col-sm-4">
                <!-- <label for="vendorName">&nbsp;</label> -->
                <!-- <input class="form-control" required name="vendorName" formControlName="COMPANY_NAME" [ngClass]="{'cell-invalid':!vendorIsValid}" type="text"> -->

                <div>&nbsp;</div>
                <span class="vendor-name text-info" *ngIf="isVendorIdDisabled">Please select a buyer, then enter a Vendor ID here.</span>
                <span class="vendor-name text-danger" [ngClass]="{'invalid':!vendorIsValid}"> {{ vendorMsg }}</span>


              </div>

            </div>
            <div class="row">
              <div class="col-sm-4">
                <label for="parentVendorId">Parent Vendor</label>
                <input class="form-control" disabled required name="parentVendorId" id="parentVendorId" formControlName="PARENTID"
                  [ngClass]="{'cell-invalid':!vendorIsValid}" type="text" inputmode="numeric" maxlength="6"
                  pattern="[0-9]{6}" max-width="3em">

              </div>

            </div>
            <div class="row">
              <div class="col-sm-8">
                <label for="vendorContact">Vendor Contact</label>
                <input type="email" class="form-control" name="vendorContact"  [required]="false"
                  formControlName="SC_EMAIL" id="vendorContact" email="true">
                  <div >
                    <small class="text-danger" *ngIf="volRebateForm.get('SC_EMAIL').errors?.email" >Email address is invalid</small>
                  </div>
                  
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <label for="sourcingType">Sourcing Type</label>
                <select class="form-control" id="sourcingType" 
                  [required]="true"
                  formControlName="SOURCING_TYPE"
                  (change)="showVolumeRebateSearch()"
                  >
                   <!-- <option *ngIf="sourcingType.length > 0" disabled selected value>Choose a Sourcing Type</option>  -->
                  <option *ngFor="let source of sourcingType" value={{source}}>{{source}}
                  </option>
                </select>

              </div>

            </div>

            <div class="row">
              <div class="col-sm-4">
                <label for="agreementTerm">Agreement Term</label>
                

              </div>
              <div class="col-sm-6">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="agreementTerm"
                    id="agreementTermRecurring" value="Recurring" (change)="setAgreementTerm()" checked
                    [required]="true">
                  <label class="form-check-label" for="agreementTermRecurring">
                    Recurring
                  </label>
                  
                </div>
                 <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="agreementTerm" id="agreementTermYear"
                    value="Year" (change)="setAgreementTerm()" [required]="true">
                  <label class="form-check-label" for="agreementTermYear">
                    Single Year
                  </label> 
                  
                 </div> 

              </div>

            </div>

              <!-- <div class="row" *ngIf="this.volRebateForm.get('agreementTerm').value === 'Year'">   -->
              <div class="row" > 
              
              <div class="col-sm-4">
                
                <label for="effectiveYear">Effective Year</label>
                <!-- <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="4"  class="form-control" name="effectiveYear" [required]="true"
                   id="effectiveYear" formControlName="VALID_YEAR" > -->
                   <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="4"  class="form-control" name="effectiveYear" [required]="true"
                   id="effectiveYear" formControlName="VALID_YEAR" matInput [matAutocomplete]="auto" (blur)="showVolumeRebateSearch()">

                   <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectYear($event)">
                    <mat-option *ngFor="let year of filteredYears | async" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-autocomplete>
                  
                    <div>
                      <small class="text-danger" *ngIf="(volRebateForm.get('VALID_YEAR').touched || volRebateForm.get('VALID_YEAR').dirty) && volRebateForm.get('VALID_YEAR').value && !volRebateForm.get('VALID_YEAR').valid" >Effective Year should not be past year</small>
                    </div>
                  
              </div>

            </div>
            <div class="row">
              <div class="col-sm-8">
                <label for="VOLUME_REBATE_PERCENT">Volume Rebate Percent</label>
              <div class="input-group">
                  <!-- {{volRebateForm.get('VOLUME_REBATE_PERCENT').errors | json}} -->
                  <span class="input-group-text">%</span>
                  <input id="VOLUME_REBATE_PERCENT" name="VOLUME_REBATE_PERCENT" class="form-control"
                        formControlName="VOLUME_REBATE_PERCENT" pattern="[0-9.,]{0,45}" 
                        (change) = "calculateRebate()"
                        [ngClass]="{'is-invalid': (volRebateForm.get('VOLUME_REBATE_PERCENT').touched || volRebateForm.get('VOLUME_REBATE_PERCENT').dirty) && !volRebateForm.get('VOLUME_REBATE_PERCENT').valid }" />

                       
              </div>
              <div ><small class="text-danger" *ngIf="volRebateForm.get('VOLUME_REBATE_PERCENT').invalid" >Please enter valid Volume Rebate Percent</small></div> 

              </div>


            </div>
            <div class="row">
              <div class="col-sm-8">
                <label for="VOLUME_REBATE_THRESHOLD">Receipt Level that must be reached before Rebate is Effective</label>
                <input id="VOLUME_REBATE_THRESHOLD" name="VOLUME_REBATE_THRESHOLD" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                formControlName="VOLUME_REBATE_THRESHOLD" pattern="[0-9,$]{0,45}"  (change)="formatCurrencyValue($event)"
                [ngClass]="{'is-invalid': (volRebateForm.get('VOLUME_REBATE_THRESHOLD').touched || volRebateForm.get('VOLUME_REBATE_THRESHOLD').dirty) && !volRebateForm.get('VOLUME_REBATE_THRESHOLD').valid}" />

              </div>

            </div>
            <!-- Special Instructions added, oops, now removed
            <div class="row">
              <div class="col-sm-8">
                <label for="SPECIAL_INSTRUCTIONS">Special Instructions</label>
                <textarea id="SPECIAL_INSTRUCTIONS" name="SPECIAL_INSTRUCTIONS" class="form-control" formControlName="SPECIAL_INSTRUCTIONS" maxlength="4000"></textarea>
              </div>
            </div> -->
          </div>
          <!-- Right side content to display vendor information-->
          <div class="col-sm-4 col-md-4">
            <div class="search-button-container">
              <button tabindex="-1" class="btn btn-primary vr-search-btn" (click)="searchVR(modalSearch)"><span class="material-icons">search</span> Find Volume Rebate</button>
            </div>
            <mat-card style="display:flex;flex-direction: column;position: absolute;min-width: 90%;max-height:375px" >
              <mat-card-header class="accent" [ngClass]="{'highlightVendorInfo':rebateVendorInfo.VendorData && rebateVendorInfo.VendorData.VENDOR_ID !== ''}">
                <mat-card-title >Vendor Information</mat-card-title>
              </mat-card-header>
              <mat-card-content >
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <h3>{{ rebateVendorInfo.VendorData?.COMPANY_NAME }}</h3>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 text-right">ID:</label>
                  <div class="col-sm-9 fw-bold">{{ rebateVendorInfo.VendorData?.PARENTID }}</div>
                </div>
                <div class="row">
                  <label class="col-sm-3 text-right">Type:</label>
                  <div class="col-sm-9 fw-bold">{{ rebateVendorInfo.VendorData?.VENDOR_TYPE }}</div>
                </div>
                <!-- <div *ngIf="selectedVendor.childVendors && selectedVendor.childVendors !== ''" class="row">
                  <label class="col-sm-4 text-right">Child Vendors:</label>
                  <div class="col-sm-8">{{ selectedVendor?.childVendors }}</div>
                </div> -->
                <div class="row">
                  <label class="col-sm-3 text-right">Email:</label>
                  <div class="col-sm-9">{{ rebateVendorInfo.VendorData?.SC_EMAIL}}</div>
                </div>
                <div class="row">
                  <label class="col-sm-3 text-right">Name:</label>
                  <div class="col-sm-9">{{ rebateVendorInfo.VendorData?.SC_FIRST_NAME }} {{ rebateVendorInfo.VendorData?.SC_LAST_NAME }}
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 text-right">Phone:</label>
                  <div class="col-sm-9">{{ rebateVendorInfo.VendorData?.SC_PHONE }}</div>
                </div>
                <div class="row">
                  <label class="col-sm-3 text-right">Fax:</label>
                  <div class="col-sm-9"></div>
                </div>
                <!--
                 <div class="row">
                  <label class="col-sm-4 text-right">Buyer:</label>
                  <div class="col-sm-8">{{ rebateVendorInfo?.buyerID }} - {{ selectedVendor?.buyerName }}</div>
                </div> 
                -->

              </mat-card-content>
            </mat-card>


          </div>

        </div>
        <div class="row">
          <div class="col-sm-9">  
           <h3>Rebate Percentage</h3>
           <small class="text-danger">***Please Note*** - Any sales beyond the highest tier will have the highest level tier rebate % applied.  As tiers are achieved the highest achieved tier rebate % will be retroactively applied back to $1.</small>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <ng-template appDynamicComponentHost></ng-template> -->
            
           <div class="row rebate-percentage">
            <!-- <div class="col-sm-1">&nbsp;</div> -->
            <div class="col-sm-9">
              &nbsp;
              <div class="row" >
                <div class="col-sm-2 text-right"><br /><strong>Level 1:</strong></div>
             <div class="col-sm-3">From ($USD): <input class="form-control"  formControlName="MLP_TIER_1_FROM" value="1" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57'  name="from1" (change)="formatRange(1,'MLP_TIER_1_FROM')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_1_FROM').touched || volRebateForm.get('MLP_TIER_1_FROM').dirty) && !volRebateForm.get('MLP_TIER_1_FROM').valid}" readonly></div>
             <div class="col-sm-3">To ($USD): <input class="form-control" formControlName="MLP_TIER_1_TO" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' name="to1" (change)="formatRange(1,'MLP_TIER_1_TO')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_1_TO').touched || volRebateForm.get('MLP_TIER_1_TO').dirty) && !volRebateForm.get('MLP_TIER_1_TO').valid}"></div>
             <div class="col-sm-3">
                 Rebate % 
                 <input class="form-control" type="text" name="MLP_TIER_1_PERCENTAGE" id="MLP_TIER_1_PERCENTAGE" 
                         onkeypress='return event.charCode >= 48 && event.charCode <= 57 || (event.charCode == 46 || event.charCode == 190 || event.charCode == 110)' 
                         formControlName="MLP_TIER_1_PERCENTAGE" pattern="[0-9.]{0,45}" 
                         (change)="rebatePercentageChange(1,$event)" [required]="volRebateForm.get('MLP_TIER_1_TO').value !== ''"
                         [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_1_PERCENTAGE').touched || volRebateForm.get('MLP_TIER_1_PERCENTAGE').dirty) && !volRebateForm.get('MLP_TIER_1_PERCENTAGE').valid}">
               
               </div>
               <div *ngIf = "rebateerr[1] && rebateerr[1] !== ''" class="offset-sm-2 col-sm-10 text-danger">{{ rebateerr[1] }}</div>

              </div>
              <div class="row" *ngIf="showRPLevel2">
                <div class="col-sm-2 text-right"><br /><strong>Level 2:</strong></div>
             <div class="col-sm-3">From ($USD): <input class="form-control" formControlName="MLP_TIER_2_FROM" type="text" name="MLP_TIER_2_FROM" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(2, 'MLP_TIER_2_FROM')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_2_FROM').touched || volRebateForm.get('MLP_TIER_2_FROM').dirty) && !volRebateForm.get('MLP_TIER_2_FROM').valid}" readonly></div>
             <div class="col-sm-3">To ($USD): <input class="form-control" formControlName="MLP_TIER_2_TO" type="text" name="MLP_TIER_2_TO" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(2,'MLP_TIER_2_TO')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_2_TO').touched || volRebateForm.get('MLP_TIER_2_TO').dirty) && !volRebateForm.get('MLP_TIER_2_TO').valid}"></div>
             <div class="col-sm-3">
                 Rebate % 
                 <input class="form-control" type="text" name="MLP_TIER_2_PERCENTAGE"  id="MLP_TIER_2_PERCENTAGE"
                         onkeypress='return event.charCode >= 48 && event.charCode <= 57 || (event.charCode == 46 || event.charCode == 190 || event.charCode == 110)' 
                         formControlName="MLP_TIER_2_PERCENTAGE" pattern="[0-9.]{0,45}" 
                         (change)="rebatePercentageChange(2)" [required]="showRPLevel2"
                         [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_2_PERCENTAGE').touched || volRebateForm.get('MLP_TIER_2_PERCENTAGE').dirty) && !volRebateForm.get('MLP_TIER_2_PERCENTAGE').valid}">
               
               </div>
               <div *ngIf = "rebateerr[2] && rebateerr[2] !== ''" class="offset-sm-2 col-sm-10 text-danger">{{ rebateerr[2] }}</div>
              </div>
              <div class="row" *ngIf="showRPLevel3">
                <div class="col-sm-2 text-right"><br /><strong>Level 3:</strong></div>
             <div class="col-sm-3">From ($USD): <input class="form-control"  formControlName="MLP_TIER_3_FROM" type="text" name="MLP_TIER_3_FROM" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(3,'MLP_TIER_3_FROM')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_3_FROM').touched || volRebateForm.get('MLP_TIER_3_FROM').dirty) && !volRebateForm.get('MLP_TIER_3_FROM').valid}" readonly></div>
             <div class="col-sm-3">To ($USD): <input class="form-control" formControlName="MLP_TIER_3_TO" type="text" name="MLP_TIER_3_TO" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(3,'MLP_TIER_3_TO')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_3_TO').touched || volRebateForm.get('MLP_TIER_3_TO').dirty) && !volRebateForm.get('MLP_TIER_3_TO').valid}"></div>
             <div class="col-sm-3">
                 Rebate % 
                 <input class="form-control" type="text" name="MLP_TIER_3_PERCENTAGE"  id="MLP_TIER_3_PERCENTAGE"
                         onkeypress='return event.charCode >= 48 && event.charCode <= 57 || (event.charCode == 46 || event.charCode == 190 || event.charCode == 110)'
                         formControlName="MLP_TIER_3_PERCENTAGE" pattern="[0-9.]{0,45}" 
                         (change)="rebatePercentageChange(3)" [required]="showRPLevel3"
                         [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_3_PERCENTAGE').touched || volRebateForm.get('MLP_TIER_3_PERCENTAGE').dirty) && !volRebateForm.get('MLP_TIER_3_PERCENTAGE').valid}">
               
               </div>
               <div *ngIf = "rebateerr[3] && rebateerr[3] !== ''" class="offset-sm-2 col-sm-10 text-danger">{{ rebateerr[3] }}</div>

              </div>
              <div class="row" *ngIf="showRPLevel4">
                <div class="col-sm-2 text-right"><br /><strong>Level 4:</strong></div>
             <div class="col-sm-3">From ($USD): <input class="form-control"  formControlName="MLP_TIER_4_FROM" type="text" name="MLP_TIER_4_FROM" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(4,'MLP_TIER_4_FROM')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_4_FROM').touched || volRebateForm.get('MLP_TIER_4_FROM').dirty) && !volRebateForm.get('MLP_TIER_4_FROM').valid}" readonly></div>
             <div class="col-sm-3">To ($USD): <input class="form-control" formControlName="MLP_TIER_4_TO" type="text" name="MLP_TIER_4_TO" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (change)="formatRange(4,'MLP_TIER_4_TO')" pattern="[0-9,$]{0,20}" [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_4_TO').touched || volRebateForm.get('MLP_TIER_4_TO').dirty) && !volRebateForm.get('MLP_TIER_4_TO').valid}"></div>
             <div class="col-sm-3">
                 Rebate % 
                 <input class="form-control" type="text" name="MLP_TIER_4_PERCENTAGE" id="MLP_TIER_4_PERCENTAGE" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || (event.charCode == 46 || event.charCode == 190 || event.charCode == 110)'
                         formControlName="MLP_TIER_4_PERCENTAGE" pattern="[0-9.]{0,45}" 
                         (change)="rebatePercentageChange(4)" [required]="showRPLevel4"
                         [ngClass]="{'is-invalid': (volRebateForm.get('MLP_TIER_4_PERCENTAGE').touched || volRebateForm.get('MLP_TIER_4_PERCENTAGE').dirty) && !volRebateForm.get('MLP_TIER_4_PERCENTAGE').valid}">
               
               </div>
               <div *ngIf = "rebateerr[4] && rebateerr[4] !== ''" class="offset-sm-2 col-sm-10 text-danger">{{ rebateerr[4] }}</div>


              </div>

            </div>
            <div class="col-sm-3" style="padding-top: 20px;">
              <!-- Placement for plus and minus-->
              <div>
                
                <mat-button-toggle-group #group="matButtonToggleGroup">
                  <mat-button-toggle value="remove"  (change)="onRebateLevelChangeAction($event.value)" [disabled]="removeBtnInactive" class="highlight-remove">
                    <i class="material-icons md-24">remove</i>
                  </mat-button-toggle>
                  <mat-button-toggle value="add"  (change)="onRebateLevelChangeAction($event.value)" [disabled]="addBtnInactive" class="highlight-remove">
                    <i class="material-icons md-24">add</i>
                  </mat-button-toggle>
                  </mat-button-toggle-group>
              </div>
              
            </div>
             
              
               
              
           </div>
          
          </div>
          

       </div>
       <div class="row" *ngIf="showSubmitErrorMsgforInProgressVR">
        <div class="col-sm-12">
          <small class="text-danger">This vendor already has volume rebates in effect for the selected criteria. Please void existing volume rebate before creating a new one.</small>
        </div>
       </div>
       <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn btn-primary" (click)="requestVolumeRebate()" [disabled]="isSubmitting || showSubmitErrorMsgforInProgressVR ||  !volRebateForm.valid">Submit</button>
        </div>
        
      </div>
          
       </form>
        

    </mat-card-content>
  
  </mat-card>
 <!-- </form>  -->


</div>

<app-modal #modalMessage>
    <div class="app-modal-header">
        {{ modalContent.header }}
    </div>
    <div class="app-modal-body">
            {{ modalContent.body }}
    </div>
    <div class='app-modal-footer'>
            {{ modalContent.footer }}
            <button *ngIf="modalContent.header!=='Success'" class="btn btn-primary modal-btn" (click)="modalMessage.hide()">OK</button>
            <button *ngIf="modalContent.header==='Success'" class="btn btn-primary modal-btn" (click)="modalMessage.hide()">OK</button>
    </div>
  </app-modal>

  <div class="vr-search-modal">
    <app-modal #modalSearch>
      <div class="app-modal-header"><div class="close-btn" (mousedown)="isClosing = true" (click)="closeVR(modalSearch)">&times;</div></div>
      <div class="app-modal-body">
        <div *ngIf="!isSearching && showSearchMsg" class="alert {{searchClass}}" [innerHTML]="searchMsg"></div>
        <div class="search-bar">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Enter Vendor Number..." [(ngModel)]="voidVendorID" (keydown.enter)="searchNewVR()">
            <div class="input-group-append">
              <select class="form-control" [ngModel]="searchType" (change)="changeSearchType($event.target.value)">
                <option selected value="Vendor">Search by Vendor</option>
                <option value="Buyer">Search by Buyer</option>
              </select>
            </div>
            <div class="input-group-append">
              <button class="btn btn-primary search-btn" [disabled]="isSearching" (click)="searchNewVR()"><span class="material-icons">search</span></button>
            </div>
          </div>
        </div>
        <div class="search-results">
          <div *ngIf="isSearching" class="spinner"><mat-spinner [diameter]="20"></mat-spinner> Searching for volume rebates... </div>
          <div *ngIf="resultsMsg !== ''" class="no-results" [innerHTML]="resultsMsg"></div>
          <mat-accordion>
          <ng-container *ngFor="let result of searchResults">           
              <mat-expansion-panel #mep="matExpansionPanel" (opened)="resetVoid()" [ngClass]="{'inactive-vr' : !isVRactive(result.VRID)}"> <!-- (opened)="panelOpenState = true" (closed)="panelOpenState = false"-->
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ result?.VRID }}</mat-panel-title>
                  <mat-panel-description>
                    {{ result?.SOURCING_TYPE }} {{ result?.VALID_YEAR }} {{ result?.ONBOARDING_STATUS == 'Data Sent To DocuSign' ? 'In Progress'  : result?.ONBOARDING_STATUS }} <span class="vr-status">{{ isVRactive(result.VRID) ? 'Active' : 'Inactive'  }}</span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="container search-result-details">
                  <div class="row">
                    <div class="col-sm-12"><h4>{{ result?.PARENTID }} {{ result?.PARENTVENDORNAME }}</h4></div>
                    <div class="col-sm-6">Effective Year: {{ result?.VALID_YEAR }}</div>
                    <div class="col-sm-6">{{ result?.IS_RECURRING ==='Y' ? 'Recurring' : 'Single Year'}}</div>
                    <div class="col-sm-12">Buyer ID: {{ result?.BUYERID }}</div>
                    <div class="col-sm-6">Volume Rebate Percentage: {{ result?.VOLUME_REBATE_PERCENT | number : '1.2'}}% </div>
                    <div class="col-sm-6">Receipt Level for Effective: {{ formatNumber(result?.VOLUME_REBATE_THRESHOLD) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-12">Special Instructions: {{ result?.SPECIAL_INSTRUCTIONS }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12"><h3>Multitier Rebate Percentage</h3></div>
                  </div>
                  <div class="row" *ngIf="result.MLP_TIER_1_PERCENTAGE && result.MLP_TIER_1_PERCENTAGE !== ''">
                    <div class="col-sm-3">From: {{ formatNumber(result?.MLP_TIER_1_FROM) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">To: {{ formatNumber(result?.MLP_TIER_1_TO) | currency : 'USD' : 'symbol' : '1.0'}}</div>
                    <div class="col-sm-3">Percent: {{ result?.MLP_TIER_1_PERCENTAGE | number : '1.2'}}%</div>
                  </div>
                  <div class="row" *ngIf="result.MLP_TIER_2_PERCENTAGE && result.MLP_TIER_2_PERCENTAGE !== ''">
                    <div class="col-sm-3">From: {{ formatNumber(result?.MLP_TIER_2_FROM) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">To: {{ formatNumber(result?.MLP_TIER_2_TO) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">Percent: {{ result?.MLP_TIER_2_PERCENTAGE | number : '1.2'}}%</div>
                  </div>
                  <div class="row" *ngIf="result.MLP_TIER_3_PERCENTAGE && result.MLP_TIER_3_PERCENTAGE !== ''">
                    <div class="col-sm-3">From: {{ formatNumber(result?.MLP_TIER_3_FROM) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">To: {{ formatNumber(result?.MLP_TIER_3_TO) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">Percent: {{ result?.MLP_TIER_3_PERCENTAGE | number : '1.2'}}%</div>
                  </div>
                  <div class="row" *ngIf="result.MLP_TIER_4_PERCENTAGE && result.MLP_TIER_4_PERCENTAGE !== ''">
                    <div class="col-sm-3">From: {{ formatNumber(result?.MLP_TIER_4_FROM) | currency : 'USD' : 'symbol' : '1.0' }}</div>
                    <div class="col-sm-3">To: {{ formatNumber(result?.MLP_TIER_4_TO) | currency : 'USD' : 'symbol' : '1.0'}}</div>
                    <div class="col-sm-3">Percent: {{ result?.MLP_TIER_4_PERCENTAGE | number : '1.2'}}%</div>
                  </div>
                </div>
                <div class="search-result-buttons">
                  <!-- <button class="btn btn-outline" (click)="mep.toggle()">Close</button> <button class="btn btn-primary" (click)="copyVR(result)">Copy</button> --> 
                  <button *ngIf="isVRactive(result.VRID) && !showVoidWarning" class="btn btn-danger" (click)="confirmVoid()">VOID</button>
                  <button *ngIf="isVRInProgress(result.VRID) && !showVoidWarning" class="btn btn-danger" (click)="requestVoid(result, true)">RECALL</button>
                  <div *ngIf="showVoidWarning" class="alert alert-warning"><p class="small text-center">This cannot be undone, are you sure?</p>
                  <div class="form-row">
                    <div class="col">
                    <select class="form-control form-control-sm" [(ngModel)]="voidReason" (change)="selectVoidReason()">
                      <option value="" disabled>Choose Void Reason...</option>
                      <option value="Vendor Relationship Does not Exist">Vendor Relationship Does not Exist</option>
                      <option value="Vendors Declined">Vendors Declined</option>
                      <option value="Event Cancelled">Event Cancelled</option>
                      <option value="Invalid Setup Criteria">Invalid Setup Criteria</option>
                      <option value="Other">Other</option>
                    </select></div>
                    <div *ngIf="showOtherReason" for="otherReason" class="col"><input type="text" class="form-control form-control-sm" maxlength="255" name="otherReason" [(ngModel)]="otherReason"/></div>
                    <div class="col"><button class="btn btn-sm btn-light" (click)="resetVoid()">Cancel</button>&nbsp;<button class="btn btn-sm btn-danger" [disabled]="!getVoidReason() || getVoidReason() === ''" (click)="requestVoid(result)">VOID</button></div>
                  </div>
                </div>

                </div>
              </mat-expansion-panel>
          </ng-container>
          </mat-accordion>
        </div>
      </div>
      <!-- <div class="app-modal-footer"> <div class="text-right"><button class="btn btn-primary btn-sm" (click)="closeVR(modalSearch)">Close</button></div></div> -->
    </app-modal>
  </div>