import { Component, OnInit } from '@angular/core';
import { UserService } from './../services/user.service';
import { GoogleAnalyticsService } from '../services/analytics.service';
import { VacService, VendorAllowanceConfirmation, VacSearchResult, VacPromoDetails, VacScanDown, VacUpdateRequest, VacCorpInfo } from './../services/vac.service';
import { parseISO, format } from 'date-fns'
// import * as moment from 'moment';

@Component({
  selector: 'app-vac-maintenance',
  templateUrl: './vac-maintenance.component.html',
  styleUrls: ['./vac-maintenance.component.css']
})
export class VacMaintenanceComponent implements OnInit {
  
  public buyerCompany = 'TSC';
  public searchQuery = '';
  public searchCriteria = 'VACID';
  public searchResults = [];
  public vacList = [];
  public sortAsc = true;
  public sortedField = '';
  public searchMsg = '';
  public isSearching = false;
  public isLoading = false;
  public loadingMsg = '';
  public errorMsg = '';
  public isError = false;
  public selectedVAC:VacSearchResult = new VacSearchResult();
  public modalContent = {
    'header': '',
    'body':'',
    'class':'',
    'footer':'',
  }

  public submitted = false;
  public isSuccess = false;
  public isLoaded = false;
  public showVoidWarning = false;
  public showRejectWarning = false;
  public showWarningModal = false;

  public voidReason = '';
  public otherReason = '';
  public showOtherReason = false;
  public vendorCompany = '';
  public isRecalled = false;
  

  constructor(private users: UserService, private vac: VacService, private ga: GoogleAnalyticsService) { }

  ngOnInit() {
    this.vac.initializeVAC();
    if(this.users.getUserRole() === 'TSC_Buyer'){
      this.buyerCompany='TSC';
    }else if(this.users.getUserRole() === 'PTS_Buyer'){
      this.buyerCompany='PETSENSE';
    }

    console.log('current user', this.users.loggedInUser);


    this.vac.resetSelectedVAC();
  }

  searchVAC() {
    const query = this.searchQuery;
    const criteria = this.searchCriteria;
    this.vacList = [];
    this.searchResults = []; // clear results
    // format search request
    const req = {'Request': {}};
    req.Request[criteria] = query;
    this.isSearching = true;
    this.searchMsg = query !== '' ? 'searching for ' + this.niceName(criteria) + ': ' + query + '...' : 'finding all available...';

    console.log('search for', req);
    const searchTime = Date.now();
    this.vac.getVACinfo(req).subscribe(
      (res:any) => {
        console.log('result',res);
        this.isSearching = false;
        // handle no results
        if (!res.VACFields || res.VACFields.length === 0 || (!res.VACFields[0].VACID || res.VACFields[0].VACID === '')) {
          this.searchMsg = 'No records found for ' + criteria + ': ' + query; 
          this.ga.sendEvent('VAC','Search for  ' + criteria + ': ' + query, 'Found 0 results', {'time_stamp':Date.now(), 'load_time':Date.now()-searchTime});
          } else {
            // display results
            this.searchMsg = '';
            let result = new VacSearchResult();

            this.vacList = res.VACFields;
            for (let r of res.VACFields) {        
              result = r;

              // filter by buyer id
              if (this.vac.isBuyerAdmin) {
                this.searchResults.push(result);
              } else {
                if (this.vac.currBuyer && this.vac.currBuyer.length > 0) {
                  // let buyer = this.vac.currBuyer.find(b => b.BuyerID === result.BuyerID && (b.Email === result.BuyerEmail || result.BuyerEmail === ''));
                  let buyer = this.vac.currBuyer.find(b => b.BuyerID === result.BuyerID); // filter ONLY by buyer ID
                  if (buyer) {
                    console.log('found matching buyer', buyer, result);
                    this.searchResults.push(result);
                  }
                }
              }
            }

            if(this.searchResults.length === 0) {
              this.searchMsg = 'No records found for ' + criteria + ': ' + query;
              this.ga.sendEvent('VAC','Search for  ' + criteria + ': ' + query, 'Found 0 results', {'time_stamp':Date.now(),'load_time':Date.now()-searchTime});  
            } else {
              this.ga.sendEvent('VAC','Search for  ' + criteria + ': ' + query, 'Found ' + this.searchResults.length + ' results', {'time_stamp':Date.now(),'load_time':Date.now()-searchTime});
            }
        }
      }, error => {
        // handle error
        console.log('error', error);
        this.isSearching = false;
        this.searchMsg = 'No records found for ' + criteria + ': ' + query;
        this.ga.sendEvent('VAC','Error searching for  ' + criteria + ': ' + query, error.message, {'time_stamp':Date.now()});  
      }
    )
  }

  showVACdetails(vacid, modal) {
    // initialize modal
    this.hideVoidWarning();
    
    // return a single VAC by ID
    this.isError = false;
    this.isLoading = true;
    this.loadingMsg = 'Requesting details for ' + vacid + '...';
    this.errorMsg = '';
    this.isLoaded = false;
    this.isSuccess = false;
    this.isRecalled = false;

    const req = {'Request': {'VACID':vacid}};
    modal.show();


    let foundVAC = this.vacList.find(v => v.VACID === vacid);
    
    if (foundVAC) {
      console.log('found in list, do not reload', foundVAC);
      this.isLoading = false;
      this.loadingMsg = '';
      this.selectedVAC = foundVAC;
      this.isLoaded = true;
      this.ga.sendEvent('VAC','Viewing VAC Details', vacid, {'time_stamp':Date.now()});

    } else {
    console.log('not already loaded, request from API', foundVAC);
    this.vac.getVACinfo(req).subscribe(
      (data:any) => {
        // show details
        // console.log(data);
        this.isLoading = false;
        this.loadingMsg = '';
        if(data && data.VACFields && data.VACFields.length > 0){
          // only take the first record
          this.selectedVAC = data.VACFields[0];
          this.isLoaded = true;
        }else {
          this.isError = true;
          this.errorMsg = 'No VAC data found for this record.';
          console.log('no data', data);
        }

        console.log('Selected VAC', this.selectedVAC);
        this.ga.sendEvent('VAC','Loaded VAC Details', vacid, {'time_stamp':Date.now()});
        
        
      }, error => {
        // handle error
        console.log('ERROR',error);
        this.isLoading = false;
        this.isError = true;
        this.errorMsg = 'There was a problem loading the VAC data.';
        this.ga.sendEvent('VAC','Error loading VAC ' + vacid, error.message, {'time_stamp':Date.now()});
      }
    );

    // read/only display in new section or modal with void button
    }



  }

  sortByField(fieldname, type?) { 
    let sorted = this.searchResults;
    this.sortedField = fieldname;
    if(type){
      if(type === 'date') {
        // order by date
        sorted = this.searchResults.sort((a, b) => new Date(a[fieldname]) > new Date(b[fieldname]) && 1 || -1);
      } else {
        // numeric sort
        sorted = this.searchResults.sort((a,b) => {
          const numa = parseFloat(a[fieldname]);
          const numb = parseFloat(b[fieldname]);
          console.log(numa,numb);
          return numa - numb;
        });
      }

    } else {
      // text sort by field
      // sorted = this.searchResults.sort((a,b) => (a[fieldname] > b[fieldname]) ? 1 : ((b[fieldname] > a[fieldname]) ? -1 : 0));
      sorted = this.searchResults.sort((a,b) => a[fieldname].localeCompare(b[fieldname])); // slower but case-insensitive
    }
    if (this.sortAsc) {
      this.sortAsc = false;
    } else {
      sorted.reverse();
      this.sortAsc = true;
    }
    console.log('sort by ' + fieldname, this.sortAsc, sorted);
    this.searchResults = sorted;
  }

  checkVoid(){
    this.showWarningModal = true;
    this.showVoidWarning = true;
    this.vendorCompany = '';
    this.vac.getVendorInfo(this.selectedVAC.VendorID).subscribe(
      v => {
        console.log(this.vac.vacVendor);
        if(v.VendorData && v.VendorData.length > 0) {
          this.vendorCompany = v.VendorData[0].CompanyName;
        }
      }
    )
  }

  checkReject() {
    this.showWarningModal = true;
    this.showRejectWarning = true;
    this.vendorCompany = '';
    this.vac.getVendorInfo(this.selectedVAC.VendorID).subscribe(
      v => {
        console.log(this.vac.vacVendor);
        if(v.VendorData && v.VendorData.length > 0) {
          this.vendorCompany = v.VendorData[0].CompanyName;
        }
      }
    )
  }

  hideVoidWarning() {
    this.showVoidWarning = false;
    this.showRejectWarning = false;
    this.showWarningModal = false;
    this.voidReason = '';
    this.otherReason = '';
  }

  niceName(string) {
    switch(string) {
      case 'PromoType':
      return 'Promotion Type';

      case 'PromoDescription':
      return 'Promotion Name';

      case 'BuyerID':
      return 'Buyer';

      case 'VendorID':
      return 'Vendor';

      default:
      return string;
    }
  }

  voidVAC(vac, reject?) {
    // possibly request vac by id for all details
    this.isLoading = true;
    this.submitted = true;
    this.isSuccess = false;
    this.isError = false;
    let voidType = 'VOID';
    // format request
    let json = new VacUpdateRequest();
    let corpinfo = new VacCorpInfo();
    let promodetails = new VacPromoDetails();
    let vacfields = new VendorAllowanceConfirmation();
    // for void, use original buyer information (may differ from logged-in buyer)
    let buyerCo = vac.BuyerCompany || this.buyerCompany;

    if (reject) {
      // REJECT request
      voidType = 'REJECT';
      this.loadingMsg = 'sending REJECT request...';
      json.DocumentData = {OnboardingStatus:'Reject', RejectedReason: this.getVoidReason(), RejectedBy: this.users.loggedInUser.userName};

    } else {
      // VOID request
      this.loadingMsg = 'sending VOID request...';
      json.DocumentData = {OnboardingStatus:'Void', VoidReason: this.getVoidReason(), VoidBy: this.users.loggedInUser.userName};
    }

    if(!vac.BuyerCompany) {
      if (vac.VendorType && vac.VendorType.indexOf('PTS') === 0) {
        buyerCo = 'PTS';
      } else {
        buyerCo = 'TSC';
      }
    }

    console.log('vac company:', vac.BuyerCompany, this.buyerCompany, vac.VendorType, buyerCo);

        corpinfo.CompanyName = this.vendorCompany;
        corpinfo.BuyerID = vac.BuyerID;
        corpinfo.BuyerEmail = vac.BuyerEmail || this.users.loggedInUser.userName;
        corpinfo.VendorID = vac.VendorID;
        corpinfo.BuyerCompany = buyerCo;
        json.VendorCorporateInformation = corpinfo;
        
        vacfields.VACID = vac.VACID;
        vacfields.PromoType = vac.PromoType;
        vacfields.PromoDescription = vac.PromoDescription;
        vacfields.PromoAbbreviation = vac.PromoAbbreviation;
        vacfields.BeginDate = format(parseISO(vac.BeginDate),'dd-MMM-yyyy');; // format VAC dates
        vacfields.EndDate = format(parseISO(vac.EndDate),'dd-MMM-yyyy'); // format VAC dates
        promodetails = vac.PromoDetails;
        promodetails.PaymentDate = format(parseISO(vac.PromoDetails.PaymentDate),'dd-MMM-yyyy'); // format VAC dates
        if (promodetails.PaymentDate === 'Invalid Date') {
          promodetails.PaymentDate = '';
        }
        vacfields.ScandownFlag = vac.ScandownFlag;

        vacfields.PromoDetails = promodetails;
        json.VACFields = vacfields;

        console.log('send '+ voidType + ' request', json);
        
        this.vac.submitVACrequest(json).subscribe(
          // handle results
          data => {
            console.log(data);
            if(data && data === 'Form Submitted' || data === 'Success') {
              this.loadingMsg = '';
              this.isLoading = false;
              this.isSuccess = true;
              if (reject) {
                this.updateRejectedVAC(vac);
                this.isSuccess = false; // enable EDIT & VOID buttons
                this.isRecalled = true;
              }
              this.hideVoidWarning();
              this.ga.sendEvent('VAC', voidType + ' request successful', vac.VACID, {'time_stamp':Date.now(), 'record_id':vac.VACID});

            } else if (data && data === 'Form Inprogress') {
              this.submitted = false;
              this.errorMsg = 'The VAC is with the EVENTS team for approval. Please get in touch with your events team group to reject the VAC.';
              this.isLoading = false;
              this.isError = true;
              this.hideVoidWarning();
              this.showWarningModal = true; // disable Recall button, don't allow to send again 
              this.ga.sendEvent('VAC', voidType + ' request already in progress', vac.VACID, {'time_stamp':Date.now(), 'record_id':vac.VACID});

            } else {
              // handle error
              this.submitted = false;
              this.errorMsg = 'The '+ voidType + ' request was rejected.';
              this.isLoading = false;
              this.isError = true;
              this.ga.sendEvent('VAC', voidType + ' request rejected', vac.VACID, {'time_stamp':Date.now(), 'record_id':vac.VACID});
              this.hideVoidWarning();
            }
          }, error => {
            console.log('error', error);
            this.errorMsg = 'There was a problem with the '+ voidType + ' request.';
            this.submitted = false;
            this.isLoading = false;
            this.isError = true;
            this.ga.sendEvent('VAC', 'Error processing ' + voidType + ' request', vac.VACID, {'time_stamp':Date.now(), 'record_id':vac.VACID, 'error_msg':error.message});
            this.hideVoidWarning();
          }
        );
     
     this.submitted = false;
     this.isLoading = false;

  }

  // TIBCO-3618 Edit VAC
  editVAC(){
    this.vac.setRevisionStatus(true);
    this.vac.selectVAC(this.selectedVAC);
    this.ga.sendEvent('VAC','Edit VAC for ' + this.selectedVAC.VendorID, this.selectedVAC.VACID, {'time_stamp':Date.now(), 'record_id':this.selectedVAC.VACID});
    this.vac.goToEditVAC();
  }

  getVoidReason() {
    if (this.voidReason === 'Other') {
      return this.otherReason;
    }
    return this.voidReason;
  }

  selectVoidReason(){
    console.log(this.voidReason + ' selected');
    if (this.voidReason === 'Other') {
      this.showOtherReason = true;
    } else {
      this.otherReason = '';
      this.showOtherReason = false;
    }
  }

  voidDisabled() {
    if (
        this.voidReason === '' ||
        (this.voidReason === 'Other' && this.otherReason === '')
      ) {
        return true;
    }
    return false;
  }


/*

Onboarding Completed: hide Edit Button, show VOID button
Onboarding InProgress: hide Edit Button, hide VOID button (in-progress)
Data Sent To DocuSign: hide Edit Button, hide VOID button (in-progress)
Void Completed: show Edit Button, submit with new VACID, hide VOID button
Void InProgress: hide Edit Button, hide VOID button (in-progress)
Rejected: show Edit Button, keep VACID, hide VOID button
Voided: show Edit Button, submit with new VACID, hide VOID button

update 6/24/2022 TIBCO-3730
Show VOID and Recall buttons
Implementation will involve, clicking Recall button will call TIBCO to be updated to Rejected and then, 
on success response, UI will refresh the screen and allow the user to search the VAC and edit/void again.
Onboarding InProgress: show Recall button
Rejected: show VOID button

*/

  showEditBtn() {
    if(
        !this.submitted && 
        this.selectedVAC.OnboardingStatus &&
        this.selectedVAC.OnboardingStatus.toUpperCase() !== 'DATA SENT TO DOCUSIGN' &&
        this.selectedVAC.OnboardingStatus.toUpperCase() !== 'ONBOARDING INPROGRESS' &&
        this.selectedVAC.OnboardingStatus.toUpperCase() !== 'VOID INPROGRESS'
      ){
        return true;
      } 
    return false;
  }

  editBtnText() {
    if( this.selectedVAC.OnboardingStatus.toUpperCase() === 'ONBOARDING COMPLETED' ||
        this.selectedVAC.OnboardingStatus.toUpperCase() === 'VOID COMPLETED'
      ) {
      return 'COPY';
    }
    return 'EDIT';
  }

  showVoidBtn() {
    if( 
        !this.submitted && 
        // this.selectedVAC.IsVACActive !== 'N' &&
        this.selectedVAC.OnboardingStatus &&
        (this.selectedVAC.OnboardingStatus.toUpperCase() === 'ONBOARDING COMPLETED' ||
        this.selectedVAC.OnboardingStatus.toUpperCase() === 'REJECTED')
      ) {
        return true;
      }
    return false;
  }

  showRejectBtn() {
    if( 
      !this.submitted && 
      // this.selectedVAC.IsVACActive !== 'N' &&
      this.selectedVAC.OnboardingStatus &&
      (this.selectedVAC.OnboardingStatus.toUpperCase() === 'ONBOARDING INPROGRESS' ||
      this.selectedVAC.OnboardingStatus.toUpperCase() === 'DATA SENT TO DOCUSIGN')
    ) {
      return true;
    }
  return false;
  }

  updateRejectedVAC(vac) {
    // TIBCO-3730 update status in-memory to Rejected, which will enable EDIT and VOID functionality
    let rejectedVAC = this.vacList.find(v => v.VACID === vac.VACID);
    if (rejectedVAC) {
      rejectedVAC.OnboardingStatus = 'Rejected';
    }
    console.log('updated rejected VAC status', rejectedVAC, this.vacList);
  }



/*

// [7/28 3:50 PM] Sandeep Wajapey - The searchable criteria will be 
circular name-(Description),VAC ID, vendor ID, SKU#, Buyer ID

POST /getvacinfofrommdm

Request:

{
    "VACFields": {
    "VACID": "12345"
  }
}

Response:

{
  "VACFields": {
    "VACID": "12345",
    "IsVACActive": "Y",
    "CompletionDate": "2021-07-28",
    "PromoType": "CIRCULAR",
    "PromoAbbreviation": "MD",
    "MfrName": "string",
    "PromoDescription": "string",
    "BeginDate": "2021-07-28",
    "EndDate": "2021-07-28",
    "IsManufacturer": "string",
    "ScandownFlag": "string",
    "PromoDetails": {
      "ScanDownPromo": [
        {
          "SKU": "",
          "ScandownAmt": "",
          "Units": "",
          "Forecast": "",
          "SKUDescription": ""
        }
      ],
      "EstimatedUnits": "string",
      "EstimatedAmt": "string",
      "Rainchecks": "string",
      "FlatDolAmt": "string",
      "TotalSKUCount": "string",
      "SpecialInstructions": "string",
      "PaymentType": "string",
      "PaymentDate": "2021-07-28"
    }
  }
}



  */

}
