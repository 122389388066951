<div style="text-align:center">
  <h2>Vendor Onboarding {{ title }}</h2>
  <div>
    <small [ngStyle]="{ 'font-weight': 'bold' }">
      Please confirm that you agree to the following terms and
      conditions.</small
    >
  </div>
  <br />
</div>
