import { MatDialog } from '@angular/material';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PopupDialogService = /** @class */ (function () {
    function PopupDialogService(dialog) {
        this.dialog = dialog;
    }
    PopupDialogService.prototype.openDialog = function (popupModalContentData) {
        var dialogRef = this.dialog.open(PopupDialogComponent, {
            width: '75%',
            height: 'auto',
            disableClose: true,
            //panelClass: 'custom-modalbox',
            data: popupModalContentData
        });
        return dialogRef;
    };
    PopupDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PopupDialogService_Factory() { return new PopupDialogService(i0.inject(i1.MatDialog)); }, token: PopupDialogService, providedIn: "root" });
    return PopupDialogService;
}());
export { PopupDialogService };
