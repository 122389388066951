// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  appurl: 'https://perf-vendor.tractorsupply.com/',
  keycloakfile: 'keycloak-perf.json',
  api: 'https://perf-vendor.tractorsupply.com/portalservice/',
  merchapi: 'https://perf-vendor.tractorsupply.com/merchVendorService/', // NEED QA NETSCALER ROUTE
  vacapi: 'https://perf-vendor.tractorsupply.com/vacService/',
  vrapi: 'https://perf-vendor.tractorsupply.com/volume-rebate/',
  pim: 'https://perf-vendor.tractorsupply.com/pim-web/',
  skuportal: 'https://perf-vendor.tractorsupply.com/skuportal/',
  aiapi: 'https://qa-api.tractorsupply.com/',
  appid: 'cff30f48-f7bd-42ee-a86b-8ed88cb412d8',
};
