  <nav mat-tab-nav-bar  mat-align-tabs="center">
    <a mat-tab-link routerLink="/buyerAdditionalVendor" routerLinkActive
       #company1Tab="routerLinkActive" [active]="company1Tab.isActive">Additional Vendor</a>
    <a mat-tab-link routerLink="/volumeRebate" routerLinkActive
       #company1Tab="routerLinkActive" [active]="company1Tab.isActive">Volume Rebate</a>
       
    <a mat-tab-link routerLink="/vendorAllowance" routerLinkActive
       #company1Tab="routerLinkActive" [active]="company1Tab.isActive">Vendor Allowance Confirmation (VAC)</a>
    <a mat-tab-link routerLink="/vacMaintenance" routerLinkActive
       #company1Tab="routerLinkActive" [active]="company1Tab.isActive">VAC Search</a>
  </nav>
<router-outlet></router-outlet>
