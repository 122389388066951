import { RestService } from './rest.service';
import { UserService } from './user.service';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
var EventService = /** @class */ (function () {
    function EventService(rest, users) {
        this.rest = rest;
        this.users = users;
        this.dummyEvents = [
            { 'id': 1,
                'eventName': 'Test Event #1',
                'eventDesc': 'This is the amazing test event. We\'re using dummy data to populate the events screen. <br/><br/>VERY limited HTML should be available for formatting these events.',
                'startDate': '2019-09-01 10:00',
                'endDate': '2019-09-01 11:00',
                'createDate': '2019-07-01 12:00',
                'author': 'TESTADMIN',
                'eventLink': 'https://www.tractorsupply.com/',
                'addToCal': 'test this link',
                'groupName': '',
                'allowedRoles': 'all'
            },
            { 'id': 2,
                'eventName': 'Karen\'s Birthday',
                'eventDesc': 'Good times.',
                'startDate': '2019-09-04 6:00',
                'endDate': '2019-09-04 22:00',
                'createDate': '2019-09-01 12:00',
                'author': 'TESTADMIN',
                'eventLink': 'https://www.nashvilleplush.com/',
                'addToCal': 'create add to cal links',
                'groupName': '',
                'allowedRoles': 'all'
            },
            { 'id': 3,
                'eventName': 'Highland Games',
                'eventDesc': 'Pretend you\'re Scottish and drink some homemade mead while you toss a telephone pole in a skirt.',
                'startDate': '2019-09-07 12:00',
                'endDate': '2019-09-07 18:00',
                'createDate': '2019-09-01 12:00',
                'author': 'TESTADMIN',
                'eventLink': 'https://www.tennesseehighlandgames.com/',
                'addToCal': 'create add to cal links',
                'groupName': '',
                'allowedRoles': 'all'
            },
            { 'id': 4,
                'eventName': 'Test Event #4',
                'eventDesc': 'Test multiple events on a single day',
                'startDate': '2019-09-15 6:00',
                'endDate': '2019-09-15 22:00',
                'createDate': '2019-09-01 12:00',
                'author': 'TESTADMIN',
                'addToCal': 'create add to cal links',
                'groupName': '',
                'allowedRoles': 'all'
            },
            { 'id': 5,
                'eventName': 'Test Event #5 with a much longer title that will wrap',
                'eventDesc': 'Absolutely Fabulous',
                'startDate': '2019-09-15 12:00',
                'endDate': '2019-09-15 18:00',
                'createDate': '2019-09-01 12:00',
                'author': 'TESTADMIN',
                'groupName': '',
                'allowedRoles': 'all'
            },
            { 'id': 6,
                'eventName': 'Test Event #6',
                'eventDesc': 'Test display of multiple day events',
                'startDate': '2019-09-25 12:00',
                'endDate': '2019-09-28 18:00',
                'createDate': '2019-09-01 12:00',
                'author': 'TESTADMIN',
                'groupName': '',
                'allowedRoles': 'all'
            }
        ];
        this.eventList = [];
        this.eventListLoaded = false;
    }
    EventService.prototype.getEventList = function () {
        return this.eventList;
    };
    EventService.prototype.retrieveEvents = function () {
        var _this = this;
        // form http request for events from API
        return this.rest.getReq('getEventsDetail', this.users.getHeader()).pipe(map(function (res) {
            console.log('event request', res);
            // this.eventList = res as Event[]; // for httpClient
            _this.eventList = res.json();
            _this.eventListLoaded = true;
            _this.eventList.sort(function (a, b) { return new Date(a.startDate).getTime() - new Date(b.startDate).getTime(); });
            // parse role list
            for (var _i = 0, _a = _this.eventList; _i < _a.length; _i++) {
                var ev = _a[_i];
                var rolesArr = [];
                if (ev.roleName && ev.roleName !== '') {
                    ev.roleName = ev.roleName.replace('null', 'all'); // prevent null values
                    if (ev.roleName.indexOf(',') > -1) {
                        // multiple roles
                        rolesArr = ev.roleName.split(',');
                    }
                    else {
                        // single role
                        rolesArr.push(ev.roleName);
                    }
                }
                ev.rolesArr = rolesArr;
                // parse buyerId and lob
                ev.buyerArr = [];
                ev.lobArr = [];
                ev.vendorIds = [];
                ev.vendorAttrArr = [];
                if (ev.buyerId && ev.buyerId !== '') {
                    if (ev.buyerId.indexOf(',') > -1) {
                        ev.buyerArr = ev.buyerId.split(',');
                    }
                    else {
                        ev.buyerArr.push(ev.buyerId);
                    }
                }
                if (ev.lob && ev.lob !== '') {
                    if (ev.lob.indexOf(',') > -1) {
                        ev.lobArr = ev.lob.split(',');
                    }
                    else {
                        ev.lobArr.push(ev.lob);
                    }
                }
                if (ev.vendor && ev.vendor !== '') {
                    if (ev.vendor.indexOf(',') > -1) {
                        ev.vendorIds = ev.vendor.split(',');
                    }
                    else {
                        ev.vendorIds.push(ev.vendor);
                    }
                }
                if (ev.vendorAttr && ev.vendorAttr !== '') {
                    if (ev.vendorAttr.indexOf(',') > -1) {
                        ev.vendorAttrArr = ev.vendorAttr.split(',');
                    }
                    else {
                        ev.vendorAttrArr.push(ev.vendorAttr);
                    }
                }
            }
            // get user role
            var userRole = _this.users.getUserRole();
            var userVendorType = [];
            if (_this.users.loggedInUser.vendorType && _this.users.loggedInUser.vendorType !== '') {
                if (_this.users.loggedInUser.vendorType.indexOf(',') > -1) {
                    // add unique
                    var vt = _this.users.loggedInUser.vendorType.split(',');
                    if (vt && vt.length > 0) {
                        var _loop_1 = function (t) {
                            var dupType = userVendorType.find(function (v) { return v == t; });
                            if (!dupType) {
                                userVendorType.push(t);
                            }
                        };
                        for (var _b = 0, vt_1 = vt; _b < vt_1.length; _b++) {
                            var t = vt_1[_b];
                            _loop_1(t);
                        }
                    }
                }
                else {
                    userVendorType.push(_this.users.loggedInUser.vendorType);
                }
                ;
            }
            // filter event list by role
            var filteredEvents = [];
            // TIBCO-2533 Vendor Communication
            // Filter by LOB, Vendor ID, or Buyer ID
            if (!userRole || (userRole && userRole.toString().toUpperCase() !== 'TSCADMIN')) {
                // TSCADMIN sees all
                var combinedEv = _this.eventList.filter(function (e) { return (!e.lob || e.lob == '') && (!e.buyerId || e.buyerId == '') && (!e.vendorIds || e.vendorIds.length === 0); }); // generic vendor events
                console.log('LOB', _this.users.loggedInUser.lob);
                if (_this.users.loggedInUser && _this.users.loggedInUser.lob) {
                    var _loop_2 = function (l) {
                        var ev = _this.eventList.filter(function (a) { return a.lob && a.lob.indexOf(l) > -1; });
                        // narrow list, only matching lob
                        console.log('events for ' + l, ev);
                        var _loop_7 = function (e) {
                            if (e && !combinedEv.find(function (m) { return m.id === e.id; })) {
                                // if it's not already in the list, add it
                                combinedEv.push(e);
                            }
                        };
                        for (var _i = 0, ev_1 = ev; _i < ev_1.length; _i++) {
                            var e = ev_1[_i];
                            _loop_7(e);
                        }
                    };
                    // lob
                    for (var _c = 0, _d = _this.users.loggedInUser.lob; _c < _d.length; _c++) {
                        var l = _d[_c];
                        _loop_2(l);
                    }
                }
                if (_this.users.loggedInUser && _this.users.loggedInUser.buyerId) {
                    console.log('BuyerID', _this.users.loggedInUser.buyerId);
                    var _loop_3 = function (b) {
                        var ev = _this.eventList.filter(function (a) { return a.buyerId && a.buyerId.indexOf(b) > -1; });
                        // narrow list, only matching buyer id
                        console.log('events for ' + b, ev);
                        var _loop_8 = function (e) {
                            if (e && !combinedEv.find(function (m) { return m.id === e.id; })) {
                                // if it's not already in the list, add it
                                combinedEv.push(e);
                            }
                        };
                        for (var _i = 0, ev_2 = ev; _i < ev_2.length; _i++) {
                            var e = ev_2[_i];
                            _loop_8(e);
                        }
                    };
                    // now buyer id
                    for (var _e = 0, _f = _this.users.loggedInUser.buyerId; _e < _f.length; _e++) {
                        var b = _f[_e];
                        _loop_3(b);
                    }
                }
                if (_this.users.loggedInUser && _this.users.loggedInUser.vendorList) {
                    console.log('VendorID', _this.users.loggedInUser.vendorList);
                    var _loop_4 = function (v) {
                        var ev = _this.eventList.filter(function (a) { return a.vendorIds && a.vendorIds.indexOf(v.VendorID) > -1; });
                        // narrow list, only matching vendor id
                        console.log('events for ' + v.VendorID, ev);
                        var _loop_9 = function (e) {
                            if (e && !combinedEv.find(function (m) { return m.id === e.id; })) {
                                // if it's not already in the list, add it
                                combinedEv.push(e);
                            }
                        };
                        for (var _i = 0, ev_3 = ev; _i < ev_3.length; _i++) {
                            var e = ev_3[_i];
                            _loop_9(e);
                        }
                    };
                    // now vendor id
                    for (var _g = 0, _h = _this.users.loggedInUser.vendorList; _g < _h.length; _g++) {
                        var v = _h[_g];
                        _loop_4(v);
                    }
                }
                console.log('filtered Events', combinedEv);
                // update filtered msglist
                _this.eventList = combinedEv;
            }
            var userEvents = [];
            console.log('User Role' + userRole);
            if (userRole) {
                if (userRole.indexOf('TSCAdmin') === -1) {
                    if (userRole === 'VendorAdmin') {
                        // vendor admin sees all vendor events + vendor admin events
                        userEvents = _this.eventList.filter(function (e) { return e.roleName !== 'TSCAdmin'; });
                    }
                    else {
                        // vendor sees generic vendor events + events for their user role only
                        userEvents = _this.eventList.filter(function (e) { return !e.roleName || e.roleName.toLowerCase() === 'null' || e.roleName.toLowerCase() === 'all' || e.roleName === 'Vendor'; });
                        // split the user role list
                        var uRoles = '';
                        if (userRole.indexOf(',') !== -1) {
                            // console.log('user role contains commas, split it!');
                            uRoles = userRole.split(/\s*,\s*/g);
                            console.log('uRoles', uRoles);
                            var _loop_5 = function (r) {
                                var roleEvents = _this.eventList.filter(function (e) { return e.roleName.indexOf(r) > -1; });
                                console.log('roleEvents for ' + r, roleEvents);
                                var _loop_10 = function (e) {
                                    var existing = userEvents.find(function (ev) { return ev.id === e.id; }); // remove duplicates
                                    if (!existing) {
                                        userEvents.push(e);
                                    }
                                };
                                for (var _i = 0, roleEvents_2 = roleEvents; _i < roleEvents_2.length; _i++) {
                                    var e = roleEvents_2[_i];
                                    _loop_10(e);
                                }
                            };
                            for (var _j = 0, uRoles_1 = uRoles; _j < uRoles_1.length; _j++) {
                                var r = uRoles_1[_j];
                                _loop_5(r);
                            }
                        }
                        else {
                            console.log('single role');
                            var r_1 = userRole;
                            var roleEvents = _this.eventList.filter(function (e) { return e.roleName.indexOf(r_1) > -1; });
                            console.log('roleEvents for ' + r_1, roleEvents);
                            var _loop_6 = function (e) {
                                var existing = userEvents.find(function (ev) { return ev.id === e.id; }); // remove duplicates
                                if (!existing) {
                                    userEvents.push(e);
                                }
                            };
                            for (var _k = 0, roleEvents_1 = roleEvents; _k < roleEvents_1.length; _k++) {
                                var e = roleEvents_1[_k];
                                _loop_6(e);
                            }
                        }
                    }
                    // console.log('events', filteredEvents);
                    // filter by vendor types
                    if (userVendorType) {
                        filteredEvents = userEvents.filter(function (e) { return !e.vendorType || e.vendorType === '' || e.vendorType == 'all' || _this.findVendorType(e.vendorType, userVendorType); });
                    }
                    else {
                        filteredEvents = userEvents.filter(function (e) { return !e.vendorType || e.vendorType === '' || e.vendorType == 'all'; });
                    }
                    var attrEvents = [];
                    if (_this.users.loggedInUser.vendorList && _this.users.loggedInUser.vendorList.length > 0) {
                        attrEvents = filteredEvents.filter(function (a) { return !a.vendorAttr || a.vendorAttr == '' || _this.showEventAttr(a); });
                    }
                    else {
                        console.log('no vendor attributes found for this vendor');
                        attrEvents = filteredEvents.filter(function (e) { return !e.vendorAttr || e.vendorAttr === ''; });
                    }
                    filteredEvents = attrEvents;
                    console.log('filtered events for ' + userRole, filteredEvents);
                }
                else {
                    // admin sees all events, no filter
                    filteredEvents = _this.eventList;
                }
            }
            else {
                // treat as basic vendor
                filteredEvents = _this.eventList.filter(function (e) { return (!e.roleName || e.roleName === '' || e.roleName.toLowerCase() === 'null' || e.roleName.toLowerCase() === 'all' || e.roleName === 'Vendor')
                    && (!e.lob || e.lob === '') && (!e.vendorIds || e.vendorIds.length === 0) && (!e.buyerId || e.buyerId === '')
                    && (!e.vendorType || e.vendorType === '') && (!e.vendorAttr || e.vendorAttr === ''); });
                console.log('no role detected', userRole, filteredEvents);
            }
            _this.eventList = filteredEvents;
            // filter by vendor attributes
            /*
            if (this.users.loggedInUser.vendorAttributes && this.users.loggedInUser.vendorAttributes !=='' ) {
              let vendorattr = [];
              if (this.users.loggedInUser.vendorAttributes.indexOf(',') > -1) {
                vendorattr = this.users.loggedInUser.vendorAttributes.split(',');
              } else {
                vendorattr = [this.users.loggedInUser.vendorAttributes];
              }
              // loop through all vendor attributes
              for (let v of vendorattr) {
                let vatr = filteredEvents.filter(e => !e.vendorAttr || e.vendorAttr === '' || e.vendorAttr.indexOf(v)-1);
                if (vatr) {
                  for (let x of vatr) {
                    let foundx = attrEvents.find(e => e.id === x.id);
                    if(!foundx) {
                      attrEvents.push(x);
                    }
                  }
                }
              }
              this.eventList = attrEvents;
  
            } else {
              attrEvents = filteredEvents.filter(e => !e.vendorAttr || e.vendorAttr === '');
              this.eventList = attrEvents;
            }
            
            */
            // console.log('final event list', this.eventList);
            return _this.eventList;
        }));
    };
    EventService.prototype.findVendorType = function (csvField, typesArr) {
        for (var _i = 0, typesArr_1 = typesArr; _i < typesArr_1.length; _i++) {
            var t = typesArr_1[_i];
            if (csvField.indexOf(t) > -1) {
                return true;
            }
        }
        return false;
    };
    EventService.prototype.showEventAttr = function (event) {
        // include this event in the list
        var showMe = true;
        var vendorsWithAttributes = this.users.loggedInUser.vendorList.find(function (v) { return v.VendorAttribute && v.VendorAttribute !== ''; });
        if (event.vendor === '' || event.vendorIds.length === 0) {
            // no vendor ID, check for any attribute in vendorList
            // if announcement/event has any Vendor Attribute, only show to those vendors
            if (event.vendorAttrArr && event.vendorAttrArr.length > 0) {
                showMe = false;
                if (vendorsWithAttributes) {
                    for (var _i = 0, _a = event.vendorAttrArr; _i < _a.length; _i++) {
                        var attr = _a[_i];
                        for (var _b = 0, vendorsWithAttributes_1 = vendorsWithAttributes; _b < vendorsWithAttributes_1.length; _b++) {
                            var vnum = vendorsWithAttributes_1[_b];
                            console.log('looking for ', attr, vnum);
                            if (vnum.VendorAttribute.indexOf(attr) > -1) {
                                showMe = true;
                                console.log('attribute found, show', vnum.VendorAttribute, attr, event);
                            }
                        }
                    }
                }
            }
        }
        else {
            // message assigned to vendor id, check if attribute is also assigned
            if (event.vendorAttrArr && event.vendorAttrArr.length > 0) {
                showMe = false;
                if (vendorsWithAttributes) {
                    var _loop_11 = function (v) {
                        var thisVendor = vendorsWithAttributes.find(function (x) { return v.VendorID === v; });
                        if (thisVendor && thisVendor.VendorAttributes) {
                            for (var _i = 0, _a = event.vendorAttrArr; _i < _a.length; _i++) {
                                var attr = _a[_i];
                                console.log('looking for ', attr, thisVendor.VendorAttributes);
                                if (thisVendor.VendorAttributes.indexOf(attr) > -1) {
                                    showMe = true;
                                    console.log('attribute found for ' + v, attr, thisVendor, event);
                                }
                            }
                        }
                    };
                    for (var _c = 0, _d = event.vendorIds; _c < _d.length; _c++) {
                        var v = _d[_c];
                        _loop_11(v);
                    }
                }
            }
        }
        console.log(showMe, event);
        return showMe;
    };
    EventService.prototype.generateICAL = function (event) {
        // iCal format
        // "BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nUID:9832@meetingplanner.io\nDTSTART:" + bookingInfo.StartDate + "\nDTEND:" + bookingInfo.EndDate + "\nDTSTAMP:" + bookingInfo.StartDate + "\nLOCATION: " + bookingInfo.SpaceDescription + "\nSUMMARY: " + bookingInfo.EventDescription + "\nSEQUENCE:0\nTRANSP:OPAQUE\nEND:VEVENT\nEND:VCALENDAR";
        /*
        BEGIN:VCALENDAR
        VERSION:2.0
        PRODID:-//hacksw/handcal//NONSGML v1.0//EN
        BEGIN:VEVENT
        UID:uid1@example.com
        DTSTAMP:19970714T170000Z
        ORGANIZER;CN=John Doe:MAILTO:john.doe@example.com
        DTSTART:19970714T170000Z
        DTEND:19970715T035959Z
        SUMMARY:Bastille Day Party
        GEO:48.85299;2.36885
        END:VEVENT
        END:VCALENDAR
    */
        var iCal = 'BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\n';
        var calDetails = '';
        // populate details
        if (event) {
            // format dates
            var start = moment(event.startDate).utc().format('YYYYMMDDTHHmmss') + 'Z';
            var end = moment(event.endDate).utc().format('YYYYMMDDTHHmmss') + 'Z';
            var safeDesc = event.eventDesc.replace(/[\r\n]+/g, "\\n"); // handle newlines
            if (event.eventLink && event.eventLink !== '') {
                safeDesc = safeDesc + "\\n\\n" + event.eventLink;
            }
            // UID unique event identifier
            calDetails = 'UID:uuid' + event.id + event.authorName + '\nDTSTART:' + start + '\nDTEND:' + end + '\nDTSTAMP:' + start + '\nSUMMARY: ' + event.eventName + '\nDESCRIPTION: ' + safeDesc;
            if (event.eventLocation) {
                calDetails = calDetails + '\nLOCATION: ' + event.eventLocation;
            }
            iCal = iCal + calDetails;
        }
        iCal = iCal + '\nSEQUENCE:0\nTRANSP:OPAQUE\nEND:VEVENT\nEND:VCALENDAR';
        console.log(iCal);
        return iCal;
    };
    return EventService;
}());
export { EventService };
var Event = /** @class */ (function () {
    function Event() {
        this.id = '';
        this.eventName = '';
        this.eventDesc = '';
        this.startDate = null;
        this.endDate = null;
        this.createDate = null;
        this.authorName = '';
        this.roleName = 'all';
        this.groupName = '';
        this.modifyDate = '';
        this.lastModifiedBy = '';
        this.eventLink = '';
        this.eventLocation = '';
        this.deleteInd = '';
        this.methodType = '';
        this.filterType = '';
        this.buyerId = '';
        this.lob = '';
        this.vendor = '';
        this.vendorType = '';
        this.vendorAttr = '';
        this.vendorIds = [];
        this.rolesArr = [];
        this.buyerArr = [];
        this.lobArr = [];
        this.vendorTypeArr = [];
        this.contactType = [];
        this.vendorAttrArr = [];
        this.docPath = '';
        this.fileName = '';
    }
    return Event;
}());
export { Event };
