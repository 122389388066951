import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { UserService } from './user.service';
import * as moment from 'moment';

import { map, filter, switchMap } from 'rxjs/operators';
import { env } from 'process';

@Injectable()
export class EventService {

  private dummyEvents = [
    { 'id':1,
      'eventName':'Test Event #1',
      'eventDesc': 'This is the amazing test event. We\'re using dummy data to populate the events screen. <br/><br/>VERY limited HTML should be available for formatting these events.',
      'startDate': '2019-09-01 10:00',
      'endDate':'2019-09-01 11:00',
      'createDate': '2019-07-01 12:00',
      'author': 'TESTADMIN',
      'eventLink': 'https://www.tractorsupply.com/',
      'addToCal': 'test this link',
      'groupName': '',
      'allowedRoles': 'all'
    },
    { 'id':2,
      'eventName':'Karen\'s Birthday',
      'eventDesc': 'Good times.',
      'startDate': '2019-09-04 6:00',
      'endDate':'2019-09-04 22:00',
      'createDate': '2019-09-01 12:00',
      'author': 'TESTADMIN',
      'eventLink': 'https://www.nashvilleplush.com/',
      'addToCal': 'create add to cal links',
      'groupName': '',
      'allowedRoles': 'all'
    },
    { 'id':3,
      'eventName':'Highland Games',
      'eventDesc': 'Pretend you\'re Scottish and drink some homemade mead while you toss a telephone pole in a skirt.',
      'startDate': '2019-09-07 12:00',
      'endDate':'2019-09-07 18:00',
      'createDate': '2019-09-01 12:00',
      'author': 'TESTADMIN',
      'eventLink': 'https://www.tennesseehighlandgames.com/',
      'addToCal': 'create add to cal links',
      'groupName': '',
      'allowedRoles': 'all'
    },
    { 'id':4,
      'eventName':'Test Event #4',
      'eventDesc': 'Test multiple events on a single day',
      'startDate': '2019-09-15 6:00',
      'endDate':'2019-09-15 22:00',
      'createDate': '2019-09-01 12:00',
      'author': 'TESTADMIN',
      'addToCal': 'create add to cal links',
      'groupName': '',
      'allowedRoles': 'all'
    },
    { 'id':5,
      'eventName':'Test Event #5 with a much longer title that will wrap',
      'eventDesc': 'Absolutely Fabulous',
      'startDate': '2019-09-15 12:00',
      'endDate':'2019-09-15 18:00',
      'createDate': '2019-09-01 12:00',
      'author': 'TESTADMIN',
      'groupName': '',
      'allowedRoles': 'all'
    },
    { 'id':6,
      'eventName':'Test Event #6',
      'eventDesc': 'Test display of multiple day events',
      'startDate': '2019-09-25 12:00',
      'endDate':'2019-09-28 18:00',
      'createDate': '2019-09-01 12:00',
      'author': 'TESTADMIN',
      'groupName': '',
      'allowedRoles': 'all'
    }
  ];
  public eventList: Event[] = [];
  public eventListLoaded = false;


  constructor(
    private rest: RestService,
    private users: UserService,
  ) { }

  getEventList(){
    return this.eventList;
  }

  retrieveEvents(){
    // form http request for events from API
    return this.rest.getReq('getEventsDetail',this.users.getHeader()).pipe(map(
      res => {
        console.log('event request', res);
        // this.eventList = res as Event[]; // for httpClient
        this.eventList = res.json();
        this.eventListLoaded = true;
        this.eventList.sort((a,b)=>new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        // parse role list
        for (let ev of this.eventList){
          let rolesArr = [];
          if(ev.roleName && ev.roleName !== ''){
            ev.roleName = ev.roleName.replace('null','all'); // prevent null values
            if (ev.roleName.indexOf(',') > -1) {
              // multiple roles
              rolesArr = ev.roleName.split(',');
            } else {
              // single role
              rolesArr.push(ev.roleName);
            }
          }
          ev.rolesArr = rolesArr;

          // parse buyerId and lob
          ev.buyerArr = [];
          ev.lobArr = [];
          ev.vendorIds = [];
          ev.vendorAttrArr = [];
          if(ev.buyerId && ev.buyerId !==''){
            if(ev.buyerId.indexOf(',')>-1){
              ev.buyerArr = ev.buyerId.split(',');
            } else {
              ev.buyerArr.push(ev.buyerId);
            }
          }
          if(ev.lob && ev.lob !==''){
            if(ev.lob.indexOf(',')>-1){
              ev.lobArr = ev.lob.split(',');
            } else {
              ev.lobArr.push(ev.lob);
            }
          }
          if (ev.vendor && ev.vendor !=='') {
            if(ev.vendor.indexOf(',')>-1){
              ev.vendorIds = ev.vendor.split(',');
            } else {
              ev.vendorIds.push(ev.vendor);
            }
          }
          if (ev.vendorAttr && ev.vendorAttr !=='') {
            if(ev.vendorAttr.indexOf(',')>-1){
              ev.vendorAttrArr = ev.vendorAttr.split(',');
            } else {
              ev.vendorAttrArr.push(ev.vendorAttr);
            }
          }
        }

        // get user role
        let userRole = this.users.getUserRole();
        let userVendorType = [];
        if (this.users.loggedInUser.vendorType && this.users.loggedInUser.vendorType !== '') {
          if (this.users.loggedInUser.vendorType.indexOf(',')>-1) {
            // add unique
            const vt = this.users.loggedInUser.vendorType.split(',');
            if (vt && vt.length > 0) {
              for (let t of vt) {
                const dupType = userVendorType.find(v => v == t);
                if (!dupType) { userVendorType.push(t);}
              }
            }
          } else {
            userVendorType.push(this.users.loggedInUser.vendorType);
          };
        }

        // filter event list by role
        let filteredEvents = [];


        // TIBCO-2533 Vendor Communication
        // Filter by LOB, Vendor ID, or Buyer ID

      if(!userRole || (userRole && userRole.toString().toUpperCase() !== 'TSCADMIN')) {
        // TSCADMIN sees all
        let combinedEv = this.eventList.filter(e => (!e.lob || e.lob =='' ) && (!e.buyerId || e.buyerId == '') && (!e.vendorIds || e.vendorIds.length === 0)); // generic vendor events
        
        console.log('LOB', this.users.loggedInUser.lob);
        if (this.users.loggedInUser && this.users.loggedInUser.lob) {
          // lob
          for (let l of this.users.loggedInUser.lob){
            const ev = this.eventList.filter(a => a.lob && a.lob.indexOf(l) > -1);
            // narrow list, only matching lob
            console.log('events for ' + l, ev);
            for (let e of ev) {
              if (e && !combinedEv.find(m => m.id === e.id)){
                // if it's not already in the list, add it
                combinedEv.push(e);
              }
            }
          }
        }
        if (this.users.loggedInUser && this.users.loggedInUser.buyerId) {
          console.log('BuyerID', this.users.loggedInUser.buyerId);
           // now buyer id
          for (let b of this.users.loggedInUser.buyerId){
            const ev = this.eventList.filter(a => a.buyerId && a.buyerId.indexOf(b) > -1);
            // narrow list, only matching buyer id
            console.log('events for ' + b, ev);
            for (let e of ev) {
              if (e && !combinedEv.find(m => m.id === e.id)){
                // if it's not already in the list, add it
                combinedEv.push(e);
              }
            }
          }
        }
        if (this.users.loggedInUser && this.users.loggedInUser.vendorList) {
          console.log('VendorID', this.users.loggedInUser.vendorList);
           // now vendor id
          for (let v of this.users.loggedInUser.vendorList){
            const ev = this.eventList.filter(a => a.vendorIds && a.vendorIds.indexOf(v.VendorID) > -1);
            // narrow list, only matching vendor id
            console.log('events for ' + v.VendorID, ev);
            for (let e of ev) {
              if (e && !combinedEv.find(m => m.id === e.id)){
                // if it's not already in the list, add it
                combinedEv.push(e);
              }
            }
          }
        }

       console.log('filtered Events', combinedEv);
        // update filtered msglist
        this.eventList = combinedEv;

      }

      let userEvents = [];
      console.log('User Role' + userRole);

        if (userRole) {
          if (userRole.indexOf('TSCAdmin') === -1) {
            if (userRole === 'VendorAdmin') {
              // vendor admin sees all vendor events + vendor admin events
              userEvents = this.eventList.filter(e => e.roleName !== 'TSCAdmin');

            } else {

              // vendor sees generic vendor events + events for their user role only
              userEvents = this.eventList.filter(e => !e.roleName || e.roleName.toLowerCase() === 'null' || e.roleName.toLowerCase() === 'all' || e.roleName === 'Vendor');

              // split the user role list
              let uRoles = '';
              if (userRole.indexOf(',') !== -1) {
                // console.log('user role contains commas, split it!');
                uRoles = userRole.split(/\s*,\s*/g);
              
               console.log('uRoles', uRoles);

              for (let r of uRoles){
                let roleEvents = this.eventList.filter(e => e.roleName.indexOf(r) > -1);
                 console.log('roleEvents for ' + r, roleEvents);
                for (let e of roleEvents){
                  let existing = userEvents.find(ev => ev.id === e.id); // remove duplicates
                  if(!existing){
                    userEvents.push(e);
                  }
                }
              }
            } else {
              console.log('single role');
              const r = userRole;
              let roleEvents = this.eventList.filter(e => e.roleName.indexOf(r) > -1);
                 console.log('roleEvents for ' + r, roleEvents);
                for (let e of roleEvents){
                  let existing = userEvents.find(ev => ev.id === e.id); // remove duplicates
                  if(!existing){
                    userEvents.push(e);
                  }
                }
              }
            }
            // console.log('events', filteredEvents);
          
            // filter by vendor types
            if (userVendorType) {
              filteredEvents = userEvents.filter(e => !e.vendorType || e.vendorType === '' || e.vendorType == 'all' || this.findVendorType(e.vendorType, userVendorType));
            } else {
              filteredEvents = userEvents.filter(e => !e.vendorType || e.vendorType === '' || e.vendorType == 'all' );
            }
            
            let attrEvents = [];
            if (this.users.loggedInUser.vendorList && this.users.loggedInUser.vendorList.length > 0) {
              attrEvents = filteredEvents.filter(a => !a.vendorAttr || a.vendorAttr == '' ||  this.showEventAttr(a));
            } else {
              console.log('no vendor attributes found for this vendor');
              attrEvents = filteredEvents.filter(e => !e.vendorAttr || e.vendorAttr === '');
            }

            filteredEvents = attrEvents;
            console.log('filtered events for ' + userRole, filteredEvents);
          } else {
            // admin sees all events, no filter
            filteredEvents = this.eventList;
          }
        } else {
          // treat as basic vendor
          filteredEvents = this.eventList.filter(e => (!e.roleName || e.roleName === '' || e.roleName.toLowerCase() === 'null' || e.roleName.toLowerCase() === 'all' || e.roleName === 'Vendor') 
                                                        && (!e.lob || e.lob==='') && (!e.vendorIds || e.vendorIds.length === 0) && (!e.buyerId || e.buyerId === '')
                                                        && (!e.vendorType || e.vendorType === '') && (!e.vendorAttr || e.vendorAttr === ''));
          console.log('no role detected', userRole, filteredEvents);
          }
          
          this.eventList = filteredEvents;
 
          // filter by vendor attributes
          /*
          if (this.users.loggedInUser.vendorAttributes && this.users.loggedInUser.vendorAttributes !=='' ) {
            let vendorattr = [];
            if (this.users.loggedInUser.vendorAttributes.indexOf(',') > -1) {
              vendorattr = this.users.loggedInUser.vendorAttributes.split(',');
            } else {
              vendorattr = [this.users.loggedInUser.vendorAttributes];
            }
            // loop through all vendor attributes
            for (let v of vendorattr) {
              let vatr = filteredEvents.filter(e => !e.vendorAttr || e.vendorAttr === '' || e.vendorAttr.indexOf(v)-1);
              if (vatr) {
                for (let x of vatr) {
                  let foundx = attrEvents.find(e => e.id === x.id);
                  if(!foundx) {
                    attrEvents.push(x);
                  }
                }
              }
            }
            this.eventList = attrEvents;

          } else {
            attrEvents = filteredEvents.filter(e => !e.vendorAttr || e.vendorAttr === '');
            this.eventList = attrEvents;
          }
          
          */

        // console.log('final event list', this.eventList);
        return this.eventList;
      }
    ));
  }

  findVendorType(csvField,typesArr) {
    for (let t of typesArr) {
      if (csvField.indexOf(t)>-1) {
        return true;
      }
    }
    return false;
  }

  showEventAttr(event) {
    // include this event in the list
    let showMe = true;
    const vendorsWithAttributes = this.users.loggedInUser.vendorList.find(v => v.VendorAttribute && v.VendorAttribute !== '');
    if (event.vendor === '' || event.vendorIds.length === 0) {
      // no vendor ID, check for any attribute in vendorList
      // if announcement/event has any Vendor Attribute, only show to those vendors
      if (event.vendorAttrArr && event.vendorAttrArr.length > 0) {
        showMe = false;
        if(vendorsWithAttributes) {
          for (let attr of event.vendorAttrArr){
            for (let vnum of vendorsWithAttributes) {
              console.log('looking for ', attr, vnum);
              if (vnum.VendorAttribute.indexOf(attr)>-1) {
                showMe = true;
                console.log('attribute found, show', vnum.VendorAttribute, attr, event);
              }
            }
          }
        }
      }

    } else {
      // message assigned to vendor id, check if attribute is also assigned
      if (event.vendorAttrArr && event.vendorAttrArr.length > 0) {
        showMe = false;
        if(vendorsWithAttributes) {
          for (let v of event.vendorIds) {
            const thisVendor = vendorsWithAttributes.find(x => v.VendorID === v);
            if (thisVendor && thisVendor.VendorAttributes) {
              for (let attr of event.vendorAttrArr) {
                console.log('looking for ', attr, thisVendor.VendorAttributes);
                if (thisVendor.VendorAttributes.indexOf(attr)>-1) {
                  showMe = true;
                  console.log('attribute found for ' + v, attr, thisVendor, event);
                }
              }
            }
          }
        }
      }
    }
    console.log(showMe, event);
    return showMe;
  }

  generateICAL(event){
    // iCal format
    // "BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nUID:9832@meetingplanner.io\nDTSTART:" + bookingInfo.StartDate + "\nDTEND:" + bookingInfo.EndDate + "\nDTSTAMP:" + bookingInfo.StartDate + "\nLOCATION: " + bookingInfo.SpaceDescription + "\nSUMMARY: " + bookingInfo.EventDescription + "\nSEQUENCE:0\nTRANSP:OPAQUE\nEND:VEVENT\nEND:VCALENDAR";
    
    /*
    BEGIN:VCALENDAR
    VERSION:2.0
    PRODID:-//hacksw/handcal//NONSGML v1.0//EN
    BEGIN:VEVENT
    UID:uid1@example.com
    DTSTAMP:19970714T170000Z
    ORGANIZER;CN=John Doe:MAILTO:john.doe@example.com
    DTSTART:19970714T170000Z
    DTEND:19970715T035959Z
    SUMMARY:Bastille Day Party
    GEO:48.85299;2.36885
    END:VEVENT
    END:VCALENDAR
*/
    let iCal = 'BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\n';
    let calDetails = '';
    
    // populate details
    if(event){
      // format dates
      let start = moment(event.startDate).utc().format('YYYYMMDDTHHmmss') + 'Z';
      let end = moment(event.endDate).utc().format('YYYYMMDDTHHmmss') + 'Z';
      let safeDesc = event.eventDesc.replace(/[\r\n]+/g,"\\n"); // handle newlines

      if (event.eventLink && event.eventLink !== ''){
        safeDesc = safeDesc + "\\n\\n" + event.eventLink;
      }

      // UID unique event identifier
      calDetails = 'UID:uuid' + event.id + event.authorName + '\nDTSTART:' + start + '\nDTEND:' + end + '\nDTSTAMP:' + start + '\nSUMMARY: ' + event.eventName + '\nDESCRIPTION: ' + safeDesc;
       if (event.eventLocation) {
         calDetails = calDetails + '\nLOCATION: ' + event.eventLocation;
       }
      
      iCal = iCal + calDetails;
    }
    iCal = iCal + '\nSEQUENCE:0\nTRANSP:OPAQUE\nEND:VEVENT\nEND:VCALENDAR';

    console.log(iCal);
    return iCal;
  }

}

export class Event {
  public id: String = '';
  public eventName: String = '';
  public eventDesc: String = '';
  public startDate: Date = null;
  public endDate: Date = null;
  public createDate: Date = null;
  public authorName: String = '';
  public roleName: String = 'all';
  public groupName: String = '';
  public modifyDate: String = '';
  public lastModifiedBy: String = '';
  public eventLink: String = '';
  public eventLocation: String = '';
  public deleteInd: String = '';
  public methodType: String = '';

  public filterType: String = '';
  public buyerId: String = '';
  public lob: String = '';
  public vendor: String = '';
  public vendorType: String = '';
  public vendorAttr: String = '';

  public vendorIds: String[] = [];
  public rolesArr: String[] = [];
  public buyerArr: String[] = [];
  public lobArr: String[] = [];
  public vendorTypeArr: String[] = [];
  public contactType: String[] = [];
  public vendorAttrArr: String[] = [];
  

  public docPath: String = '';
  public fileName: String = '';
  public file: File;

}
