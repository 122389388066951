import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { UserService } from './../services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "./user.service";
var ManageInvitesService = /** @class */ (function () {
    function ManageInvitesService(router, http, users) {
        this.router = router;
        this.http = http;
        this.users = users;
        this.vendorInviteList = [];
        this.headers = {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': this.users.getHeader()
        };
        // private api = environment.merchapi = '/svc/' ? '/dev-svc/' : environment.merchapi; // for local dev with DEV env only... change for QA deployment
        this.api = environment.merchapi;
    }
    ManageInvitesService.prototype.checkPermissions = function () {
        // get current user, check roles
        var currentUser = this.users.loggedInUser;
        var userAllowed = false;
        var allowedRoles = ['VMD']; // roles with permission to view
        // redirect user with no permission
        if (allowedRoles.indexOf(this.users.getUserRole()) > -1) {
            userAllowed = true;
        }
        else {
            // try roles from MV User Table
            var userRoles = [];
            if (currentUser.roleName && currentUser.roleName.indexOf(',') > -1) {
                // retrieve all roles
                var allRoles = currentUser.roleName.split(',');
                userRoles = allRoles;
            }
            else {
                userRoles.push(currentUser.roleName);
            }
            for (var _i = 0, userRoles_1 = userRoles; _i < userRoles_1.length; _i++) {
                var role = userRoles_1[_i];
                console.log('User has role: ' + role);
                if (allowedRoles.indexOf(role) > -1) {
                    userAllowed = true;
                }
            }
        }
        if (!userAllowed) {
            console.log('*** User Not Allowed ***');
            this.router.navigate(['error']);
        }
    };
    ManageInvitesService.prototype.getDuplicateVendors = function () {
        var url = this.api + 'getduplicatevendorinfo';
        return this.http.get(url, { headers: this.headers }).pipe(map(function (data) {
            return data;
        }, function (error) {
            return error;
        }));
    };
    ManageInvitesService.prototype.postApprovalStatus = function (request) {
        var url = this.api + 'getvmdapprovalstatus';
        return this.http.post(url, request, { headers: this.headers }).pipe(map(function (data) {
            return data;
        }, function (error) {
            return error;
        }));
    };
    ManageInvitesService.ngInjectableDef = i0.defineInjectable({ factory: function ManageInvitesService_Factory() { return new ManageInvitesService(i0.inject(i1.Router), i0.inject(i2.HttpClient), i0.inject(i3.UserService)); }, token: ManageInvitesService, providedIn: "root" });
    return ManageInvitesService;
}());
export { ManageInvitesService };
var VendorInviteDetail = /** @class */ (function () {
    function VendorInviteDetail() {
    }
    return VendorInviteDetail;
}());
export { VendorInviteDetail };
var DuplicateVendorDetail = /** @class */ (function () {
    function DuplicateVendorDetail() {
    }
    return DuplicateVendorDetail;
}());
export { DuplicateVendorDetail };
