<div class="container">
    <div class="row">
      <div class="col-sm-12 bgimg"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2>Frequently Asked Questions</h2>

        <div class="questions-list">
          <div class="row" *ngFor="let faq of faqList; let x = index;">
              <div class="col-sm-12">
                  <div class="faq-group">
                    <a class="question" (click)="toggleCollapse('faq'+x, $event)"><i class="indicator material-icons">arrow_drop_down_circle</i>{{faq.q}}</a>
                    <div id="{{'faq' + x}}" class="answer closed" [innerHTML]="faq.a"></div>
                  </div>
                </div>
              
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- 
          <div class="col-sm-12">
            <div class="faq-group">
              <a class="question" (click)="toggleCollapse('faq1', $event)"><i class="indicator material-icons">arrow_drop_down_circle</i>To be or not to be?</a>
              <div id="faq1" class="answer closed">
                  <strong>That</strong> is the question.
              </div>
            </div>
          </div>
  -->
