import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { VendorApiService } from './../vendor-services/vendor-api.service';
import { UserService } from './../services/user.service';
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


type AOA = Array<Array<any>>;
function s2ab(s: string): ArrayBuffer {
  const buf: ArrayBuffer = new ArrayBuffer(s.length);
  const view: Uint8Array = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

@Injectable({
  providedIn: 'root'
})
export class VacService {
  // TIBCO-3533
  public vacBuyer = [];
  public vacVendor;
  private sheetData: BehaviorSubject<any> = new BehaviorSubject('');
  public isBuyerAdmin = false;
  public currBuyer = []; // hold the list of buyers

  // edit VAC functionality - TIBCO-3618
  private isRevision = false;
  private selectedVAC = new VendorAllowanceConfirmation();

  constructor(private rest: RestService, private api: VendorApiService, private user: UserService, private router: Router) { }

  submitVACrequest(json) {
    let url = 'sendvacinfotodocusign';
    return this.api.vacReq(url, json).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));

  }

  getBuyerInfo(){
   return this.api.getBuyerDetails().pipe(map((data: any) => {
      // get unique
      console.log('Buyers', data.Data);
      if(data && data.Data) {
        for (let d of data.Data) {
          // let dup = this.vacBuyer.find(b => b.Email === d.Email);
          // console.log(d.Email + ' ' + d.BuyerID);
          let dup = this.vacBuyer.find(b => b.Email === d.Email && b.BuyerID === d.BuyerID);
          // console.log('duplicate', dup);
          if(!dup) {
            // console.log('add to list', d);
            // check for buyer and vendor types match
            if (
              (this.user.getUserRole() === 'PTS_Buyer' && d.VendorType.indexOf('PTS') > -1) ||
              (this.user.getUserRole() !== 'PTS_Buyer' && d.VendorType.indexOf('PTS') === -1)
              ) {
                  this.vacBuyer.push(d);
                }
          }
        }
      }
      // this.vacBuyer = data.Data;
      console.log(this.vacBuyer);
      return data.Data;
    }));
  }

  getVendorInfo(vendor, email?) {
    // let url = this.api + 'queryvendorinfo';
    // format request from vendor info
    console.log(vendor);
    let json = {};
    if (email) {
      json['Email'] = vendor; //this.users.loggedInUser.userName
    } else {
      json['VendorID'] = vendor;
    }
    console.log(json);
    // return this.api.apiReq(url, json).pipe(map(
    return this.api.requestAddVendor(json).pipe(map(
      data => {
       this.vacVendor = data;
       return this.vacVendor;
      }
    ));
  }

  retrieveVendorInformation(vendorId){
    console.log("Vendor ID:",vendorId);
    let json = {};
    json['VENDOR_ID'] = vendorId;
    const authToken = this.user.getHeader();
    return this.rest.bwReqVendorInfo('getvendorinfo',json,authToken).pipe(
      map( data =>{
        console.log("FINAL RESPONSE:",data);
        this.vacVendor = data;
        return this.vacVendor;
      },
      (err:HttpErrorResponse) =>{
         return err;
      }
      )
    )

  }

  initializeVAC(vendor?){
    console.log(this.vacBuyer);
    if (!this.vacBuyer || this.vacBuyer.length === 0) {
      console.log('load buyer list', this.vacBuyer);
      this.getBuyerInfo().subscribe(
        buyerdata => {
        // match with current user
        // if match, allow access to only buyer's VAC (add filter in search or filter results if needed)
        // may be more than one buyer ID
        // otherwise, treat as buyer admin and allow access to all domestic vacs (not pts)
        console.log('username:', this.user.loggedInUser);
        console.log('buyerlist', buyerdata);

        if(this.user.loggedInUser.groupName.indexOf('Buyer')>-1 && this.user.loggedInUser.userName) {
          this.currBuyer = buyerdata.filter(b => b.Email === this.user.loggedInUser.userName);
          if (!this.currBuyer || this.currBuyer.length === 0) {
            // admin
            console.log('current logged in buyer is admin');
            this.isBuyerAdmin = true;
            this.currBuyer = [];
          } else {
            // assign buyer ids
            this.isBuyerAdmin = false;
            console.log('current logged in buyer', this.currBuyer);

          }
        }

      });
    }
    if(vendor && !this.vacVendor) {
      this.getVendorInfo(vendor);
    }
    console.log('initialize Buyer and Vendor', this.vacBuyer, this.vacVendor);
  }

  getPromoDetails(company?) {
    if(!company){
      company = 'TSC';
    }
    if (company === 'PETSENSE') {
      company = 'PTS';
    }
    let json = {'Buyer':company};
    let url = 'getpromodetails';
    return this.api.vacReq(url, json).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

  getVACinfo(request) {
    const url = 'getvacinfofrommdm';
    return this.api.vacReq(url, request).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

  getSheetData(){
    return this.sheetData.asObservable();
  }

  importSheet(evt:any, existingData){
    
    let startTime = Date.now();
    let loadTime = null;
    let arrayContent = [];
    let xlsxData = [];
    let tempData = [];

    let _self = this;

    console.log('import event', evt);

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      xlsxData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      arrayContent = xlsxData;
      // console.log(JSON.stringify(this.arrayContent));
      var rowCount = arrayContent.length;

      // if(rowCount && rowCount <= 1001){
      console.log('loading ' + (rowCount - 1) + ' rows from worksheet');

      var objArray = [];
      for (let r = 1; r < rowCount; r++) {
        // add header as key for each column
        objArray[r - 1] = {};
          for (var k = 0; k < arrayContent[0].length && k < arrayContent[r].length; k++) {
              var key = arrayContent[0][k];
             
              if(key){
                objArray[r - 1][key] = this.replaceNull(arrayContent[r][k]);
              }


        }

        if (r % 100 === 0){
          console.log('loading row ' + r + ' of ' + rowCount);
        }
        
      }
        console.log('import spreadsheet', objArray);
        // console.log('all attributes', this.fam.attrList);
        loadTime = Date.now();
        console.log('spreadsheet to object in ' + (loadTime - startTime) + 'ms' );

        // copy sheetData
       
        // tempData = JSON.parse(JSON.stringify(this.sheetData));
        tempData = JSON.parse(JSON.stringify(objArray));
        // console.log(tempData);     
    
      let parseTime = Date.now();
      console.log('previous sheet', existingData);
      console.log('updated sheet in '+ (parseTime - loadTime) + 'ms', tempData);

      this.sheetData.next(this.copyArrayObject(tempData));
    };
  

  reader.readAsBinaryString(target.files[0]);
 
}

  exportSheet(hot){
    let exportFileName = '';

    const headers = hot.getColHeader();
    //console.log(description)
    const sheet = hot.getData();
    let fname = 'VAC Scandown Promo Data';

    // add headers to top row
    sheet.unshift(headers);
    //sheet.push();
   
    // remove last column in each row
    for (let row of sheet) {
      const btns = row.pop();
      const fcst = row.pop(); // remove forecast also
    }
    // prepare csv export
    this.downloadCSV(sheet, fname);

  }

  downloadCSV(sheet, filename) {
    let values = [];
    // convert number format to string
    for (let x of sheet){
      const row = x.map(String);

      for (let col in row) {
        if(!row[col] || row[col] === null || row[col] === 'null'){
          row[col] = '';
        }
      }
      values.push(row);
      
    }
    console.log('CSV sheet',values);
    
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(values);

    /* save to file */
    const wbout:string = XLSX.utils.sheet_to_csv(ws);
    saveAs(new Blob([s2ab(wbout)]), filename + '.csv');
  }

  copyArrayObject(obj) {
    return obj.map(x => Object.assign({}, x));
  }

replaceNull(val) {
    if(!val || val === null || val === 'null'){
      console.log('replace null here', val);
      val = '';
    }
    console.log('replaced', val);
    return val;
  }



// edit VAC functionality - TIBCO-3618

getRevisionStatus() {
  return this.isRevision;
}

setRevisionStatus(status:boolean) {
  if (status===true) {
    this.isRevision = true;
  } else {
    this.isRevision = false;
  }
}

selectVAC(vac) {
  this.selectedVAC = vac;
  this.setRevisionStatus(true);
}

getSelectedVAC(){
  return this.selectedVAC;
}

resetSelectedVAC() {
  this.selectedVAC = null;
  this.setRevisionStatus(false);
  console.log('VAC reset', this.getSelectedVAC(), this.getRevisionStatus());
}

goToEditVAC() {
  this.router.navigate(['/vendorAllowance']);
}





}

export class VacUpdateRequest {
  DocumentData: VacDocData;
  VendorCorporateInformation: VacCorpInfo;
  VendorCompanyContacts: {BuyersVendorContact_SC: VacCompanyContact};
  VACFields: VendorAllowanceConfirmation;
}

export class VendorAllowanceConfirmation {
  ScandownFlag:string = 'Y';
  VACID:string = '';
  PromoType:string = '';
  PromoAbbreviation:string = '';
  PromoDescription:string = '';
  IsManufacturer:string = 'N';
  EndDate:string = ''; // format 19-JUL-2021,
  BeginDate:string = ''; // format 19-JUL-2021,
  // CompletionDate:string = null; // format 19-JUL-2021,
  MfrName:string = '';
  MfrEmail:string = '';
  PromoDetails: VacPromoDetails;
  SubmittedBy: string = '';
}

export class VacDocData {
  OnboardingStatus:string = 'Submitted';
  VoidBy?:string = '';
  VoidReason?:string = '';
  RejectedBy?:string = '';
  RejectedReason?:string = '';
}

export class VacPromoDetails {
  ScanDownPromo: VacScanDown[];
  ScanDownPromoPercent?: VacScanDownPercent[];
  FlatDolAmt:string = '';
  FlatDollarSkuList:string = '';
  SpecialInstructions:string = '';
  EstimatedUnits:string = '';
  PaymentDate:string = ''; // format 19-JUL-2021
  Rainchecks:string = '';
  TotalSKUCount:string = '';
  PaymentType:string = '';
  EstimatedAmt:string = '';
  Frequency:string = '';
  NoOfPayments:string = '';
}

export class VacScanDown {
  Forecast:string = '';
  SKUDescription:string = '';
  SKU:string = '';
  ScandownAmt:any = null;
  Units:number = null;
}

export class VacScanDownPercent {
  SKU:string = '';
  SKUDescription:string = '';
  ScandownAmtPercent:any = null;
  Sales$:number = null;
  Result:string = '';
}

export class VacCompanyContact {
  ContactType:string;
  FirstName:string;
  LastName:string;
  PhoneNo:string;
  Email:string;
}

export class VacCorpInfo {
  BuyerID:string;
  BuyerCompany:string;
  BuyerFirstName:string;
  BuyerLastName:string;
  BuyerEmail:string;
  CompanyName:string;
  VendorID:string;
  VendorType:string;
}

export class VacSearchResult {
  VACID:string = '';
  BuyerID:string = '';
  BuyerEmail: string = '';
  VendorID:string = '';
  VendorType:string = '';
  IsVACActive:string = '';
  OnboardingStatus: string = '';
  PromoType:string = '';
  PromoAbbreviation:string = '';
  PromoDescription:string = '';
  BeginDate:string = ''; // format 19-JUL-2021,
  EndDate:string = ''; // format 19-JUL-2021,
  PromoDetails: VacPromoDetails;

}


// UI uses same VAC ID for Rejected VACs and Status=Rejected
// UI uses new VAC ID for VOID VACs


/*


 {
    "DocumentData": {
        "OnboardingStatus": "Submitted"
    },
    "VendorCorporateInformation": {
        "VendorID": "543748",
        "BuyerID": "115",
        "BuyerCompany": "TSC",
        "BuyerFirstName": "David",
        "BuyerLastName": "King",
        "BuyerEmail": dking@tractorsupply.com,
        "CompanyName": "PILOT AUTOMOTIVE INC"
    },
    "VendorCompanyContacts": {
        "BuyersVendorContact_SC": {
            "ContactType": "SC",
            "FirstName": "Chris",
            "LastName": "Crabill",
            "PhoneNo": "352-735-2999",
            "Email": inf_smallick@tractorsupply.com
        }
    },
    "VACFields": {
        "ScandownFlag": "Y",
        "PromoAbbreviation": "MD",
        "IsManufacturer": "Y",
        "VACID": "",
        "PromoType": "CIRCULAR",
        "PromoDescription": "Dev testing",
        "CompletionDate": "",
        "MfrName": "Ariat",
        "EndDate": "19-JUL-2021",
        "BeginDate": "19-JUL-2021",
        "PromoDetails": {
            "ScanDownPromo": [
                {
                    "Forecast": "test1",
                    "SKUDescription": "Clothing",
                    "SKU": "10003",
                    "ScandownAmt": "99",
                    "Units": "50"
                },
                {
                    "Forecast": "test2",
                    "SKUDescription": "Clothing",
                    "SKU": "10004",
                    "ScandownAmt": "99",
                    "Units": "50"
                }
            ],
            "FlatDolAmt": "99",
            "SpecialInstructions": "NA",
            "EstimatedUnits": "50",
            "PaymentDate": "19-JUL-2021",
            "PaymentType": "NA",
            "Rainchecks": "Y",
            "TotalSKUCount": "2",
            "EstimatedAmt": "30"
        }
    }
}

*/
