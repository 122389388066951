<div class="container">
  <h6>New Vendor Onboarding</h6>

  <div *ngIf="showMsg!=''" class="myDiv">
    <div class="row">
      <div  *ngIf="showMsg=='User has been added successfully'" class="col-xs-12">
        <p class="alert alert-success">
          <strong>{{showMsg}}</strong> Do you want to go Home page ?<button class="btn btn-success" (click)="clearMessage()">Yes</button><button class="btn btn-light" (click)="clearValues()">No</button>
        </p>
      </div>
      <div  *ngIf="showMsg!='User has been added successfully'" class="col-xs-12">
        <p class="alert alert-danger">
          <strong>{{showMsg}}</strong> Do you want to go Home page ?<button class="btn btn-info" (click)="clearMessage()">Yes</button><button class="btn btn-light" (click)="clearValues()">No</button>
        </p>
      </div>
    </div>
  </div>

  <div (click)="clearValues()">
    <div *ngIf="venodorInvite.buyerCompany=='TSC'">
      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <p>Complete the form below to begin the process of creating a TSC Vendor Partner</p>
          </div>
        </div>
        <div class="col-sm-4">
          <img src="'../../assets/TSC JPG.jpg" alt="TSC"/>
        </div>
      </div>
    </div>

    <div *ngIf="venodorInvite.buyerCompany=='PETSENSE'">
      <div class="row">
        <div class="col-sm-8">
          <div class="intro">
            <p>Complete the form below to begin the process of creating a PetSense Vendor Partner</p>
          </div>
        </div>
        <div class="col-sm-4">
          <img src="'../../assets/petsense_logo.png" alt="petSense"/>
        </div>
      </div>
    </div>

    <form #form="ngForm" (ngSubmit)="onSubmit(form)" ngNativeValidate>
      <div class="row">
        <div class="col-sm-5">
          <label for="vendorName">Vendor Name</label>
          <input #vendorNameInputTextRef type="text" id="vendorName"  name="vendorName" class="form-control"  maxlength="45" required
                 [(ngModel)]="venodorInvite.company" (change)="vendorNameChange(venodorInvite.company)">
          
        </div>
        <div class="col-sm-5">
         
          <div class="text-center loader-icon"  *ngIf="isLoading">
            <mat-spinner [diameter]="25"></mat-spinner>
           </div> 
        </div>
      </div>
      <div class="row" *ngIf="onboarindReasonText.length > 0">
        <div class="col-sm-5">
          <label for="reasonToContinue">Reason </label>
          <textarea type="text" class="form-control" readonly >{{onboarindReasonText.trim()}}</textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <label for="userName">User Name/Email Address</label>
          <input type="email" id="userName" class="form-control" name="userName"  maxlength="45" required title="Required: Please enter a valid email address"
                 [(ngModel)]="venodorInvite.email"  pattern="^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$"
                 oninvalid="setCustomValidity('Enter a valid email address')"
                 oninput="setCustomValidity('')" 
                 (change)="setBuyerContactInfo()" >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <label for="firstName">First Name</label>
          <input id="firstName" name="firstName" class="form-control"  maxlength="45" required title="Required: Please enter a name without numbers or special characters"
                  [(ngModel)]="venodorInvite.firstName" pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a valid value without Numbers & Special Characters')"
                 oninput="setCustomValidity('')" 
                 (change)="setBuyerContactInfo()">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <label for="lastName">Last Name</label>
          <input id="lastName" name="lastName" class="form-control"  maxlength="45"  required title="Required: Please enter a name without numbers or special characters"
                 [(ngModel)]="venodorInvite.lastName" pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a valid value without Numbers & Special Characters')"
                 oninput="setCustomValidity('')" 
                 (change)="setBuyerContactInfo()">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-7">
          <div class="intro">
            <table>
              <tr>
                <td><label class="introLab" for="isPlaceHolderVendor">Is this Existing placeHolder Vendor?</label></td>
                <td>
                  <div><select class="form-control" name="isPlaceHolderVendor" required id="isPlaceHolderVendor" [value]="isPlaceHolderVendor"
                               (input)="isPlaceHolderVendor=$event.target.value" checked>
                    <option>Y</option>
                    <option>N</option>
                  </select></div>
                </td>

                <td>
                  <div *ngIf="isPlaceHolderVendor=='Y'"><input id="vendorNumber" class="form-control" type="text"
                                                               name="vendorNumber"
                                                               placeholder="Vendor Number" maxlength="6" required
                                                               [(ngModel)]="venodorInvite.vendorNumber" pattern="[0-9]{6}"
                                                               (change)="setAddVendorType(form)"><div *ngIf="vendorNumberError" class="alert alert-danger"> Please enter a valid vendor number</div></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="venodorInvite.buyerCompany=='TSC'">
        <ng-container *ngIf="isPlaceHolderVendor!='Y'">
          <div class="row">
            <div class="col-sm-5">
              <label for="vendorTypey">Vendor Type</label>
              <select class="form-control" name="vendorType" required id="vendorTypey"
                      [(ngModel)]="venodorInvite.vendorType" (change)="onVendorTypeChange()">
                <option>Domestic</option>
                <option>Dropship</option>
                <option>Import</option>
                <option value="LiveGoods">Live Goods</option>
                <option value="Hay">Hay Forage</option>
                <option>PlaceHolder-Domestic</option>
                <option>PlaceHolder-Import</option>
              </select>
            </div>
          </div>
        </ng-container>

          <ng-container *ngIf="isPlaceHolderVendor=='Y'">
            <div class="row">
            <div class="col-sm-5">
                <label for="vendorTypen">Vendor Type</label>
                <select class="form-control" name="vendorType" required id="vendorTypen"
                        [(ngModel)]="venodorInvite.vendorType">
                  <option *ngIf="this.placeHolderImport">Import</option>
                  <option *ngIf="this.placeHolderDomestic">Domestic</option>
                </select>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="venodorInvite.buyerCompany=='PETSENSE'">
          <div class="row">
            <div class="col-sm-5">
              <label for="vendorType1">Vendor Type</label>
              <select class="form-control" name="vendorType"  id="vendorType1"
                      [(ngModel)]="venodorInvite.vendorType">
                <option value="PTSManufacturer">Manufacturer</option>
                <option value="PTSDistributor">Distributor</option>
              </select>
            </div>
          </div>
        </div>

      <div class="row">
        <div class="col-sm-5">
          <label>Buyer Id</label>
          <ng-container *ngIf="!form.dirty || (form.dirty && countBuyers(venodorInvite.vendorType) > 0)">
            <select class="form-control" id="buyer" [(ngModel)]="venodorInvite.buyer" name="buyer" > <!-- required -->
              <ng-container *ngFor="let buyerinfo of buyerData; let i = index">
                <option *ngIf="buyerinfo.VendorType && buyerinfo.VendorType === venodorInvite.vendorType"
                        value={{i}}>{{buyerinfo.BuyerID}}&nbsp;{{buyerinfo.FirstName}}&nbsp;{{buyerinfo.LastName}}
                </option>
              </ng-container>
            </select>
          </ng-container>
          
          <ng-container *ngIf="form.dirty && countBuyers(venodorInvite.vendorType) === 0">
              <select class="form-control" disabled required></select>
               <span class="text-danger">
                     No buyers are found for {{ venodorInvite.vendorType }}.
               </span>
          </ng-container>

          <input type="hidden" id="buyerEmail" name="buyerEmail" [(ngModel)]="venodorInvite.buyerEmail">
        </div>
      </div>

      <div *ngIf="venodorInvite.buyerCompany=='TSC'">

        <div *ngIf="venodorInvite.vendorType=='Domestic'">
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor Scan-based:
                </label></td>
                <td><input class="form-check-input" type="radio" name="scanBased" id="scanYes" value="Y" required
                           [(ngModel)]="venodorInvite.isScanBased">
                  <label class="form-check-label" for="scanYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="scanBased" id="scanNo" value="N" required
                           [(ngModel)]="venodorInvite.isScanBased">
                  <label class="form-check-label" for="scanNo">No</label></td>
              </tr>
            </table>
          </div>
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor a test vendor:
                </label></td>
                <td><input class="form-check-input" type="radio" name="testVendor" id="testVendorYes" value="Y" required
                           [(ngModel)]="venodorInvite.isTestVendor">
                  <label class="form-check-label" for="testVendorYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="testVendor" id="testVendorNo" value="N" required
                           [(ngModel)]="venodorInvite.isTestVendor">
                  <label class="form-check-label" for="testVendorNo">No</label></td>
              </tr>
            </table>
          </div>
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor VMI:
                </label></td>
                <td><input class="form-check-input" type="radio" name="vmiVendor" id="vmiVendorYes" value="Y" required
                           [(ngModel)]="venodorInvite.isVendorVMI">
                  <label class="form-check-label" for="vmiVendorYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="vmiVendor" id="vmiVendorNo" value="N" required
                           [(ngModel)]="venodorInvite.isVendorVMI">
                  <label class="form-check-label" for="vmiVendorNo">No</label></td>
              </tr>
            </table>
          </div>
          <div *ngIf="venodorInvite.isScanBased == 'N' && venodorInvite.isVendorVMI=='N' && venodorInvite.isTestVendor=='N'">
            <div class="row">
              <div class="col-sm-5">
                <label for="orderPlacement">If EDI not required for initial order placement</label>
                <select class="form-control" name="orderPlacement"  id="orderPlacement" required
                        [(ngModel)]="venodorInvite.orderPlacement">
                  <option>One Time Buy</option>
                  <option>Temporary Fax PO</option>
                  <option>Hay Forage</option>
                </select>
              </div>
            </div>
          </div>

          <div *ngIf="venodorInvite.isScanBased == 'N' && venodorInvite.isVendorVMI=='N' && venodorInvite.isTestVendor=='Y'">
            <div class="row">
              <div class="col-sm-5">
                <label for="orderPlacement">If EDI not required for initial order placement</label>
                <select class="form-control" name="orderPlacement"  id="orderPlacementtest" required
                        [(ngModel)]="venodorInvite.orderPlacement">
                  <option>Temporary Fax PO</option>
                </select>
              </div>
            </div>
          </div> 
        </div>
        <div *ngIf="venodorInvite.vendorType=='LiveGoods'">

          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor Scan-based:
                </label></td>
                <td><input class="form-check-input" type="radio" name="scanBased" id="scanYes" value="Y" required
                           [(ngModel)]="venodorInvite.isScanBased">
                  <label class="form-check-label" for="scanYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="scanBased" id="scanNo" value="N" required
                           [(ngModel)]="venodorInvite.isScanBased">
                  <label class="form-check-label" for="scanNo">No</label></td>
              </tr>
            </table>
          </div>
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor VMI :
                </label></td>
                <td><input class="form-check-input" type="radio" name="vmiVendor" id="vmiVendorYes" value="Y" required
                           [(ngModel)]="venodorInvite.isVendorVMI">
                  <label class="form-check-label" for="vmiVendorYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="vmiVendor" id="vmiVendorNo" value="N" required
                           [(ngModel)]="venodorInvite.isVendorVMI">
                  <label class="form-check-label" for="vmiVendorNo">No</label></td>
              </tr>
            </table>
          </div>
          <div *ngIf="venodorInvite.isScanBased == 'N' && venodorInvite.isVendorVMI=='N'">
            <div class="row">
              <div class="col-sm-5">
                <label for="orderPlacement">If EDI not required for initial order placement</label>
                <select class="form-control" name="orderPlacement"  id="orderPlacement" required
                        [(ngModel)]="venodorInvite.orderPlacement">
                  <option>One Time Buy</option>
                  <option>Temporary Fax PO</option>
                  <option>Hay Forage</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="venodorInvite.vendorType=='Dropship'">
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Does the vendor have online RTV exceptions ?
                </label></td>
                <td><input class="form-check-input" type="radio" name="rtvException" id="rtvExceptionYes" value="Y" required
                           [(ngModel)]="venodorInvite.isRTVExceptions">
                  <label class="form-check-label" for="rtvExceptionYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="rtvException" id="rtvExceptionNo" value="N" required
                           [(ngModel)]="venodorInvite.isRTVExceptions">
                  <label class="form-check-label" for="rtvExceptionNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="venodorInvite.vendorType=='Import' || venodorInvite.vendorType=='PlaceHolder-Import' ">
          <div class="row">
            <div class="col-sm-6">
              <label for="sourceAgentValue">Sourcing Agent (Select "No Agent" if a sourcing agent is not required)</label>
              <select class="form-control" name="sourceAgentValue" id="sourceAgentValue"
                      [(ngModel)]="venodorInvite.sourcingAgent">
                <option>No Agent</option>
                <option>ALLIANCE TRADEHOUSE PVT LTD</option> 
                <option>CO LYNCH ENTERPRISES INC</option>
                <option>DIRECT PRODUCT SOURCING INTERNATIONAL (DPS)</option>
                <option>FLAT WORLD HOME PVT LTD</option>               
                <option>LF FASHION PTE LTD</option>
                <option>TSP SOURCING INDIA PVT LTD</option>
               <!-- <option>PRESTIGE GLOBAL CO.LTD</option> -->
                
              </select>
            </div>
          </div>

          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Is this vendor supplying exclusive brands? :
                </label></td>
                <td><input class="form-check-input" type="radio" name="exclusiveBrand" id="exclusiveYes" value="Y" required
                           [(ngModel)]="venodorInvite.isExclusiveBrands">
                  <label class="form-check-label" for="exclusiveYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="exclusiveBrand" id="exclusiveNo" value="N" required
                           [(ngModel)]="venodorInvite.isExclusiveBrands">
                  <label class="form-check-label" for="exclusiveNo">No</label></td>
              </tr>
            </table>
          </div>
        </div>

        <div *ngIf="venodorInvite.vendorType=='PlaceHolder-Domestic' || venodorInvite.vendorType=='PlaceHolder-Dropship' || venodorInvite.vendorType=='PlaceHolder-Import'">
          <div class="row">
            <div class="col-sm-5">
              <label for="expectedOrderDate">Expected Order Date</label>
              <input type="date" id="expectedOrderDate" class="form-control" name="expectedOrderDate" class="form-control" required
                      min="{{ tomorrow | date:'yyyy-MM-dd'}}" oninput="setCustomValidity('')" [value]="venodorInvite.expectedOrderDate | date:'yyyy-MM-dd'"
                      (change)="validateDate(form)" [(ngModel)]="venodorInvite.expectedOrderDate">
            </div>
          </div> <!--  -->

          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Freight Terms and Lead Time :
                </label></td>

                <div *ngIf="venodorInvite.vendorType=='PlaceHolder-Domestic' || venodorInvite.vendorType=='PlaceHolder-Dropship' && venodorInvite.vendorType!=='PlaceHolder-Import'">
                <td><input class="form-check-input" type="radio" name="freightTerm" id="freightTermFPV" value="FPV" required
                           [(ngModel)]="venodorInvite.freightTerms" (click)="setFPVValue()">
                  <label class="form-check-label" for="freightTermFPV">FPV</label></td>
                <td><input class="form-check-input" type="radio" name="freightTerm" id="freightTermFCT" value="FCT" required
                           [(ngModel)]="venodorInvite.freightTerms" (click)="setFCTValue()">
                  <label class="form-check-label" for="freightTermFCT">FCT</label></td>
                  </div>
                  <div *ngIf="venodorInvite.vendorType=='PlaceHolder-Import'">

                  <td><input class="form-check-input" type="radio" name="freightTerm" id="freightTermFOB" value="FOB" required
                    [(ngModel)]="venodorInvite.freightTerms" (click)="setFOBValue()">
           <label class="form-check-label" for="freightTermFOB">FOB</label></td>
          </div>
              </tr>
            </table>
          </div>

          <div *ngIf="venodorInvite.freightTerms=='FPV'">

            <div class="row">
              <div class="col-sm-3">TSC Receiving:</div>
              <div class="col-sm-1"><input id="tscReceivingFPV"  name="tscReceiving" class="form-control"  required [(ngModel)]="venodorInvite.tscReceiving"  disabled pattern="[0-9]{0,3}"
                  oninvalid="setCustomValidity('Should be less than 4 digits')"
                  oninput="setCustomValidity('')">
              </div>

              <div class="col-sm-4">Number of Days Travel to Destination:</div>
              <div class="col-sm-1"><input id="daysToDestinationFPV" name="daysToDestination" class="form-control"  required [(ngModel)]="venodorInvite.daysToDestination"  pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">Number of Days Ready to Ship:</div>
              <div class="col-sm-1"><input id="daysToShipFPV" name="daysToShip" class="form-control"  required  [(ngModel)]="venodorInvite.daysToShip" pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')">
              </div>

              <div class="col-sm-4">Number of Days in Transit to New Stores:</div>
              <div class="col-sm-1"><input id="leadTimeStoreFPV" name="leadTimeStore" class="form-control"  required  [(ngModel)]="venodorInvite.leadTimeStore" pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')"></div>
            </div>

          </div>

          <div *ngIf="venodorInvite.freightTerms=='FCT'">
            
            <div class="row">
              <div class="col-sm-3">TSC Receiving:</div>
              <div class="col-sm-1"><input id="tscReceivingFCT"  name="tscReceiving" class="form-control"  required [(ngModel)]="venodorInvite.tscReceiving"  disabled pattern="[0-9]{0,3}"
                  oninvalid="setCustomValidity('Should be less than 4 digits')"
                  oninput="setCustomValidity('')">
              </div>

              <div class="col-sm-4">Number of Days Travel to Destination:</div>
              <div class="col-sm-1"><input id="daysToDestinationFCT" name="daysToDestination" class="form-control"  required [(ngModel)]="venodorInvite.daysToDestination"  pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">Number of Days Ready to Ship:</div>
              <div class="col-sm-1"><input id="daysToShipFCT" name="daysToShip" class="form-control"  required  [(ngModel)]="venodorInvite.daysToShip" pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')">
              </div>

              <div class="col-sm-4">Number of Days in Transit to New Stores:</div>
              <div class="col-sm-1"><input id="leadTimeStoreFCT" name="leadTimeStore" class="form-control"  required  [(ngModel)]="venodorInvite.leadTimeStore" pattern="[0-9]{0,3}"
                oninvalid="setCustomValidity('Should be less than 4 digits')"
                oninput="setCustomValidity('')"></div>
            </div>

          </div>

          <!-- FOB form field added  -->
        <div *ngIf="venodorInvite.freightTerms=='FOB'">
            
          <div class="row">
                <div class="col-sm-3">TSC Receiving:</div>
                <div class="col-sm-1"><input id="tscReceivingFOB"  name="tscReceiving" class="form-control"  required [(ngModel)]="venodorInvite.tscReceiving"  disabled pattern="[0-9]{0,3}"
                    oninvalid="setCustomValidity('Should be less than 4 digits')"
                    oninput="setCustomValidity('')">
                </div>
  
                <div class="col-sm-4">Number of Days Travel to Destination:</div>
                <div class="col-sm-1"><input id="daysToDestinationFOB" name="daysToDestination" class="form-control"  required [(ngModel)]="venodorInvite.daysToDestination"  pattern="[0-9]{0,3}"
                  oninvalid="setCustomValidity('Should be less than 4 digits')"
                  oninput="setCustomValidity('')">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Number of Days Ready to Ship:</div>
                <div class="col-sm-1"><input id="daysToShipFOB" name="daysToShip" class="form-control"  required  [(ngModel)]="venodorInvite.daysToShip" pattern="[0-9]{0,3}"
                  oninvalid="setCustomValidity('Should be less than 4 digits')"
                  oninput="setCustomValidity('')">
                </div>
  
                <div class="col-sm-4">Number of Days in Transit to New Stores:</div>
                <div class="col-sm-1"><input id="leadTimeStoreFOB" name="leadTimeStore" class="form-control"  required  [(ngModel)]="venodorInvite.leadTimeStore" pattern="[0-9]{0,3}"
                  oninvalid="setCustomValidity('Should be less than 4 digits')"
                  oninput="setCustomValidity('')"></div>
              </div>

          </div>


          <div class="row">
            <div class="col-sm-5">
            <label for="addressLine1">Address :</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-9">
              <div class="form-row input">
              <div class="col">
              <input  id="addressLine1" class="form-control" name="addressLine1" placeholder="Street Address"  required  [(ngModel)]="venodorInvite.addressLine1">
              </div>
              <div class="col">
              <input  id="addressLine2" class="form-control" name="addressLine2"  placeholder="Apt, Suite, Bldg, Gate Code. (optional)"  [(ngModel)]="venodorInvite.addressLine2">
            </div>
            </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-9">
              <div class="form-row input">
                <div class="col" *ngIf="venodorInvite.vendorType=='PlaceHolder-Import'">
                  <label class="placeholder" *ngIf="!venodorInvite.country || venodorInvite.country == ''">Country</label>
                  <select id="country" class="form-control" name="country" required [(ngModel)]="venodorInvite.country" (change)="setCountry(venodorInvite.country)">
                    <!--<option [ngValue]='' disabled>Country</option>-->
                    <option *ngFor="let c of countryList" [value]="c.ABBR">{{c.ABBR}} {{c.NAME}}</option>
                  </select>
                </div>
                <div class="col">
                    <label class="placeholder" *ngIf="!venodorInvite.state || venodorInvite.state == ''">State</label> 
                    <select id="state" class="form-control" [disabled]="!stateList || stateList.length < 2" [required]="stateList && stateList.length > 1" name="state" class="form-control" [(ngModel)]="venodorInvite.state" >
                      <!--<option [ngValue]='' disabled>State</option>-->
                      <option *ngFor="let s of stateList" [value]="s.ABBR">{{s.NAME}}</option>
                    </select>
                  </div>
                <div class="col">
                  <input  id="city"  name="city" class="form-control" placeholder="City"  required [(ngModel)]="venodorInvite.city" maxlength="40" pattern="[A-Za-z ]{1,40}"
                          oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          oninput="setCustomValidity('')">
                </div>
                <div class="col">
                  <input  type="number" id="zipCode" class="form-control" name="ZipCode" class="form-control" required placeholder="Zip Code"
                          [(ngModel)]="venodorInvite.zipCode" pattern="[A-Z0-9 -]{5,10}"
                          oninvalid="setCustomValidity('must be a 5-digit number')"
                          oninput="setCustomValidity('')">
                  <input *ngIf="venodorInvite.vendorType=='PlaceHolder-Domestic'" type="hidden"  name="country" value="US" [(ngModel)]="venodorInvite.country">
                </div>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-sm-5">
              <label>Product Content Contact :</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-9">
              <div class="form-row input">
                <div class="col">
                  <input  id="productContactName" class="form-control" name="productContactName" placeholder="First Name"  required  [(ngModel)]="venodorInvite.productContentContactFirstName"
                          pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          oninput="setCustomValidity('')">
                </div>
                <!-- <div class="col">
                  <input  id="productContentContactMidInitial" class="form-control" name="productContentContactMidInitial"  placeholder="Middle Name"  [(ngModel)]="venodorInvite.productContentContactMidInitial"
                          pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          oninput="setCustomValidity('')">
                </div> -->
                <div class="col">
                  <input  id="productContentContactLastName" class="form-control" name="productContentContactLastName" required placeholder="Last Name"  [(ngModel)]="venodorInvite.productContentContactLastName"
                          pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          oninput="setCustomValidity('')">
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <div class="form-row input">
                <div class="col">
                  <div class="no-outline">
                  <input type="email" id="productContentContactEmail" class="form-control" name="productContentContactEmail"  placeholder="Email"  required  [(ngModel)]="venodorInvite.productContentContactEmail" >
                </div></div>
                <div class="col">
                  <input  id="productContentContactNumber"  name="productContentContactNumber" class="form-control" placeholder="Contact Number"   required [(ngModel)]="venodorInvite.productContentContactNumber" pattern="[0-9]{10}"
                          oninvalid="setCustomValidity('must be a 10-digit number')"
                          oninput="setCustomValidity('')">
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-sm-5">
              <label>Buyer's Vendor Contact :</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-9">
              <div class="form-row input">
                <div class="col">
                  <input  id="buyersVendorContactFirstName" class="form-control" name="buyersVendorContactFirstName" placeholder="First Name"  required
                          [(ngModel)]="venodorInvite.buyersVendorContactFirstName" pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          [readonly]="venodorInvite.vendorType === 'PlaceHolder-Domestic' || venodorInvite.vendorType === 'PlaceHolder-Import'" 
                          oninput="setCustomValidity('')">
                </div>
                <!-- <div class="col">
                  <input  id="buyersVendorContactMidInitial" class="form-control" name="buyersVendorContactMidInitial"  placeholder="Middle Name"  [(ngModel)]="venodorInvite.buyersVendorContactMidInitial"
                          pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          oninput="setCustomValidity('')">
                </div> -->
                <div class="col">
                  <input  id="buyersVendorContactLastName" class="form-control" name="buyersVendorContactLastName"  placeholder="Last Name"  required [(ngModel)]="venodorInvite.buyersVendorContactLastName"
                          pattern="[A-Za-z]{0,45}" oninvalid="setCustomValidity('Enter a Valid Value without Numbers & Special Characters')"
                          [readonly]="venodorInvite.vendorType === 'PlaceHolder-Domestic' || venodorInvite.vendorType === 'PlaceHolder-Import'" 
                          oninput="setCustomValidity('')">
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <div class="form-row input">
                <div class="col">
                  <input  type="email" id="buyersVendorContactEmail" class="form-control" name="buyersVendorContactEmail"  placeholder="Email"  required  
                  [(ngModel)]="venodorInvite.buyersVendorContactEmail"
                  [readonly]="venodorInvite.vendorType === 'PlaceHolder-Domestic' || venodorInvite.vendorType === 'PlaceHolder-Import'">
                </div>
                <div class="col">
                  <input  id="buyersVendorContactNumber"  name="buyersVendorContactNumber" class="form-control" placeholder="Contact Number"   required [(ngModel)]="venodorInvite.buyersVendorContactNumber" pattern="[0-9]{10}"
                          oninvalid="setCustomValidity('must be a 10-digit number')"
                          oninput="setCustomValidity('')">
                </div>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="venodorInvite.vendorType=='Hay Forage' || venodorInvite.vendorType=='Hay' ">
          <div class="intro">
            <table>
              <tr>
                <td><label class="form-check-label">Does this need to be setup for EDI ?
                </label></td>
                <td><input class="form-check-input" type="radio" name="ediSetUp" id="ediSetUpYes" value="Y" required
                           [(ngModel)]="venodorInvite.isEDISetup">
                  <label class="form-check-label" for="ediSetUpYes">Yes</label></td>
                <td><input class="form-check-input" type="radio" name="ediSetUp" id="ediSetUpNo" value="N" required
                           [(ngModel)]="venodorInvite.isEDISetup">
                  <label class="form-check-label" for="ediSetUpNo">No</label></td>
              </tr>
            </table>
          </div>

          <div *ngIf="venodorInvite.isEDISetup=='Y'">
            <div class="row">
              <div class="col-sm-5">
                <label for="orderPlacementHay">If EDI not required for initial order placement</label>
                <select class="form-control" name="orderPlacementHay"  id="orderPlacementHay" required
                        [(ngModel)]="venodorInvite.orderPlacement">
                  <option>One Time Buy</option>
                  <option>Temporary Fax PO</option>
                  <option>Hay Forage</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="venodorInvite.buyerCompany=='PETSENSE'">
        <div class="petSenAlign">
          <table>
            <tr>
              <td><label class="form-check-label">Is this vendor Scan-based ?
              </label></td>
              <td><input class="form-check-input" type="radio" name="scanBasedPts" id="scanBasedValueYes" value="Y" required
                         [(ngModel)]="venodorInvite.isScanBased">
                <label class="form-check-label" for="scanBasedValueYes">Yes</label></td>
              <td><input class="form-check-input" type="radio" name="scanBasedPts" id="scanBasedValueNo" value="N" required
                         [(ngModel)]="venodorInvite.isScanBased">
                <label class="form-check-label" for="scanBasedValueNo">No</label></td>
            </tr>
          </table>
        </div>

        <div *ngIf="venodorInvite.isScanBased=='N'">
        <div class="row">
          <div class="col-sm-5">
            <label for="orderPlacementPts">Please select an EDI option below</label>
            <select class="form-control" name="orderPlacementPts"  id="orderPlacementPts" required
                    [(ngModel)]="venodorInvite.orderPlacement">
              <option>EDI Setup</option>
              <option>Temporary Fax PO</option>
              <option>One Time Buy</option>
              <option>Hay Forage</option>
            </select>
          </div></div>
        </div>
        </div>

        <div class="intro" *ngIf="showLTL()">
          <table>
            <tr>
              <td><label class="form-check-label">Will this vendor be LTL Shipment?:
              </label></td>
              <td><input class="form-check-input" type="radio" name="ltlVendor" id="ltlVendorYes" value="Y" required
                         [(ngModel)]="venodorInvite.isLTLShipment">
                <label class="form-check-label" for="ltlVendorYes">Yes</label></td>
              <td><input class="form-check-input" type="radio" name="ltlVendor" id="ltlVendorNo" value="N" required
                         [(ngModel)]="venodorInvite.isLTLShipment">
                <label class="form-check-label" for="ltlVendorNo">No</label></td>
            </tr>
          </table>
        </div>

      <div class="col-sm-12" align="center">
        <button align="center"  class="btn btn-primary" [disabled]="!this.onboardingFormSubmit">Submit</button>
      </div>
    </form>
  </div>
</div>

<app-modal #modalError>
  <div class="app-modal-header">
      Error
  </div>
  <div class="app-modal-body">
          {{ modalContent.body }}
  </div>
  <div class='app-modal-footer'>
          <button class="btn btn-danger modal-btn" (click)="logout()">OK</button>
  </div>
</app-modal>

<div id="myModal" class="modal">
  <div class="modal-content">
    <div class="app-modal-header">
      <h4 class="lead">Vendor already exists</h4>
    </div>
    <div class="app-modal-body">
      <br>
      <h2>
        {{tableData}}
      </h2>
                  <thead>
                  <tr>
                    <th>Vendor ID</th>
                    <th>Vendor Name</th>
                    <th>Vendor Type</th>
                    <th>SC Contact</th>
                    <th>Vendor Status</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of responseData">
                      <td>{{data.VendorID}}</td>
                      <td>{{data.CompanyName}}</td>
                      <td>{{data.VendorType}}</td>
                      <td>{{data.SCEmail}}</td>
                      <td>{{data.IsVendorActive}}</td>
                    </tr>
                  </tbody>
                <mat-form-field style="min-width: 350px;"> 
                  <mat-label>Reason (required)</mat-label>
                  <textarea #reasonTextRef matInput [(ngModel)]="reasonText" placeholder="Enter reason text to continue if you choose yes (minimum 10 characters)"
                        cdkAutosizeMinRows ="2"
                       cdkAutosizeMaxRows="5"
                       pattern="[0-9A-Za-z ]{0,128}"
                       maxlength="128" (ngModelChange)="checkReasonTextLength($event)"></textarea>
                 </mat-form-field>
    </div>
    <div class='app-modal-footer'>
      <div style="text-align: center">
        <br>
        <button class="btn btn-outline-secondary" (click)="cancel()">No</button>&nbsp;
        <button class="btn btn-primary" [disabled]="reason" (click)="proceed()">Yes</button>
      </div>
    </div>
  </div>
</div>



