export class AddVendorBuyer {

  constructor(
    public company?: string,
    public email?: string,
    public lastName?: string,
    public firstName?: string,
    public buyerCompany?: string,
    public buyerEmail?:string,
    public vendorType?: string,
    public isEDISetup?: string,
    public parentVendorNumber?: string,
    public parentVendorName?: string,
    public isAdditionalVendor?: string,
    public vendorNumber?: string,
    public addressLine1?: string,
    public addressLine2?: string,
    public addressLine3?:string,
    public country?: string,
    public city?: string,
    public state?: string,
    public zipCode?: string,
    public remitName?: string,
    public remitAddressLine1?: string,
    public remitAddressLine2?: string,
    public remitAddressLine3?:string,
    public remitCountry?: string,
    public remitCity?: string,
    public remitState?: string,
    public remitZipCode?: string,
    public sameVendorFunding?: string,
    public samePaymentTerms?: string,
    public sameFreightTerms?: string,
    public sameLeadTime?: string,
    public contactchanges?: string,
    public isRTVChanges?: string,
    public isVendorScanBased?: string,
    public AdditionalVendorReason?: string,
    public VendorFundingClarify?: string,
    public isTermsRemainSame?: string) { }

}
