<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <h2>{{ selectedReport.reportName }}</h2>
    </div>
    <div class="col-sm-6 text-right">
      <select (change)="changeReport($event)" class="form-control form-control-sm" *ngIf="reportsList && reportsList.length > 1">
        <option [value]="null">Select a report...</option>
        <option *ngFor="let r of reportsList" [selected]="r.reportId === selectedReport.reportId" [value]="r.reportId" >{{ r.reportName }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 bgimg"></div>
    <div class="col-sm-12 embed-report" *ngIf="embedded">
      <!-- <iframe #powerBIembed style="width:100%;height:67vh;border:1px dotted #999" [src]="powerBI">Loading Dashboard...</iframe> -->
      <powerbi-report [embedConfig] = "PBIconfig" style="display:block;width:100%;height:80vh;"></powerbi-report>
    </div>
    <div class="col-sm-12" *ngIf="!embedded && !initLoad">
      <div class="alert alert-danger">There was a problem loading data from PowerBI.</div>
    </div>

  </div>

</div>
