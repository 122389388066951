import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { UserService } from './../services/user.service';

import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageInvitesService {

  public vendorInviteList = [];
  private headers = {
    'Content-Type':'application/json;charset=utf-8',
    'accept':'application/json',
    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Authorization': this.users.getHeader()
  }
  // private api = environment.merchapi = '/svc/' ? '/dev-svc/' : environment.merchapi; // for local dev with DEV env only... change for QA deployment
  private api = environment.merchapi;

  constructor(
    private router:Router,
    private http: HttpClient,
    private users: UserService,

  ) { }


  checkPermissions(){
    // get current user, check roles
    const currentUser = this.users.loggedInUser;
    let userAllowed = false;
    const allowedRoles = ['VMD']; // roles with permission to view

    // redirect user with no permission
    if (allowedRoles.indexOf(this.users.getUserRole())>-1) {
      userAllowed = true;
    } else {
      // try roles from MV User Table
      let userRoles = [];
      if (currentUser.roleName && currentUser.roleName.indexOf(',') > -1) {
        // retrieve all roles
        let allRoles = currentUser.roleName.split(',');
        userRoles = allRoles;
      } else {
        userRoles.push(currentUser.roleName);
      }
      for (let role of userRoles) {
        console.log('User has role: ' + role);
        if (allowedRoles.indexOf(role) > -1) {
          userAllowed = true;
        }
      }
    }
    if (!userAllowed) {
      console.log('*** User Not Allowed ***');
      this.router.navigate(['error']);
    }
  }

  getDuplicateVendors() {
    const url = this.api + 'getduplicatevendorinfo';
    return this.http.get(url,{headers:this.headers}).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

  postApprovalStatus(request) {
    const url = this.api + 'getvmdapprovalstatus';
    return this.http.post(url, request, {headers:this.headers}).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

}

export class VendorInviteDetail {
  public TempVendorID: string;
  public CompanyName: string;
  public Email: string;
  public FirstName: string;
  public LastName: string;
  public isPlaceHolderVendor: string;
  public remitName: string;
  public VendorType: string;
  public BuyerID: string;
  public buyerCompany: string;
  public buyerEmail: string;
  public BuyerOverride: string;
  public BuyerOverrideReason: string;
  public OnboardingStatus: string;
  public DuplicateVendors: DuplicateVendorDetail[];
  public VMDComments: string; // Reject: reason or Approve: reason
  public VMDAction: string; // Reject or Approve
  
  public isScanBased?: string;
  public isTestVendor?: string;
  public isVendorVMI?: string;
  public isRTVExceptions?: string;
  public isEDISetup?: string;
  public isAdditionalVendor?: string;
  public sourcingAgent?: string;
  public freightTerms?: string;
  public daysToShip?: string;
  public daysToDestination?: string;
  public tscReceiving?: string;
  public deliveryTime?: string;
  public PlaceholderAddress?: string;
  public productContentContactFirstName?: string;
  public productContentContactLastName?: string;
  public productContentContactNumber?: string;
  public productContentContactEmail?: string;
  public buyersVendorContactFirstName?: string;
  public buyersVendorContactLastName?: string;
  public buyersVendorContactEmail?: string;
  public isExclusiveBrands?: string;
  public expectedOrderDate?: string;

  public addressLine1?: string;
  public addressLine2?: string;
  public city?: string;
  public state?: string;
  public zipCode?: string;
  public country?: string;

}

export class DuplicateVendorDetail {
  public VendorID?: string;
  public CompanyName: string;
  public OnboardingStatus: string;
}
