import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Injectable()
export class GuardService implements CanActivate, CanActivateChild {
  constructor(private authService: UserService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{

        let authenticated: boolean = this.authService.isAuthenticated();
        if (authenticated) {
          // see if a specific role is required
          if (route.data && route.data.roles) {
            let allowed = false;
            console.log('checking user roles & permissions', this.authService.getUserRole());
            for (let role of route.data.roles) {
              console.log('check role: ' + role);
              if (role === this.authService.getUserRole()) {
                allowed = true;
              }
            }
            if (allowed) {
              return allowed;
            } else {
              console.log('role not allowed, auth guard redirect to home');
              this.router.navigate(['/']); // return home
            }
          } else {
            // no roles specified, all roles allowed
            return true;
          }
        } else {
          console.log('user not allowed, auth guard redirect to login');
          this.router.navigate(['/'])
        }
  }

  canActivateChild(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
       return this.canActivate(route, state);
  }
}
