import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';
import { of, Observable } from 'rxjs';
import { UserService } from './../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class VendorApiService {

  private api = environment.merchapi; // merchVendorService
  private portalsvc = environment.api;  // portalservice
  private headers = new HttpHeaders({
    'Content-Type':'application/json;charset=utf-8',
    'accept':'application/json',
    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Authorization': this.users.getHeader()
  });
  public contractFlags = {
    'payment': false, // IS_PAYMENTTERMSCHANGE
    'freight': false, // IS_FREIGHTTERMSCHANGE
    'rtv': false, // IS_RTVCHANGE
    'rebate': false, // IS_VOLUMEREBATECHANGE
    'funding': false, // IS_VENDORFUNDINGCHANGE
    'address': false, // IS_ADDRESSCHANGE
    'remittance': false, // IS_REMITTANCECHANGE
    // 'noncontract': false // IS_NONCONTRACTCHANGE
  };

  // update to force send these fields, regardless of type
  public sendReadOnly = ['VENDOR_TYPE','VENDOR_ID','SC_EMAIL','TOTAL_DELIVERY_TIME','TSC_RECEIVING','TIME_TO_DESTINATION',
                          'COMPANY_NAME','VA_LEGALENTITYTYPE','PARENT_CO_NAME_1','VA_PROVINCE','VA_ATTN','WEB_URL',
                          'VA_ADDRESS_LINE1','VA_ADDRESS_LINE2','VA_CITY','VA_COUNTRY','VA_STATE','VA_ZIP',
                          'BUYERCOMPANY','BUYEREMAIL','BUYERID', 'ONLINE_RETURN_EXCEPTIONS'
                          // ,'RA_NAME','RA_ADDRESS_LINE1','RA_ADDRESS_LINE2','RA_CITY','RA_COUNTRY','RA_STATE','RA_ZIP' // NOA send these as well
                        ];
 // public vendorList;

  constructor(private http:HttpClient, private users:UserService) {
    
  }

 requestData(data,endpoint?) {
    // request {'Email':'inf_vijayak@tractorsupply.com','VendorInfo':'N'}
    // include token, headers here
    // this.headers.append('Authorization', this.users.getHeader());
    // console.log('ADD TOKEN', this.headers);

    let url = this.api + 'getonboardingdata';
    if(endpoint){
      url = this.api + endpoint;
    }
    //const url = 'https://dev-vendor.tractorsupply.com/merchVendorService/' + 'getonboardingdata';
    let resp = this.http.post(url, data, {headers: this.headers});
    // this.vendorList = resp;
    return resp;

  }

  requestAddVendor(data,endpoint?) {
    let url = this.api + 'queryvendorinfo';
    if(endpoint){
      url = this.api + endpoint;    }
    return this.http.post(url, data, {headers: this.headers});
  }

  getBuyerDetails(endpoint?) {
    let url = this.api + 'getbuyerinfo';
    if(endpoint){
      url = this.api + endpoint;    
    }
    return this.http.get(url, {headers: this.headers});
  }


  setContractFlag(flag, value:boolean) {
    this.contractFlags[flag] = value;
  }

  submitData(questions, isSubmit?: boolean) {
    let json = {};
    json['ONBOARDING_STATUS'] = isSubmit ? 'Submitted' : 'Saved';

    // handle flags for contractual change
    if(isSubmit){
      json['IS_PAYMENTTERMSCHANGE'] = this.contractFlags.payment ? 'Y' : 'N';
      json['IS_FREIGHTTERMSCHANGE'] = this.contractFlags.freight ? 'Y' : 'N';
      json['IS_RTVCHANGE'] = this.contractFlags.rtv ? 'Y' : 'N';
      json['IS_VOLUMEREBATECHANGE'] = this.contractFlags.rebate ? 'Y' : 'N';
      json['IS_VENDORFUNDINGCHANGE'] = this.contractFlags.funding ? 'Y' : 'N';
      json['IS_ADDRESSCHANGE'] = this.contractFlags.address ? 'Y' : 'N';
      json['IS_REMITTANCECHANGE'] = this.contractFlags.remittance ? 'Y' : 'N'; // uncomment to activate TIBCO-3751
      // json['IS_NONCONTRACTCHANGE'] = this.contractFlags.noncontract ? 'Y' : 'N';
    }

    console.log('contract flags', this.contractFlags);

    console.log('saved questions', questions);

    if (questions.TempVendorID && questions.TempVendorID!=='') {
      json['TEMP_VENDOR_ID'] = questions.TempVendorID;
    }

    // format to update schema, key/value pairs only
    if(questions.tabs){
      for (let tab of questions.tabs){
       // console.log('updating fields for ' + tab.title);
        // if(tab.title !=='Vendor Company Contacts'){
          console.log('processing ' + tab.title);
        for (let q of tab.fields) {
           if ( q.type === 'displaytext' || q.type === 'hidden'
                || (q.editable && q.editable === 'N' 
                && this.sendReadOnly.indexOf(q.key) === -1)) { // send only vendor data fields for submit
            // don't send non-editable fields
            // console.log('do not include ' + q.key, q);
           } else {
              // console.log('include ' + q.key, q);
              const key = q.key;
              let val = q.value;
              if (val === true || val === false) {
                // convert boolean back to text
                val = val === true ? 'Y' : 'N';
              } else if (q.type === 'email'){
                val = val.toLowerCase();
              }

              if(!val) {
                val = ''; // don't send undefined
              }

                json[key] = val;
                // console.log('updated ' + key + ' to ' + json[key]);
              
            // }
            }
           }
        }
      }


    console.log('send update request', json);

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    const url = this.api + 'sendvendorinfotodocusign';
    // Reset the contract flags for the previous setted one
    let contractableItems = ['payment','freight','rtv', 'rebate','funding','address','remittance'];
        for(let item of contractableItems){
            this.setContractFlag(item, false);
        }
    return this.http.post(url, json, {headers, responseType: 'text'});
    // return of(json); // test without making network request

  }

  httpPost(url, data) {
    return this.http.post(url, data, {headers: this.headers});
  }

  apiReq(endpoint, json?){
    const url = this.api + endpoint;
    if (json) {
      // post
      return this.http.post(url, json, {headers: this.headers});
    } else {
      // get
      return this.http.get(url, {headers: this.headers});
    }
  }
  duplicateVendorCheckCall(endPoint, data){
    let url = this.api + 'companynamecheck';
    if(endPoint){
      url = this.api+endPoint;
    }
    return this.http.post(url,data,{headers: this.headers});
  }

  vacReq(endpoint, json?) {
    const url = environment.vacapi + endpoint;
    console.log(url, json);
    if (json) {
      // post
      return this.http.post(url, json, {headers: this.headers});
    } else {
      // get
      return this.http.get(url, {headers: this.headers});
    }

  }

  svcReq(endpoint, json?) {
    const url = this.portalsvc + endpoint;
    console.log(url, json);
    if (json) {
      // post
      return this.http.post(url, json, {headers: this.headers});
    } else {
      // get
      return this.http.get(url, {headers: this.headers});
    }

  }



}
