import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { UserService } from './../services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/user.service";
var VendorApiService = /** @class */ (function () {
    // public vendorList;
    function VendorApiService(http, users) {
        this.http = http;
        this.users = users;
        this.api = environment.merchapi; // merchVendorService
        this.portalsvc = environment.api; // portalservice
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': this.users.getHeader()
        });
        this.contractFlags = {
            'payment': false,
            'freight': false,
            'rtv': false,
            'rebate': false,
            'funding': false,
            'address': false,
            'remittance': false,
        };
        // update to force send these fields, regardless of type
        this.sendReadOnly = ['VENDOR_TYPE', 'VENDOR_ID', 'SC_EMAIL', 'TOTAL_DELIVERY_TIME', 'TSC_RECEIVING', 'TIME_TO_DESTINATION',
            'COMPANY_NAME', 'VA_LEGALENTITYTYPE', 'PARENT_CO_NAME_1', 'VA_PROVINCE', 'VA_ATTN', 'WEB_URL',
            'VA_ADDRESS_LINE1', 'VA_ADDRESS_LINE2', 'VA_CITY', 'VA_COUNTRY', 'VA_STATE', 'VA_ZIP',
            'BUYERCOMPANY', 'BUYEREMAIL', 'BUYERID', 'ONLINE_RETURN_EXCEPTIONS'
            // ,'RA_NAME','RA_ADDRESS_LINE1','RA_ADDRESS_LINE2','RA_CITY','RA_COUNTRY','RA_STATE','RA_ZIP' // NOA send these as well
        ];
    }
    VendorApiService.prototype.requestData = function (data, endpoint) {
        // request {'Email':'inf_vijayak@tractorsupply.com','VendorInfo':'N'}
        // include token, headers here
        // this.headers.append('Authorization', this.users.getHeader());
        // console.log('ADD TOKEN', this.headers);
        var url = this.api + 'getonboardingdata';
        if (endpoint) {
            url = this.api + endpoint;
        }
        //const url = 'https://dev-vendor.tractorsupply.com/merchVendorService/' + 'getonboardingdata';
        var resp = this.http.post(url, data, { headers: this.headers });
        // this.vendorList = resp;
        return resp;
    };
    VendorApiService.prototype.requestAddVendor = function (data, endpoint) {
        var url = this.api + 'queryvendorinfo';
        if (endpoint) {
            url = this.api + endpoint;
        }
        return this.http.post(url, data, { headers: this.headers });
    };
    VendorApiService.prototype.getBuyerDetails = function (endpoint) {
        var url = this.api + 'getbuyerinfo';
        if (endpoint) {
            url = this.api + endpoint;
        }
        return this.http.get(url, { headers: this.headers });
    };
    VendorApiService.prototype.setContractFlag = function (flag, value) {
        this.contractFlags[flag] = value;
    };
    VendorApiService.prototype.submitData = function (questions, isSubmit) {
        var json = {};
        json['ONBOARDING_STATUS'] = isSubmit ? 'Submitted' : 'Saved';
        // handle flags for contractual change
        if (isSubmit) {
            json['IS_PAYMENTTERMSCHANGE'] = this.contractFlags.payment ? 'Y' : 'N';
            json['IS_FREIGHTTERMSCHANGE'] = this.contractFlags.freight ? 'Y' : 'N';
            json['IS_RTVCHANGE'] = this.contractFlags.rtv ? 'Y' : 'N';
            json['IS_VOLUMEREBATECHANGE'] = this.contractFlags.rebate ? 'Y' : 'N';
            json['IS_VENDORFUNDINGCHANGE'] = this.contractFlags.funding ? 'Y' : 'N';
            json['IS_ADDRESSCHANGE'] = this.contractFlags.address ? 'Y' : 'N';
            json['IS_REMITTANCECHANGE'] = this.contractFlags.remittance ? 'Y' : 'N'; // uncomment to activate TIBCO-3751
            // json['IS_NONCONTRACTCHANGE'] = this.contractFlags.noncontract ? 'Y' : 'N';
        }
        console.log('contract flags', this.contractFlags);
        console.log('saved questions', questions);
        if (questions.TempVendorID && questions.TempVendorID !== '') {
            json['TEMP_VENDOR_ID'] = questions.TempVendorID;
        }
        // format to update schema, key/value pairs only
        if (questions.tabs) {
            for (var _i = 0, _a = questions.tabs; _i < _a.length; _i++) {
                var tab = _a[_i];
                // console.log('updating fields for ' + tab.title);
                // if(tab.title !=='Vendor Company Contacts'){
                console.log('processing ' + tab.title);
                for (var _b = 0, _c = tab.fields; _b < _c.length; _b++) {
                    var q = _c[_b];
                    if (q.type === 'displaytext' || q.type === 'hidden'
                        || (q.editable && q.editable === 'N'
                            && this.sendReadOnly.indexOf(q.key) === -1)) { // send only vendor data fields for submit
                        // don't send non-editable fields
                        // console.log('do not include ' + q.key, q);
                    }
                    else {
                        // console.log('include ' + q.key, q);
                        var key = q.key;
                        var val = q.value;
                        if (val === true || val === false) {
                            // convert boolean back to text
                            val = val === true ? 'Y' : 'N';
                        }
                        else if (q.type === 'email') {
                            val = val.toLowerCase();
                        }
                        if (!val) {
                            val = ''; // don't send undefined
                        }
                        json[key] = val;
                        // console.log('updated ' + key + ' to ' + json[key]);
                        // }
                    }
                }
            }
        }
        console.log('send update request', json);
        var headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        var url = this.api + 'sendvendorinfotodocusign';
        // Reset the contract flags for the previous setted one
        var contractableItems = ['payment', 'freight', 'rtv', 'rebate', 'funding', 'address', 'remittance'];
        for (var _d = 0, contractableItems_1 = contractableItems; _d < contractableItems_1.length; _d++) {
            var item = contractableItems_1[_d];
            this.setContractFlag(item, false);
        }
        return this.http.post(url, json, { headers: headers, responseType: 'text' });
        // return of(json); // test without making network request
    };
    VendorApiService.prototype.httpPost = function (url, data) {
        return this.http.post(url, data, { headers: this.headers });
    };
    VendorApiService.prototype.apiReq = function (endpoint, json) {
        var url = this.api + endpoint;
        if (json) {
            // post
            return this.http.post(url, json, { headers: this.headers });
        }
        else {
            // get
            return this.http.get(url, { headers: this.headers });
        }
    };
    VendorApiService.prototype.duplicateVendorCheckCall = function (endPoint, data) {
        var url = this.api + 'companynamecheck';
        if (endPoint) {
            url = this.api + endPoint;
        }
        return this.http.post(url, data, { headers: this.headers });
    };
    VendorApiService.prototype.vacReq = function (endpoint, json) {
        var url = environment.vacapi + endpoint;
        console.log(url, json);
        if (json) {
            // post
            return this.http.post(url, json, { headers: this.headers });
        }
        else {
            // get
            return this.http.get(url, { headers: this.headers });
        }
    };
    VendorApiService.prototype.svcReq = function (endpoint, json) {
        var url = this.portalsvc + endpoint;
        console.log(url, json);
        if (json) {
            // post
            return this.http.post(url, json, { headers: this.headers });
        }
        else {
            // get
            return this.http.get(url, { headers: this.headers });
        }
    };
    VendorApiService.ngInjectableDef = i0.defineInjectable({ factory: function VendorApiService_Factory() { return new VendorApiService(i0.inject(i1.HttpClient), i0.inject(i2.UserService)); }, token: VendorApiService, providedIn: "root" });
    return VendorApiService;
}());
export { VendorApiService };
