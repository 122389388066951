import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UserService } from './app/services/user.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

 // platformBrowserDynamic().bootstrapModule(AppModule);


UserService.init()
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch(err => {
    console.log(err);
    console.log('user auth init fail');
  });

  