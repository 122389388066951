import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PopupDialogData } from '../modal/popup-dialog.model';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PopupDialogService {

  constructor(public dialog: MatDialog) { }

  openDialog(popupModalContentData:PopupDialogData):MatDialogRef<PopupDialogComponent>{
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      width:'75%',
      height:'auto',
      disableClose: true,
      //panelClass: 'custom-modalbox',
      data: popupModalContentData
    })
    return dialogRef;
  }
}
