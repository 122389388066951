import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService } from './../services/document.service';
import { saveAs } from 'file-saver';
var DocumentsComponent = /** @class */ (function () {
    function DocumentsComponent(docs, router) {
        this.docs = docs;
        this.router = router;
        this.testDocsList = [
            {
                'filename': 'TSC-05-111GC Standard Vendor Agreement.pdf',
                'filesize': '234k',
                'lastupdated': '2019-06-12 09:42',
                'selected': false,
                'url': 'assets/docs/TSC-05-111GC Standard Vendor Agreement.pdf',
                'permissions': ''
            },
            {
                'filename': 'TSC-00-00-7 Regulations by State and Region.pdf',
                'filesize': '15378k',
                'lastupdated': '2019-06-12 09:42',
                'selected': false,
                'url': 'assets/docs/TSC-00-00-7 Regulations by State and Region.pdf',
                'permissions': ''
            },
            {
                'filename': 'SV-110 Licensing Addendum for Livestock.docx',
                'filesize': '156k',
                'lastupdated': '2019-06-12 09:42',
                'selected': false,
                'url': 'assets/TSC-05-111GC Standard Vendor Agreement.pdf',
                'permissions': ''
            },
            {
                'filename': 'SV-112 Marketing Requirements.docx',
                'filesize': '488k',
                'lastupdated': '2019-06-12 09:42',
                'selected': false,
                'url': 'assets/docs/SV-112 Marketing Requirements.docx',
                'permissions': ''
            },
            {
                'filename': 'USA-2-45-ii USDA Requirements for Non-Edible Plants section ii.pdf',
                'filesize': '20385k',
                'lastupdated': '2019-06-12 09:42',
                'selected': false,
                'url': 'assets/docs/USA-2-45-ii USDA Requirements for Non-Edible Plants section ii.pdf',
                'permissions': ''
            }
        ];
        this.docsList = [];
        this.fullDocsList = [];
        this.subFolders = [];
        this.visibleSubs = [];
        this.modalContent = {
            'header': '',
            'body': '',
            'footer': ''
        };
        this.showMenu = true;
    }
    DocumentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('Selected Category: ' + this.docs.selectedCategory);
        if (this.docs.selectedCategory !== '' && this.docs.documentCategories.find(function (c) { return c.category === _this.docs.selectedCategory; })) {
            this.showDocs(this.docs.selectedCategory, true);
        }
        else {
            // not clicked directly
            this.showDocs();
        }
        // get documents list
        this.docs.retrieveDocuments().subscribe(function (data) {
            // console.log('document list', data);
            _this.fullDocsList = data;
            for (var _i = 0, _a = _this.fullDocsList; _i < _a.length; _i++) {
                var d = _a[_i];
                if (!d.categoryName || d.categoryName === '') {
                    d.categoryName = 'All';
                }
                d.combinedArr = []; //initialize
                // populate combined array for folder/subfolders
                var cats = d.categoryName.split(',');
                console.log(cats);
                for (var _b = 0, cats_1 = cats; _b < cats_1.length; _b++) {
                    var c = cats_1[_b];
                    if (c.indexOf('/') > -1) {
                        // get subfolder
                        var folderLevel = c.split('/');
                        // move to array
                        d.combinedArr.push({ category: folderLevel[0], subfolder: folderLevel[1] });
                    }
                    else {
                        // no subfolder
                        d.combinedArr.push({ category: c, subfolder: '' });
                    }
                }
            }
            _this.docsList = _this.fullDocsList;
            console.log('all docs', _this.fullDocsList, _this.docs.documentCategories);
            console.log('initial category: ' + _this.docs.selectedCategory);
            if (_this.docs.selectedCategory !== '' && _this.docs.documentCategories.find(function (c) { return c.category === _this.docs.selectedCategory; })) {
                _this.docsList = _this.fullDocsList.filter(function (d) { return d.categoryName === '' || d.categoryName === 'All' || d.categoryName.indexOf(_this.docs.selectedCategory) > -1; });
                console.log('category docs', _this.docsList);
                // parse subcategories
                var docCat = _this.docs.documentCategories.find(function (c) { return c.category === _this.docs.selectedCategory; });
                if (docCat) {
                    _this.subFolders = docCat.subfolders;
                }
                console.log(_this.subFolders, docCat);
                _this.docsList.sort(_this.sortBy('name'));
            }
        });
        var dis = this;
        window.addEventListener('popstate', function () {
            if (_this.docs.selectedCategory !== '') {
                _this.docs.selectedCategory = '';
                _this.showMenu = true;
            }
            else {
                _this.showMenu = true;
            }
        });
    };
    DocumentsComponent.prototype.selectDownload = function (id) {
        if (this.docsList[id].selected) {
            this.docsList[id].selected = false;
        }
        else {
            this.docsList[id].selected = true;
        }
        console.log('selected ' + this.docsList[id].fileName, this.docsList);
    };
    DocumentsComponent.prototype.downloadDocs = function (modal) {
        var _this = this;
        var selectedDocs = this.docsList.filter(function (d) { return d.selected === true; });
        var downloadedDocs = [];
        var _loop_1 = function (d) {
            console.log('downloading ' + d.fileName);
            // use download document service, returns the files
            this_1.docs.downloadDocument(d).subscribe(function (data) {
                saveAs(data, d.fileName.toString());
                downloadedDocs.push(d);
            }, function (error) {
                console.log(error);
                _this.modalContent.header = 'Error';
                _this.modalContent.body = 'There was a problem downloading the document.';
                _this.modalContent.footer = error.text();
                modal.show();
            });
        };
        var this_1 = this;
        for (var _i = 0, selectedDocs_1 = selectedDocs; _i < selectedDocs_1.length; _i++) {
            var d = selectedDocs_1[_i];
            _loop_1(d);
        }
        var files = downloadedDocs.map(function (d) { return d.filename; });
        console.log('Downloaded ' + downloadedDocs.length + ' files: ', files);
    };
    DocumentsComponent.prototype.downloadDoc = function (d, modal) {
        var _this = this;
        // single file download
        this.docs.downloadDocument(d).subscribe(function (data) {
            saveAs(data, d.fileName);
        }, function (error) {
            console.log(error);
            _this.modalContent.header = 'Error';
            _this.modalContent.body = 'There was a problem downloading the document.';
            _this.modalContent.footer = error.text();
            modal.show();
        });
    };
    DocumentsComponent.prototype.disableDownload = function () {
        var disabled = true;
        var selected = this.docsList.filter(function (d) { return d.selected === true; });
        if (selected.length > 0) {
            disabled = false;
        }
        return disabled;
    };
    DocumentsComponent.prototype.showDocsMenu = function () {
        return this.showMenu;
    };
    DocumentsComponent.prototype.toggleSub = function (index) {
        if (!this.visibleSubs[index]) {
            this.visibleSubs[index] = true;
        }
        else {
            this.visibleSubs[index] = false;
        }
    };
    DocumentsComponent.prototype.showSub = function (index) {
        return this.visibleSubs[index];
    };
    DocumentsComponent.prototype.subEmpty = function (sub) {
        for (var _i = 0, _a = this.docsList; _i < _a.length; _i++) {
            var d = _a[_i];
            for (var _b = 0, _c = d.combinedArr; _b < _c.length; _b++) {
                var c = _c[_b];
                if (c.subfolder && c.subfolder === sub) {
                    return false;
                }
            }
        }
        return true;
    };
    DocumentsComponent.prototype.showDocs = function (cat, home) {
        var _this = this;
        if (cat) {
            this.showMenu = false;
            this.docs.selectedCategory = cat;
            if (this.docs.documentCategories.find(function (c) { return c.category === _this.docs.selectedCategory; })) {
                this.docsList = this.fullDocsList.filter(function (d) { return d.categoryName === '' || d.categoryName === 'All' || d.categoryName.indexOf(_this.docs.selectedCategory) > -1; });
                // parse subcategories
                var docCat = this.docs.documentCategories.find(function (c) { return c.category === _this.docs.selectedCategory; });
                if (docCat) {
                    this.subFolders = docCat.subfolders;
                }
                console.log(this.docs.selectedCategory, this.docsList);
                if (!home) {
                    window.history.pushState({}, 'Documents', '/documents?');
                }
            }
            else {
                this.docsList = this.fullDocsList;
            }
        }
        else {
            this.showMenu = true;
            this.docs.selectedCategory = '';
            this.docsList = this.fullDocsList;
        }
        this.docsList.sort(this.sortBy('name'));
    };
    DocumentsComponent.prototype.sortBy = function (key, order) {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }
            var varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            var varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
            var comparison = 0;
            if (varA > varB) {
                comparison = 1;
            }
            else if (varA < varB) {
                comparison = -1;
            }
            return ((order === 'desc') ? (comparison * -1) : comparison);
        };
    };
    DocumentsComponent.prototype.hasSub = function (doc, sub) {
        for (var _i = 0, _a = doc.combinedArr; _i < _a.length; _i++) {
            var f = _a[_i];
            if (f.subfolder === sub) {
                return true;
            }
        }
        return false;
    };
    DocumentsComponent.prototype.noSub = function (doc, category) {
        var showInFolder = false;
        for (var _i = 0, _a = doc.combinedArr; _i < _a.length; _i++) {
            var f = _a[_i];
            if (f.category === category || f.category === 'All') {
                if (!f.subfolder || f.subfolder === '') {
                    showInFolder = true;
                }
            }
        }
        return showInFolder;
    };
    return DocumentsComponent;
}());
export { DocumentsComponent };
