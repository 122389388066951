/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./error-page.component";
var styles_ErrorPageComponent = [i0.styles];
var RenderType_ErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPageComponent, data: {} });
export { RenderType_ErrorPageComponent as RenderType_ErrorPageComponent };
export function View_ErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-sm-12 bgimg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error - Page Not Found"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're sorry, the page you requested was not found."])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We'll get this fixed as soon as possible. In the meantime... please "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["href", "/"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["return to the home page."]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_ErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-page", [], null, null, null, View_ErrorPageComponent_0, RenderType_ErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.ErrorPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageComponentNgFactory = i1.ɵccf("app-error-page", i4.ErrorPageComponent, View_ErrorPageComponent_Host_0, {}, {}, []);
export { ErrorPageComponentNgFactory as ErrorPageComponentNgFactory };
