import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { UserService } from './user.service';
import {Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
@Injectable()
export class DocumentService {

  public documentList = [];
  public documentsLoaded = false;
  public documentCategories = [
    {
      category: 'Electronic Data Interchange (EDI)',
      thumbnail: 'edi.jpg',
      show: '',
      subfolders: []
    },
    {
      category: 'Logistics & Compliance Information',
      thumbnail: 'dclogistics.jpg',
      show: 'TSC',
      subfolders: [
          {category: 'Domestic Vendor Guidelines'},
          {category: 'Import Vendor Guidelines'},
          {category: 'QA'},
          {category: 'SKUs & POs'},
          {category: 'Vendor Operations & Compliance Portal'},
          {category: 'Contact Lists'}
        ]
      },
    {
      category: 'Merchandising Events & Promotion',
      thumbnail: 'merchandising.jpg',
      show: 'TSC',
      subfolders: []
    },
    {
      category: 'Product Set-up & Maintenance',
      thumbnail: 'maintenance.jpg',
      show: '',
      subfolders: [
        {category: 'SKU Create Forms'}
      ]
    },
    {
      category: 'Vendor Partner Information',
      thumbnail: 'vendorinfo.jpg',
      show: '',
      subfolders: [
        {category: 'Calendars'},
        {category: 'VIS'},
        {category: 'Partner & Policy Updates'},
        {category: 'PIM'}
      ]
    }
  ];
  public selectedCategory = '';
  
  constructor(
    private rest: RestService,
    private users: UserService,
  ) { }

  getDocumentList(){
    return this.documentList;
  }
  
  // retrieve document list
  retrieveDocuments(){
    return this.rest.getReq('getDocumentsDetails', this.users.getHeader()).pipe(map(
      res => {
        console.log('documents requested', res);
        this.documentList = res.json();
        this.documentsLoaded = true;


          // TIBCO-2952 parse filename to folder and file
          /*
          for (let d of this.documentList) {
            console.log(d.fileName);
            if(d.fileName && d.fileName.indexOf('/') > -1){
              const fileNameArr = d.fileName.split('/');
              d.folderName = fileNameArr[0];
              d.fileName = fileNameArr[1];
              console.log(d.folderName, d.fileName);
            } else {
              d.folderName = '';
            }
          }
          */
        return this.documentList;
      }, error => {
        console.log('error retrieving document list', error);
        return error;
      }));
  }

  // download a document

  downloadDocument(doc, isPath?){
    const location = isPath ? doc : doc.fileLocation;
    const url = 'download';
    const req = {'value': location};
    console.log('downloading ' + location);
    if(location && location !== '') {
      return this.rest.fileDownload(url, req, this.users.getHeader()).pipe(map(
        data => {
          console.log(data);
          return data;
        }, error => {
          console.log(error);
          return error;
        }

      ));
    }
  }

}

export class Doc {
  public id: String = '';
  public name: String = '';
  public fileName: String = '';
  public fileSize: String = '';
  public modifyDate: Date = null;
  public createDate: Date = null;
  public groupName: String = '';
  public roleName: String = '';
  public authorName: String = '';
  public lastModifiedBy: String = '';
  public type: String = '';
  public fileLocation: String = '';
  public file: File;
  public selected: Boolean = false;
  public rolesArr: String[] = [];
  public categoryName: String = '';
  public catArr: String[] = [];
  public combinedArr: Folder[] = [];

}

export class Folder {
  public category: String = '';
  public subfolder: String = '';
}
