import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {PopupDialogData, PopupDialogResponse} from '../modal/popup-dialog.model';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css']
})
export class PopupDialogComponent implements OnInit {

   
  onCloseDialogResponse={
    continueOnborading: false,
  }
  reasonText:any;
  errMsg:string;
  @ViewChild('reasonTextRef') reasonTextElement: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupDialogData

  ) { }
  
  ngOnInit() {
    this.errMsg='';
  }

  checkReasonTextLength(event:any){
    if(event.length >=10){
      this.errMsg="";
    }

  }
  
  notificationOk(){
    const responseNotification:PopupDialogResponse={
      continueOnborading: false,
    }
    this.dialogRef.close(responseNotification);
  }
  onYesBtnClick(){
    if(!this.reasonText || this.reasonText.length<10){
      this.errMsg="Please enter minimum of 10 characters for reason text";
      this.reasonTextElement.nativeElement.focus();
    }else {
      this.errMsg='';
      const dialogResponse:PopupDialogResponse={
        continueOnborading: true,
        reasonText: this.reasonText
      }
      this.dialogRef.close(dialogResponse);

    }

  }
  onNoBtnClick(){
    const dialogResponse:PopupDialogResponse={
      continueOnborading: false,
    }
    this.dialogRef.close(dialogResponse);
    
  }
}
