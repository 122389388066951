import { OnInit } from '@angular/core';
import { UserService } from './../services/user.service';
import { GoogleAnalyticsService } from '../services/analytics.service';
import { VacService, VendorAllowanceConfirmation, VacPromoDetails, VacScanDown, VacUpdateRequest, VacCorpInfo, VacCompanyContact, VacScanDownPercent } from './../services/vac.service';
import { ChangeDetectorRef } from '@angular/core';
import { isAfter, parseISO, format, addDays } from 'date-fns';
import * as Handsontable from 'handsontable';
// import * as moment from 'moment';
var VacComponent = /** @class */ (function () {
    function VacComponent(users, vac, ref, ga) {
        var _this = this;
        this.users = users;
        this.vac = vac;
        this.ref = ref;
        this.ga = ga;
        this.allowedVendorTypes = ['Dropship', 'Petsense', 'Domestic', 'PTSDistributor', 'PTSManufacturer', 'Import'];
        this.buyerCompany = 'TSC';
        this.buyerList = [];
        this.defaultPromoTypes = ['Circular', 'Events', 'Resets/Clearance', 'Other'];
        this.TSCTypes = ['Price Cut Program', 'PDQ Allowance'];
        this.PTSTypes = ['BMSM/Value Program', 'ISO New Store Discount', 'Out of Date/Defective', 'Loyalty'];
        this.promoTypes = [];
        this.allPromos = [];
        this.promosByType = [];
        this.vendorInfo = new VacCorpInfo();
        this.selectedBuyerID = '';
        this.selectedVendor = new VendorInfo();
        this.PaymentNumber = 2;
        this.PaymentFrequency = 'One-Time';
        this.rainChecks = 'Y';
        this.duplicateSkus = [];
        this.formattedAmount = '';
        //public notRequired = ['FlatDolAmt','ScandownPromo','VACID','PromoDetails','BeginDate','EndDate','PaymentDate','SpecialInstructions','MfrName','PromoAbbreviation','NoOfPayments', 'Rainchecks', 'TotalSKUCount', 'Frequency']; // list of optional fields
        this.vendorIsValid = true;
        this.vendorMsg = '';
        this.showGrid = true;
        this.showGridSDPercent = false;
        this.estimates = [];
        this.uploadFilename = '';
        this.sheetError = '';
        this.sheetLoading = false;
        this.runonce = false;
        this.isSuccess = false;
        this.requestInProgress = false;
        this.isUploadReady = false;
        this.modalContent = {
            'header': 'Success',
            'body': 'VAC submitted.',
            'class': 'success',
            'footer': '',
        };
        this.enableScanDownPercentOption = false;
        this.colHeaders = ['SKU', 'SKU Description', 'Scandown Amount', 'Units', 'Forecast', ''];
        this.colHeadersScanDownPercent = ['SKU', 'SKU Description', 'Scandown %', 'Sales $', 'Result', ''];
        this.gridColumns = [
            {
                data: 'SKU',
                width: '15%',
            },
            {
                data: 'SKUDescription',
                width: '35%',
            },
            {
                data: 'ScandownAmt',
                // renderer: 'text',
                width: '16%',
                renderer: this.renderDecimal,
            },
            {
                data: 'Units',
                width: '10%',
                renderer: this.renderNumber,
            },
            {
                data: 'Forecast',
                width: '14%',
                renderer: this.renderForecast,
                readOnly: true,
            },
            {
                renderer: this.generateButtons,
                width: '10%',
                readOnly: true,
                className: 'col-buttons',
                disableVisualSelection: true
            },
        ];
        this.gridColumnsScanDownPercent = [
            {
                data: 'SKU',
                width: '15%',
            },
            {
                data: 'SKUDescription',
                width: '35%',
            },
            {
                data: 'ScandownAmtPercent',
                width: '16%',
                renderer: this.renderScanDownPercentage,
            },
            {
                data: 'Sales$',
                width: '10%',
                renderer: this.renderDecimal,
            },
            {
                data: 'Result',
                width: '14%',
                renderer: this.renderResult,
                readOnly: true,
            },
            {
                renderer: this.generateButtons,
                width: '10%',
                readOnly: true,
                className: 'col-buttons',
                disableVisualSelection: true
            },
        ];
        this.gridOptions = {
            viewportColumnRenderingOffset: 10,
            viewportRowRenderingOffset: 'auto',
            renderAllRows: true,
            stretchH: "all",
            manualRowResize: false,
            manualRowMove: false,
            manualColumnMove: false,
            contextMenu: false,
            autoColumnSize: false,
            filters: false,
            dropdownMenu: false,
            allowEmpty: false,
            preventOverflow: 'horizontal',
            height: 300,
            colHeaders: this.colHeaders,
            columns: this.gridColumns,
            columnSorting: false,
            sortIndicator: false,
            manualColumnResize: false,
            fillHandle: {
                autoInsertRow: false
            },
            invalidCellClassName: 'attr-value-invalid',
            beforeOnCellMouseDown: function (event, coords, TD) { },
            beforeChange: function (changes, source) { },
            afterValidate: function (isValid, value, row, prop) { },
            afterChange: function (changes, source) { },
            afterRender: function () {
                _this.estimateAmount();
                _this.ref.detectChanges();
            }
        };
        this.gridOptionsPercentScanDown = {
            viewportColumnRenderingOffset: 10,
            viewportRowRenderingOffset: 'auto',
            renderAllRows: true,
            stretchH: "all",
            manualRowResize: false,
            manualRowMove: false,
            manualColumnMove: false,
            contextMenu: false,
            autoColumnSize: false,
            filters: false,
            dropdownMenu: false,
            allowEmpty: false,
            preventOverflow: 'horizontal',
            height: 300,
            colHeaders: this.colHeadersScanDownPercent,
            columns: this.gridColumnsScanDownPercent,
            columnSorting: false,
            sortIndicator: false,
            manualColumnResize: false,
            fillHandle: {
                autoInsertRow: false
            },
            invalidCellClassName: 'attr-value-invalid',
            beforeOnCellMouseDown: function (event, coords, TD) { },
            beforeChange: function (changes, source) { },
            afterValidate: function (isValid, value, row, prop) { },
            afterChange: function (changes, source) { },
            afterRender: function () {
                _this.estimateAmountForSDPercent();
                _this.ref.detectChanges();
            }
        };
    }
    VacComponent.prototype.unloadHandler = function (event) {
        console.log('leaving page, reset form');
        this.vac.resetSelectedVAC();
    };
    VacComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b;
        if (this.users.getUserRole() === 'TSC_Buyer') {
            this.buyerCompany = 'TSC';
            this.enableScanDownPercentOption = false;
            (_a = this.defaultPromoTypes).push.apply(_a, this.TSCTypes);
        }
        else if (this.users.getUserRole() === 'PTS_Buyer') {
            this.buyerCompany = 'PETSENSE';
            this.enableScanDownPercentOption = true;
            (_b = this.defaultPromoTypes).push.apply(_b, this.PTSTypes);
        }
        this.vac.getPromoDetails(this.buyerCompany).subscribe(function (data) {
            if (data && data.Data) {
                console.log('promo types for ' + _this.buyerCompany, data);
                _this.allPromos = data.Data;
                _this.showPromoTypes(_this.allPromos);
            }
            else {
                console.log('no promo type data, use default');
                _this.promoTypes = _this.defaultPromoTypes;
            }
        }, function (error) {
            console.log('error loading promo types, use default', error);
            _this.promoTypes = _this.defaultPromoTypes;
        });
        console.log('VAC page loaded');
        this.vac.initializeVAC();
        var scandown = new VacScanDown();
        this.VAC = new VendorAllowanceConfirmation();
        this.VAC.PromoDetails = new VacPromoDetails();
        this.VAC.PromoDetails.ScanDownPromo = [];
        // this.gridOptions.data = this.VAC.PromoDetails.ScanDownPromo;
        console.log('VAC object', this.VAC);
        // TIBCO-3618 Edit VAC
        console.log('Editing a VAC?', this.vac.getRevisionStatus());
        if (this.vac.getRevisionStatus()) {
            // parse VAC object to form sections
            // see reverse of submit VAC
            this.showSelectedVAC();
        }
        else {
            this.setFormDefaults(this.buyerCompany);
        }
    };
    VacComponent.prototype.ngAfterViewInit = function () {
        this.hot = this.vacgrid.getHandsontableInstance();
        if (!this.vac.getRevisionStatus()) {
            this.hot.alter('insert_row', 1, 8); //initialze grid
        }
        // this.hot.updateSettings({data: this.VAC.PromoDetails.ScanDownPromo});
    };
    VacComponent.prototype.ngOnDestroy = function () {
        this.vac.resetSelectedVAC();
    };
    VacComponent.prototype.canSubmit = function (form) {
        // console.log(form);
        // form validation
        if (!this.vendorIsValid) {
            return false;
        }
        if (!form.valid) {
            return false;
        }
        if (this.requestInProgress) {
            return false;
        }
        if (this.showGrid || this.showGridSDPercent) {
            // check for valid grid if scandown
            if (this.hot) {
                var firstCell = this.hot.getDataAtCell(0, 0); // check for any content in grid
                if (!firstCell || firstCell === '') {
                    return false;
                }
                else {
                    if (!this.hot.getDataAtCell(0, 2) || !this.hot.getDataAtCell(0, 3)) {
                        return false;
                    }
                }
            }
            if (this.showGrid) {
                if (!this.VAC.PromoDetails.EstimatedUnits || this.VAC.PromoDetails.EstimatedUnits == '0' ||
                    !this.VAC.PromoDetails.EstimatedAmt || this.VAC.PromoDetails.EstimatedAmt == '0') {
                    //calculated from grid
                    return false;
                }
            }
            if (this.showGridSDPercent) {
                // console.log("Total Sale$ ", this.VAC.PromoDetails.TotalSales$, this.VAC.PromoDetails.EstimatedAmt);
                if (!this.VAC.PromoDetails.EstimatedUnits || this.VAC.PromoDetails.EstimatedUnits == '0.00' ||
                    !this.VAC.PromoDetails.EstimatedAmt || this.VAC.PromoDetails.EstimatedAmt == '0.00') {
                    //calculated from grid
                    return false;
                }
            }
        }
        else {
            // console.log('flat', this.showGrid, this.VAC.PromoDetails.FlatDolAmt);
            if (this.VAC.PromoDetails.FlatDolAmt === '') {
                return false;
            }
        }
        // validate dates
        if (this.BeginDate === '' || this.EndDate === '' || this.PaymentDate === '') {
            return false;
        }
        else {
            if (isAfter(parseISO(this.BeginDate), parseISO(this.EndDate))) { // if(moment(this.BeginDate).isAfter(this.EndDate)) {
                return false;
            }
        }
        return true;
    };
    VacComponent.prototype.selectType = function (index) {
        if (index === 1) {
            if (this.hot) {
                this.hot.clear();
                this.hot.deselectCell();
                this.hot.render();
            }
            this.showGrid = false;
            this.showGridSDPercent = false;
        }
        else if (index === 2) {
            if (this.hot) {
                this.hot.clear();
                this.hot.deselectCell();
                this.hot.render();
            }
            this.showGridSDPercent = true;
            this.showGrid = false;
        }
        else {
            if (this.hot) {
                this.hot.clear();
                this.hot.deselectCell();
                this.hot.render();
            }
            this.showGrid = true;
            this.showGridSDPercent = false;
        }
        console.log(index, this.showGrid);
    };
    VacComponent.prototype.dateInvalid = function () {
        if (this.BeginDate && this.EndDate) {
            var begin = new Date(this.BeginDate); // moment(this.VAC.BeginDate);
            var end = new Date(this.EndDate); // this.VAC.EndDate;
            if (isAfter(begin, end)) { // if(begin.isAfter(end, 'day')) {
                return true;
                // this.VAC.EndDate = '';
            }
        }
        return false;
    };
    VacComponent.prototype.setFormDefaults = function (company) {
        console.log('set form defaults', company);
        if (company === 'TSC') {
            this.VAC.PromoDetails.PaymentType = 'Debit';
            this.VAC.MfrName = 'N/A';
            this.rainChecks = 'Y';
            this.VAC.PromoDetails.Frequency = 'One-Time';
            this.PaymentFrequency = 'One-Time';
        }
        if (company === 'PETSENSE') {
            this.rainChecks = 'N';
        }
    };
    VacComponent.prototype.setPromoDates = function (changePromoType) {
        var _this = this;
        console.log('set promo dates', this.VAC.PromoType, this.allPromos, this.selectedVendor.VendorType, '"' + this.VAC.PromoDescription + '"');
        if (this.VAC && this.VAC.PromoType) {
            if (changePromoType && this.vac.getRevisionStatus()) {
                // reset promo description in revision
                console.log('reset VAC Description', this.loadedVAC.PromoType, this.VAC.PromoType);
                this.BeginDate = '';
                this.EndDate = '';
                this.PaymentDate = '';
                this.VAC.PromoDescription = '';
                this.VAC.PromoAbbreviation = '';
            }
            if (this.selectedVendor && (this.selectedVendor.VendorType === 'Domestic' || this.selectedVendor.VendorType === 'Import') && this.VAC.PromoDescription) {
                var selectedEvent = this.allPromos.find(function (t) { return t.PromotionName && _this.VAC.PromoDescription && t.PromotionName.trim() === _this.VAC.PromoDescription.trim() && t.PromotionType === _this.VAC.PromoType; });
                console.log('Domestic: Selected Promotion', selectedEvent);
                if (selectedEvent) {
                    this.BeginDate = format(parseISO(selectedEvent.StartDate), 'yyyy-MM-dd') || '';
                    this.EndDate = format(parseISO(selectedEvent.EndDate), 'yyyy-MM-dd') || '';
                    this.PaymentDate = this.EndDate ? format(addDays(parseISO(selectedEvent.EndDate), 1), 'yyyy-MM-dd') : '';
                }
                else {
                    this.showPromoList();
                }
            }
            else {
                var selectedType = this.promoTypes.find(function (t) { return t.PromotionType === _this.VAC.PromoType; });
                console.log('selected Type', selectedType);
                this.showPromoList();
                if (selectedType) {
                    // set the default dates
                    if (selectedType.StartDate) {
                        this.BeginDate = format(parseISO(selectedType.StartDate), 'yyyy-MM-dd') || ''; // moment(selectedType.BeginDate).format('YYYY-MM-DD') || '';
                    }
                    if (selectedType.EndDate) {
                        this.EndDate = format(parseISO(selectedType.EndDate), 'yyyy-MM-dd') || ''; // moment(selectedType.EndDate).format('YYYY-MM-DD') || '';
                        this.PaymentDate = this.EndDate ? format(addDays(parseISO(selectedType.EndDate), 1), 'yyyy-MM-dd') : ''; // moment(this.EndDate).add(1,'day').format('YYYY-MM-DD') : '';
                    }
                }
            }
        }
        // reset VACID here if needed
    };
    VacComponent.prototype.showPromoTypes = function (promos) {
        // parse response, get unique promo types
        console.log('promo types', promos);
        var _loop_1 = function (type) {
            var found = this_1.promoTypes.find(function (x) { return x.PromotionType === type.PromotionType; });
            if (!found) {
                this_1.promoTypes.push(type);
            }
        };
        var this_1 = this;
        for (var _i = 0, promos_1 = promos; _i < promos_1.length; _i++) {
            var type = promos_1[_i];
            _loop_1(type);
        }
        this.showPromoList();
    };
    VacComponent.prototype.showPromoList = function (promos) {
        if (!promos) {
            promos = this.allPromos;
        }
        this.promosByType = []; // initialize
        if (!this.vac.getRevisionStatus()) {
            this.BeginDate = '';
            this.EndDate = '';
            this.PaymentDate = '';
            this.VAC.PromoDescription = '';
            this.VAC.PromoAbbreviation = '';
        }
        // get events/dates for vendor type    
        //  if(this.selectedVendor && this.selectedVendor.VendorType === 'Domestic'){
        // if domestic, dates are tied to the events drop down
        this.promosByType = [];
        for (var _i = 0, promos_2 = promos; _i < promos_2.length; _i++) {
            var promo = promos_2[_i];
            if (promo.PromotionType === this.VAC.PromoType) {
                this.promosByType.push(promo);
            }
        }
        // } else {
        // otherwise dates are in the promo and events is free form
        //  }
        // TIBCO-3618 - add old promo type on edit
        // don't do this, user must pick a valid choice
        /*
    
    
        if (this.vac.getRevisionStatus()) {
          const vac = this.vac.getSelectedVAC();
          let desc = this.promosByType.find(t => t.PromotionName === vac.PromoDescription);
          console.log('desc', vac.PromoDescription, this.promoTypes);
          if (!desc) {
            const abbv = this.promoTypes.find(t => t.PromotionType === this.VAC.PromoType);
            let addPromo  = {
              'PromotionType': vac.PromoType,
              'PromotionName': vac.PromoDescription,
              'PromotionAbbv': abbv ? abbv.PromotionAbbv : '', // vac.PromoAbbreviation
              'StartDate': vac.BeginDate,
              'EndDate': vac.EndDate,
              'BuyerType': this.buyerCompany
            }
            
            // this.promosByType.unshift(addPromo); // add to array for display
          }
        } */
        console.log('Promos by Type', this.promosByType);
    };
    VacComponent.prototype.showDropDown = function () {
        var show = true;
        if (!this.selectedVendor ||
            !this.promosByType ||
            this.promosByType.length == 0 ||
            (this.promosByType.length == 1 && !this.promosByType[0].PromotionName) ||
            (this.promosByType.length == 1 && this.promosByType[0].PromotionName == '')) {
            show = false;
        }
        return show;
    };
    VacComponent.prototype.showRainChecks = function () {
        // show/hide criteria here
        return true;
    };
    VacComponent.prototype.resetUpload = function () {
        this.uploadFilename = '';
        this.upload.nativeElement.value = null;
    };
    VacComponent.prototype.uploadTemplate = function (event) {
        var _this = this;
        // get current data
        var currData = this.hot.getData();
        // handle the upload
        this.uploadFilename = event.target.files[0].name;
        this.sheetError = '';
        this.sheetLoading = true;
        // validate file type
        var filetype = event.target.files[0].type;
        var validmime = ['application/vnd.ms-excel', 'text/csv'];
        var valid = validmime.find(function (mime) { return mime === filetype; });
        if (!valid) {
            console.log('invalid mime type, must be csv', filetype);
            this.sheetError = 'Invalid file, please use the provided template.';
            this.sheetLoading = false;
            this.resetUpload();
            return false;
        }
        this.vac.importSheet(event, currData);
        console.log(event);
        // wait for sheetData to populate
        this.vac.getSheetData().subscribe(function (resp) {
            _this.sheetLoading = false;
            _this.sheetError = 'Processing data for display...';
            if (resp) {
                // validate sheet
                /*
                if (resp.length === 0 ||
                  (!resp[0]['SKU'] && !resp[0]['Units'] && !resp[0]['Scandown Amount']) ) {
                  console.log('not valid', resp);
                  // handle errors
                  this.sheetError = 'Invalid file, please use the provided template.';
                  this.resetUpload();
                  return false;
                
                }
                */
                if (resp.length === 0 ||
                    (!_this.showGridSDPercent && (!('SKU' in resp[0]) || !('Units' in resp[0]) || !('Scandown Amount' in resp[0])))
                    || (_this.showGridSDPercent && (!('SKU' in resp[0]) || !('Sales $' in resp[0]) || !('Scandown %' in resp[0])))) {
                    console.log('not valid', resp);
                    // handle errors
                    _this.sheetError = 'Invalid file, please use the provided template.';
                    _this.resetUpload();
                    return false;
                }
                // parse the data (and optionally update VAC object... which should update on submit)
                var sheet = [];
                if (!_this.showGridSDPercent) {
                    var _loop_2 = function (row) {
                        var r = new VacScanDown();
                        r.SKU = row['SKU'].toString().substring(0, 7);
                        r.SKUDescription = row['SKU Description'];
                        r.ScandownAmt = row['Scandown Amount'];
                        r.Units = row['Units'];
                        // r.Forecast = row['Forecast'];
                        // unique skus only
                        var sku = sheet.find(function (x) { return x.SKU === r.SKU; });
                        if (!sku) {
                            sheet.push(r);
                        }
                    };
                    for (var _i = 0, resp_1 = resp; _i < resp_1.length; _i++) {
                        var row = resp_1[_i];
                        _loop_2(row);
                    }
                }
                else if (_this.showGridSDPercent) {
                    var _loop_3 = function (row) {
                        if (Object.keys(row).length > 0) {
                            var r_1 = new VacScanDownPercent();
                            r_1.SKU = row['SKU'].toString().substring(0, 7);
                            r_1.SKUDescription = row['SKU Description'];
                            r_1.ScandownAmtPercent = row['Scandown %'];
                            r_1.Sales$ = row['Sales $'];
                            var sku = sheet.find(function (x) { return x.SKU === r_1.SKU; });
                            if (!sku) {
                                sheet.push(r_1);
                            }
                        }
                    };
                    for (var _a = 0, resp_2 = resp; _a < resp_2.length; _a++) {
                        var row = resp_2[_a];
                        _loop_3(row);
                    }
                }
                // update grid
                console.log('updated grid data', sheet);
                // let newGridHeight = sheet.length * 16; // 1em in px?
                var newGridHeight = 300;
                if (newGridHeight < 360) {
                    newGridHeight = 360;
                }
                ; // min height
                _this.hot.updateSettings({ data: sheet, height: newGridHeight });
                _this.sheetError = '';
                _this.resetUpload();
                var skuList = sheet.map(function (x) { return x.SKU; }).join(',');
                console.log('success for ' + skuList);
                _this.ga.sendEvent('VAC', 'Sheet data uploaded successfully', skuList, { 'time_stamp': Date.now(), 'record_id': _this.VAC.VACID || 'New VAC' });
            }
            else {
                // show an error
                // this.sheetError = 'There was a problem reading the uploaded file.';
                _this.sheetError = 'Loading spreadsheet data...';
                _this.resetUpload();
            }
        }, function (error) {
            //handle document load errors
            _this.sheetLoading = false;
            _this.sheetError = 'There was a problem loading the file.';
            _this.resetUpload();
            _this.ga.sendEvent('VAC', 'Error uploading sheet data', error.message, { 'time_stamp': Date.now(), 'error_msg': error.message });
            console.log(error);
        });
    };
    VacComponent.prototype.downloadTemplate = function () {
        this.vac.exportSheet(this.hot);
    };
    VacComponent.prototype.checkVendor = function (id) {
        var _this = this;
        console.log('check vendor ' + id);
        if (id.length !== 6) {
            this.vendorIsValid = false;
            this.vendorMsg = 'Please enter a valid vendor ID';
        }
        else {
            // check vendor info
            this.vac.getVendorInfo(id).subscribe(function (vendor) {
                console.log(vendor);
                if (vendor && vendor.VendorData && vendor.VendorData.length > 0) {
                    var v = vendor.VendorData[0];
                    if (v && v.VendorID === id && _this.isAllowedVendor(v)) {
                        _this.vendorIsValid = true;
                        _this.vendorMsg = v.CompanyName; //vendor name
                        _this.selectedVendor = v;
                        _this.showPromoTypes(_this.allPromos);
                    }
                    else {
                        _this.vendorIsValid = false;
                        _this.vendorMsg = 'Please enter a valid vendor ID';
                        _this.selectedVendor = new VendorInfo();
                    }
                }
                else {
                    _this.vendorIsValid = false;
                    _this.vendorMsg = 'Please enter a valid vendor ID';
                    _this.selectedVendor = new VendorInfo();
                }
            }, function (error) {
                console.log(error);
                _this.vendorIsValid = false;
                _this.vendorMsg = 'Please enter a valid vendor ID';
                _this.selectedVendor = new VendorInfo();
            });
        }
    };
    VacComponent.prototype.changeVendorContact = function (newEmail) {
        console.log('changed email address', newEmail);
        this.selectedVendor.FirstName = '';
        this.selectedVendor.LastName = '';
    };
    VacComponent.prototype.isAllowedVendor = function (vendor) {
        if (vendor.IsVendorActive !== 'N' &&
            this.allowedVendorTypes.indexOf(vendor.VendorType) > -1) {
            return true;
        }
        else {
            return false;
        }
    };
    VacComponent.prototype.generateButtons = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        var addbtn = document.createElement('button');
        addbtn.classList.add('btn-add', 'btn-add-' + row);
        addbtn.title = 'Add Row';
        addbtn.innerHTML = '+';
        Handsontable.dom.addEvent(addbtn, 'click', function () {
            instance.alter('insert_row');
        });
        var delbtn = document.createElement('button');
        delbtn.classList.add('btn-del', 'btn-del-' + row);
        delbtn.innerHTML = '&times;';
        delbtn.title = "Delete Row";
        Handsontable.dom.addEvent(delbtn, 'click', function () {
            instance.alter('remove_row', row);
        });
        if (instance.countRows() > 1) {
            td.append(delbtn);
        }
        if (row === instance.countRows() - 1) {
            td.append(addbtn);
        }
    };
    VacComponent.prototype.multiply = function (a, b) {
        var limit = 2; // max number of decimal places allowed
        var commonMultiplier = 1000000;
        a *= commonMultiplier;
        b *= commonMultiplier;
        return parseFloat(((a * b) / (commonMultiplier * commonMultiplier)).toFixed(limit));
    };
    VacComponent.prototype.renderForecast = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        var amt = instance.getDataAtCell(row, 2);
        var qty = instance.getDataAtCell(row, 3);
        var limit = 2; // max number of decimal places allowed
        if (amt && qty) {
            console.log('row data', amt, qty);
            var z = 10000;
            qty = qty.toString().replace(/,/g, '') * z;
            var newAmt = amt.toString().replace(/,/g, '') * z;
            var newQty = qty;
            var rowAmt = (newAmt * newQty) / (z * z); // fix large decimal places errors
            // console.log('amount', rowAmt); // format in $
            if (rowAmt) {
                td.innerHTML = '$' + parseFloat(rowAmt.toFixed(limit)).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }); // add commas 
                // td.innerHTML = '$' + parseFloat(rowAmt.toFixed(limit)).toLocaleString(); // add commas
            }
        }
        else {
            td.innerHTML = '';
        }
    };
    VacComponent.prototype.renderResult = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        var percent = instance.getDataAtCell(row, 2);
        var sales$ = instance.getDataAtCell(row, 3);
        var limit = 2; // max number of decimal places allowed
        if (percent && sales$) {
            var z = 10000;
            sales$ = sales$.toString().replace(/,/g, '');
            var newPercent = percent.toString().replace(/,/g, '');
            var newSales$ = sales$;
            var result = ((newPercent / 100) * newSales$);
            if (result) {
                td.innerHTML = '$' + parseFloat(result.toFixed(limit)).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }); // add commas 
                // td.innerHTML = '$' + parseFloat(rowAmt.toFixed(limit)).toLocaleString(); // add commas
            }
        }
        else {
            td.innerHTML = '';
        }
    };
    VacComponent.prototype.renderNumber = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (value) {
            console.log('Value', value);
            // remove commas
            var nocommas = value.toString().replace(/,/g, '');
            // change to number
            var num = parseFloat(nocommas);
            // change back to string ::eyeroll::
            if (isNaN(num)) {
                td.innerHTML = '';
            }
            else {
                td.innerHTML = num.toLocaleString();
            }
        }
    };
    VacComponent.prototype.renderDecimal = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (value) {
            // change to number
            var num = parseFloat(value);
            if (isNaN(num)) {
                td.innerHTML = '';
            }
            else {
                // td.innerHTML = num.toLocaleString('en-US', {maximumFractionDigits:2,minimumFractionDigits:0});
                td.innerHTML = num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            }
        }
    };
    VacComponent.prototype.renderScanDownPercentage = function (instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (value) {
            // change to number
            var num = parseFloat(value);
            if (isNaN(num)) {
                td.innerHTML = '';
            }
            else {
                console.log(" Decimal Value=>", Number(num / 100).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }));
                // td.innerHTML = num.toLocaleString('en-US', {maximumFractionDigits:2,minimumFractionDigits:0});
                td.innerHTML = Number(num / 100).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
        }
    };
    VacComponent.prototype.estimateUnits = function () {
        var estUnits = 0;
        var data = [];
        if (this.hot) {
            data = this.hot.getDataAtCol(3); // units
            console.log('calculate from data', data);
            for (var i = 0; i < data.length; i++) {
                if (!isNaN(+data[i])) {
                    estUnits = estUnits + (+data[i]);
                }
            }
            this.VAC.PromoDetails.EstimatedUnits = estUnits.toFixed(0).toLocaleString();
            this.ref.markForCheck();
        }
    };
    VacComponent.prototype.totalSales$Amt = function () {
        var totalSales$ = 0.0;
        var data = [];
        if (this.hot) {
            data = this.hot.getDataAtCol(3); // sales $
            for (var i = 0; i < data.length; i++) {
                if (!isNaN(+data[i])) {
                    totalSales$ = totalSales$ + (+data[i]);
                }
            }
            //this.VAC.PromoDetails.TotalSales$ = totalSales$.toFixed(2).toLocaleString();
            this.VAC.PromoDetails.EstimatedUnits = totalSales$.toFixed(2).toLocaleString();
            this.ref.markForCheck();
        }
    };
    // chg array
    // [2, "ScandownAmt", undefined, "111"]
    // row, header, old value, new value
    VacComponent.prototype.estimateAmount = function () {
        var estAmount = 0;
        var data = [];
        if (this.hot) {
            data = this.hot.getData(); // scandownamt
            for (var row in data) {
                if (data[row][0] && data[row][0].length > 0) {
                    // limit sku length to 7 numeric digits
                    var sku = data[row][0].toString().replace(/[^\d]/g, '');
                    console.log('sku ' + sku + ' has ' + sku.length + ' digits.');
                    if (sku !== data[row][0].toString() || sku.length > 7) {
                        if (sku.length > 7) {
                            this.hot.setDataAtCell(row, 0, sku.substring(0, 7));
                        }
                        else {
                            this.hot.setDataAtCell(row, 0, sku);
                        }
                    }
                }
                if (data[row][2] && data[row][3]) {
                    console.log('row ' + row);
                    var amt = parseFloat(data[row][2].toString().replace(/,/g, '-'));
                    var units = parseFloat(data[row][3].toString().replace(/,/g, '-'));
                    var rowAmt = 0;
                    if (!isNaN(amt) && !isNaN(units)) {
                        rowAmt = this.multiply(units, amt);
                    }
                    console.log('row amount', rowAmt);
                    // this.hot.setDataAtCell(row,4,rowAmt); // set forecast
                    estAmount = estAmount + rowAmt;
                }
            }
            this.VAC.PromoDetails.EstimatedAmt = estAmount.toFixed(2).toLocaleString();
            // this.VAC.PromoDetails.EstimatedAmt = estAmount.toLocaleString();
            this.estimateUnits();
        }
    };
    VacComponent.prototype.estimateAmountForSDPercent = function () {
        var estAmount = 0.0;
        var data = [];
        if (this.hot) {
            data = this.hot.getData(); // scandownamt
            for (var row in data) {
                if (data[row][0] && data[row][0].length > 0) {
                    // limit sku length to 7 numeric digits
                    var sku = data[row][0].toString().replace(/[^\d]/g, '');
                    console.log('sku ' + sku + ' has ' + sku.length + ' digits.');
                    if (sku !== data[row][0].toString() || sku.length > 7) {
                        if (sku.length > 7) {
                            this.hot.setDataAtCell(row, 0, sku.substring(0, 7));
                        }
                        else {
                            this.hot.setDataAtCell(row, 0, sku);
                        }
                    }
                }
                if (data[row][2] && data[row][3]) {
                    var scandownPercent = parseFloat(data[row][2].toString().replace(/,/g, ''));
                    var sales$Amt = parseFloat(data[row][3].toString().replace(/,/g, ''));
                    var rowResult = ((scandownPercent / 100) * sales$Amt);
                    var result = parseFloat(rowResult.toFixed(2));
                    var rowResultValue = 0;
                    if (!isNaN(result)) {
                        rowResultValue = result;
                    }
                    estAmount = estAmount + rowResultValue;
                }
            }
            this.VAC.PromoDetails.EstimatedAmt = estAmount.toFixed(2).toLocaleString();
            this.totalSales$Amt();
        }
    };
    VacComponent.prototype.formatCommas = function (e, fieldname) {
        // console.log('format event', e, fieldname);
        var amt = e.target.value.replace(/[^\d\.\-]/g, '').valueOf(); //force number format
        var formattedAmt = amt.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, currency: 'USD' }); // format with commas
        this.VAC.PromoDetails[fieldname] = amt;
        console.log('formatCommas', e.target.value, amt, formattedAmt);
    };
    VacComponent.prototype.formatFlatAmt = function () {
        console.log('formatting flat dollar amount', this.formattedAmount);
        var amt = parseFloat(this.formattedAmount.replace(/[^\d\.\-]/g, '')); // force number format
        console.log(amt);
        var fmtAmt = amt.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
        console.log(fmtAmt);
        this.formattedAmount = fmtAmt;
        this.VAC.PromoDetails.FlatDolAmt = this.unformatNumber(fmtAmt);
    };
    VacComponent.prototype.unformatNumber = function (str) {
        return parseFloat(str.replace(/[^\d\.\-]/g, '')).toFixed(2);
    };
    VacComponent.prototype.setPaymentDate = function () {
        if (this.EndDate && this.EndDate !== '') {
            var enddate = new Date(this.EndDate);
            var unixDate = enddate.setUTCHours(5, 0, 0); // handle central time zone
            var pmtDate = addDays(enddate, 1);
            this.PaymentDate = format(pmtDate, 'yyyy-MM-dd'); // moment(this.EndDate).add(1,'day').format('YYYY-MM-DD');
            console.log(this.EndDate, unixDate, enddate, pmtDate, this.PaymentDate);
        }
    };
    VacComponent.prototype.showSelectedVAC = function (vac) {
        // parse vac object into visible form
        vac = vac ? vac : this.vac.getSelectedVAC();
        console.log('Editing this VAC', vac);
        this.loadedVAC = vac;
        if (vac.OnboardingStatus.toUpperCase() === 'REJECTED') {
            this.VAC.VACID = vac.VACID;
        }
        console.log('keep ID only if status is Rejected', vac.OnboardingStatus, this.VAC);
        var buyer = this.vac.vacBuyer.find(function (b) { return b.BuyerID === vac.BuyerID && b.Email === vac.BuyerEmail; }); //b.CompanyName === this.buyerCompany &&
        this.selectedBuyerID = buyer ? buyer.BuyerID + '|' + buyer.Email : vac.BuyerID;
        this.selectedVendorID = vac.VendorID;
        this.checkVendor(this.selectedVendorID);
        if (this.buyerCompany === 'PETSENSE') {
            this.VAC.MfrName = vac.MfrName;
            this.VAC.MfrEmail = vac.MfrEmail;
        }
        console.log('BUYER', this.selectedBuyerID);
        // promo section
        this.VAC.PromoType = vac.PromoType;
        this.VAC.PromoDescription = vac.PromoDescription;
        this.VAC.PromoAbbreviation = vac.PromoAbbreviation;
        this.BeginDate = format(parseISO(vac.BeginDate), 'yyyy-MM-dd');
        this.EndDate = format(parseISO(vac.EndDate), 'yyyy-MM-dd');
        console.log(this.BeginDate, this.EndDate);
        // promo details
        this.VAC.PromoDetails = vac.PromoDetails;
        this.formattedAmount = vac.PromoDetails.FlatDolAmt;
        this.gridOptions.data = this.VAC.PromoDetails.ScanDownPromo;
        this.rainChecks = vac.PromoDetails.Rainchecks;
        this.VAC.ScandownFlag = vac.ScandownFlag;
        if (vac.ScandownFlag === 'N') {
            this.selectType(1);
            this.formatFlatAmt();
        }
        else {
            this.selectType(0);
        }
        // payment section
        this.VAC.PromoDetails.PaymentType = vac.PromoDetails.PaymentType;
        this.PaymentNumber = vac.PromoDetails.PaymentNumber;
        this.PaymentFrequency = vac.PromoDetails.Frequency || 'One-Time';
        this.PaymentDate = format(parseISO(vac.PromoDetails.PaymentDate), 'yyyy-MM-dd');
        console.log('updated VAC', this.VAC);
        this.ga.sendEvent('VAC', 'Selected VAC ' + this.VAC.VACID + ' for edit', this.users.loggedInUser.id, { 'time_stamp': Date.now(), 'record_id': this.VAC.VACID });
    };
    VacComponent.prototype.validateSkuList = function () {
        var valid = true;
        this.duplicateSkus = []; // reset list
        var skus = [];
        if (this.VAC.PromoDetails.ScanDownPromo && this.VAC.PromoDetails.ScanDownPromo.length > 0) {
            var _loop_4 = function (row) {
                console.log('validate row', row);
                if (row.SKU && row.SKU !== '') {
                    var found = skus.find(function (s) { return s === row.SKU; });
                    if (found) {
                        valid = false;
                        this_2.duplicateSkus.push(row.SKU);
                        console.log('duplicate sku found ', row.SKU);
                    }
                    else {
                        skus.push(row.SKU);
                    }
                }
            };
            var this_2 = this;
            // check for duplicates
            for (var _i = 0, _a = this.VAC.PromoDetails.ScanDownPromo; _i < _a.length; _i++) {
                var row = _a[_i];
                _loop_4(row);
            }
        }
        return valid;
    };
    VacComponent.prototype.generateVACrequest = function () {
        var _this = this;
        var req = new VacUpdateRequest();
        console.log(this.selectedVendor, this.selectedBuyerID, req);
        // no OnboardingStatus, contact type or phone number included in vendor info response
        req['DocumentData'] = { OnboardingStatus: 'Submitted' };
        var corpinfo = new VacCorpInfo();
        console.log('Buyer', this.selectedBuyerID);
        var buyerInfo = this.selectedBuyerID.split('|');
        this.selectedBuyer = this.vac.vacBuyer.find(function (b) { return b.BuyerID == buyerInfo[0] && b.Email == buyerInfo[1]; });
        console.log(buyerInfo, this.selectedBuyer);
        if (this.selectedBuyer) {
            corpinfo.BuyerID = this.selectedBuyer.BuyerID;
            corpinfo.BuyerCompany = this.selectedBuyer.CompanyName;
            corpinfo.BuyerEmail = this.selectedBuyer.Email;
            corpinfo.BuyerFirstName = this.selectedBuyer.FirstName;
            corpinfo.BuyerLastName = this.selectedBuyer.LastName;
        }
        // populate corporate info
        corpinfo.VendorID = this.selectedVendor.VendorID;
        corpinfo.CompanyName = this.selectedVendor.CompanyName;
        corpinfo.VendorType = this.selectedVendor.VendorType;
        req.VendorCorporateInformation = corpinfo;
        // populate contact info
        var contact = new VacCompanyContact();
        contact.Email = this.selectedVendor.Email || '';
        contact.FirstName = this.selectedVendor.FirstName || '';
        contact.LastName = this.selectedVendor.LastName || '';
        // no contact type or phone number included in vendor info response
        req.VendorCompanyContacts = { 'BuyersVendorContact_SC': contact };
        // set Manufacturer Flag
        if (this.buyerCompany === 'PETSENSE' || this.buyerCompany === 'PTS') {
            if (this.VAC.MfrName && this.VAC.MfrName !== '') {
                this.VAC.IsManufacturer = 'Y';
            }
            else {
                this.VAC.IsManufacturer = 'N';
                this.VAC.MfrName = 'N/A';
            }
        }
        // set promo abbreviation
        var abbv = this.promoTypes.find(function (t) { return t.PromotionType === _this.VAC.PromoType; });
        console.log('abbreviation', abbv, this.VAC.PromoType, this.promoTypes);
        if (abbv) {
            this.VAC.PromoAbbreviation = abbv.PromotionAbbv;
        }
        // copy grid data to submit object
        // clear unused fields
        if (!this.showGrid && !this.showGridSDPercent) {
            // flat
            this.VAC.PromoDetails.EstimatedAmt = '';
            this.VAC.PromoDetails.EstimatedUnits = '';
            this.VAC.PromoDetails.Rainchecks = 'N';
            this.VAC.PromoDetails.TotalSKUCount = '';
            this.VAC.ScandownFlag = 'N';
            // this.VAC.PromoDetails.TotalSales$ = ''; // new field
            // don't send scandown info if not scandown
            delete this.VAC.PromoDetails.ScanDownPromo;
            delete this.VAC.PromoDetails.ScanDownPromoPercent; // new object
            // this.VAC.PromoDetails.ScanDownPromo = [new VacScanDown()];
            // let p = this.VAC.PromoDetails.ScanDownPromo[0];
            // p.ScandownAmt = this.vac.replaceNull(p.ScandownAmt).toString();
            // p.Units = this.vac.replaceNull(p.Units).toString();
            // remove newlines from FlatSkuList
            if (this.VAC.PromoDetails.FlatDollarSkuList && this.VAC.PromoDetails.FlatDollarSkuList !== '') {
                this.VAC.PromoDetails.FlatDollarSkuList = this.VAC.PromoDetails.FlatDollarSkuList.replace(/(\r\n|\r|\n)/g, '');
            }
        }
        else {
            // scandown
            this.VAC.PromoDetails.ScanDownPromo = [];
            this.VAC.PromoDetails.Rainchecks = this.rainChecks;
            this.VAC.PromoDetails.FlatDolAmt = '';
            this.VAC.PromoDetails.FlatDollarSkuList = '';
            this.formattedAmount = '';
            // this.VAC.ScandownFlag = 'Y';
            this.VAC.ScandownFlag = this.showGridSDPercent ? 'P' : this.enableScanDownPercentOption && this.showGrid ? 'A' : 'Y';
            var skuData = this.hot.getData();
            for (var _i = 0, skuData_1 = skuData; _i < skuData_1.length; _i++) {
                var row = skuData_1[_i];
                var p = new VacScanDown();
                p.SKU = this.vac.replaceNull(row[0]).toString();
                p.SKUDescription = this.vac.replaceNull(row[1]).toString().replace(/\n/g, " "); // replace newline characters with single space
                p.ScandownAmt = parseFloat(this.vac.replaceNull(row[2])).toFixed(2); // toLocaleString() if commas are needed in grid values
                p.Units = this.showGridSDPercent ? parseFloat(this.vac.replaceNull(row[3])).toFixed(2) : this.vac.replaceNull(row[3]).toString(); // toLocaleString() if commas are needed in grid values
                // p.Forecast = this.vac.replaceNull(row[4]).toString();
                // calculate forecast
                p.Forecast = this.showGridSDPercent ? ((row[2] / 100) * row[3]).toFixed(2) : this.multiply(+row[2], +row[3]).toFixed(2); // toLocaleString();
                if (p.SKU && p.SKU !== '') {
                    this.VAC.PromoDetails.ScanDownPromo.push(p);
                }
            }
            this.VAC.PromoDetails.TotalSKUCount = this.VAC.PromoDetails.ScanDownPromo.length.toString(); // count rows with skus
        }
        // format dates
        // this.VAC.BeginDate = moment(this.BeginDate).format('DD-MMM-YYYY');
        // this.VAC.EndDate = moment(this.EndDate).format('DD-MMM-YYYY');
        // this.VAC.PromoDetails.PaymentDate = moment(this.PaymentDate).format('DD-MMM-YYYY');
        this.VAC.BeginDate = format(parseISO(this.BeginDate), 'dd-MMM-yyyy');
        this.VAC.EndDate = format(parseISO(this.EndDate), 'dd-MMM-yyyy');
        this.VAC.PromoDetails.PaymentDate = format(parseISO(this.PaymentDate), 'dd-MMM-yyyy');
        // payment details
        this.VAC.PromoDetails.Frequency = this.PaymentFrequency;
        if (this.PaymentFrequency === 'One-Time') {
            this.VAC.PromoDetails.NoOfPayments = '';
        }
        else {
            this.VAC.PromoDetails.NoOfPayments = this.PaymentNumber.toString();
        }
        // add SubmittedBy field (logged in user)
        this.VAC.SubmittedBy = this.users.loggedInUser.userName;
        // populate vac fields
        req.VACFields = JSON.parse(JSON.stringify(this.VAC));
        for (var _a = 0, _b = Object.keys(req.VACFields); _a < _b.length; _a++) {
            var key = _b[_a];
            if (key !== 'PromoDetails') {
                req.VACFields[key] = req.VACFields[key].toString();
            }
        }
        req.VACFields.PromoDetails.EstimatedAmt = this.VAC.PromoDetails.EstimatedAmt.toLocaleString(); // format with commas
        // if (this.VAC.PromoDetails.FlatDolAmt && this.VAC.PromoDetails.FlatDolAmt !== '') {
        if (this.VAC.ScandownFlag == 'N' && this.formattedAmount && this.formattedAmount !== '') {
            // req.VACFields.PromoDetails.FlatDolAmt = formattedAmount.toLocaleString('en-US', {maximumFractionDigits:2,minimumFractionDigits:2});
            req.VACFields.PromoDetails.FlatDolAmt = this.unformatNumber(this.formattedAmount);
        }
        // console.log('formatted', this.VAC.PromoDetails.FlatDolAmt, req.VACFields.PromoDetails.FlatDolAmt);
        // use currency pipe? https://stackoverflow.com/questions/54396044/how-to-achieve-currency-type-input-in-angular-5
        req.VACFields.PromoDetails.EstimatedUnits = this.VAC.PromoDetails.EstimatedUnits.toLocaleString();
        //console.log(req);
        return req;
    };
    VacComponent.prototype.resetForm = function (modal, form) {
        console.log('resetting form', this.hot);
        form.reset();
        this.VAC = new VendorAllowanceConfirmation(); // clear form on success
        this.VAC.PromoDetails = new VacPromoDetails();
        this.selectedBuyerID = '';
        this.selectedVendorID = '';
        this.selectedVendor = new VendorInfo();
        this.BeginDate = '';
        this.EndDate = '';
        this.PaymentDate = '';
        this.PaymentNumber = 2;
        this.rainChecks = 'N';
        this.showGrid = true;
        this.showGridSDPercent = false;
        this.hot = this.vacgrid.getHandsontableInstance();
        this.vendorMsg = '';
        this.formattedAmount = '';
        if (this.hot) {
            // this.hot.updateSettings({data: null});
            this.hot.clear();
            this.hot.deselectCell();
        }
        this.setFormDefaults(this.buyerCompany);
        this.vac.setRevisionStatus(false);
        modal.hide();
    };
    VacComponent.prototype.hideMsg = function (modal) {
        if (!modal) {
            modal = this.modal;
        }
        // console.log('hiding modal', modal);
        modal.hide();
    };
    VacComponent.prototype.testSubmit = function (modal, e) {
        // e.preventDefault();
        console.log('testing submit');
        this.isSuccess = false;
        var vacRequest = this.generateVACrequest();
        console.log(this.VAC, vacRequest);
        this.modalContent.header = 'Testing';
        this.modalContent.class = 'info';
        this.modalContent.body = 'Submit triggered, check console for VAC details';
        this.isSuccess = true;
        modal.show();
    };
    VacComponent.prototype.submitVACform = function (modal, e) {
        var _this = this;
        e.preventDefault();
        this.isSuccess = false;
        this.requestInProgress = true;
        var vacRequest = this.generateVACrequest();
        console.log(this.VAC, vacRequest);
        if (!this.validateSkuList()) {
            // duplicates found in skus
            this.modalContent.header = 'Failed';
            this.modalContent.class = 'danger';
            this.modalContent.body = 'Please remove the duplicate skus in the scandown list and try again: ' + this.duplicateSkus.join(', ');
            console.log('duplicate skus in scandown list', this.duplicateSkus);
            this.requestInProgress = false;
            // modal.show();
            alert(this.modalContent.body);
        }
        else {
            // sku list is valid, submit request
            this.vac.submitVACrequest(vacRequest).subscribe(function (data) {
                console.log('sent vac request', data);
                // handle valid responses
                if (data === 'Form Submitted' || data === 'Success') {
                    _this.modalContent.header = 'Success';
                    _this.modalContent.class = 'success';
                    // this.modalContent.body = 'VAC submitted.';
                    _this.modalContent.body = 'Your VAC document has been submitted successfully.<br />Vendor will recieve an email from DocuSign for signature.<br /><em>Note for Store-side Buyers: Once reviewed and approved by the Events Team, the Vendor will receive an email from DocuSign for signature.';
                    _this.isSuccess = true;
                    _this.requestInProgress = false;
                    _this.ga.sendEvent('VAC', 'VAC ' + (_this.VAC.VACID || 'for vendor ' + _this.selectedVendorID) + ' submitted successfully', 'Buyer: ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': _this.VAC.VACID || _this.selectedVendorID });
                    _this.vac.resetSelectedVAC();
                    modal.show();
                }
                else {
                    console.log('vac request rejected by service', data);
                    _this.modalContent.header = 'Error';
                    _this.modalContent.class = 'danger';
                    _this.modalContent.body = 'Sorry, the system rejected your VAC request.';
                    _this.requestInProgress = false;
                    _this.ga.sendEvent('VAC', 'System Rejected VAC for vendor ' + _this.selectedVendorID, 'Buyer: ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': _this.VAC.VACID || _this.selectedVendorID });
                    modal.show();
                }
            }, function (error) {
                console.log('error submitting vac request', error);
                _this.modalContent.header = 'Error';
                _this.modalContent.class = 'danger';
                _this.modalContent.body = 'Sorry, there was a problem sending your VAC request.';
                _this.requestInProgress = false;
                _this.ga.sendEvent('VAC', 'Error Processing VAC for vendor ' + _this.selectedVendorID, 'Buyer: ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': _this.VAC.VACID || _this.selectedVendorID, 'error_msg': error.message });
                modal.show();
            });
        }
    };
    return VacComponent;
}());
export { VacComponent };
var VendorInfo = /** @class */ (function () {
    function VendorInfo() {
        this.CompanyName = '';
        this.Email = '';
        this.FirstName = '';
        this.LastName = '';
        this.VendorID = '';
        this.VendorType = '';
        this.IsVendorActive = '';
    }
    return VendorInfo;
}());
export { VendorInfo };
