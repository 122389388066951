import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { requireCheckboxesToBeCheckedValidator } from "./require-checkboxes-to-be-checked.validator";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { GoogleAnalyticsService } from "../services/analytics.service";
import { VERSION,
  MatDialogRef,
  MatDialog,
  MatSnackBar,
  MAT_DIALOG_DATA } from '@angular/material';
import {DialogComponent} from '../dialog/dialog.component';

import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';
import { RestService } from "../services/rest.service";

@Component({
  selector: "app-consent",
  templateUrl: "./consent.component.html",
  styleUrls: ["./consent.component.css"]
})
export class ConsentComponent implements OnInit {
 // version = VERSION;
  title = "Consent Request";
  submitted: boolean;
  vendorTypeList;
  notesHTML = '<div style="margin-top:1em">&nbsp;</div>' +
  '<h3>Onboarding Steps</h3><ol><li>Complete your data in the Tractor Supply/Petsense by Tractor Supply Vendor Portal</li><li>Data is sent to your buying team for review</li><li>You will have an opportunity to review the data pre-populated on contractual documentation in DocuSign prior to signature. <span style="color:#ce1128;font-weight:bold;text-decoration:underline;">Please do not sign the document during the review step</span></li><li>Once negotiations are completed you will receive a specific request to sign documents through DocuSign. You can assign the signature request to another person in your company if needed</li><li>You will upload your Certificate of Insurance in DocuSign during the signature step</li><li>Our Risk Management team will review your COI and respond with feedback as needed</li><li>If a change to the COI is needed, you will receive a DocuSign task to load a new COI with required changes.</li></ol>' +
  '<h3>Best Practices</h3><ol><li>All systems are optimized for Google Chrome</li><li>If you are making edits to documents in your DocuSign tasks, you should only use Microsoft Word. The use of other editors such as Google Docs will cause issues with our automated DocuSign system.</li><li>DocuSign will recognize changes made automatically. You do not need to use Microsoft Track Changes, leave comments in documents, or format with strike-through, etc.</li><li>Always leave detailed comments in the “Comments” window within your DocuSign task. Comments will be reviewed by your buying team.</li></ol>' +
  '<p>By clicking <em>Accept</em> below, you acknowledge you have reviewed the documents requested and are ready to begin the negotiation process.</p><p>The parties to this contract agree that electronic signatures, whether digital or encrypted, are the legal equivalents of an individual\'s manual signature and shall have the same legal effect, validity and enforceability as manual signatures to bind the party/parties supplying electronic signatures to this contract.</p><p>We look forward to doing business with you. If you have any questions or need assistance with this portal, please contact your TSC or Petsense by Tractor Supply Merchandising Team representative.</p>' +
  '<p><br />Thank you,<br /><b>Tractor Supply Company Merchandising Team</b><br/><b>Petsense by Tractor Supply Merchandising Team</b></p>' +
  '</div>';
  public VRM = '/assets/docs/TSC_Vendor_Requirements_Manual.pdf' // Vendor Requirements Manual updated 6/8/2023
  // previous path: /assets/docs/VRM.pdf

  constructor(
    private router: Router,
    public ga: GoogleAnalyticsService,
    public users: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    public rest: RestService
  ) {
    // let user = this.users.getUser();
    this.ga.setUser(this.users.getSessionId());
  }

  form = new FormGroup({
    mySingleCheckbox: new FormControl(false, [Validators.requiredTrue])
  });

  ngOnInit(){
    this.users.getUserDetails(this.users.loggedInUser).subscribe(data => {
      if (data.json()) {
        const resp = data.json();
        console.log('testing vendor type response', resp[0].vendorType);
        this.ga.sendEvent('Vendor Consent', 'Display ' + resp[0].vendorType + ' for user ' + this.users.loggedInUser.id, Date.now().toString());
        this.openDefaultDialog();
        /* // NSC-2 generic consent page for all vendor types
        if (resp[0].vendorType === "Dropship"){
          this.openDropshipDialog();
        } else if (resp[0].vendorType === 'Import' || resp[0].vendorType === 'Domestic') {
          this.openSVDialog();
        } else if (resp[0].vendorType === 'PTSManufacturer' || resp[0].vendorType === 'PTSDistributor') {
          this.openPSDialog(resp[0].vendorType);
        } else if (resp[0].vendorType === 'Hay') {
          this.openHayDialog();
        } else if (resp[0].vendorType === 'LiveGoods') {
          this.openLiveDialog();
        } else {
          // this.openDefaultDialog();
          // for placeholder vendors, use the same as Import and Domestic
          this.openSVDialog();
        } */
      } else {
        // response format not expected, show error or
        console.log('getUserDetails response format problem:', data);
        this.ga.sendEvent('Vendor Consent', 'Display default type for user ' + this.users.loggedInUser.id, Date.now().toString());
        // this.openSVDialog();
        this.openDefaultDialog();
      }
    }, error => {
      // handle error
      console.log('Error in getUserDetails:', error);
      // this.openSVDialog();
      this.openDefaultDialog();
    });
}


  onSubmit() {
    //console.log(this.form.value);
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.trackConsent();
    // this.router.navigate(["vendor-onboarding"]);
    this.router.navigate(["vendor-onboarding"], { replaceUrl: true });
  }



  openSVDialog(){
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message: 'Welcome to Tractor Supply!<br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers as they live Life Out Here.<br/>' +
        '<br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/>' +
        '<br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">You must click the below links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/>' +
        '1.\t<b><a target="_blank" href="' + this.VRM + '">Vendor Requirements Manual</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '2.\t<b><a target="_blank" href="/assets/docs/TSC_Vendor_CodeOfConduct.pdf">Vendor Code of Conduct</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '3.\t<b><a target="_blank" href="/assets/docs/standardvendoragreement.pdf">Vendor Agreement</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '4.\t<b><a target="_blank" href="/assets/docs/vendorsetupschedule.pdf">Vendor Setup Schedule</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '5.\t<b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review Tractor Supply\'s requirements for the Transportation EDI and Store/DC EDI Programs.  Please share these requirements with your IT/EDI team.<br/>' + /* /assets/docs/EDI.pdf */
        '6.\t<b><a target="_blank" href="/assets/docs/TSCOI.pdf">Certificate of Insurance (COI)</a></b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.<br/>' +
	    	'7.\t<b><a target="_blank" href="/assets/docs/fcpa-combined.pdf">Foreign Corrupt Practices Act</a></b>: If you are a foreign company and/or TSC will be importing your goods as importer of record, you will be asked to provide additional information regarding your business practices.  Please review the linked documents; you will be asked to answer questions and certify your compliance with US and local law at a later point in DocuSign.<br/>' +
        '8.\t<b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.'+

        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"])
      }
    });

  }


  openHayDialog() {
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message: 'Welcome to Tractor Supply!<br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers as they live Life Out Here.<br/>' +
        '<br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/>' +
        '<br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">You must click the below links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/>' +
        '1.\t<b><a target="_blank" href="' + this.VRM + '">Vendor Requirements Manual</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '2.\t<b><a target="_blank" href="/assets/docs/TSC_Vendor_CodeOfConduct.pdf">Vendor Code of Conduct</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '3.\t<b><a target="_blank" href="/assets/docs/hayvendoragreement.pdf">Vendor Agreement</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '4.\t<b><a target="_blank" href="/assets/docs/vendorsetupschedule.pdf">Vendor Setup Schedule</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '5.\t<b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review Tractor Supply\'s requirements for the Transportation EDI and Store/DC EDI Programs.  Please share these requirements with your IT/EDI team.<br/>' +
        '6.\t<b><a target="_blank" href="/assets/docs/TSCOI.pdf">Certificate of Insurance (COI)</a></b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.<br/>' +
	    	'7.\t<b><a target="_blank" href="/assets/docs/fcpa-combined.pdf">Foreign Corrupt Practices Act</a></b>: If you are a foreign company and/or TSC will be importing your goods as importer of record, you will be asked to provide additional information regarding your business practices.  Please review the linked documents; you will be asked to answer questions and certify your compliance with US and local law at a later point in DocuSign.<br/>' +
        '8.\t<b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.'+

        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"])
      }
    });

  }

  openLiveDialog(){
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message: 'Welcome to Tractor Supply!<br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers as they live Life Out Here.<br/>' +
        '<br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/>' +
        '<br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">You must click the below links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/>' +
        '1.\t<b><a target="_blank" href="' + this.VRM + '">Vendor Requirements Manual</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '2.\t<b><a target="_blank" href="/assets/docs/TSC_Vendor_CodeOfConduct.pdf">Vendor Code of Conduct</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '3.\t<b><a target="_blank" href="/assets/docs/livegoodsvendoragreement.pdf">Vendor Agreement</a></b>: This document is for review only.  Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version.  You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '4.\t<b><a target="_blank" href="/assets/docs/vendorsetupschedule.pdf">Vendor Setup Schedule</a></b>: This document is for review only.  Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version.  You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '5.\t<b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review Tractor Supply\'s requirements for the Transportation EDI and Store/DC EDI Programs.  Please share these requirements with your IT/EDI team.<br/>' +
        '6.\t<b><a target="_blank" href="/assets/docs/TSCOI.pdf">Certificate of Insurance (COI)</a></b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.<br/>' +
	    	'7.\t<b><a target="_blank" href="/assets/docs/fcpa-combined.pdf">Foreign Corrupt Practices Act</a></b>: If you are a foreign company and/or TSC will be importing your goods as importer of record, you will be asked to provide additional information regarding your business practices.  Please review the linked documents; you will be asked to answer questions and certify your compliance with US and local law at a later point in DocuSign.<br/>' +
        '8.\t<b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.'+

        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"])
      }
    });

  }

  openPSDialog(type?){
    let agreementLink = '/assets/docs/ptsdistributoragreement.pdf';
    let agreementText = 'Distributor Agreement';
    if(type && type === 'PTSManufacturer'){
      agreementLink = '/assets/docs/ptsvendoragreement.pdf';
      agreementText = 'Vendor Agreement';
    }
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message: 'Welcome to Petsense!<br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers.<br/>' +
        '<br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/>' +
        '<br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">You must click the below links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/>' +
        '1.\t<b><a target="_blank" href="' + agreementLink + '">' + agreementText + '</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '2.\t<b><a target="_blank" href="/assets/docs/PTSVSS.pdf">Vendor Setup Schedule</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '3.\t<b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review Petsense\'s requirements for the Transportation EDI and Store/DC EDI programs.  Please share these requirements with your IT/EDI team.<br/>' +
        '4.\t<b><a target="_blank" href="/assets/docs/PTSCOI.pdf">Certificate of Insurance (COI)</a></b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.<br/>' +
        '5.\t<b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.'+

        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"])
      }
    });

  }

  // NSC-2 MultiVendor - generic consent page for all vendors 2/20/2024
  openDefaultDialog(){
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message: 'Welcome to Tractor Supply or Petsense by Tractor Supply!<br/><br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers.<br/><br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/><br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">Please click the links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/><ol>' +
        '<li><b><a target="_blank" href="/assets/docs/TSC_Vendor_CodeOfConduct.pdf">Vendor Code of Conduct</a></b>:  Please read this document in full prior to beginning our onboarding process.</li>' +
        '<li><b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review requirements for the Transportation EDI and Store/DC EDI Programs.  Please share these requirements with your IT/EDI team.</li>' + /* /assets/docs/EDI.pdf */
        '<li><b>Certificate of Insurance (COI)</b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.' +
        '<ol type="a"><li>Tractor Supply vendors: <b><a target="_blank" href="/assets/docs/TSCOI.pdf">click here</a></b> for your COI</li>' +
        '<li>Petsense by Tractor Supply vendors: <b><a target="_blank" href="/assets/docs/PTSCOI.pdf">click here</a></b> for your COI</li></ol></li>' +  
	    	'<li><b><a target="_blank" href="/assets/docs/fcpa-combined.pdf">Foreign Corrupt Practices Act</a></b>: If you are a foreign company and/or TSC will be importing your goods as importer of record, you will be asked to provide additional information regarding your business practices.  Please review the linked documents; you will be asked to answer questions and certify your compliance with US and local law at a later point in DocuSign.</li>' +
        '<li><b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.</li>'+
        '</ol>'+
        'Contractual Documents available later in the onboarding proccess:<br/><ol>'+
        '<li><b>Vendor Requirements Manual</a></b>:<ol type="a">' + 
        '<li>Tractor Supply vendors: A copy will be available to you for review in our Vendor Portal at a later time.  If you wish to review this sooner, you can request a copy from your Tractor Supply Merchandising Team.</li>' +
        '<li>Petsense by Tractor Supply vendors:  you will <b>not</b> need a copy.</li></ol></li>' + 
        '<li><b>Vendor Agreement:</b><ol type="a">' + 
        '<li>Tractor Supply and Petsense by Tractor Supply vendors will receive a copy through DocuSign at a later time.  If you wish to review this sooner, you can request a copy from your Tractor Supply or Petsense by Tractor Supply Merchandising Team</li></ol></li>' +
        '<li><b>Vendor Setup Schedule:</b><ol type="a">' + 
        '<li>Tractor Supply and Petsense by Tractor Supply vendors will receive a copy through DocuSign at a later time.  If you wish to review this sooner, you can request a copy from your Tractor Supply or Petsense by Tractor Supply Merchandising Team.</li></ol></li>' + 
        '</ol>' + 
        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"])
      }
    });

  }

  //needs vendor requirement manual and COI
  openDropshipDialog() {
    const dialogRef = this.dialog.open(DialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        message:  'Welcome to Tractor Supply!<br/>' +
        'We look forward to our partnership with you as we mutually seek to serve our customers as they live Life Out Here.<br/>' +
        '<br/>' +
        'As part of our new vendor onboarding process, we wanted to make you aware of our vendor requirements and other items relevant to our relationship with our vendor community.<br/>' +
        '<br/>' +
        '<span style="text-decoration:underline;font-weight:bold;">You must click the below links below in blue and review each document before proceeding:</span> <br/>' +
        '<br/>' +
        '1.\t<b><a target="_blank" href="' + this.VRM + '">Vendor Requirements Manual</a></b>:  Please read this document in full prior to beginning our onboarding process. Note that the manual covers the business relationship for both Omni-Channel and store vendors; For Omni-Channel vendors please make sure you are aware of sections 9-"Pre-Shipment/Packaging Requirements" & 13-"Omni-Channel".<br/>' +
        '2.\t<b><a target="_blank" href="/assets/docs/TSC_Vendor_CodeOfConduct.pdf">Vendor Code of Conduct</a></b>:  Please read this document in full prior to beginning our onboarding process.<br/>' +
        '3.\t<b><a target="_blank" href="/assets/docs/dropshipvendoragreement.pdf">Vendor Agreement</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '4.\t<b><a target="_blank" href="/assets/docs/dropshipvss.pdf">Vendor Setup Schedule</a></b>: This document is for review only. Do <span style="text-decoration:underline;font-weight:bold;">not</span> edit or change this version. You will have the ability to make edits at a later time in DocuSign.<br/>' +
        '5.\t<b><a target="_blank" href="https://www.tractorsupply.com/policies-information_vendor-information_edi-guidelines.html">EDI Requirements</a></b>: Please review Tractor Supply\'s requirements for the Transportation EDI, Direct to Consumer EDI, and Direct to Consumer Label Guidelines.  Please share these requirements with your IT/EDI team.<br/>' +
        '6.\t<b><a target="_blank" href="/assets/docs/TSCOI.pdf">Certificate of Insurance (COI)</a></b>: <span style="color:#ce1128;font-weight:bold;">Important**</span> You are required to submit a COI in DocuSign at time of signature with language and coverage amounts listed in the sample document. Failure to provide a COI that meets these specific requirements can result in onboarding delays. Please share with your Risk Management team or insurance provider to ensure you carry the appropriate coverage.<br/>' +
        '7.\t<b><a target="_blank" href="/assets/docs/termsofuse.pdf">Vendor Portal Terms and Conditions</a></b>: Please read the entire Vendor Portal Terms and Conditions.'+     
        
        this.notesHTML,
        buttonText: {
          ok: 'Accept'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.trackConsent();
        this.router.navigate(["vendor-onboarding"]);
      }
    });
  }


  trackConsent() {
    const api = environment.merchapi + 'consentagreement';
    const headers = new HttpHeaders({'Content-Type': 'application/json;charset=utf-8', 'accept': 'application/json'});
    const json = {'SC_Email': this.users.loggedInUser.userName, 'ConsentAgreed': 'Y'};
    const startTime = Date.now();

    // set flag for consent page
    this.ga.setConsent(true)

    this.http.post(api, json, {headers:headers}).subscribe(
      resp => {
        console.log('sent consent', resp);
        const responseTime = Date.now() - startTime;
        this.ga.sendEvent('Vendor Consent', 'User ' + this.users.loggedInUser.id + ' agrees to consent terms' , Date.now().toString(), {'load_time':responseTime});
        // vendorType included in response, can be assigned globally if needed elsewhere
        // if (!this.users.loggedInUser.vendorType || this.users.loggedInUser.vendorType === ''){
          this.users.loggedInUser.vendorType = resp['VendorType']; // if user arrived at this page, need the vendor type for the current onboarding form
        // }
      }, error => {
        console.log('error sending consent request', error);
        const responseTime = Date.now() - startTime;
        this.ga.sendEvent('Vendor Consent', this.users.loggedInUser.id + ' request error: ' + error, Date.now().toString(), {'load_time':responseTime});
      }
    );
  }

}

/*
        '<br/><br/>' +
        'Do the following:<br/>' + 
'<br/>' +
        '1.\t<b>Click Accept</b> to begin our vendor onboarding process. You will be asked to provide information that will be used to populate our Vendor Agreement, Vendor Setup Schedule, and other relevant documents in DocuSign.  <br/>' +
        '2.\tObtain a COI that meets the requirements in step 5 above.<br/>' +
        '<br/>' +
      	'Once you have submitted your information, the TSC buying team will receive a notification and will review your documents.  Upon their approval, you will then be able to review the pre-populated documents to ensure correctness.<br/>' +
        'By clicking Accept below, you acknowledge you have reviewed the documents requested and are ready to begin the negotiation process.<br/>' +
        'The parties to this contract agree that electronic signatures, whether digital or encrypted, are the legal equivalents of an individual’s manual signature and shall have the same legal effect, validity and enforceability as manual signatures to bind the party/parties supplying electronic signatures to this contract.' +
        '<br/>' +
        'We look forward to doing business with you.  If you have any questions or need assistance with this portal, please contact your TSC Merchandising Team representative.<br/>' +
        '<br/>' +
        'Thank you,<br/>'+
        'Tractor Supply Company Merchandising Team<br/>'+

*/
