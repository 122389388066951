/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documents.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../modal/modal.component.ngfactory";
import * as i4 from "../modal/modal.component";
import * as i5 from "./documents.component";
import * as i6 from "../services/document.service";
import * as i7 from "@angular/router";
var styles_DocumentsComponent = [i0.styles];
var RenderType_DocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentsComponent, data: {} });
export { RenderType_DocumentsComponent as RenderType_DocumentsComponent };
function View_DocumentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "cat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDocs(_v.context.$implicit.category) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "thumbnail"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(../assets/" + _v.context.$implicit.thumbnail) + ")")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.category; _ck(_v, 5, 0, currVal_1); }); }
function View_DocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "document-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Document Downloads"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docs.documentCategories; _ck(_v, 5, 0, currVal_0); }, null); }
function View_DocumentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading documents..."]))], null, null); }
function View_DocumentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "folder folder-empty"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.category; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+ click to open folder"]))], null, null); }
function View_DocumentsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- click to close folder"]))], null, null); }
function View_DocumentsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "h3", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSub(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "folder": 0, "folder-open": 1 }), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "hover-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.showSub(_v.parent.context.index)); _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.showSub(_v.parent.context.index); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showSub(_v.parent.context.index); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.category; _ck(_v, 3, 0, currVal_1); }); }
function View_DocumentsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-sm-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "doc-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDoc(_v.parent.context.$implicit, i1.ɵnov(_v.parent.parent.parent.parent.parent, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "download-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDoc(_v.parent.context.$implicit, i1.ɵnov(_v.parent.parent.parent.parent.parent, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), (_v.parent.context.$implicit.modifyDate || _v.parent.context.$implicit.createDate), "MMMM d, y")); _ck(_v, 7, 0, currVal_1); }); }
function View_DocumentsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_12)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSub(_v.context.$implicit, _v.parent.parent.context.$implicit.category); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DocumentsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-sm-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last Update"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_11)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docsList; _ck(_v, 10, 0, currVal_0); }, null); }
function View_DocumentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subEmpty(_v.context.$implicit.category); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.subEmpty(_v.context.$implicit.category); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showSub(_v.context.index); _ck(_v, 6, 0, currVal_2); }, null); }
function View_DocumentsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "doc-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDoc(_v.parent.context.$implicit, i1.ɵnov(_v.parent.parent.parent, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "download-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDoc(_v.parent.context.$implicit, i1.ɵnov(_v.parent.parent.parent, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), (_v.parent.context.$implicit.modifyDate || _v.parent.context.$implicit.createDate), "MMMM d, y")); _ck(_v, 5, 0, currVal_1); }); }
function View_DocumentsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noSub(_v.context.$implicit, _co.docs.selectedCategory); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DocumentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-6 col-md-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Documents: ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-sm-6 col-md-3 document-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDocs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AB back to all Document Downloads"])), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "documents-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_5)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "row header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "col-sm-7"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last Update"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_13)), i1.ɵdid(20, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "upload-documents"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.docsList || (_co.docsList.length < 1)); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.subFolders; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.docsList; _ck(_v, 20, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docs.selectedCategory; _ck(_v, 3, 0, currVal_0); }); }
export function View_DocumentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 9, "app-modal", [], null, null, null, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i1.ɵdid(7, 114688, [["modalMessage", 4]], 0, i4.ModalComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "div", [["class", "app-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, 1, 1, "div", [["class", "app-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, 2, 3, "div", [["class", "app-modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMenu; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.showMenu; _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.modalContent.header; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.modalContent.body; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.modalContent.footer; _ck(_v, 13, 0, currVal_4); }); }
export function View_DocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-documents", [], null, null, null, View_DocumentsComponent_0, RenderType_DocumentsComponent)), i1.ɵdid(1, 114688, null, 0, i5.DocumentsComponent, [i6.DocumentService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentsComponentNgFactory = i1.ɵccf("app-documents", i5.DocumentsComponent, View_DocumentsComponent_Host_0, {}, {}, []);
export { DocumentsComponentNgFactory as DocumentsComponentNgFactory };
