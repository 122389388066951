/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./event-modal.component";
var styles_EventModalComponent = [i0.styles];
var RenderType_EventModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventModalComponent, data: {} });
export { RenderType_EventModalComponent as RenderType_EventModalComponent };
export function View_EventModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal fade"], ["tabindex", "-1"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "in": 0 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "display": 0, "opacity": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "modal-content event-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "event-header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal fade"; var currVal_1 = _ck(_v, 2, 0, _co.visibleAnimate); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, (_co.visible ? "block" : "none"), (_co.visibleAnimate ? 1 : 0)); _ck(_v, 3, 0, currVal_2); }, null); }
export function View_EventModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-modal", [], null, null, null, View_EventModalComponent_0, RenderType_EventModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.EventModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventModalComponentNgFactory = i1.ɵccf("app-event-modal", i3.EventModalComponent, View_EventModalComponent_Host_0, {}, {}, [".app-modal-header", ".app-modal-body", ".app-modal-footer"]);
export { EventModalComponentNgFactory as EventModalComponentNgFactory };
