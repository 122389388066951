import { ComponentFactoryResolver, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User, UserService } from './../services/user.service';
// import { DashboardService } from './../vendor-dashboard/services/dashboard.service';
import { VendorApiService } from './../vendor-services/vendor-api.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VacService } from '../services/vac.service';
import { GoogleAnalyticsService } from '../services/analytics.service';
import { VolumeRebateVoidRequest } from './model/volume-rebate-info.model';
import { map, startWith } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { VolumeRebateSearchComponent } from './volume-rebate-search/volume-rebate-search.component';
import { RestService } from '../services/rest.service';
var VolumeRebateComponent = /** @class */ (function () {
    function VolumeRebateComponent(user, api, fb, router, vac, ga, 
    // private dash: DashboardService,
    restApi, currencyPipe, matDialog, componentFactoryResolver) {
        this.user = user;
        this.api = api;
        this.fb = fb;
        this.router = router;
        this.vac = vac;
        this.ga = ga;
        this.restApi = restApi;
        this.currencyPipe = currencyPipe;
        this.matDialog = matDialog;
        this.componentFactoryResolver = componentFactoryResolver;
        this.vendorNumberData = [];
        this.currentUser = new User();
        this.rebateVendorInfo = {
            VendorData: null,
            VolumeRebate: null,
        };
        this.selectedVendor = {
            'VendorID': '',
            'vendorName': '',
            'vendorType': '',
            'childVendors': '',
            'contactFirstName': '',
            'contactLastName': '',
            'contactEmail': '',
            'contactPhone': '',
            'contactFax': '',
            'buyerID': '',
            'buyerName': ''
        };
        this.today = new Date(Date.now()).toISOString().split('T')[0];
        this.isLoading = false;
        this.rebateerr = [];
        this.initialRebateLevel = '0';
        this.modalContent = {
            'header': '',
            'body': '',
            'footer': ''
        };
        this.isSubmitting = false;
        // new changes 
        this.selectedVendorID = '';
        this.vendorIsValid = true;
        this.vendorMsg = '';
        //public allowedVendorTypes = ['Dropship','Petsense','Domestic','PTSDistributor','PTSManufacturer','Import'];
        this.allowedVendorTypes = ['Domestic', 'Import'];
        this.sourcingType = ['All', 'Domestic', 'Import'];
        this.buyerCompany = 'TSC';
        //public sourcingType: 
        this.showRPLevel2 = false;
        this.showRPLevel3 = false;
        this.showRPLevel4 = false;
        this.removeBtnInactive = true;
        this.addBtnInactive = true;
        this.effectiveYearDisable = true;
        this.years = [];
        this.vacBuyers = [];
        this.selectedBuyerID = '';
        this.existingVolumeRebate = [];
        this.searchMsg = 'This vendor already has volume rebates in effect for the selected criteria.<br />Please select a volume rebate below to void before creating a new one.';
        this.searchClass = 'alert-danger';
        this.showSearchMsg = false;
        this.isSearching = false;
        this.searchResults = [];
        this.resultsMsg = '';
        this.voidReason = '';
        this.otherReason = '';
        this.showOtherReason = false;
        this.showVoidWarning = false;
        this.isVendorIdDisabled = true;
        this.showSubmitErrorMsgforInProgressVR = false;
        this.voidVendorID = '';
        this.searchType = 'Vendor';
        this.isClosing = false;
    }
    VolumeRebateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser = this.user.loggedInUser || this.user.getUser();
        console.log('logged in user', this.currentUser);
        console.log("VAC BUYER=>", this.vac.vacBuyer);
        if (this.user.getUserRole() === 'TSC_Buyer') {
            this.buyerCompany = 'TSC';
        }
        else if (this.user.getUserRole() === 'PTS_Buyer') {
            this.buyerCompany = 'PETSENSE';
        }
        this.createForm(); // initialize form
        /*
        this.vacBuyers = this.vac.vacBuyer;
        if(this.vac.vacBuyer.length >0){
          this.vacBuyers = this.vac.vacBuyer
        }else {
          this.vac.initializeVAC();
          this.vacBuyers = this.vac.vacBuyer;
          console.log("New vac buyers:", this.vacBuyers);
        }
        */
        this.vac.getBuyerInfo().subscribe(function (buyersList) {
            console.log("Buyers list:", buyersList);
            if (buyersList.length > 0) {
                var _loop_1 = function (buyer) {
                    var dup = _this.vacBuyers.find(function (b) { return b.Email === buyer.Email && b.BuyerID === buyer.BuyerID; });
                    if (!dup) {
                        if ((_this.user.getUserRole() === 'PTS_Buyer' && buyer.VendorType.indexOf('PTS') > -1) ||
                            (_this.user.getUserRole() !== 'PTS_Buyer' && buyer.VendorType.indexOf('PTS') === -1)) {
                            _this.vacBuyers.push(buyer);
                        }
                    }
                };
                for (var _i = 0, buyersList_1 = buyersList; _i < buyersList_1.length; _i++) {
                    var buyer = buyersList_1[_i];
                    _loop_1(buyer);
                }
            }
        });
        //  this.vac.getBuyerInfo().subscribe( (buyerData) =>{
        //   console.log('username:', this.user.loggedInUser);
        //     console.log('buyerlist', buyerData);
        //     this.vacBuyers = buyerData;
        //  })
        // we'll probably use the sc contact instead... if we have that info
        // this.setAddVendorTypeForUser(); // quick request to populate dropdown
        // getonboarding data with children
        // spinner for select vendor
        // temporary comment
        /*
        this.isLoading = true;
    
         const parentReq = {
          'Email': this.currentUser.userName,
          'VendorInfo': 'Y',
         };
         let childReq = {
            'VendorID': '',
            'VendorInfo': 'Y',
            'ChildVendor': 'Y'
         };
    
          this.api.requestData(parentReq).subscribe(
          (data: any) => {
            // console.log('returned vendor data', data);
            this.allVendors = data.Data;
              // now get child info for each parent
              for (let vendor of this.allVendors) {
                vendor.ContactEmail = parentReq.Email;
                vendor.CompanyName = this.getFieldByKey(vendor, 'COMPANY_NAME');
                vendor.VendorType = this.getFieldByKey(vendor, 'VENDOR_TYPE');
                vendor.BuyerID = this.getFieldByKey(vendor, 'BUYERID');
                vendor.BuyerName = this.getFieldByKey(vendor, 'BUYER_FIRST_NAME') + ' ' + this.getFieldByKey(vendor, 'BUYER_LAST_NAME');
                childReq.VendorID = vendor.VendorID;
                console.log('updated vendor record', vendor);
                this.getChildren(childReq);
              }
    
          }, error => {
            console.log(error);
            this.isLoading = false;
          }
        );
        */
        // meeting notes
        // set flag for child vendor? allow child, get from child call same as dashboard
        // on submit, modal for success, get buyer alignment message from business
        // getonboardingdata api, child vendors Y
        // buyer info in Corporate Info tab
        // DMM handled in BW service
        // prepopulate all fields from onboarding data
        // send flag if percentage is reduced only, compare to original. RebatePercentageReduced Y or N
        // is recurrring, Y or show year field on N
        // estimated volume rebate is read only, add estimated receipts * percentage
        // 
        this.formValueChanges();
        this.setValidYears();
    };
    VolumeRebateComponent.prototype.formValueChanges = function () {
        /*
        this.volRebateForm.valueChanges.subscribe( val =>{
          console.log("FORM value:", val);
          //if(val.VALID_YEAR)
        })
        */
        // this.volRebateForm.get('VALID_YEAR').valueChanges.subscribe( val =>{
        //   if(val.length== 4){
        //     const currentYear = new Date().getFullYear();
        //   if(val<=currentYear){
        //     alert("Value should be greate then current year..");
        //   }
        //   }
        // });
    };
    VolumeRebateComponent.prototype.setValidYears = function () {
        var _this = this;
        this.years = [];
        var year = (new Date().getFullYear());
        for (var i = 1; i <= 5; i++) {
            this.years.push(year + i);
        }
        this.filteredYears = this.volRebateForm.get('VALID_YEAR').valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    VolumeRebateComponent.prototype._filter = function (value) {
        return this.years.filter(function (year) {
            // console.log(year.toString().includes(value.toString()), year);
            // return year===value
            return year.toString().includes(value.toString());
        });
    };
    // new changes starts 
    VolumeRebateComponent.prototype.checkVendor = function (id) {
        var _this = this;
        console.log('check vendor ' + id, this.selectedBuyerID);
        if (!this.selectedBuyerID || this.selectedBuyerID === '') {
            this.buyerSelectionInput.nativeElement.focus();
        }
        else if (id.length !== 6) {
            this.vendorIsValid = false;
            this.vendorMsg = 'Please enter a valid vendor ID';
            this.rebateVendorInfo.VendorData = {};
            this.rebateVendorInfo.VolumeRebate = {};
            this.selectedVendorID = '';
        }
        else {
            // check vendor info
            this.selectedVendorID = id;
            this.vac.retrieveVendorInformation(id).subscribe(function (vendor) {
                console.log("vendor info response:", vendor);
                // Look for any existing Volume Rebate 
                if (vendor && vendor.VolumeRebate) {
                    if (vendor.VolumeRebate.VRData && vendor.VolumeRebate.VRData.length > 0) {
                        _this.rebateVendorInfo.VolumeRebate = vendor.VolumeRebate;
                        _this.existingVolumeRebate = _this.rebateVendorInfo.VolumeRebate.VRData;
                        // this.openVolumeRebatePopup();
                    }
                    else {
                        _this.rebateVendorInfo.VolumeRebate = {};
                        _this.existingVolumeRebate = [];
                    }
                }
                if (vendor && vendor.VendorData && Object.keys(vendor.VendorData).length > 0) {
                    // let v = vendor.VendorData[0];
                    var v = vendor.VendorData;
                    console.log("V=>", v);
                    if (v && v.VENDOR_ID === id && _this.isAllowedVendor(v)) {
                        _this.vendorIsValid = true;
                        _this.vendorMsg = v.CompanyName; //vendor name
                        //this.selectedVendor = v;
                        _this.changeSearchType('Vendor');
                        _this.rebateVendorInfo.VendorData = vendor.VendorData;
                        // this.rebateVendorInfo.VendorData.VENDOR_CONTACT = this.rebateVendorInfo.VendorData.SC_EMAIL;
                        Object.keys(_this.volRebateForm.controls).forEach(function (key) {
                            // let val = this.getFieldByKey(vendorTabs,key);
                            // console.log('assign to form', key);
                            if (key !== 'VOLUME_REBATE_PERCENT') {
                                var val = _this.getFormValueByKey(_this.rebateVendorInfo.VendorData, key);
                                if (val && val !== '') {
                                    // console.log("Updating form value for ",key , val);
                                    _this.volRebateForm.controls[key].patchValue(val);
                                }
                            }
                        });
                        /*
                         if(this.volRebateForm.get('VOLUME_REBATE_PERCENT') && this.volRebateForm.get('VOLUME_REBATE_PERCENT').value !=='') {
                           this.initialRebateLevel = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
                         }
                         */
                        // handle agreement term
                        // if (this.volRebateForm.get('IS_RECURRING') && this.volRebateForm.get('IS_RECURRING').value === 'Y') {
                        //   this.volRebateForm.controls['agreementTerm'].patchValue('Recurring');
                        // }
                        _this.ga.sendEvent('Volume Rebate', 'Vendor Data loaded successfully', 'Vendor ' + id + ' for Buyer ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': id });
                        // reset VR search
                        _this.showSubmitErrorMsgforInProgressVR = false;
                        _this.showSearchMsg = false;
                        console.log(" Vendor information:", _this.rebateVendorInfo);
                        // this.showPromoTypes(this.allPromos);
                    }
                    else {
                        _this.vendorIsValid = false;
                        _this.vendorMsg = 'Please enter a valid vendor ID';
                        _this.vendorIsValid = true;
                        // this.selectedVendor = new VendorInfo();
                        _this.rebateVendorInfo.VendorData = {};
                        _this.rebateVendorInfo.VolumeRebate = {};
                        _this.ga.sendEvent('Volume Rebate', 'Invalid Vendor ID', 'Vendor ' + id + ' for Buyer ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': id });
                        // this.volRebateForm.reset();
                    }
                }
                else {
                    _this.vendorIsValid = false;
                    _this.vendorMsg = vendor.APIResponse == 'Record not found' ? 'Please enter a valid vendor ID' : vendor.APIResponse;
                    // this.selectedVendor = new VendorInfo();
                    _this.rebateVendorInfo.VendorData = {};
                    _this.rebateVendorInfo.VolumeRebate = {};
                    _this.ga.sendEvent('Volume Rebate', 'Vendor Data not found', 'Vendor ' + id + ' for Buyer ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'record_id': id });
                    // this.volRebateForm.reset();
                }
            }, function (error) {
                console.log(error);
                _this.vendorIsValid = false;
                _this.vendorMsg = 'Please enter a valid vendor ID';
                _this.ga.sendEvent('Volume Rebate', 'Error requesting Vendor data', 'Vendor ' + id + ' for Buyer ' + _this.selectedBuyerID, { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': id });
                //this.volRebateForm.reset();
                //this.selectedVendor = new VendorInfo();
            });
        }
    };
    VolumeRebateComponent.prototype.onRebateLevelChangeAction = function (value) {
        // console.log("ADDORREMOVE", value);
        if (value === 'add') {
            // show level2 or level 3
            if (!this.showRPLevel2) {
                this.showRPLevel2 = true;
                var level1To = this.volRebateForm.get('MLP_TIER_1_TO').value.replace(/[^0-9\.]+/g, "");
                console.log("level1To=", level1To);
                var formattedLevel2From = (Number(level1To) + 1).toLocaleString(undefined, { maximumFractionDigits: 0 });
                // this.volRebateForm.get('MLP_TIER_2_FROM').patchValue(Number(level1To)+1);
                this.volRebateForm.get('MLP_TIER_2_FROM').patchValue(formattedLevel2From);
            }
            else if (!this.showRPLevel3) {
                this.showRPLevel3 = true;
                var level2To = this.volRebateForm.get('MLP_TIER_2_TO').value.replace(/[^0-9\.]+/g, "");
                console.log("level2To=", level2To);
                var formattedLevel3From = (Number(level2To) + 1).toLocaleString(undefined, { maximumFractionDigits: 0 });
                this.volRebateForm.get('MLP_TIER_3_FROM').patchValue(formattedLevel3From);
            }
            else {
                this.showRPLevel4 = true;
                var level3To = this.volRebateForm.get('MLP_TIER_3_TO').value.replace(/[^0-9\.]+/g, "");
                console.log("level3To=", level3To);
                var formattedLevel4From = (Number(level3To) + 1).toLocaleString(undefined, { maximumFractionDigits: 0 });
                this.volRebateForm.get('MLP_TIER_4_FROM').patchValue(formattedLevel4From);
            }
            this.addBtnInactive = true;
        }
        if (value === 'remove') {
            // hide either level 2 or level 3 or level 4
            if (this.showRPLevel4 === true) {
                this.rebateerr[4] = "";
                this.volRebateForm.controls['MLP_TIER_4_FROM'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_4_TO'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_4_PERCENTAGE'].patchValue('');
                this.showRPLevel4 = false;
            }
            else if (this.showRPLevel3 === true) {
                this.rebateerr[3] = "";
                this.volRebateForm.controls['MLP_TIER_3_FROM'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_3_TO'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_3_PERCENTAGE'].patchValue('');
                this.showRPLevel3 = false;
            }
            else if (this.showRPLevel2 === true) {
                this.rebateerr[2] = "";
                this.volRebateForm.controls['MLP_TIER_2_FROM'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_2_TO'].patchValue('');
                this.volRebateForm.controls['MLP_TIER_2_PERCENTAGE'].patchValue('');
                this.showRPLevel2 = false;
            }
            var errorDoesNotExist = this.rebateerr.every(function (value) { return value === '' || value === null; });
            console.log("Error not exist during remove button:", errorDoesNotExist, this.rebateerr, +this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value, Number(this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value).toFixed(2));
            this.addBtnInactive = errorDoesNotExist ? false : true;
        }
        /*
        if(this.showRPLevel2 && this.showRPLevel3){
          this.removeBtnInactive = false;
        }else if(this.showRPLevel2 || this.showRPLevel3) {
          this.removeBtnInactive = false;
        }else {
          this.removeBtnInactive = true;
        }
        */
        this.removeBtnInactive = !(this.showRPLevel2 || this.showRPLevel3 || this.showRPLevel4);
        // this.addBtnInactive = this.showRPLevel4? true:false;
    };
    VolumeRebateComponent.prototype.rebatePercentageChange = function (level, $event) {
        switch (level) {
            case 1:
                if (this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value) {
                    var formatedValue = Number(this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value).toFixed(2);
                    this.volRebateForm.controls['MLP_TIER_1_PERCENTAGE'].patchValue(formatedValue);
                }
                if (+this.volRebateForm.get('MLP_TIER_1_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value > 0) {
                    if (+this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value < +this.volRebateForm.get('VOLUME_REBATE_PERCENT').value) {
                        this.rebateerr[level] = 'Level ' + level + " rebate % Can't be lower than main volume rebate %";
                        this.volRebateForm.controls['MLP_TIER_' + level + "_PERCENTAGE"].setErrors({ 'invalid': true });
                    }
                    else {
                        if (this.rebateerr[level].indexOf('lower than main volume') > -1) {
                            this.rebateerr[level] = '';
                        }
                    }
                    var errorDoesNotExist = this.rebateerr.every(function (value) { return value === '' || value === null; });
                    this.addBtnInactive = errorDoesNotExist ? false : true;
                }
                break;
            case 2:
                if (this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value) {
                    var formatedValue = Number(this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value).toFixed(2);
                    this.volRebateForm.controls['MLP_TIER_2_PERCENTAGE'].patchValue(formatedValue);
                }
                if (+this.volRebateForm.get('MLP_TIER_2_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value > 0) {
                    if (+this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value) {
                        this.rebateerr[level] = 'Level ' + level + " rebate % Can't be lower than the level 1 rebate %";
                        this.volRebateForm.controls['MLP_TIER_' + level + "_PERCENTAGE"].setErrors({ 'invalid': true });
                    }
                    else {
                        if (this.rebateerr[level].indexOf('lower than the level 1') > -1) {
                            this.rebateerr[level] = '';
                        }
                    }
                    var errorDoesNotExist = this.rebateerr.every(function (value) { return value === '' || value === null; });
                    this.addBtnInactive = errorDoesNotExist ? false : true;
                }
                break;
            case 3:
                if (this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value) {
                    var formatedValue = Number(this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value).toFixed(2);
                    this.volRebateForm.controls['MLP_TIER_3_PERCENTAGE'].patchValue(formatedValue);
                }
                if (+this.volRebateForm.get('MLP_TIER_3_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value > 0) {
                    if (+this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value) {
                        this.rebateerr[level] = 'Level ' + level + " rebate % Can't be lower than level 2 rebate %";
                        this.volRebateForm.controls['MLP_TIER_' + level + "_PERCENTAGE"].setErrors({ 'invalid': true });
                    }
                    else {
                        if (this.rebateerr[level].indexOf('lower than level 2') > -1) {
                            this.rebateerr[level] = '';
                        }
                    }
                    var errorDoesNotExist = this.rebateerr.every(function (value) { return value === '' || value === null; });
                    this.addBtnInactive = errorDoesNotExist ? false : true;
                }
                break;
            case 4:
                if (this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value) {
                    var formatedValue = Number(this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value).toFixed(2);
                    this.volRebateForm.controls['MLP_TIER_4_PERCENTAGE'].patchValue(formatedValue);
                }
                if (+this.volRebateForm.get('MLP_TIER_4_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value > 0) {
                    if (+this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value) {
                        this.rebateerr[level] = 'Level ' + level + "  rebate % Can't be lower than level 3 rebate %";
                        this.volRebateForm.controls['MLP_TIER_' + level + "_PERCENTAGE"].setErrors({ 'invalid': true });
                    }
                    else {
                        if (this.rebateerr[level].indexOf('lower than level 3') > -1) {
                            this.rebateerr[level] = '';
                        }
                    }
                    var errorDoesNotExist = this.rebateerr.every(function (value) { return value === '' || value === null; });
                    this.addBtnInactive = errorDoesNotExist ? false : true;
                }
                break;
            default:
                this.addBtnInactive = true;
        }
        // each row, previous to must not be blank if rebate is present
        if (+level > 1) {
            console.log(level, this.volRebateForm.controls['MLP_TIER_' + (+level - 1) + '_TO'], this.volRebateForm.controls['MLP_TIER_' + (+level) + '_PERCENTAGE']);
            if (this.volRebateForm.controls['MLP_TIER_' + (+level - 1) + '_TO'].value == '' &&
                this.volRebateForm.controls['MLP_TIER_' + (+level) + '_PERCENTAGE'].value != '') {
                this.rebateerr[level] = 'Level ' + (+level - 1) + " to can't be empty, add a value or remove row " + level;
                this.volRebateForm.controls['MLP_TIER_' + (+level - 1) + '_TO'].setErrors({ 'invalid': true });
            }
        }
    };
    VolumeRebateComponent.prototype.isAllowedVendor = function (vendor) {
        if (vendor.IS_VENDOR_ACTIVE !== 'N' &&
            this.allowedVendorTypes.indexOf(vendor.VENDOR_TYPE) > -1) {
            return true;
        }
        else {
            return false;
        }
    };
    VolumeRebateComponent.prototype.createForm = function () {
        // build form to match contract
        var emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$";
        this.volRebateForm = this.fb.group({
            // SC_EMAIL: [this.currentUser.userName,[ Validators.email ]],
            // SC_FIRST_NAME: this.currentUser.firstName,
            // SC_LAST_NAME: this.currentUser.lastName,
            SC_EMAIL: ['', [Validators.email]],
            SC_FIRST_NAME: '',
            SC_LAST_NAME: '',
            BUYERID: ['', [Validators.required]],
            VENDOR_ID: '',
            PARENTID: '',
            VENDOR_TYPE: '',
            SOURCING_TYPE: ['All'],
            COMPANY_NAME: '',
            //VENDOR_CONTACT:['',[ Validators.email ]],
            agreementTerm: 'Recurring',
            IS_RECURRING: '',
            VALID_YEAR: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            VOLUME_REBATE_PERCENT: this.setDefaultPercent(2),
            VOLUME_REBATE_THRESHOLD: ['', [Validators.required]],
            // SPECIAL_INSTRUCTIONS: '',
            MLP_TIER_1_FROM: '1',
            MLP_TIER_2_FROM: '',
            MLP_TIER_3_FROM: '',
            MLP_TIER_4_FROM: '',
            MLP_TIER_1_TO: ['', [Validators.required]],
            MLP_TIER_2_TO: '',
            MLP_TIER_3_TO: '',
            MLP_TIER_4_TO: '',
            MLP_TIER_1_PERCENTAGE: ['', [Validators.required]],
            MLP_TIER_2_PERCENTAGE: '',
            MLP_TIER_3_PERCENTAGE: '',
            MLP_TIER_4_PERCENTAGE: '',
        });
        this.setAgreementTerm();
        this.volRebateForm.controls['VENDOR_ID'].disable();
        this.isVendorIdDisabled = true;
        /*
        this.volRebateForm = this.fb.group({
         SC_EMAIL: this.currentUser.userName,
         firstname: this.currentUser.firstName,
         lastname: this.currentUser.lastName,
         VENDOR_ID: '',
         VENDOR_TYPE: '',
         COMPANY_NAME: '',
         SC_PHONE: '',
         faxnumber: '',
         BUYER_ID: '',
         EFFECTIVEDATE: '',
         agreementTerm: '',
         IS_RECURRING: '',
         VALID_YEAR: '',
         PROJECTED_RECEIPTS: '',
         VOL_REBATE_ACCURALPERC: '',
         ESTIMATED_VOLUMEREBATE: '',
         VOLUME_REBATE_PERCENT: '',
         EFFECTIVE_RECEIPT_LEVEL: '',
         REBATE_PERCENT_1: '',
         REBATE_PERCENT_2: '',
         REBATE_PERCENT_3: '',
         MULTI_LEVEL_PAYOUT1: '',
         MULTI_LEVEL_PAYOUT2: '',
         MULTI_LEVEL_PAYOUT3: '',
         from1:'',
         from2:'',
         from3:'',
         to1:'',
         to2:'',
         to3:'',
         REBATE_PERCENT_REDUCED:'N',
   
   
   
       });
       */
    };
    VolumeRebateComponent.prototype.setDefaultPercent = function (value) {
        return value.toFixed(2);
    };
    VolumeRebateComponent.prototype.openVolumeRebatePopup = function () {
        var dialogRef = this.matDialog.open(VolumeRebateSearchComponent, {
            data: { vendorId: this.selectedVendorID, existingVRData: this.existingVolumeRebate }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("Dialog closed", result);
        });
    };
    VolumeRebateComponent.prototype.formatCurrencyValue = function (event) {
        // const input = event.target.value.replace('/[\.|$]/g','');
        var input = event.target.value.replace(/[^a-zA-Z 0-9]+/g, '');
        console.log("Event value:", input);
        //const returnvalue = this.currencyPipe.transform(input,'USD','$','1:0');
        var returnvalue = this.currencyPipe.transform(input, 'USD', '$', '1.0');
        console.log("Formatted value:", returnvalue);
        this.volRebateForm.get('VOLUME_REBATE_THRESHOLD').patchValue(returnvalue);
        this.formatRange(1); // to validate Level1 value must be > than Receipt level
    };
    VolumeRebateComponent.prototype.setSelectedBuyer = function (event) {
        var _this = this;
        if (event.target.value != '') {
            var buyerInfo = event.target.value;
            // split the value
            this.selectedBuyerID = buyerInfo.split('|')[0];
            var fname_1 = buyerInfo.split('|')[1].split(':')[0];
            var lname_1 = buyerInfo.split('|')[1].split(':')[1];
            var buyer = this.vacBuyers.find(function (b) { return b.BuyerID == _this.selectedBuyerID && b.FirstName == fname_1 && b.LastName == lname_1; });
            if (buyer) {
                this.selectedBuyer = buyer;
            }
            console.log("Selected Buyer id:", event.target.value, this.selectedBuyerID, this.selectedBuyer);
            // this.selectedBuyerID = event.target.value;
            this.isVendorIdDisabled = false;
            this.volRebateForm.controls['VENDOR_ID'].enable();
        }
    };
    VolumeRebateComponent.prototype.selectVendor = function (vendorNumber) {
        var _this = this;
        this.createForm(); // initialize form
        Object.keys(this.selectedVendor).forEach(function (v) { return _this.selectedVendor[v] = ''; }); // initialize selected vendor
        this.searchResults = [];
        var foundVendor = this.allVendors.find(function (v) { return v.VendorID === vendorNumber; });
        if (foundVendor) {
            this.currentUser = this.user.loggedInUser || this.user.getUser();
            // console.log('selected vendor', foundVendor, this.currentUser);
            // set selectedVendor
            this.selectedVendor.VendorID = vendorNumber;
            this.selectedVendor.vendorName = foundVendor.CompanyName;
            this.selectedVendor.vendorType = foundVendor.VendorType;
            this.selectedVendor.childVendors = this.findChildren(vendorNumber) ? this.findChildren(vendorNumber).join(', ') : '';
            this.selectedVendor.contactEmail = foundVendor.ContactEmail;
            this.selectedVendor.contactFirstName = this.currentUser.firstName; // this.getFieldByKey(foundVendor,'SC_FIRST_NAME'); 
            this.selectedVendor.contactLastName = this.currentUser.lastName; //this.getFieldByKey(foundVendor,'SC_LAST_NAME'); 
            this.selectedVendor.buyerID = foundVendor.BuyerID;
            this.selectedVendor.buyerName = foundVendor.BuyerName;
            this.selectedVendor.contactEmail = foundVendor.ContactEmail;
            //console.log(this.selectedVendor.contactEmail, this.currentUser.userName);
            console.log('From Vendor API', this.allVendors, this.selectedVendor);
            var vendorTabs_1 = this.allVendors.find(function (v) { return v.VendorID === vendorNumber; });
            // set form fields
            Object.keys(this.volRebateForm.controls).forEach(function (key) {
                var val = _this.getFieldByKey(vendorTabs_1, key);
                // console.log('assign to form', key, val);
                if (val && val !== '') {
                    _this.volRebateForm.controls[key].patchValue(val);
                }
            });
            if (this.volRebateForm.get('VOLUME_REBATE_PERCENT') && this.volRebateForm.get('VOLUME_REBATE_PERCENT').value !== '') {
                this.initialRebateLevel = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
            }
            // handle agreement term
            if (this.volRebateForm.get('IS_RECURRING') && this.volRebateForm.get('IS_RECURRING').value === 'Y') {
                this.volRebateForm.controls['agreementTerm'].patchValue('Recurring');
            }
        }
    };
    VolumeRebateComponent.prototype.getChildren = function (request) {
        var _this = this;
        this.isLoading = true;
        this.api.requestData(request).subscribe(function (data) {
            // console.log(this.user.loggedInUser.userName);
            console.log('requested child vendor data from api', data);
            if (data && data['Data']) {
                var _loop_2 = function (v) {
                    var foundVendor = _this.allVendors.find(function (r) { return r.VendorID === v.VendorID; });
                    // console.log('Child Vendor, add to list', v, foundVendor);
                    if (!foundVendor && (!v.OnboardingStatus || v.OnboardingStatus === 'Onboarding Completed')) {
                        v.ParentVendorNumber = request.VendorID;
                        v.CompanyName = _this.getFieldByKey(v, 'COMPANY_NAME');
                        v.VendorType = _this.getFieldByKey(v, 'VENDOR_TYPE');
                        v.BuyerID = _this.getFieldByKey(v, 'BUYER_ID');
                        v.BuyerName = _this.getFieldByKey(v, 'BUYER_FIRST_NAME') + ' ' + _this.getFieldByKey(v, 'BUYER_LAST_NAME');
                        // v.ContactEmail = this.getFieldByKey(v, 'SC_EMAIL');
                        // console.log(v);
                        _this.allVendors.push(v);
                    }
                };
                for (var _i = 0, _a = data['Data']; _i < _a.length; _i++) {
                    var v = _a[_i];
                    _loop_2(v);
                }
            }
            _this.isLoading = false;
            // update dropdown
            _this.populateVendorSelection();
            console.log('vendor info (allvendors)', _this.vendorNumberData, _this.allVendors);
        }, function (error) {
            console.log(error);
            // update dropdown
            _this.populateVendorSelection(); //this will just have parents, child request failed
            _this.ga.sendEvent('Volume Rebate', 'Error requesting child vendors', 'for' + request.VendorID, { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': request.VendorID });
            _this.isLoading = false;
        });
    };
    VolumeRebateComponent.prototype.setAddVendorTypeForUser = function () {
        var _this = this;
        this.vendorNumberData = [];
        this.getVendorTypeForUser().subscribe(function (data) {
            _this.vendorNumberData = data.VendorData;
            console.log('Vendor data (small req)', _this.vendorNumberData);
        });
    };
    VolumeRebateComponent.prototype.formatRange = function (index, frmControlName) {
        var form = this.volRebateForm;
        // initialize error handling
        this.rebateerr[0] = '';
        for (var x = 1; x < 5; x++) {
            this.rebateerr[x] = '';
            //form.controls['from'+x].setErrors(null);
            //form.controls['to'+x].setErrors(null);
            form.controls['MLP_TIER_' + x + '_FROM'].setErrors(null);
            form.controls['MLP_TIER_' + x + '_TO'].setErrors(null);
        }
        if (index) {
            //let fieldname = 'MULTI_LEVEL_PAYOUT' + index;
            var range = form.controls['MLP_TIER_' + index + '_FROM'].value + '-' + form.controls['MLP_TIER_' + index + "_TO"].value;
            if (frmControlName) {
                var frmControlValue = form.controls[frmControlName].value.toString().replace(/[^0-9\.]+/g, "");
                if (frmControlValue) {
                    var formattedValue = new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 0,
                    }).format(Number(form.controls[frmControlName].value.toString().replace(/[^0-9\.]+/g, "")));
                    console.log("formattedValue currency value:", formattedValue);
                    form.controls[frmControlName].patchValue(formattedValue);
                }
            }
            // validation, don't overlap
            if (index == 1) {
                console.log("XXXX", +form.controls['MLP_TIER_' + index + "_TO"].value.replace(/[^0-9\.]+/g, ""), +form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""));
                console.log("CONDITION:", +form.controls['MLP_TIER_' + index + "_TO"].value.replace(/[^0-9\.]+/g, "") < +form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""));
                if (form.controls['MLP_TIER_' + index + "_TO"] && form.controls['MLP_TIER_' + index + "_TO"].value &&
                    (+form.controls['MLP_TIER_' + index + "_TO"].value.replace(/[^0-9\.]+/g, "") < +form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""))) {
                    this.rebateerr[index] = 'Level ' + index + " To: Can't be lower than Receipt level that must be reached before rebate is effective value";
                    form.controls['MLP_TIER_' + index + "_TO"].setErrors({ 'invalid': true });
                }
            }
            for (var x = 1; x < 5; x++) {
                // each row, to must be greater than from
                if (form.controls['MLP_TIER_' + x + '_FROM'].value && form.controls['MLP_TIER_' + x + "_TO"].value != '' &&
                    +form.controls['MLP_TIER_' + x + '_FROM'].value.toString().replace(/[^0-9\.]+/g, "") >= +form.controls['MLP_TIER_' + x + "_TO"].value.toString().replace(/[^0-9\.]+/g, "")) {
                    // ( +(form.controls['MLP_TIER_'+x+'_FROM'].value.replace(/[^0-9\.]+/g, "")) >= +(form.controls['MLP_TIER_'+x+"_TO"].value.replace(/[^0-9\.]+/g, "")))) {
                    // mark invalid
                    form.controls['MLP_TIER_' + x + "_TO"].setErrors({ 'invalid': true });
                    // to must be greater than from
                    this.rebateerr[x] = 'Level ' + x + ': Value of "To" must be greater than value of "From"';
                }
                else {
                }
                // each row, from must be greater than previous to
                if (x > 1) {
                    if (form.controls['MLP_TIER_' + (+x - 1) + "_TO"].value && form.controls['MLP_TIER_' + x + '_FROM'].value &&
                        +form.controls['MLP_TIER_' + (+x - 1) + "_TO"].value.toString().replace(/[^0-9\.]+/g, "") != +form.controls['MLP_TIER_' + x + '_FROM'].value.toString().replace(/[^0-9\.]+/g, "") - 1) {
                        // mark invalid
                        // form.controls['MLP_TIER_'+x+'_FROM'].setErrors({'invalid':true});
                        // from must be greater than previous to
                        // this.rebateerr[x]='Level ' + x + ': Value of "From" must be greater than value of "To" in Level ' + (+x-1);
                        // update from value instead
                        var toValue = +form.controls['MLP_TIER_' + (+x - 1) + "_TO"].value.toString().replace(/[^0-9\.]+/g, "");
                        var newValue = (toValue + 1).toLocaleString(undefined, { maximumFractionDigits: 0 });
                        form.controls['MLP_TIER_' + x + '_FROM'].patchValue(newValue);
                        console.log('updated From row ' + x);
                    }
                }
                // each row, previous to must not be blank if rebate is present
                if (x > 1) {
                    if (form.controls['MLP_TIER_' + (+x - 1) + '_TO'].value == '' &&
                        form.controls['MLP_TIER_' + (+x) + '_PERCENTAGE'].value != '') {
                        this.rebateerr[x] = 'Level ' + (+x - 1) + " to can't be empty, add a value or remove row " + x;
                        form.controls['MLP_TIER_' + (+x - 1) + '_TO'].setErrors({ 'invalid': true });
                    }
                }
            }
            //this.volRebateForm.controls[fieldname].patchValue(range);
            this.rebatePercentageChange(index); // calling this method to either activate or deactivate add button
            // console.log(this.volRebateForm.get(fieldname));
        }
    };
    VolumeRebateComponent.prototype.calculateRebate = function () {
        //const receipts = this.volRebateForm.controls['PROJECTED_RECEIPTS'].value;
        //const percent = this.volRebateForm.controls['VOL_REBATE_ACCURALPERC'].value;
        var percent = +this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
        // perform math
        // const total = Math.round((+percent/100)); // round to whole number
        //const formattedValue = +(Math.round(+percent * 100) / 100).toFixed(2);
        var formattedValue = percent.toFixed(2);
        console.log("Formatted value:", formattedValue, +formattedValue);
        // apply to form
        if (!isNaN(+formattedValue)) {
            this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].patchValue(formattedValue);
            this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].setErrors(null);
        }
        else {
            this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].setErrors({ 'invalid': true });
        }
    };
    VolumeRebateComponent.prototype.roundN = function (num, n) {
        //const ss = Math.round(2);
        // return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
    };
    VolumeRebateComponent.prototype.populateVendorSelection = function () {
        var _loop_3 = function (v) {
            console.log('populating vendor', v);
            if (!v.parentVendorNumber) {
                var fId = this_1.vendorNumberData.find(function (vnd) { return vnd.VendorID === v.VendorID; });
                if (!fId &&
                    (!v.ParentVendorNumber || v.ParentVendorNumber === '') &&
                    v.isVendorActive !== 'N' &&
                    v.VendorType !== 'Dropship' && // dropship not allowed
                    v.VendorType.indexOf('Placeholder') < 0 && // placeholder vendors not allowed
                    (!v.OnboardingStatus || v.OnboardingStatus === 'Onboarding Completed')) {
                    var vInfo = { 'VendorID': v.VendorID, 'CompanyName': v.CompanyName, 'buyerId': v.BuyerID, 'buyerName': v.BuyerName, 'vendorType': v.VendorType, 'status': v.OnboardingStatus };
                    this_1.vendorNumberData.push(vInfo);
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.allVendors; _i < _a.length; _i++) {
            var v = _a[_i];
            _loop_3(v);
        }
        // add children
        var children = this.allVendors.filter(function (c) { return c.ParentVendorNumber && c.ParentVendorNumber !== ''; });
        if (children && children.length > 0) {
            for (var _b = 0, children_1 = children; _b < children_1.length; _b++) {
                var child = children_1[_b];
                console.log('child', child);
                // loop through parents
                for (var i in this.vendorNumberData) {
                    if (this.vendorNumberData[+i].VendorID === child.ParentVendorNumber) {
                        // add child after parent in list
                        var childInfo = { 'VendorID': child.VendorID, 'CompanyName': child.CompanyName, 'buyerId': child.BuyerID, 'buyerName': child.BuyerName, 'vendorType': child.VendorType, 'ParentVendorNumber': child.ParentVendorNumber };
                        this.vendorNumberData.splice(+i, 0, childInfo);
                    }
                }
            }
        }
    };
    VolumeRebateComponent.prototype.setAgreementTerm = function () {
        // handle agreement term
        // console.log(this.volRebateForm.get('agreementTerm').value);
        if (this.volRebateForm.get('agreementTerm')) {
            if (this.volRebateForm.get('agreementTerm').value === 'Year') {
                this.volRebateForm.controls['IS_RECURRING'].patchValue('N');
                // const year = new Date(this.volRebateForm.get('EFFECTIVEDATE').value).getFullYear();
                // if(year){
                //   this.volRebateForm.controls['VALID_YEAR'].patchValue(year);
                // }
                console.log("VALID_YEAR:", this.volRebateForm.controls['VALID_YEAR'].value);
            }
            else {
                this.volRebateForm.controls['IS_RECURRING'].patchValue('Y');
                //this.volRebateForm.controls['VALID_YEAR'].patchValue('');
            }
        }
        console.log('changed Valid Year to ' + this.volRebateForm.controls['VALID_YEAR'].value);
        console.log('changed Is Recurring to ' + this.volRebateForm.controls['IS_RECURRING'].value);
        this.showVolumeRebateSearch();
    };
    VolumeRebateComponent.prototype.requestVolumeRebate = function () {
        var _this = this;
        console.log("Inside requestVolumeRebate");
        event.preventDefault();
        this.setAgreementTerm(); // finalize
        // add SC_EMAIL
        //this.volRebateForm.controls['SC_EMAIL'].patchValue(this.currentUser.userName);
        // format effective date
        console.log("Inside requestVolumeRebate123...");
        // filter to send only contract fields (not agreementTerm, etc.)
        var sendContract = {};
        sendContract['ONBOARDING_STATUS'] = 'Submitted';
        sendContract['SUBMITTED_BY'] = this.currentUser.userName;
        // const buyerChosen:BuyerInfo[]  = this.vacBuyers.filter( (prop:BuyerInfo) => +prop.BuyerID== +this.selectedBuyerID);
        //   if(buyerChosen){
        //    sendContract['BUYEREMAIL'] = buyerChosen[0].Email;
        //    sendContract['BUYER_FIRST_NAME'] = buyerChosen[0].FirstName;
        //    sendContract['BUYER_LAST_NAME'] = buyerChosen[0].LastName;
        //    sendContract['BUYERCOMPANY'] = buyerChosen[0].CompanyName;
        // }
        if (this.selectedBuyer) {
            sendContract['BUYEREMAIL'] = this.selectedBuyer.Email;
            sendContract['BUYER_FIRST_NAME'] = this.selectedBuyer.FirstName;
            sendContract['BUYER_LAST_NAME'] = this.selectedBuyer.LastName;
            sendContract['BUYERCOMPANY'] = this.selectedBuyer.CompanyName;
            sendContract['BUYERID'] = this.selectedBuyer.BuyerID;
        }
        sendContract['PARENTVENDORNAME'] = this.rebateVendorInfo.VendorData.COMPANY_NAME;
        sendContract['SC_PHONE'] = this.rebateVendorInfo.VendorData.SC_PHONE;
        sendContract['REBATE_PERCENT_REDUCED'] = 'N';
        // set rebate reduced flag
        var currentVRPercent = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
        var currentVRSourcingType = this.volRebateForm.controls['SOURCING_TYPE'].value;
        var currentValidYear = this.volRebateForm.controls['VALID_YEAR'].value;
        console.log("Existing volume rebate:", this.existingVolumeRebate);
        if (this.volRebateForm.get('agreementTerm').value === 'Year' && this.existingVolumeRebate && this.existingVolumeRebate.length > 0) {
            if (currentVRSourcingType.toLowerCase() === 'all') {
                var filterdData = this.existingVolumeRebate.filter(function (data) { return data.SOURCING_TYPE.toLowerCase() === 'all'
                    || data.SOURCING_TYPE.toLowerCase() === 'domestic'
                    || data.SOURCING_TYPE.toLowerCase() === 'import'; });
                if (filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT) {
                    sendContract['REBATE_PERCENT_REDUCED'] = 'Y';
                }
            }
            else if (currentVRSourcingType.toLowerCase() === 'domestic') {
                var filterdData = this.existingVolumeRebate.filter(function (data) { return (data.SOURCING_TYPE.toLowerCase() === 'domestic' ||
                    data.SOURCING_TYPE.toLowerCase() === 'all'); });
                if (filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT) {
                    sendContract['REBATE_PERCENT_REDUCED'] = 'Y';
                }
            }
            else if (currentVRSourcingType.toLowerCase() === 'import') {
                var filterdData = this.existingVolumeRebate.filter(function (data) { return (data.SOURCING_TYPE.toLowerCase() === 'import' ||
                    data.SOURCING_TYPE.toLowerCase() === 'all'); });
                if (filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT) {
                    sendContract['REBATE_PERCENT_REDUCED'] = 'Y';
                }
            }
        }
        // this.volRebateForm.controls['REBATE_PERCENT_REDUCED'].patchValue('N');
        /*
        if(this.volRebateForm.controls['VOLUME_REBATE_PERCENT'] && this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value){
          const newPercent = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
          if (+newPercent < +this.initialRebateLevel) {
            this.volRebateForm.controls['REBATE_PERCENT_REDUCED'].patchValue('Y');
          }
        }
        */
        for (var _i = 0, _a = Object.keys(this.volRebateForm.controls); _i < _a.length; _i++) {
            var key = _a[_i];
            // console.log(key);
            if (this.isUpperCase(key.substring(0, 1)) && this.volRebateForm.controls[key].value && this.volRebateForm.controls[key].value !== '') {
                /*
                if(key ==='VOLUME_REBATE_THRESHOLD'){
                  sendContract[key] = this.volRebateForm.controls[key].value.replace('$','');
                }else{
                  sendContract[key] = this.volRebateForm.controls[key].value
                }
                */
                if (key === 'VOLUME_REBATE_THRESHOLD' || key.startsWith('MLP_TIER_')) {
                    sendContract[key] = this.volRebateForm.controls[key].value.toString().replace(/[^0-9\.]+/g, "");
                }
                else if (key === 'BUYERID') {
                    sendContract[key] = this.selectedBuyerID || sendContract[key];
                }
                else {
                    sendContract[key] = this.volRebateForm.controls[key].value;
                }
            }
        }
        console.log(sendContract, JSON.stringify(sendContract));
        this.isSubmitting = true;
        this.submitVolRebateForm(sendContract).subscribe(function (data) {
            console.log('submitted form', sendContract, data);
            // prevent submitting again
            _this.isSubmitting = false;
            // handle various "success" responses
            if (data && data.toString() === 'Submitted') {
                // let currVendor = this.allVendors.find(v => v.VendorID === this.selectedVendor.VendorID);
                // currVendor.OnboardingStatus = "Submitted - In-progress";
                //this.populateVendorSelection();
                _this.modalContent.header = 'Success';
                _this.modalContent.body = 'Your request has been successfully submitted and has entered the DocuSign approval process.';
                _this.modalMessage.show();
                _this.clearFormFields();
                _this.ga.sendEvent('Volume Rebate', 'Volume Rebate request successful for' + sendContract['PARENTID'], JSON.stringify(sendContract), { 'time_stamp': Date.now(), 'record_id': sendContract['PARENTID'] });
            }
            else {
                console.log('failed request', data);
                // show error, it didn't work
                _this.modalContent.header = 'Error';
                _this.modalContent.body = 'There was a problem processing your request, please try again or contact your representative.';
                _this.modalMessage.show();
                _this.ga.sendEvent('Volume Rebate', 'Volume Rebate Request Failed for Vendor ' + sendContract['PARENTID'], JSON.stringify(sendContract), { 'time_stamp': Date.now(), 'error_msg': data.toString(), 'record_id': sendContract['PARENTID'] });
            }
        }, function (error) {
            console.log(error);
            _this.isSubmitting = false;
            _this.modalContent.header = 'Error';
            _this.modalContent.body = 'There was a problem processing your request, please try again or contact your representative.';
            _this.modalMessage.show();
            _this.ga.sendEvent('Volume Rebate', 'Volume Rebate Request Error for Vendor ' + sendContract['PARENTID'], JSON.stringify(sendContract), { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': sendContract['PARENTID'] });
        });
    };
    VolumeRebateComponent.prototype.submitVolRebateForm = function (contract) {
        // convert to json
        var json = contract; // JSON.stringify(contract);
        // convert fields to numbers
        /*
        for (let i=1;i<5;i++) {
          if (json['MLP_TIER_' + i + '_TO']) {
            const formattedTo = this.formatNumber(json['MLP_TIER_' + i + '_TO']);
            json['MLP_TIER_' + i + '_TO'] = formattedTo;
          }
          if (json['MLP_TIER_' + i + '_FROM']) {
            const formattedFrom = this.formatNumber(json['MLP_TIER_' + i + '_FROM']);
            json['MLP_TIER_' + i + '_FROM'] = formattedFrom;
          }
        }
    
        if (json.EFFECTIVE_RECEIPT_LEVEL) {
          const formattedNumber = this.formatNumber(json.EFFECTIVE_RECEIPT_LEVEL);
          json.EFFECTIVE_RECEIPT_LEVEL = formattedNumber;
        }
    
        if (json.VOLUME_REBATE_THRESHOLD) {
          const formattedNumber = this.formatNumber(json.VOLUME_REBATE_THRESHOLD);
          json.VOLUME_REBATE_THRESHOLD = formattedNumber;
        }
        */
        // submit form api: sendvolumerebatetodocusign
        // return this.api.apiReq('sendvolumerebatetodocusign',json);
        return this.restApi.bwReqVRSubmit('sendvolumerebatetodocusign', json);
        // return of('tested',contract, json);
    };
    VolumeRebateComponent.prototype.clearFormFields = function () {
        this.createForm();
        this.rebateVendorInfo.VendorData = {};
        this.rebateVendorInfo.VolumeRebate = {};
        this.searchResults = [];
        // reset visible volume rebate rows
        this.showRPLevel2 = false;
        this.showRPLevel3 = false;
        this.showRPLevel4 = false;
    };
    VolumeRebateComponent.prototype.getVendorTypeForUser = function () {
        var json = {
            'Email': this.currentUser.userName,
        };
        return this.api.requestAddVendor(json);
    };
    VolumeRebateComponent.prototype.getFormValueByKey = function (vrVendorInfo, key) {
        var value = '';
        if (Object.keys(vrVendorInfo).length > 0) {
            if (key in vrVendorInfo) {
                value = vrVendorInfo[key];
            }
        }
        return value;
    };
    VolumeRebateComponent.prototype.getFieldByKey = function (vendor, key) {
        var value = '';
        if (vendor.tabs) {
            for (var _i = 0, _a = vendor.tabs; _i < _a.length; _i++) {
                var tab = _a[_i];
                var foundField = tab.fields.find(function (f) { return f.key === key; });
                if (foundField && foundField.value) {
                    value = foundField.value;
                    // console.log('found ', key, value, tab.title);
                    return value;
                }
            }
        }
        return value;
    };
    VolumeRebateComponent.prototype.findChildren = function (id) {
        var children = [];
        for (var _i = 0, _a = this.allVendors; _i < _a.length; _i++) {
            var vendor = _a[_i];
            if (vendor.ParentVendorNumber === id) {
                children.push(vendor.VendorID);
            }
        }
        return children;
    };
    VolumeRebateComponent.prototype.isOnboarding = function (vendor) {
        if (!vendor.OnboardingStatus || vendor.OnboardingStatus !== 'Onboarding Completed') {
            return false;
        }
        return true;
    };
    VolumeRebateComponent.prototype.isUpperCase = function (string) {
        return /^[A-Z]*$/.test(string);
    };
    VolumeRebateComponent.prototype.goToPage = function (path) {
        this.router.navigate([path]);
    };
    // Volume Rebate Search
    VolumeRebateComponent.prototype.searchVR = function (modal) {
        var _this = this;
        if (this.selectedVendorID && (!this.voidVendorID || this.selectedVendorID != this.voidVendorID)) {
            if (modal) {
                // this.voidVendorID = this.selectedVendorID; // if modal is not true , keep voidVendorId from input search text value
                this.voidVendorID = this.rebateVendorInfo.VendorData.PARENTID || this.selectedVendorID;
            }
        }
        if (modal) {
            modal.show();
        }
        if (this.voidVendorID) {
            console.log('searching for ' + this.voidVendorID);
            this.resultsMsg = '';
            this.searchResults = [];
            this.isSearching = true;
            // subscribe to search service
            var url = 'getvrinfo';
            var req_1 = {};
            // const req = {'VRIDs': {'VRID': []},'VENDOR_ID': ''};
            if (this.searchType === 'Buyer') {
                req_1['BUYER_ID'] = this.voidVendorID;
            }
            else {
                req_1['VENDOR_ID'] = this.voidVendorID;
            }
            this.restApi.bwReqVRSubmit(url, req_1).subscribe(function (results) {
                // response
                // console.log('Volume Rebates for ' + req[0], results);
                if (results && results.VRInfo && results.VRInfo.length > 0) {
                    _this.resultsMsg = '';
                    _this.searchResults = results.VRInfo;
                    _this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + _this.searchType + ' Search', _this.searchResults.length + ' results found for ' + _this.voidVendorID, { 'time_stamp': Date.now(), 'record_id': _this.voidVendorID });
                }
                else {
                    _this.resultsMsg = req_1[0] ? 'No Volume Rebates found for ' + req_1[0] : 'No Volume Rebates found.';
                    _this.searchResults = [];
                    _this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + _this.searchType + ' Search', 'No results found for ' + _this.voidVendorID, { 'time_stamp': Date.now(), 'record_id': _this.voidVendorID });
                }
                _this.isSearching = false;
            }, function (error) {
                console.log(error);
                _this.resultsMsg = 'The search was not completed due to an error.';
                _this.isSearching = false;
                _this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + _this.searchType + ' Search Error ', error.message, { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': _this.voidVendorID });
            });
        }
    };
    VolumeRebateComponent.prototype.searchNewVR = function () {
        this.showSearchMsg = false;
        this.searchVR();
    };
    VolumeRebateComponent.prototype.changeSearchType = function (value) {
        console.log('searching by ' + value);
        this.searchType = value;
    };
    VolumeRebateComponent.prototype.closeVR = function (modal) {
        modal.hide();
        this.selectedVendorID = this.volRebateForm.get('PARENTID').value;
        this.isClosing = false;
        return false;
    };
    VolumeRebateComponent.prototype.showBuyerName = function (buyerId, email) {
        var result = '';
        var buyer = this.vacBuyers.find(function (b) { return b.BuyerID === buyerId && b.Email === email; });
        if (buyer) {
            result = buyerId + ' - ' + buyer.FirstName + ' ' + buyer.LastName;
        }
        return result;
    };
    VolumeRebateComponent.prototype.requestVoid = function (vr, isRecall) {
        var _this = this;
        // get void info
        if (vr) {
            // create VOID Request
            var voidObject = new VolumeRebateVoidRequest();
            var voidReq = Object.assign(voidObject, { 'VRID': vr.VRID });
            // add VOID Fields
            voidReq.PARENTID = vr.PARENTID;
            voidReq.PARENTVENDORNAME = vr.PARENTVENDORNAME;
            voidReq.COMPANY_NAME = vr.COMPANY_NAME;
            voidReq.SC_EMAIL = vr.SC_EMAIL;
            voidReq.VENDOR_TYPE = vr.VENDOR_TYPE;
            // Volume Rebate Recall
            if (isRecall) {
                voidReq.ONBOARDING_STATUS = 'Reject';
            }
            else {
                voidReq.ONBOARDING_STATUS = 'Void';
                voidReq.VOID_REASON = this.getVoidReason();
            }
            voidReq.VOID_BY = this.user.loggedInUser.userName;
            voidReq.BUYEREMAIL = vr.BUYEREMAIL;
            voidReq.BUYER_FIRST_NAME = vr.BUYER_FIRST_NAME;
            console.log('final VOID request', voidReq);
            // make void request and handle response
            this.isSearching = true;
            this.submitVolRebateForm(voidReq).subscribe(function (data) {
                console.log(data);
                _this.isSearching = false;
                _this.searchResults.length = 0;
                _this.resultsMsg = (isRecall ? 'Recall' : 'Void') + ' request has been sent.';
                _this.showSearchMsg = false;
                _this.showVoidWarning = false;
                _this.ga.sendEvent('Volume Rebate', (isRecall ? 'Recall' : 'Void') + ' request successful', 'VRID ' + vr.VRID, { 'time_stamp': Date.now(), 'record_id': vr.VRID });
            }, function (error) {
                _this.isSearching = false;
                console.log(error);
                _this.resultsMsg = 'There was an error sending the ' + (isRecall ? 'Recall' : 'Void') + +' request.';
                _this.showVoidWarning = false;
                _this.ga.sendEvent('Volume Rebate', 'Error requesting ' + (isRecall ? 'Recall' : 'Void'), 'VRID ' + vr.VRID, { 'time_stamp': Date.now(), 'error_msg': error.message, 'record_id': vr.VRID });
            });
        }
    };
    VolumeRebateComponent.prototype.selectYear = function (e) {
        var selectedYear = e.option.value;
        this.volRebateForm.controls['VALID_YEAR'].patchValue(selectedYear);
        console.log('autocomplete selected year ', selectedYear, this.volRebateForm.get('VALID_YEAR').value);
        this.showVolumeRebateSearch(this.volRebateForm);
    };
    VolumeRebateComponent.prototype.showVolumeRebateSearch = function (form) {
        console.log('closing modal?', this.isClosing);
        if (this.isClosing) {
            this.isClosing = false;
            return;
        }
        if (!form) {
            form = this.volRebateForm;
        }
        var currentYear = new Date().getFullYear();
        if (form.get('VALID_YEAR').value && +form.get('VALID_YEAR').value < currentYear) {
            form.controls['VALID_YEAR'].setErrors({ 'invalid': true });
            return;
        }
        // Current Volume Rebate Request Details
        var effectiveDate = form.get('VALID_YEAR').value;
        var sourcingType = form.get('SOURCING_TYPE').value;
        var isRecurring = form.get('IS_RECURRING').value;
        // compare to each volume rebate for vendor
        var showSearch = false;
        // only check if all necessary fields are populated and there are existing volume rebates
        if (this.existingVolumeRebate && this.existingVolumeRebate.length > 0 && effectiveDate && sourcingType && isRecurring) {
            // check each existing active or in-progress volume rebate
            for (var _i = 0, _a = this.existingVolumeRebate; _i < _a.length; _i++) {
                var vr = _a[_i];
                if (vr.IS_VR_ACTIVE === 'Y' || vr.ONBOARDING_STATUS.toUpperCase().indexOf('DOCUSIGN') > -1) {
                    console.log(vr);
                    if (vr.IS_RECURRING === 'Y' && (vr.VALID_YEAR < effectiveDate || (vr.VALID_YEAR >= effectiveDate && isRecurring == 'Y'))) {
                        switch (sourcingType.toUpperCase()) {
                            case 'ALL':
                                showSearch = true;
                                break;
                            case 'IMPORT':
                                if (vr.SOURCING_TYPE.toUpperCase() !== 'DOMESTIC')
                                    showSearch = true;
                                break;
                            case 'DOMESTIC':
                                if (vr.SOURCING_TYPE.toUpperCase() !== 'IMPORT')
                                    showSearch = true;
                                break;
                        }
                        console.log('Show search if is recurring and sourcing types match', showSearch, sourcingType, vr.SOURCING_TYPE);
                    }
                    else {
                        // existing vol rebate not recurring
                        console.log('not recurring');
                        if (vr.VALID_YEAR == effectiveDate) {
                            switch (sourcingType.toUpperCase()) {
                                case 'ALL':
                                    showSearch = true;
                                    break;
                                case 'IMPORT':
                                    if (vr.SOURCING_TYPE.toUpperCase() !== 'DOMESTIC')
                                        showSearch = true;
                                    break;
                                case 'DOMESTIC':
                                    if (vr.SOURCING_TYPE.toUpperCase() !== 'IMPORT')
                                        showSearch = true;
                                    break;
                            }
                            console.log('Show search if date matches and sourcing types match', showSearch, sourcingType, vr.SOURCING_TYPE);
                        }
                    }
                }
            }
        }
        if (showSearch) {
            // trigger the search function and modal
            this.searchVR(this.modalSearch);
            this.showSearchMsg = true;
            this.showSubmitErrorMsgforInProgressVR = true;
        }
        else {
            this.showSubmitErrorMsgforInProgressVR = false;
        }
        console.log('Show Existing VR? ', showSearch, effectiveDate, sourcingType, isRecurring, this.existingVolumeRebate);
    };
    VolumeRebateComponent.prototype.isVRactive = function (id) {
        var active = this.existingVolumeRebate.find(function (a) { return a.VRID === id && a.IS_VR_ACTIVE === 'Y'; });
        if (active) {
            return true;
        }
        else {
            var active_1 = this.searchResults.find(function (a) { return a.VRID === id && a.DELETE_INDICATOR !== 'Y' && a.ONBOARDING_STATUS.indexOf('Docusign') == -1; });
            if (active_1) {
                return true;
            }
        }
        return false;
    };
    VolumeRebateComponent.prototype.isVRInProgress = function (id) {
        var inprogress = this.existingVolumeRebate.find(function (a) { return a.VRID === id && (a.ONBOARDING_STATUS.toUpperCase() == 'DATA SENT TO DOCUSIGN' || a.ONBOARDING_STATUS.toUpperCase() == 'IN PROGRESS'); }); // find in-progress statuses
        if (inprogress) {
            return true;
        }
        else {
            var inprogress_1 = this.searchResults.find(function (a) { return a.VRID === id && (a.ONBOARDING_STATUS.toUpperCase() == 'DATA SENT TO DOCUSIGN' || a.ONBOARDING_STATUS.toUpperCase() == 'IN PROGRESS'); });
            if (inprogress_1) {
                return true;
            }
        }
        return false;
    };
    VolumeRebateComponent.prototype.getVoidReason = function () {
        if (this.voidReason === 'Other') {
            return this.otherReason;
        }
        return this.voidReason;
    };
    VolumeRebateComponent.prototype.selectVoidReason = function () {
        console.log(this.voidReason + ' selected');
        if (this.voidReason === 'Other') {
            this.showOtherReason = true;
        }
        else {
            this.otherReason = '';
            this.showOtherReason = false;
        }
    };
    VolumeRebateComponent.prototype.confirmVoid = function () {
        this.showVoidWarning = true;
    };
    VolumeRebateComponent.prototype.resetVoid = function () {
        this.showOtherReason = false;
        this.showVoidWarning = false;
        this.otherReason = '';
        this.voidReason = '';
    };
    VolumeRebateComponent.prototype.formatNumber = function (string) {
        // format as 2 decimal places with no comma
        if (string && string !== '') {
            string = string.replaceAll(',', ''); // remove commas
            var num = parseInt(string);
            string = isNaN(num) ? string : num.toFixed(2);
        }
        return string;
    };
    return VolumeRebateComponent;
}());
export { VolumeRebateComponent };
