import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddVendorBuyer} from '../modal/AddVendorBuyer';
import {VendorApiService} from '../vendor-services/vendor-api.service';
import {ValidationService} from '../vendor-services/validation.service';
import {GoogleAnalyticsService} from '../services/analytics.service';
import {newUserRequest, User, UserService} from '../services/user.service';
import {InviteService} from '../services/invite.service';
import {Router} from '@angular/router';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';
import * as moment from 'moment';
import { AdditionalVendorCheckRequest } from '../modal/AdditionalVendorCheckRequest.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdditionalVendorCheckResponse } from '../modal/AdditionalVendorCheckResponse.model';
import { PopupDialogService } from '../services/popup-dialog.service';
import { PopupDialogResponse } from '../modal/popup-dialog.model';
@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {
  @ViewChild ('modalMessage') modal;
  @ViewChild ('modalValidateAddress') addressModal;

  vendorForm: FormGroup;
  vendor = new AddVendorBuyer();
  selectedUser = new User();
  buyerComp;
  userEmail;
  buyerData;
  fname;lname;companyName;
  allStates;
  stateList;
  remitStates;
  countryList;
  vendorData;
  vendorNumberData;
  showMsg: string = "";
  public domesticVendorType = true;
  public dropshipVendorType = true;
  public importVendorType = true;
  public manuVendorType=true;
  public distributorVendorType=true;
  
  tableData = '';
  reasonText = '';
  responseData;
  reason: boolean= true;

  modalContent = {
    'header':'Success',
    'body':'User added successfully.',
    'footer':''
  }
  userValue = "";
  public isShipperAddressCopied = false;
  isSuccess = true;
  disableType = true;

  public addressList = [];
  public addressData;
  public selectedAddressList = [];
  public submitted = false;
  
  //NSC-64
  additionalVendorDuplicateReasonText:string = '';
  isBuyerOverride:string = 'N';
  chkboxChecked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private api: VendorApiService,
    private users: UserService,
    private inviteService: InviteService,
    private router: Router,
    private vs: ValidationService,
    private ga: GoogleAnalyticsService,
    private poupDialogService: PopupDialogService,
  ){}



  ngOnInit() {
    this.users.getUser();
    // console.log("Testing" +this.users.loggedInUser.userName);
    this.userEmail=this.users.loggedInUser.userName;
    this.fname=this.users.loggedInUser.firstName;
    this.lname=this.users.loggedInUser.lastName;
    this.companyName=this.users.loggedInUser.vendorName;
    // console.log("Company Name" +this.users.loggedInUser.vendorName);
    this.allStates = (states as any).default;
    this.countryList = (countries as any).default;
    this.setAddVendorTypeForUser();
    /*
    this.vendorForm = this.fb.group({
      firstName: this.fname,
      lastName: this.lname,
      email: this.userEmail,
      parentVendorNumber: ['',[Validators.required, Validators.maxLength(6),Validators.minLength(6)]],
      AdditionalVendorReason: ['',[Validators.required, Validators.maxLength(45)]],
      vendorType:[{value: '', disabled: this.isTypeDisabled()}],
      addressLine1:['',[Validators.required, Validators.maxLength(45)]],
      addressLine2:'',
      state: ['',[Validators.required]],
      country:['',[Validators.required]],
      city:['',[Validators.required]],
      zipCode:['',[Validators.required]],
      // remitName:'',
      remitAddressLine1:['',[Validators.required, Validators.maxLength(45)]],
      remitAddressLine2:'',
      //remitState: '',
      //remitCountry:'',
      //remitCity:'',
      //remitZipCode:'',
      remitState: ['',[Validators.required]],
      remitCountry:['',[Validators.required]],
      remitCity:['',[Validators.required]],
      remitZipCode:['',[Validators.required]],
      sameVendorFunding:'Y',
      samePaymentTerms:'Y',
      sameFreightTerms:'Y',
      // sameLeadTime:'Y',
      contactchanges:'N',
      isRTVChanges:'N',
      isVendorScanBased:'N',
      isTermsRemainSame:'Y',
      isAdditionalVendor:'Y',
      isEDISetup:'Y',
      isBuyerInitiated: 'N' // mark vendor initiated invite
    });
     */
    this.initializeFormControls();
    // sort country list TIBCO-3702
    // sort alphabetically by Name
    this.countryList.sort((a,b) => a.NAME.localeCompare(b.NAME));

    // move usa to front
    const usIdx = this.countryList.findIndex(x => x.NAME === 'USA');
    if (usIdx > -1) {
    const usa = this.countryList.splice(usIdx,1);
    if (usa[0]) {this.countryList.unshift(usa[0]);}
    }
  }

  initializeFormControls(){
    this.vendorForm = this.fb.group({
      firstName: this.fname,
      lastName: this.lname,
      email: this.userEmail,
      parentVendorNumber: ['',[Validators.required, Validators.maxLength(6),Validators.minLength(6)]],
      AdditionalVendorReason: ['',[Validators.required, Validators.maxLength(45)]],
      vendorType:[{value: '', disabled: this.isTypeDisabled()}],
      addressLine1:['',[Validators.required, Validators.maxLength(45)]],
      addressLine2:'',
      state: ['',[Validators.required]],
      country:['',[Validators.required]],
      city:['',[Validators.required]],
      zipCode:['',[Validators.required]],
      // remitName:'',
      remitAddressLine1:['',[Validators.required, Validators.maxLength(45)]],
      remitAddressLine2:'',
      //remitState: '',
      //remitCountry:'',
      //remitCity:'',
      //remitZipCode:'',
      remitState: ['',[Validators.required]],
      remitCountry:['',[Validators.required]],
      remitCity:['',[Validators.required]],
      remitZipCode:['',[Validators.required]],
      sameVendorFunding:'Y',
      samePaymentTerms:'Y',
      sameFreightTerms:'Y',
      // sameLeadTime:'Y',
      contactchanges:'N',
      isRTVChanges:'N',
      isVendorScanBased:'N',
      isTermsRemainSame:'Y',
      isAdditionalVendor:'Y',
      isEDISetup:'Y',
      isBuyerInitiated: 'N', // mark vendor initiated invite
      isBuyerOverride: 'N', // optional, default with N
      buyerOverrideReason:''

    });
    this.chkboxChecked = false;
}

  

  isTypeDisabled() {
    return this.disableType;
  }




  populateTestData(): void {
    this.vendorForm.patchValue({
      firstName: 'Jack',
      lastName: 'Harkness',
      sendCatalog: false
    });
  }

  validateAddress() {
    // format address list
    this.addressList = [];
    if(this.vendorForm.get('country').value === 'USA') {
      this.addressList.push({subtitle:'Shipping Address', label:'Address1', key:'addressLine1', value:this.vendorForm.get('addressLine1').value});
      this.addressList.push({subtitle:'Shipping Address', label:'Address2', key:'addressLine2', value:this.vendorForm.get('addressLine2').value});
      this.addressList.push({subtitle:'Shipping Address', label:'City', key:'city', value:this.vendorForm.get('city').value});
      this.addressList.push({subtitle:'Shipping Address', label:'Country', key:'country', value:this.vendorForm.get('country').value});
      this.addressList.push({subtitle:'Shipping Address', label:'State', key:'state', value:this.vendorForm.get('state').value});
      this.addressList.push({subtitle:'Shipping Address', label:'ZIP', key:'zipCode', value:this.vendorForm.get('zipCode').value});
    }
    if(this.vendorForm.get('remitCountry').value === 'USA' && !this.isShipperAddressCopied) {
      this.addressList.push({subtitle:'Remittance Address', label:'Address1', key:'remitAddressLine1', value:this.vendorForm.get('remitAddressLine1').value});
      this.addressList.push({subtitle:'Remittance Address', label:'Address2', key:'remitAddressLine2', value:this.vendorForm.get('remitAddressLine2').value});
      this.addressList.push({subtitle:'Remittance Address', label:'City', key:'remitCity', value:this.vendorForm.get('remitCity').value});
      this.addressList.push({subtitle:'Remittance Address', label:'Country', key:'remitCountry', value:this.vendorForm.get('remitCountry').value});
      this.addressList.push({subtitle:'Remittance Address', label:'State', key:'remitState', value:this.vendorForm.get('remitState').value});
      this.addressList.push({subtitle:'Remittance Address', label:'ZIP', key:'remitZipCode', value:this.vendorForm.get('remitZipCode').value});
    }

    if (this.addressList && this.addressList.length > 0) {
    // call validation service
    this.vs.validateAddresses(this.addressList).subscribe(
      data => {
        // handle response
        this.addressData = data;
        this.selectedAddressList = [];
        let invalidForms = [];
        for (let addr of this.addressData) {
          if(addr[1].address1 === 'No match found.') {
            invalidForms.push(addr[0].title);
          } else if (addr[1].address1.startsWith('%xav'))  {
            // error from service, just use original address
            addr[1] = addr[0];
          }
          // format state name from abbreviation
          if (addr[1].state && addr[1].state !== ''){
            let stateName = this.vs.formatState(addr[1].state, addr[1].country);
            if(stateName) {
              addr[1].state = stateName;
            }
            
          }      
          // format country name from abbreviation
          if (addr[0].country && addr[0].country !== ''){
            let cnm = this.vs.formatCountry(addr[0].country);
            addr[0].country = cnm;
          }

          if (addr[1].country && addr[1].country !== ''){
            let cname = this.vs.formatCountry(addr[1].country);
            addr[1].country = cname;
          }
          
        
        }
        if(invalidForms.length > 0){
          // mark fields invalid
          for (let title of invalidForms) {
            let bad = this.addressList.filter(f => f.subtitle === title && f.label !== 'Address2' && f.label !== 'Remittance Name');
            if(bad){
              for (let field of bad){
                if (this.vendorForm.controls[field.key]){
                  this.vendorForm.controls[field.key].setErrors({'incorrect': true});
                  this.vendorForm.controls[field.key].markAsTouched();
                }
              }
            }
          }
          this.submitted = false;
          this.isSuccess = false;
          this.modalContent.header = 'Invalid Address';
          this.modalContent.body = 'Please correct the invalid fields in ' + invalidForms.join(', ');
          this.modal.show();
          this.ga.sendEvent('Additional Vendor', 'Address Validation - Invalid Fields', invalidForms.join(', '), {'time_stamp':Date.now()});

        } else {
        // display the address validation modal
        this.addressModal.show();
        this.ga.sendEvent('Additional Vendor', 'Address Validation Success', JSON.stringify(this.addressData) , {'time_stamp':Date.now()});
        }      
      }, error => {
        // unable to validate addresses
        console.log('address validation failed, submitting anyway');
        this.ga.sendEvent('Additional Vendor', 'Address Validation Error', error.message, {'time_stamp':Date.now(), 'error_msg':error.message});
        //this.save();
        this.checkInprogressAdditionalVendor(); // NSC-64


      }
    );
    } else {
      // only validate USA addresses
      //this.save();
      console.log("Inside else loop of address validation...");
      this.checkInprogressAdditionalVendor(); // NSC-64

    }    
  }

  selectAddress() {
    console.log('saving the addresses', this.selectedAddressList, this.addressData);
    // copy selected values to the tab
    // console.log('allTabs', this.allTabs);

    for(let addr of this.selectedAddressList) {
      // console.log('Addr', addressTab, addr);
      let addressType = this.addressList.filter(f => f.subtitle === addr.title);
     //  console.log('address type', addressType, addr);
      for(let field of addressType){
        // console.log('field', field);
        const label = field.label.toLowerCase().replace(' ',''); // match format
        if(addr[label] && addr[label] !== ''){
          field.value = addr[label];
          // console.log('update field ' + label + ' to ' + field.value);
        }
      }
    }

    // update display
    for (let field of this.addressList) {
      this.vendorForm.controls[field.key].setValue(field.value);
    }

    // trigger submit only if valid
    //this.save(); // commented as part of NSC-64
    this.checkInprogressAdditionalVendor();// Added as part of NSC-64

    this.addressModal.hide();
  }

  selectAddressOption(index, add){
    // console.log('address index', index, this.addressData[index]);
    // console.log('selected address', add, this.addressData[index][add]);
    // see form-group.component for functionality
    let selectedAdd = this.addressData[index][add] || [];
    selectedAdd.title = this.addressData[index][0].title || '';

    // update the selected values
    let found = this.selectedAddressList.find(a => a.title === selectedAdd.title);
    if(found){
      // already in list, update
      found = selectedAdd;
    } else {
      // not in list, add
      this.selectedAddressList.push(selectedAdd);
      console.log('selected addresses', this.selectedAddressList, this.addressData);
    }
  }

  fieldCheck(){
    this.vendorForm.controls['addressLine1'].setErrors(null);
    this.vendorForm.controls['addressLine2'].setErrors(null);
    this.vendorForm.controls['city'].setErrors(null);
    this.vendorForm.controls['state'].setErrors(null);
    this.vendorForm.controls['country'].setErrors(null);
    this.vendorForm.controls['zipCode'].setErrors(null);
}

fieldCheckRemit(){
    this.vendorForm.controls['remitAddressLine1'].setErrors(null);
    this.vendorForm.controls['remitAddressLine2'].setErrors(null);
    this.vendorForm.controls['remitCity'].setErrors(null);
    this.vendorForm.controls['remitState'].setErrors(null);
    this.vendorForm.controls['remitCountry'].setErrors(null);
    this.vendorForm.controls['remitZipCode'].setErrors(null);
}

  save() {
    console.log(this.vendorForm);
    // console.log('Saved: ' + JSON.stringify(this.vendorForm.value));
    this.selectedUser.firstName =  this.vendorForm.get('firstName').value;
    this.selectedUser.lastName = this.vendorForm.get('lastName').value;
    this.selectedUser.groupName = "Guest";
    this.selectedUser.roleName = "Guest";
    this.selectedUser.createDate = new Date() + "";
   // this.selectedUser.vendorName = this.vendorForm.get('company').value;
    this.selectedUser.vendorNumber = this.vendorForm.get('parentVendorNumber').value+"";
    this.selectedUser.userName = this.vendorForm.get('email').value.toLowerCase();
    this.selectedUser.vendorType = this.vendorForm.get('vendorType').value;
    //this.submitUser(this.selectedUser);
     // NSC-64 changes 
     if(this.isBuyerOverride=== 'Y'){
      this.vendorForm.controls['isBuyerOverride'].setValue('Y');
      this.vendorForm.controls['buyerOverrideReason'].setValue(this.additionalVendorDuplicateReasonText);
    }
    
    this.inviteService.postAdditionalVendorForm(JSON.stringify(this.vendorForm.value)).subscribe(data => {
        console.log(JSON.stringify(data));
        let value=JSON.stringify(data);
        value=value.replace("_body","body");
        
        /*
        // scroll to top 
        window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log('should scroll to top now...');
        */

        let parsed = JSON.parse(value);
        if(parsed["body"]==='Success'){
          // this.showMsg="User has been added successfully";
          this.modalContent.header = 'Success';
          this.modalContent.body = 'You\'ve successfully submitted your request, please click “OK” to be redirected to the onboarding screen. Some information has already been filled in, but you will need to fill in everything else. If you have questions please reach out to your merchandising team.<br/><br/>If your submission was identified as a duplicate , you will receive an email once it has been approved for set up.  Please follow the link from the email to complete your request.';
          this.isSuccess = true;
          this.modal.show();
          this.ga.sendEvent('Additional Vendor', 'Invite Success', this.selectedUser.vendorNumber, {'time_stamp':Date.now(), 'record_id':this.selectedUser.vendorNumber});
        }else {
          // this.showMsg = parsed["body"];
          this.modalContent.header = 'Failed';
          let msg = 'You have a change request in progress. Please complete your active request before submitting a new request. If you feel this is an error or issue please reach out to vendormasterdata@tractorsupply.com';
          // handle other messages
          console.log('Error message from service', parsed['body']);
          this.modalContent.body = msg;
          this.isSuccess = false;
          this.modal.show();
          this.ga.sendEvent('Additional Vendor', 'Error: Request in progress for ' + this.selectedUser.vendorNumber, parsed['body'], {'time_stamp':Date.now(), 'error_msg':parsed['body'], 'record_id':this.selectedUser.vendorNumber});
        }
      }, error => {
        // this.showMsg = "Failed";
        // service call failure response, 4xx or 5xx
        console.log('Server Error', error);
        this.modalContent.header = 'Failed';
        this.modalContent.body = 'There was a problem with your entry. Try submitting again and if you continue to experience problems please contact vendormasterdata@tractorsupply.com';
        this.isSuccess = false;
        this.modal.show();
        this.ga.sendEvent('Additional Vendor', 'Invite Error for ' + this.selectedUser.vendorNumber, error.message, {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':this.selectedUser.vendorNumber});
        /*
        // scroll to top 
        window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log('should scroll to top now...');
        */
      }
    );
  }

  setCountry(abbr, isRemit?){
    // console.log(abbr);
    // try full name
    let currCountry = this.countryList.find(c => c.NAME === abbr);
    if (currCountry) {
      abbr = currCountry.ABBR;
    }
    // get the states
    if (isRemit) {
      this.remitStates = this.allStates.filter(s => s.COUNTRY === abbr);
      this.remitStates = this.allStates.filter(s => s.COUNTRY === abbr);
      this.vendorForm.controls['remitAddressLine1'].setErrors(null);
      this.vendorForm.controls['remitAddressLine2'].setErrors(null);
      this.vendorForm.controls['remitCity'].setErrors(null);
      this.vendorForm.controls['remitState'].setErrors(null);
      this.vendorForm.controls['remitCountry'].setErrors(null);
      this.vendorForm.controls['remitZipCode'].setErrors(null);
    } else {
      this.stateList = this.allStates.filter(s => s.COUNTRY === abbr);
      this.vendorForm.controls['addressLine1'].setErrors(null);
      this.vendorForm.controls['addressLine2'].setErrors(null);
      this.vendorForm.controls['city'].setErrors(null);
      this.vendorForm.controls['state'].setErrors(null);
      this.vendorForm.controls['country'].setErrors(null);
      this.vendorForm.controls['zipCode'].setErrors(null);
    }
    this.vendor.state = ''; // clear state
    // console.log(this.stateList, abbr);
  }

  copyShipperAddress() {
    this.remitStates = this.stateList;
    const shipperAddressLine1 = this.vendorForm.get('addressLine1').value;
    const shipperAddressLine2 = this.vendorForm.get('addressLine2').value;
    const shipperAddressCity = this.vendorForm.get('city').value;
    const shipperAddressState = this.vendorForm.get('state').value;
    const shipperAddressCountry = this.vendorForm.get('country').value;
    const shipperAddressZip = this.vendorForm.get('zipCode').value;
    this.vendorForm.controls['remitAddressLine1'].setValue(shipperAddressLine1);
    this.vendorForm.controls['remitAddressLine2'].setValue(shipperAddressLine2);
    this.vendorForm.controls['remitCity'].setValue(shipperAddressCity);
    this.vendorForm.controls['remitState'].setValue(shipperAddressState);
    this.vendorForm.controls['remitCountry'].setValue(shipperAddressCountry);
    this.vendorForm.controls['remitZipCode'].setValue(shipperAddressZip);
  }




  setAddVendorType(){
    this.vendorData = [];
    this.domesticVendorType = false;
    this.dropshipVendorType = false;
    this.importVendorType = false;
    this.manuVendorType = false;
    this.distributorVendorType = false;
    // this.vendorForm.controls['remitName'].setValue('');
    if(this.vendorForm.get('parentVendorNumber').value.length === 6) {
      this.getParentVendorType(this.vendorForm.get('parentVendorNumber').value).subscribe((data: any) => {
        console.log(data);
        this.vendorData = data.VendorData;
        this.disableType = false;
        this.vendorForm.controls['vendorType'].enable();
        if (!this.vendorData || !this.vendorData.length) {
          this.vendorForm.controls['parentVendorNumber'].setErrors({'incorrect': true});
        } else if (this.vendorData || this.vendorData.length) {
          this.vendorForm.controls['parentVendorNumber'].setErrors({'incorrect': true});
          // console.log('set error by default',this.vendorForm.controls['parentVendorNumber'].errors);
          if(this.vendorData.length>0){
            const matchedRecord:any[] = this.vendorData.filter(data => data.VendorID === this.vendorForm.get('parentVendorNumber').value);
            this.companyName = matchedRecord[0].CompanyName;
          }
          for (let vendor of this.vendorData) {
            console.log('get type for ', vendor);
            if(vendor.VendorType === 'Domestic'){
              this.vendorForm.controls['parentVendorNumber'].setErrors(null);
              this.vendorForm.controls['vendorType'].setValue('Domestic');
             // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
              this.domesticVendorType = true;
            }else if(vendor.VendorType === 'Dropship'){
              this.vendorForm.controls['parentVendorNumber'].setErrors(null);
              this.vendorForm.controls['vendorType'].setValue('Dropship');
             // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
              this.dropshipVendorType = true;
              // console.log('clear error',this.vendorForm.controls['parentVendorNumber'].errors);
            }else if(vendor.VendorType === 'Import'){
              this.vendorForm.controls['parentVendorNumber'].setErrors(null);
              this.vendorForm.controls['vendorType'].setValue('Import');
             // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
              this.importVendorType = true;
            }else if(vendor.VendorType === 'PTSManufacturer'){
              this.vendorForm.controls['parentVendorNumber'].setErrors(null);
              this.vendorForm.controls['vendorType'].setValue('PTSManufacturer');
            //  this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
              this.manuVendorType = true;
            }else if(vendor.VendorType === 'PTSDistributor'){
              this.vendorForm.controls['parentVendorNumber'].setErrors(null);
              this.vendorForm.controls['vendorType'].setValue('PTSDistributor');
             // this.vendorForm.controls['remitName'].setValue(vendor.CompanyName);
              this.distributorVendorType = true;
            }/*else{
              this.vendorForm.controls['parentVendorNumber'].setErrors({'incorrect': true});
            }*/
          }
        }
      });
    }
  }


  setAddVendorTypeForUser(){
    this.vendorNumberData = [];
      this.getVendorTypeForUser().subscribe((data: any) => {
        // console.log(data);
        this.vendorNumberData = data.VendorData;
      });

  }

  addNewUser(){
    this.selectedUser = new User();
  }

  getParentVendorType(vendorNumber){
    // console.log('request info for ', vendorNumber);
    let vendorId = vendorNumber;
    const json = {
      'VendorID': vendorId
    }
    return this.api.requestAddVendor(json);
  }

  setChecked(){
    if(this.chkboxChecked){
      this.chkboxChecked = false;
    }else{
      this.chkboxChecked = true;
    }
  }

  ValidateAdditionalVendorForms(){
    // First validate addresses
    this.validateAddress();


  }
  checkInprogressAdditionalVendor(){
    this.responseData = [];
    this.tableData ='';
    //const companyName = this.vendorForm.get('company').value;
    const companyName = this.companyName;
    const shipperAddressCity = this.vendorForm.get('city').value;
    const shipperAddressState = this.vendorForm.get('state').value;
    const shipperAddressCountry = this.vendorForm.get('country').value;
    const shipperAddressZip = this.vendorForm.get('zipCode').value;
    const additionalVendorCheckRequest:AdditionalVendorCheckRequest ={
      CompanyName: companyName,
      SACity: shipperAddressCity,
      SAState: shipperAddressState,
      SACountry: shipperAddressCountry,
      SAZip: shipperAddressZip

    };
    this.additionalVendorCheckApiCall(additionalVendorCheckRequest).subscribe((response:AdditionalVendorCheckResponse)=>{
      console.log("RESPONSE:", response);
      if(response['AdditionalVendorCheck'][0].IsApprovalNeeded === 'N'){
        // go ahead and submit the form without any popup message or reason text
        this.save();

      }else {
        this.responseData = response['AdditionalVendorCheck'];
        for(let i in this.responseData){
          this.responseData[i].SAZip = this.responseData[i].SAZip.substring(0,5);
          if(this.responseData[i].IsVendorActive.toLowerCase() == 'y'){
            this.responseData[i].IsVendorActive = 'Active';
          }
          if(this.responseData[i].IsVendorActive.toLowerCase() == 'n'){
            this.responseData[i].IsVendorActive = 'Inactive';
            }
        }
        let displayMessage="The below vendor(s) already exists with name of "+companyName+" and \
        Shipping information ( "+shipperAddressCountry+", "+shipperAddressState+", "+shipperAddressCity+", "+shipperAddressZip.substring(0,5)+" ). Are you sure you want to proceed (YES/NO)?";
        this.tableData = displayMessage;
        var modal = document.getElementById("myModal");
        modal.style.display = "block";

        // get the reason text and show popup modal
      //   let displayMessage="<p>A vendor already exists with name of <b>"+response.CompanyName+"</b> and \
      //   Shipping information ( "+response.SACountry+", "+response.SAState+", "+response.SACity+", "+response.SAZip.substring(0,5)+" ). are you sure you want to proceed (YES/NO)?</p>";
                
      //   let popupData ={
      //    title: "Continue?",
      //    message: displayMessage,
      //    showReasonText:true  
      //  }
      //  let dialogServiceRef = this.poupDialogService.openDialog(popupData);
      //  dialogServiceRef.afterClosed().subscribe((result:PopupDialogResponse)=>{
      //   const continueOnborading = result.continueOnborading;
      //   if(continueOnborading){
      //     // If YES
      //     this.additionalVendorDuplicateReasonText = result.reasonText;
      //     this.isBuyerOverride = 'Y';
      //     this.save();

      //   }else{
      //     // If NO clear the vendor number
      //     this.additionalVendorDuplicateReasonText ='';
      //     this.isBuyerOverride = 'N';
      //     this.initializeFormControls();
          
      //   }

      //  })
      }
    },
    (error)=>{
      
      console.log("Error in Additional vendor check API..", error);

    }
    );
   

  }

  proceed(){
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    const continueOnboarding = true;
    if(continueOnboarding){
      this.additionalVendorDuplicateReasonText = this.reasonText;
      this.isBuyerOverride = 'Y';
      this.save();
  }
}
  
  cancel(){
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    this.additionalVendorDuplicateReasonText ='';
    this.isBuyerOverride = 'N';
    this.initializeFormControls();
  }

  checkReasonTextLength(event:any){
    this.reason = true;
    if(event.length != 0 && event.length >= 10){
      this.reason = false;
    }

  }

  additionalVendorCheckApiCall(request:AdditionalVendorCheckRequest):Observable<AdditionalVendorCheckResponse>{
    const apiEndPoint = 'additionalvendorcheck';
   return  this.api.duplicateVendorCheckCall(apiEndPoint, request).pipe( map((data:AdditionalVendorCheckResponse)=>{
      return data;

    }));

  }

  submitUser(user){
    console.log('adding user', user);

    let req;
    // handle multiple roles
    if (user.roleName && Array.isArray(user.roleName)) {
      user.roleName = user.roleName.join(',');
    }
    if (user.roleName.indexOf('VendorAdmin') > -1) {
      user.roleName = 'VendorAdmin'; // remove unnecessary roles
      user.groupName = 'VENDOR_ADMIN';
    }else if (user.roleName.indexOf('Guest') > -1) {
      user.roleName = 'Guest'; // remove unnecessary roles
      user.groupName = 'GUEST';
    } else {
      // not vendor admin
      user.groupName = 'Vendors';
    }
    // new user
    // console.log('creating new user');
    this.modalContent.body = 'User ' + user.userName + ' has been added.';
    user.createDate = moment().utc().format('M/DD/YYYY, h:mm:ss A');
    // parse to new user request object

    this.users.getUserDetails(user).subscribe(
      data => {
        console.log(data);
        if(data && data.json() && data.json().length > 0) {
          // console.log('User already exists');
          this.showMsg="User already exists";
          this.ga.sendEvent('Additional Vendor', 'User Create Error', 'User ' + user.firstName + ' ' + user.lastName + ' already exists', {'time_stamp':Date.now()});
        } else {
          req = new newUserRequest();
          req.loggedUser = this.users.loggedInUser.userName;
          req.userName = user.userName.toLowerCase();
          req.email = user.userName.toLowerCase();
          req.firstName = user.firstName;
          req.lastName = user.lastName;
          req.roleName = user.roleName;
          req.vendorName = user.vendorName;
          req.vendorNumber = this.users.loggedInUser.vendorNumber;
          req.createDate = user.createDate;
          req.createBy = this.users.loggedInUser.userName.toLowerCase();
          req.groupName = user.groupName;
          req.roleName = user.roleName;
          req.vendorType = user.vendorType;
          console.log('user request', req);
          this.users.editVendorUser(req).subscribe(
            data => {
              console.log(data);
              this.addNewUser();
              this.ga.sendEvent('Additional Vendor', 'User Create', user.firstName + ' ' + user.lastName + ' created successfully', {'time_stamp':Date.now()});
            }, error => {
              console.log(error);
              this.showMsg= "User has been added successfully";
              this.ga.sendEvent('Additional Vendor', 'User ' + user.firstName + ' ' + user.lastName + ' added with error', error.message, {'time_stamp':Date.now(), 'error_msg':error.message});
            }
          );
        }
      }
    );
  }

  clearMessage(){
    this.showMsg="";
    this.userValue="";
    this.router.navigateByUrl('/');
  }

  clearValues(){
    this.showMsg="";
    this.userValue="";
  }

  getBuyerInfo(){
    this.api.getBuyerDetails().subscribe((data: any) => {
      this.buyerData = data.Data;
    });

  }

  getVendorTypeForUser(){
    const json = {
      "Email":this.userEmail
    }
    return this.api.requestAddVendor(json);
  }


goToPage(path) {
  this.router.navigate([path]);
}

}
