<div class="container">
    <div *ngIf="showMenu" class="document-menu">
        <h2>Document Downloads</h2>
        <div class="flex-row">
          <div *ngFor="let c of docs.documentCategories" class="cat" (click)="showDocs(c.category)">
            <div class="thumbnail" [ngStyle]="{'background-image':'url(../assets/' + c.thumbnail + ')'}">
            </div>
            <div class="text">
              {{ c.category }}
            </div>
          </div>
        </div>
      </div>
    <div *ngIf="!showMenu" class="row">
      <div class="col-sm-6 col-md-9">
        <h2>Documents: {{ docs.selectedCategory }}</h2>
      </div>
      <div class="col-sm-6 col-md-3 document-nav">
        <div (click)="showDocs()">&laquo; back to all Document Downloads</div>
      </div>
      <div class="col-sm-12">
        <!--<div class="search">
          <input placeholder="search not enabled..." class="form-control" type="text"> 
        </div> -->

        <div class="documents-list">
          <div *ngIf="!docsList || docsList.length < 1">Loading documents...</div>

          <div *ngFor="let subfolder of subFolders; let x = index;">
            <h3 *ngIf="subEmpty(subfolder.category)" class="folder folder-empty">{{ subfolder.category }}</h3>
            <h3 *ngIf="!subEmpty(subfolder.category)" [ngClass]="{'folder':true, 'folder-open':showSub(x)}" (click)="toggleSub(x)">
              {{ subfolder.category }} 
                <span class="hover-text">
                  <span *ngIf="!showSub(x)">+ click to open folder</span>
                  <span *ngIf="showSub(x)">- click to close folder</span>
                </span>
            </h3>
            <div *ngIf="showSub(x)">
              <div class="row header">
                  <div class="col-sm-1">&nbsp;</div>
                  <!--  <div class="col-sm-1 text-right">Size</div> -->
                  <div class="col-sm-6">Title </div>
                  <div class="col-sm-3">Last Update</div>
                  <div class="col-sm-2"></div>
                </div>
              <ng-container *ngFor="let doc of docsList">
                <div class="row" *ngIf="hasSub(doc, subfolder.category)">
                    <div class="col-sm-1">&nbsp;</div>
                    <div class="col-sm-6"><span class="doc-name" (click)="downloadDoc(doc,modalMessage)">{{ doc.name }}</span></div>     
                    <div class="col-sm-3">{{ doc.modifyDate || doc.createDate | date: 'MMMM d, y' }}</div>
                    <div class="col-sm-2"><div class="download-button"><button class="btn btn-download" (click)="downloadDoc(doc,modalMessage)">Download</button></div></div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row header">
           <!--  <div class="col-sm-1"></div>
            <div class="col-sm-1 text-right">Size</div> -->
            <div class="col-sm-7">Title </div>
            <div class="col-sm-3">Last Update</div>
            <div class="col-sm-2"></div>
          </div>
          <ng-container *ngFor="let doc of docsList; let x = index;">
          <div class="row" *ngIf="noSub(doc, docs.selectedCategory)">
              <!-- <div class="col-sm-1">
                <div class="checkbox">
                  <input type="checkbox" (change)="selectDownload(x)" id="{{ 'doc' + x }}" name="{{ 'doc' + x }}">
                  <label for="{{ 'doc' + x }}"></label>
                </div>
              </div>
              <div class="col-sm-1 text-right">{{ doc.fileSize }}</div> -->
              <div class="col-sm-7"><span class="doc-name" (click)="downloadDoc(doc,modalMessage)">{{ doc.name }}</span></div>     
              <div class="col-sm-3">{{ doc.modifyDate || doc.createDate | date: 'MMMM d, y' }}</div>
              <div class="col-sm-2"><div class="download-button"><button class="btn btn-download" (click)="downloadDoc(doc,modalMessage)">Download</button></div></div>
          </div>
          </ng-container>
          <!--<div class="download-button"><button [disabled]="disableDownload(modalMessage)"class="btn btn-download" (click)="downloadDocs()">Download Selected Documents</button></div> -->
        </div>
        <div class="upload-documents">
          <!-- this section intentionally left blank -->
        </div>
      </div>
    </div>
  </div>

  <app-modal #modalMessage>
      <div class="app-modal-header">
          {{ modalContent.header }}
      </div>
      <div class="app-modal-body">
              {{ modalContent.body }}
      </div>
      <div class='app-modal-footer'>
              {{ modalContent.footer }} 
              <button (click)="modalMessage.hide()">OK</button>
      </div>
  </app-modal>
