<div *ngIf="!showCow" class="header">
  <a *ngIf="!isGuest()" routerLink="" class="header-link"></a>
  <div class="header-logo"><h1>Vendor Partner Portal</h1></div>
  <div class="header-nav">
    <ul *ngIf="showNav" class="nav">
              <li *ngIf="!isGuest()"><a routerLink="">Home</a></li>
              <li *ngIf="isAdmin()"><a routerLink="admin/announcements">Announcements</a></li>
              <li *ngIf="isAdmin()"><a routerLink="admin/events">Events</a></li>
              <li *ngIf="isAdmin()"><a routerLink="admin/documents">Documents</a></li>
              <li *ngIf="isAdmin()"><a routerLink="admin/users">Users</a></li>
              <li *ngIf="isVMD()"><a routerLink="manage-invites">Vendor Invites</a></li>
           <!--  <li *ngIf="isVendorAdmin()"><a routerLink="account">Account Management</a></li>  -->
              <li *ngIf="isTSCBuyer() || isPTSBuyer() "><a routerLink="vendorInvite">Vendor Invite</a></li>
              <li *ngIf="isTSCBuyer() || isPTSBuyer() "><a routerLink="buyerMaintenance">Buyer Maintenance</a></li>
              <li *ngIf="isVendorAdmin() && showButton('addvendor')"><a routerLink="addVendor">Additional Vendor</a></li>
              <!-- <li *ngIf="isVendorAdmin() && showButton('volrebate')"><a routerLink="volumeRebate">Volume Rebate</a></li> -->
              <li *ngIf="(isVendorAdmin() || isVendor()) && showButton('dashboard')"><a routerLink="vendor-dashboard">Vendor Dashboard</a></li>
              <li *ngIf="(isVendorAdmin() || isProductContent()) && showButton('onboarding') || (isGuest() && isOnboarding())"><a routerLink="consent">Continue Onboarding</a></li>
    </ul>
  <!-- <div *ngIf="isVendorAdmin() || isAdmin() || isTSCBuyer() || isPTSBuyer()" class="hamburger" (click)="toggleNav()"><i *ngIf="!showNav" class="material-icons">menu</i><i *ngIf="showNav" class="material-icons">close</i></div> -->
  </div>
  <div *ngIf="users.loggedInUser!==null" class="current-user">Hello, {{ users.loggedInUser?.firstName }} | <a routerLink="" (click)="users.logout()">Logout</a></div>
</div>
<div class="portal-content" *ngIf="users.isLoggedIn() && !showCow">
  <router-outlet></router-outlet>
</div>

<div class="no-access container" *ngIf="!users.isLoggedIn() && !showCow">
  <!-- page for SSO users without vendor portal roles -->
  <div class="row">
    <div class="col-sm-8 offset-sm-2 text-center">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <div class="alert alert-danger" role="alert">
          <h2>Access Denied</h2>
      </div>
      <p>&nbsp;</p>
      <p>You don't have access to this website.<br />If you require access to the TSC Partner Portal, please contact your representative.</p>
    </div>
  </div>
</div>

<div class="footer container">
  <div class="row">
    <div class="footer-wrapper col-sm-12">
      <p class="text-center">&copy;{{ currentYear | date:'yyyy'}} Tractor Supply Company.</p>
      <p class="text-center small" *ngIf="users.isLoggedIn() && !isGuest()"><a routerLink="">Home</a> | <a routerLink="faq">FAQ</a> | <a routerLink="" (click)="users.logout()">Logout</a></p>
    </div>
  </div>
  
</div>

<div *ngIf="showCow" class="maintenance">
 
    <div id="bg"></div>

    <h1>We're trying to get things <span>moooooving</span></h1>
    <h4><span>Thank you for your patience. This site is undergoing routine maintenance.</span></h4>
        

    <div id="moo"><span></span><h6>Moo.</h6></div>
    <div id="logo"><img src="assets/tsc-logo.png"></div>
    <div id="menu" *ngIf="isAdmin()"><button class="btn btn-secondary" routerLink="">Page Admin</button></div>

</div>
