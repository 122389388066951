
<div class="container">
  <div class="row">
    <div class="col-sm-9">
      <h2>Look up a Vendor Allowance Confirmation (VAC)</h2>

      <div class="form-row">
        <label class="col-sm-1 col-form-label text-right">
          Search
        </label>
        <div class="form-group col-sm-6">
          <input class="form-control" type="text" [(ngModel)]="searchQuery">
        </div>
        <div class="form-group col-sm-3">
          <select class="form-control" [(ngModel)]="searchCriteria">
            <option value="VACID">by VAC ID</option>
            <option value="PromoType">by Promotion Type</option>
            <option value="PromoDescription">by Promotion Name</option>
            <option value="VendorID">by Vendor Number</option>
            <option value="SKU">by SKU</option>
            <option value="BuyerID">by Buyer ID</option>
        </select>
        </div>
        <div class="form-group col-sm-2">
          <button class="search-btn" [disabled]="isSearching" (click)="searchVAC()"><span class="material-icons">search</span></button>
        </div>        
      </div>
    </div>
    <div class="col-sm-3">
      <div *ngIf="buyerCompany=='PETSENSE'" class="logo pts-logo">
        <img src="'../../assets/petsense_logo.png" alt="petSense"/>
      </div>
      <div *ngIf="buyerCompany=='TSC'" class="logo tsc-logo">
        <img src="'../../assets/TSC JPG.jpg" alt="TSC"/>
      </div>
    </div>
  </div>
  <div class="search-results">
    <div class="row">
      <div class="col-sm-12">
        <h2>Search Results:</h2>
      </div>
    </div>
    <div class="row headers">
      <div class="col-sm-3">
        <div (click)="sortByField('VACID')">VACID <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'VACID'}"></span></div>
      </div>
      <div class="col-sm-2">
        <div (click)="sortByField('PromoType')">Type <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'PromoType'}"></span></div>
      </div>
      <div class="col-sm-2">
        <div (click)="sortByField('PromoDescription')">Name <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'PromoDescription'}"></span></div>
      </div>
      <div class="col-sm-1">
        <div (click)="sortByField('BuyerID','number')">Buyer <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'BuyerID'}"></span></div>
      </div>
      <div class="col-sm-1">
        <div (click)="sortByField('VendorID','number')">Vendor <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'VendorID'}"></span></div>
      </div>
      <div class="col-sm-1">
        <div (click)="sortByField('BeginDate','date')">Start <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'BeginDate'}"></span></div>
      </div>
      <div class="col-sm-1">
        <div (click)="sortByField('EndDate','date')">End <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'EndDate'}"></span></div>
      </div>
      <div class="col-sm-1">
        <div (click)="sortByField('IsVACActive')">Status <span [ngClass]="{'indicator':true, 'sortasc': sortAsc, 'visible': sortedField === 'IsVACActive'}"></span></div>
      </div>
    </div>
    <ng-container *ngIf="searchResults.length > 0">
    <div *ngFor="let vac of searchResults" class="row">
      <div class="col-sm-3">
        <div class="vac-link" (click)="showVACdetails(vac.VACID,modalVAC)">{{ vac.VACID }}</div>
      </div>
      <div class="col-sm-2">
        {{ vac.PromoType }}
      </div>
      <div class="col-sm-2">
        {{ vac.PromoDescription }}
      </div>
      <div class="col-sm-1">
        {{ vac.BuyerID }}
      </div>
      <div class="col-sm-1">
        {{ vac.VendorID }}
      </div>
      <div class="col-sm-1">
        {{ vac.BeginDate | date:'MM/dd/yyyy'}}
      </div>
      <div class="col-sm-1">
        {{ vac.EndDate | date:'MM/dd/yyyy'}}
      </div>
      <div *ngIf="vac.OnboardingStatus && vac.OnboardingStatus!==''" class="col-sm-1">
        <div *ngIf="vac.OnboardingStatus.toUpperCase()==='ONBOARDING COMPLETED'" class="badge badge-sm badge-success">Completed</div>
        <div *ngIf="vac.OnboardingStatus.toUpperCase() === 'DATA SENT TO DOCUSIGN' ||
                    vac.OnboardingStatus.toUpperCase() === 'ONBOARDING INPROGRESS' ||
                    vac.OnboardingStatus.toUpperCase() === 'VOID INPROGRESS'" class="badge badge-sm badge-info">In-Progress</div>
        <div *ngIf="vac.OnboardingStatus.toUpperCase()==='REJECTED'" class="badge badge-sm badge-dark">Rejected</div>
        <div *ngIf="vac.OnboardingStatus.toUpperCase()==='VOID COMPLETED' ||
                    vac.OnboardingStatus.toUpperCase()==='VOIDED'" class="badge badge-sm badge-danger">Voided</div>
      </div>
      <div *ngIf="!vac.OnboardingStatus || vac.OnboardingStatus===''" class="col-sm-1">
        <!-- <div class="badge badge-sm badge-success" *ngIf="vac.IsVACActive !== 'N'">Active</div>
        <div class="badge badge-sm badge-danger" *ngIf="vac.IsVACActive === 'N'">Inactive</div> -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="searchResults.length < 1 && this.searchMsg !==''">
    <div class="alert alert-warning">
      <mat-spinner *ngIf="isSearching" [diameter]="18"></mat-spinner>
       {{ this.searchMsg }}
    </div>
  </ng-container>
  </div>
</div>

<app-modal #modalVAC>
  <div class="app-modal-header row">
    <div class="close-btn" (click)="modalVAC.hide()">&times;</div>
    <div class="col">VAC Details</div>
    <div class="col text-right" *ngIf="!isLoading">
      <button *ngIf="showEditBtn()" [disabled]="showWarningModal || isSuccess" class="btn btn-sm btn-outline-secondary" (click)="editVAC()">{{ editBtnText() }}</button>&nbsp;
      <button *ngIf="showVoidBtn()" [disabled]="showWarningModal || isSuccess" class="btn btn-sm btn-danger" title="This cannot be undone, are you sure?" (click)="checkVoid()">VOID</button>
      <button *ngIf="showRejectBtn()" [disabled]="showWarningModal || isSuccess" class="btn btn-sm btn-danger" title="This cannot be undone, are you sure?" (click)="checkReject()">RECALL</button>
      <!-- <div *ngIf="!showVoidBtn() && !showEditBtn()" class="small">This VAC is in-progress and<br/>cannot be modified.</div> -->
    </div>    
  </div>

  <div class="app-modal-body vac-details">
    <div class="text-center" *ngIf="isLoading">
      <mat-spinner [diameter]="24"></mat-spinner> {{ loadingMsg }}<br />
      
    </div>
    <div class="alert alert=secondary" *ngIf="isSuccess">The VOID request was sucessfully sent.</div>
    <div class="alert alert=secondary" *ngIf="isRecalled">You've recalled this VAC successfully, you can now edit or void the VAC request</div>
    <div *ngIf="isError" class="alert alert-danger">{{ errorMsg }}</div>
    <div *ngIf="showVoidWarning || showRejectWarning" class="alert alert-secondary text-center"><p>This cannot be undone, are you sure?</p>
      <div class="row">
        <div class="col-sm-7">
          <select class="form-control form-control-sm" [(ngModel)]="voidReason" (change)="selectVoidReason()">
          <option value="" disabled>Choose Reason...</option>
          <option value="Vendor Relationship Does not Exist">Vendor Relationship Does not Exist</option>
          <option value="Vendors Declined">Vendors Declined</option>
          <option value="Event Cancelled">Event Cancelled</option>
          <option value="Invalid Setup Criteria">Invalid Setup Criteria</option>
          <option value="Other">Other</option>
        </select>
        <div *ngIf="showOtherReason" for="otherReason"><input type="text" class="form-control form-control-sm" maxlength="255" name="otherReason" [(ngModel)]="otherReason"/></div>
      </div>
        <div *ngIf="showVoidWarning" class="col-sm-5 text-right">  
          <button class="btn btn-danger btn-sm" [disabled]="voidDisabled()" (click)="voidVAC(selectedVAC)">VOID</button> &nbsp;
          <button class="btn btn-secondary btn-sm" (click)="hideVoidWarning()">Cancel</button>
        </div>
        <div *ngIf="showRejectWarning" class="col-sm-5 text-right">  
          <button class="btn btn-danger btn-sm" [disabled]="voidDisabled()" (click)="voidVAC(selectedVAC, true)">RECALL</button> &nbsp;
          <button class="btn btn-secondary btn-sm" (click)="hideVoidWarning()">Cancel</button>
        </div>
      </div>
    </div>
    <div *ngIf="(!isLoading && !isError && selectedVAC) || (isLoaded && selectedVAC)" class="flex-container">
      <div class="flex-3-4 vac-id">{{ selectedVAC.VACID }}</div>
      <div class="flex-1-4 text-right">

        <div class="badge badge-sm badge-success" *ngIf="selectedVAC.OnboardingStatus && selectedVAC.OnboardingStatus.toUpperCase()==='ONBOARDING COMPLETED'">Completed</div>
        <div class="badge badge-sm badge-info" *ngIf="selectedVAC.OnboardingStatus && ( 
                                                      selectedVAC.OnboardingStatus.toUpperCase()==='DATA SENT TO DOCUSIGN'||
                                                      selectedVAC.OnboardingStatus.toUpperCase() === 'ONBOARDING INPROGRESS' ||
                                                      selectedVAC.OnboardingStatus.toUpperCase() === 'VOID INPROGRESS')">In-Progress</div>
        <div class="badge badge-sm badge-dark" *ngIf="selectedVAC.OnboardingStatus && selectedVAC.OnboardingStatus.toUpperCase()==='REJECTED'">Rejected</div>
        <div class="badge badge-sm badge-danger" *ngIf="selectedVAC.OnboardingStatus && 
                                                      (selectedVAC.OnboardingStatus.toUpperCase()==='VOID COMPLETED' ||
                                                      selectedVAC.OnboardingStatus.toUpperCase()==='VOIDED')">Voided</div>
        <!-- <div class="badge badge-sm badge-success" *ngIf="selectedVAC.IsVACActive !== 'N'">Active</div>
        <div class="badge badge-sm badge-danger" *ngIf="selectedVAC.IsVACActive === 'N'">Inactive</div> -->

      </div>
      <div class="flex-1">{{ selectedVAC.PromoDescription }}</div>
      <div class="flex-2">Vendor {{ selectedVAC.VendorID }}</div>
      <div class="flex-1 promo-details"><h4>Promotion Details</h4></div>
      <div class="flex-1-3">{{ selectedVAC.PromoType }}</div> <div class="flex-2-3 text-right">{{ selectedVAC.BeginDate | date: 'MM/dd/yyyy' }} - {{ selectedVAC.EndDate | date: 'MM/dd/yyyy'}}</div>
      <ng-container *ngIf="selectedVAC && selectedVAC.PromoDetails && (!selectedVAC.PromoDetails.FlatDolAmt || selectedVAC.PromoDetails.FlatDolAmt === '')">
        <div class="flex-1-3">{{ selectedVAC.PromoDetails?.EstimatedAmt | currency : 'USD' : 'symbol' }}</div> <!-- currency : 'USD' : 'symbol' : '1.0-0' -->
        <div class="flex-1-3">{{ selectedVAC.PromoDetails?.EstimatedUnits | number:'1.0':'en-US' }} units</div>
        <div class="flex-1-3">{{ selectedVAC.PromoDetails?.Rainchecks ? 'Rain Checks' : 'No Rain Checks' }}</div>
      </ng-container>
      <div class="flex-1 promo-details"><h4>Payment Details</h4></div>
      <div class="flex-2" *ngIf="selectedVAC && selectedVAC.PromoDetails && selectedVAC.PromoDetails.FlatDolAmt && selectedVAC.PromoDetails.FlatDolAmt !== ''">Flat Amount: ${{ selectedVAC.PromoDetails?.FlatDolAmt}}</div>
      <div class="flex-1-4"> {{ selectedVAC.PromoDetails?.PaymentType }}</div><div class="flex-1-4"> {{ selectedVAC.PromoDetails?.PaymentDate | date: 'MM/dd/yyyy' }}</div>
      <div class="flex-1" *ngIf="selectedVAC && selectedVAC.PromoDetails && selectedVAC.PromoDetails.SpecialInstructions && selectedVAC.PromoDetails.SpecialInstructions !== ''">Special Instructions: {{ selectedVAC.PromoDetails?.SpecialInstructions }}</div>
      <div class="flex-1" *ngIf="selectedVAC && selectedVAC.PromoDetails && selectedVAC.PromoDetails.FlatDollarSkuList && selectedVAC.PromoDetails.FlatDollarSkuList !== ''">SKUS: {{ selectedVAC.PromoDetails?.FlatDollarSkuList }}</div>
      <div class="scandown-list" *ngIf="selectedVAC && selectedVAC.PromoDetails && selectedVAC.PromoDetails.ScanDownPromo && selectedVAC.PromoDetails.ScanDownPromo.length > 0 && selectedVAC.ScandownFlag !== 'N' && selectedVAC.PromoDetails.FlatDolAmt === ''">
        <div class="col-sm-12"><div class="row">
          <div class="col-sm-2">SKU</div><div class="col-sm-4">Desc</div><div class="col-sm-2">Amt</div><div class="col-sm-2">Units</div><div class="col-sm-2">Forecast</div>
        </div>
        <div class="row" *ngFor="let r of selectedVAC.PromoDetails.ScanDownPromo">
          <div class="col-sm-2">{{ r.SKU }}</div>
          <div class="col-sm-4">{{ r.SKUDescription }}</div>
          <div class="col-sm-2"><span *ngIf="r.SKU && r.SKU !== ''"></span>{{ r.ScandownAmt | currency : 'USD' : 'symbol':'1.0'}}</div> <!--  -->
          <div class="col-sm-2">{{ r.Units | number:'1.0':'en-US' }}</div>
          <div class="col-sm-2">{{ r.Forecast | currency : 'USD' : 'symbol' : '1.0' }}</div>
        </div>
      </div></div>
    </div>
      

  </div>
  <div class="app-modal-footer">
    <button class="btn btn-primary btn-sm" (click)="modalVAC.hide()">Hide Details</button>
  </div>
</app-modal>

<app-modal #modalMessage>
  <div class="app-modal-header" [class]="'alert alert-' + modalContent.class">
    {{ modalContent.header }}
  </div>
  <div class="app-modal-body">

      {{ modalContent.body }}

  </div>
  <div class='app-modal-footer'>
    {{ modalContent.footer }}
    <button class="btn btn-primary" (click)="modalMessage.hide()">OK</button>
  </div>
</app-modal>

