<div class="row account-content">
    <div class="col-md-6 col-sm-12">
        <div class="new-btn" (click)="addNewUser()"><i class="material-icons">add_circle</i> Add User</div>
    <h1>Account Management</h1>
    <form class="edit-form" #vForm="ngForm">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Username</label>
              <input class="form-control" readonly required type="text" name="username" [(ngModel)]="users.loggedInUser.userName">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
              <div class="form-group">
                <label>First Name</label>
                <input class="form-control" type="text" name="firstName" [(ngModel)]="users.loggedInUser.firstName">
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input class="form-control" type="text" name="lasttName" [(ngModel)]="users.loggedInUser.lastName">
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <div class="form-group">
                    <label>Company</label>
                    <input class="form-control" readonly type="text" name="vendorName" [(ngModel)]="users.loggedInUser.vendorName">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                      <label>Vendor #</label>
                      <input class="form-control" readonly type="text" name="vendorId" [(ngModel)]="users.loggedInUser.vendorNumber">
                    </div>
                  </div>
              </div>
             <!-- 
         <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label>Create Date</label>
              <input type="text" readonly class="form-control" name="createDate" [ngModel]="createDate">
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label>Created By</label>
                <input type="text" readonly class="form-control" name="createdBy" [ngModel]="author">
              </div>
            </div>
        </div> -->
        <div class="row">
          <div class="col-sm-12 text-right">
              <input type="hidden" name="id" [ngModel]="users.loggedInUser.id">
              <button class="btn update-btn" [disabled]="!vForm.dirty || !vForm.valid">Update Vendor Account</button>
          </div>
        </div>
      </form>
  </div>
  <div class="col-md-6 col-sm-12">
       <div class="user-search input-group" style="visibility:hidden">
          <input class="form-control" name="searchQuery" placeholder="find a user..." [(ngModel)]="searchQuery">
          <select class="input-group-append" name="searchCriteria" [(ngModel)]="searchCriteria"><option value="username">by Username</option><option value="role">by Role</option></select>
          <!-- <button class="input-group-append" (click)="searchUsers()"><i class="material-icons">search</i></button> -->
      </div>
      <div class="search-results items-list">
        <div class="row header-row">
          <div class="col-sm-5 sortable" (click)="sortUsers('userName')">Username</div><div class="col-sm-5 sortable" (click)="sortUsers('roleName')">Role(s)</div><div class="col-sm-2"></div>
        </div>
        <div class="row" *ngFor="let user of userList">
          <div class="col-sm-5 title">{{user.userName}}</div><div class="col-sm-5">{{user.roleName}}</div><div class="list-btns col-sm-2 text-right"><span *ngIf="user.roleName!=='VendorAdmin'" (click)="editUser(user.id)">Edit</span> <span class="delete" (click)="vendorDeleteUser(user,modalDelete)">Del</span></div>
      </div>
      </div>
    
      <div class="row">
        <div class="col-sm-12">
          <form #vuForm="ngForm" class="add-user-form">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Email Address</label>
                  <input class="form-control" required [disabled]="isEdit" type="text" name="userName" required [(ngModel)]="selectedUser.userName" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                  <div class="form-group">
                    <label>First Name</label>
                    <input class="form-control" type="text" name="firstName" [(ngModel)]="selectedUser.firstName">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input class="form-control" type="text" name="lastName" [(ngModel)]="selectedUser.lastName">
                    </div>
                  </div>
              </div>
            <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Role(s)</label>
                    <select required multiple class="form-control" name="rolesArr" [(ngModel)]="selectedUser.rolesArr">
                      <option *ngFor="let role of rolesList" [value]="role">{{ role }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 text-center">
                  <button class="btn add-btn" [disabled]="!vuForm.valid" *ngIf="!selectedUser.id || selectedUser.id===''" (click)="submitUser(selectedUser, modalMessage)">Add User <i class="material-icons">add_circle</i> </button><!--  -->
                  <button class="btn add-btn" *ngIf="selectedUser.id && selectedUser.id !==''" (click)="submitUser(selectedUser, modalMessage)">Modify User</button>
                  <!-- <button class="btn delete-btn" *ngIf="selectedUser.id && selectedUser.id!==''" (click)="vendorDeleteUser(selectedUser,modalDelete)">Delete User</button> -->
                  <button class="btn cancel-btn" (click)="addNewUser()">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>


<app-modal #modalDelete>
  <div class="app-modal-header">Are You Sure?</div>
  <div class="app-modal-body">
      <p>Clicking "Delete" below will remove this user from all TSC networks.</p>
  </div>
  <div class='app-modal-footer'>
    <button class="confirm-delete-btn" (click)="deleteUser(selectedUser,modalMessage);modalDelete.hide();">Delete</button><button class="cancel-btn" (click)="modalDelete.hide()">Cancel</button>
  </div>
</app-modal>

<app-modal #modalMessage>
    <div class="app-modal-header">
        {{ modalContent.header }}
    </div>
    <div class="app-modal-body">
        {{ modalContent.body }}
    </div>
    <div class='app-modal-footer'>
         {{ modalContent.footer }} 
        <button class="add-btn" (click)="modalMessage.hide()">OK</button>
    </div>
</app-modal>
