/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal fade"], ["tabindex", "-1"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "in": 0 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "display": 0, "opacity": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal fade"; var currVal_1 = _ck(_v, 2, 0, _co.visibleAnimate); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, (_co.visible ? "block" : "none"), (_co.visibleAnimate ? 1 : 0)); _ck(_v, 3, 0, currVal_2); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, {}, {}, [".app-modal-header", ".app-modal-body", ".app-modal-footer"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
