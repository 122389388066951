import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageInvitesService, VendorInviteDetail, DuplicateVendorDetail } from '../services/manage-invites.service';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';

@Component({
  selector: 'app-manage-invites',
  templateUrl: './manage-invites.component.html',
  styleUrls: ['./manage-invites.component.css']
})
export class ManageInvitesComponent implements OnInit {

  @ViewChild('modalDetails') details;  // this isn't working

  public vendorInvites: VendorInviteDetail[] = [];
  public modalContent = {
    'header': '',
    'body':'',
    'class':'',
    'footer':'',
  }
  public modalType = 'display';
  public displayRecord:VendorInviteDetail = new VendorInviteDetail();
  public doNotList = ['CompanyName','DuplicateVendors'];
  public editingName = false;
  public originalName = '';
  public editingAddress = false;
  public newAddress = {
    'addressLine1':'',
    'addressLine2':'',
    'city':'',
    'state':'',
    'zipCode':'',
    'country':''
  }
  public loading = [];
  public sortDirection = 'none';
  public allStates;
  public stateList;
  public countryList;

  constructor(
    public inv:ManageInvitesService,
  ) { }

  ngOnInit() {

    // check permissions! Only VMD users allowed
    // if not allowed, redirect to error page
    this.inv.checkPermissions();

    // this.populateTestData();
    this.getDuplicateList();
    this.manageCountries();
    

  }

  populateTestData() {
    let vendor1 = new VendorInviteDetail();
    let vendor2 = new VendorInviteDetail();
    let vendor3 = new VendorInviteDetail();
    let vendor4 = new VendorInviteDetail();

    // generate dummy data
    vendor1.CompanyName = 'Test Vendor 1';
    vendor1.Email = 'email1@address.tsc';
    vendor1.VendorType = 'Domestic';
    vendor1.BuyerID = '111';
    vendor1.TempVendorID = 'ABC123';


    vendor2.CompanyName = 'Test Vendor 2';
    vendor2.Email = 'email2@address.tsc';
    vendor2.VendorType = 'Dropship';
    vendor2.BuyerID = '116';
    vendor2.TempVendorID = 'XYZ234';


    vendor3.CompanyName = 'Test Vendor 1: A Second Location';
    vendor3.Email = 'email1@address.tsc';
    vendor3.VendorType = 'Domestic';
    vendor3.BuyerID = '111';
    vendor3.TempVendorID = 'LMNOP';


    vendor4.CompanyName = 'Test Vendor: Use The Fourth, Luke';
    vendor4.Email = 'email4@address.tsc';
    vendor4.VendorType = 'Import';
    vendor4.BuyerID = '112';
    vendor4.TempVendorID = 'KHJLA99';

  
    // populate display
    this.vendorInvites.push(vendor1);
    this.vendorInvites.push(vendor2);
    this.vendorInvites.push(vendor3);
    this.vendorInvites.push(vendor4);

    // generate duplicates
    console.log(this.vendorInvites);
    for (let v in this.vendorInvites) {
      let vendor = this.vendorInvites[v];
      console.log(vendor);
      let dupes = Math.floor(Math.random() * 10);
      vendor.DuplicateVendors = [];
      for (let r = 0;r < dupes; r++) {
        let duplicate = new DuplicateVendorDetail();
        duplicate.VendorID = Math.floor(10000 + Math.random() * 90000) + v + r;
        duplicate.CompanyName = vendor.CompanyName + v + r;
        duplicate.OnboardingStatus = +v > 2 ? 'In Progress' : 'Onboarding Complete';
        vendor.DuplicateVendors.push(duplicate);
      }
    }
  }

  getDuplicateList() {
    this.vendorInvites = [];
    this.startLoading('duplicates');
    // get the list of duplicate vendors
    this.inv.getDuplicateVendors().subscribe(
      resp => {
        console.log('get duplicates:', resp);

        // create new duplicate records
        if (resp && resp['Vendors']) {
          for (let v of resp['Vendors']) {
            let duplicate = new VendorInviteDetail();
            Object.assign(duplicate,v);
            console.log(duplicate, v);
           // add to list
           this.vendorInvites.push(duplicate);
          }
        }

        this.endLoading('duplicates');
      }, error => {
        console.log('error getting duplicates', error);
        this.endLoading('duplicates');
      }
    );
  }

  sortInvites(fieldname, e) {
    // toggle sort
    console.log(e);
    let asc = e.target.className.includes('up') ? true : false;
    this.vendorInvites = this.sortBy(this.vendorInvites, fieldname, asc);

    // reset sort class
    let sortbtns = document.getElementsByClassName('sort');
    for (let i in sortbtns) {
      sortbtns[i].className = 'sort';
    }

    // set current sort class
    let direction = "";
    if (e.target.className.includes('up')) {
      direction = ' down';
    } else if (e.target.className.includes('down')) {
      direction = '';
    } else {
      direction = ' up';
    }
    e.target.nativeElement.className = 'sort' + direction;


  }

  showDetails(id,type?) {
    // assign class based on display type
    if (type) {
      if (type == 'reject') {
        this.modalContent.class = 'danger';
        this.modalContent.header = 'Reject Duplicate Vendor Name';
        this.modalContent.body = '';
      } else {
        this.modalContent.class = 'success';
        this.modalContent.header = 'Accept Duplicate Vendor Name';
        this.modalContent.body = '';
      }
    } else {
      this.modalContent.class = '';
      this.modalContent.header = 'Request Details';
      this.modalContent.body = '';
    }

    // get details from record
    this.displayRecord = this.vendorInvites[id];
    for(let d in this.displayRecord.DuplicateVendors){
      if(this.displayRecord.DuplicateVendors[d]['Zip']){
      this.displayRecord.DuplicateVendors[d]['Zip'] = this.displayRecord.DuplicateVendors[d]['Zip'].substring(0,5);
      }

      if(this.displayRecord.DuplicateVendors[d]['AddressLine2']){
        this.displayRecord.DuplicateVendors[d]['AddressLine1'] = this.displayRecord.DuplicateVendors[d]['AddressLine1'] + this.displayRecord.DuplicateVendors[d]['AddressLine2']
      }
    }
    this.displayRecord.VMDComments = '';
    this.modalType = type || 'display';
    this.editingName = false;
    this.originalName = ''+this.displayRecord.CompanyName;
    this.details.show();

  }

  hideDetails(modal) {
    // clear selection
    this.displayRecord = new VendorInviteDetail();
    this.originalName = '';
    this.modalType = 'display';
    this.endLoading('savereq');
    this.stopEditing();

    // close modal
    modal.hide();
  }

  editCompanyName(value) {
    if (value && value == 'stop') {
      this.editingName = false;
    } else {
      this.editingName = true;
    }
  }

  finishEditingName(e) {
    console.log('finish editing triggered');
    // on blur or other event, update name
    this.editingName = false;
    const max = '96';
    console.log(e.target.value, this.displayRecord.CompanyName);
    if (e && e.target && e.target.value && e.target.value!='') {
      // perform name validation
      let newName = e.target.value.trim();
      // remove subsequent whitespace, filter whitelist characters
      newName = newName.replace(/\s+/g, ' ').replace(/[^[\]\w \-~!@#$%&*(){}+=:;"'?\\/><,.]/g, '');
      // trim to max length
      newName = newName.length > max ? newName.substring(0,max) : newName;
      // assign to 
      this.displayRecord.CompanyName = newName; 
      console.log('renamed vendor: ', this.originalName, this.displayRecord.CompanyName);
    }
  }

  stopEditing() {
    this.editingName = false;
    this.editingAddress = false;
    Object.assign(this.newAddress,{'addressLine1':'', 'addressLine2':'', 'city': '','state': '', 'zipCode': '', 'country': ''});
  }

  editAddress() {
    let r = this.displayRecord;
    this.setCountry(r.country);
    Object.assign(this.newAddress,{'addressLine1': r.addressLine1, 'addressLine2': r.addressLine2, 'city': r.city,'state': r.state, 'zipCode': r.zipCode, 'country': r.country});
    this.editingAddress = true;
  }

  finishEditingAddress() {
    // update the address
    let n = this.newAddress;
    Object.assign(this.displayRecord,{'addressLine1': n.addressLine1, 'addressLine2': n.addressLine2, 'city': n.city,'state': n.state, 'zipCode': n.zipCode, 'country': n.country});
    console.log(this.displayRecord);
    this.stopEditing();
  }



  listKey(item) {
    // compare to list of ignored/hidden keys
    if (this.doNotList.indexOf(item.key)>-1) {
      return false;
    }

    // compare to dynamic keys
    switch(item.key) {
      case '':

      break;
    } 

    return true;
  }

  sortBy(column, e, array?) {
    // define array
    if(!array) {
      array = this.vendorInvites;
    }

    // select field for sorting
    array.sort((a,b)=>a[column] > b[column] ? 1 : -1);
      // handle text vs numeric sort
      

    // initalize sort display
    let arrows = document.querySelectorAll('.sort');
    for (let a of Array.from(arrows)) {
      a.className = 'sort';
    }

    // sort direction
    let arrow = e.target.children[0];
    switch(this.sortDirection) {
      case 'asc':
        this.sortDirection = 'desc';
        array.reverse();
        arrow.className = 'sort down';
      break;
      default:
        this.sortDirection = 'asc';
        arrow.className = 'sort up';
      break;
    }

    // result
    return array;
  }

  updateReason(e) {
    if (e.target.value) {
      this.displayRecord.VMDComments = e.target.value;
    }
  }

  allowSubmit() {
    let commentsComplete = false;
    let vendorName = false;
    if (this.displayRecord.VMDComments && this.displayRecord.VMDComments!='') {
      commentsComplete = true;
    }
    if (!this.editingName) {
      vendorName = true;
    }

    return commentsComplete && vendorName;
  }

  validateRequest(type, modal, e) {
    e.preventDefault();
    // accept company name as entered, no validation
    const isAccept = type =='accept' ? true : false;

    console.log('Details modal', this.details);

    let reqJSON = {
      'TempVendorID':this.displayRecord.TempVendorID,
      'CompanyName': this.displayRecord.CompanyName,
      'IsVMDApproved': isAccept ? 'Y':'N',
      'VMDComments': this.displayRecord.VMDComments
    };

    if (this.displayRecord.isAdditionalVendor == 'Y') {
       reqJSON['AddressLine1'] = this.displayRecord.addressLine1;
       reqJSON['AddressLine2'] = this.displayRecord.addressLine2;
       reqJSON['City'] = this.displayRecord.city;
       reqJSON['State'] = this.displayRecord.state;
       reqJSON['Country'] = this.displayRecord.country;
       reqJSON['Zip'] = this.displayRecord.zipCode;
    }

    if(!isAccept) {
      reqJSON.CompanyName = this.originalName;
      console.log('reject, so use original name', this.originalName, reqJSON);
    }

    // check for required comments
    if (this.displayRecord.VMDComments !== '') {
      this.startLoading('savereq');
      this.inv.postApprovalStatus(reqJSON).subscribe(
        data => {
          // success response?
          console.log('response data', data);
          this.details.hide();
          this.endLoading('savereq');
          this.modalContent.class = 'success';
          this.modalContent.header = 'Completed';
          this.modalContent.body = 'The ' + (isAccept ? 'approval' : 'rejection') + ' has been processed.';
          this.modalContent.footer = '';
          if (data == 'Error') {
            this.modalContent.class = 'danger';
            this.modalContent.header = 'Error';
            this.modalContent.body = 'There was a problem ' + (isAccept ? 'approving' : 'rejecting') + ' the duplicate vendor.';
            this.modalContent.footer = '';
          } else {
            // success, reload the list
            this.getDuplicateList();
          }
          modal.show();
        }, error => {
          this.details.hide();
          this.endLoading('savereq');
          // error response
          console.log(error);
          this.modalContent.class = 'danger';
          this.modalContent.header = 'Error';
          this.modalContent.body = 'There was a problem ' + (isAccept ? 'approving' : 'rejecting') + ' the duplicate vendor.';
          this.modalContent.footer = error.status + ': ' + error.message;
          modal.show();

        }
      )
    } else {
      // don't submit, fix error first
      console.log('Need reason');
      this.modalContent.class = 'danger';
      this.modalContent.header = 'Validation Error';
      this.modalContent.body = 'You must add a reason.';
      this.modalContent.footer = '';
      modal.show();
    } 

  }



  // // // // // // // // // // // // // // // // // //

  startLoading(section) {
    if (this.loading.indexOf(section) == -1) {
      this.loading.push(section); // add to loading array
    }
  }

  endLoading(section) {
    const idx = this.loading.indexOf(section);
    if (idx > -1) {
      this.loading.splice(idx,1); // remove from loading array
    }
    console.log(this.loading, idx, section);
  }

  isLoading(section) {
    if (this.loading.indexOf(section) > -1) {
      return true;
    }
    return false;
  }

  manageCountries() {
    // retrieve country and state lists from JSON in assets folder
    this.allStates = (states as any).default;
    this.countryList = (countries as any).default;

    // sort country list TIBCO-3702
    // sort alphabetically by Name
    this.countryList.sort((a,b) => a.NAME.localeCompare(b.NAME));

    // move usa to front
    const usIdx = this.countryList.findIndex(x => x.NAME === 'USA');
    if (usIdx > -1) {
    const usa = this.countryList.splice(usIdx,1);
    if (usa[0]) {this.countryList.unshift(usa[0]);}
    }

  }

  setCountry(abbr){
    // console.log(abbr);
    this.newAddress.country = abbr; // event returns full name, looks up abbr in countryList
    // try full name
    let currCountry = this.countryList.find(c => c.NAME === abbr);
    if (currCountry) {
      abbr = currCountry.ABBR;
    }
    // get the states
      this.stateList = this.allStates.filter(s => s.COUNTRY === abbr);
      this.newAddress.state = ''; // clear state
    // console.log(this.stateList, abbr);
  }

}




/*
Vendor Name (Editable)
User Email Address
First Name
Last Name
Is this Existing Placeholder Vendor?
Vendor Type
Buyer ID
Buyer Reason provided above when proceeded with Y.
Options provided by Buyer based on Vendor Type selection.
                For Domestic:

                                Is this vendor Scan-based:            Yes/No

                                Is this vendor a test vendor :        Yes/No

                                Is this vendor VMI :          Yes/No

                For Dropship:

                                Does the vendor have online RTV exceptions ?     Yes/No

                For Import:

                                Sourcing Agent ( If Selected)

                For Hay Forage:

                                Does this need to be setup for EDI ?         Yes/No

                For PlaceHolder-Domestic: 

                               Freight Terms and Lead Time : FPV/FVT

                               Address

                               Product Content Contact

                               Buyer's Vendor Contact

                For PlaceHolder-Import: 

                              Sourcing Agent (Select "No Agent" if a sourcing agent is not required)

                              Is this vendor supplying exclusive brands? YES/NO

                              Expected Order Date

                              Freight Terms and Lead Time: FOB     

                              Address

                              Product Content Contact

                              Buyer's Vendor Contact

        10. Include All the Matching Company Names and their VendorIDs that were identified as part of Duplicate check.

Approve and Reject options to be available for the VMD Admin.  

VMD should have the ability to edit the Vendor Name above and Approve the record for further processing to start Vendor onboarding. 
Approval reason should be captured upon Approval by VMD Admin and the comments need to be stored in Staging table as VMD Comments.   

If VMD Admin clicks on Reject, a Reject reason should be captured and store across the record in Staging table as VMD Comments. 
No Information submitted by the buyer should be updated in Staging table for the Rejected record.

*/