import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
// import { RestService } from './rest.service';
import { UserService } from './user.service';
import { GoogleAnalyticsService } from './analytics.service';
import { map, mergeMap, concatMap } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { Address } from './../vendor-dashboard/address-section/address';

@Injectable()
export class AddressService {

  private api = environment.api;

constructor(
    // private rest: RestService,
    private http: Http,
    private user: UserService,
    private ga: GoogleAnalyticsService,
  ) { }


verifyAddress(address):Promise<Address>{
            const appId = environment.appid;
            const url = 'getAddressVerification';
            let headers = new Headers();
            headers.append('Content-Type','application/json');
            headers.append('Authorization', this.user.getHeader());
            let requestJson = {
                  "avsRequest": {
                          "address1": address.address1,
                          "address2": address.address2,
                          "city": address.city,
                          "stateOrProvinceName": address.state,
                          "country": address.country && address.country !== "USA" ? address.country :"US",
                          "postalCode": address.zipcode
                      }
                };
              return this.http.get(this.api + 'getAIToken', {headers: headers}).pipe(concatMap(
              data => this.aiReq(url, JSON.stringify(requestJson),  "Bearer " +data.json().jwt).pipe(map(
              response => {
                let responseJson = response.json();
                if (responseJson && responseJson.avsResponse && responseJson.avsResponse.decision === "ACCEPT"){
                   if (responseJson.avsResponse.suggestedAddress && responseJson.avsResponse.suggestedAddress.address1){
                      let suggestedAddress = {} as Address;
                      suggestedAddress.address1 = responseJson.avsResponse.suggestedAddress.address1;
                      suggestedAddress.address2 = responseJson.avsResponse.suggestedAddress.address2;
                      suggestedAddress.city = responseJson.avsResponse.suggestedAddress.city;
                      suggestedAddress.state = responseJson.avsResponse.suggestedAddress.state;
                      suggestedAddress.country = responseJson.avsResponse.suggestedAddress.country;
                      suggestedAddress.zipcode = responseJson.avsResponse.suggestedAddress.zipCode;
                      /*
                      if (address.title === "Shipper Address"){
                        suggestedAddress.title = "Suggested Shipper Address";
                      } else if (address.title === "Remittance Address"){
                        suggestedAddress.title = "Suggested Remittance Address";
                      } else if (address.title === "Corporate Address"){
                        suggestedAddress.title = "Suggested Corporate Address";
                      } else if (address.title === "Return Goods Address"){
                        suggestedAddress.title = "Suggested Return Goods Address";
                      } */
                      if (address.title.indexOf('Suggested') === -1) {
                        suggestedAddress.title = 'Suggested ' + address.title; 
                      }
                      return suggestedAddress;
                    } else {
                      return address;
                    }
                } else {
                  return null;
                }
              }, error => {
                console.log('error while verifying address', error);
                this.ga.sendEvent('Address Validation', 'Error refreshing token', error.message, {'time_stamp':Date.now()});
                return null;
              }
            ))
            )).toPromise();
          }

  aiReq (url, json, AIToken) {
    let AIheaders = new Headers();
    let authToken = this.user.getHeader();
    AIheaders.append('Content-Type','application/json');
    AIheaders.append('Authorization', authToken);
    AIheaders.append('AIToken', AIToken);

    return this.http.post(this.api + url, json, {headers: AIheaders}).pipe(map(
      data => {
        console.log('Address Validation success', data);
        // this.ga.sendEvent('Address Validation', 'Validation Successful', , {'time_stamp':Date.now()});
        return data;

      }, error => {
        console.log('address validation error ', error);
        this.ga.track('/VendorPortal/Error/' + url);
        this.ga.sendEvent('Address Validation', 'Validation Service Error', error.message, {'time_stamp':Date.now()});
        return error;
      }
    ));

  }

}
