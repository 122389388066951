import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { User, UserService } from './../services/user.service';
// import { DashboardService } from './../vendor-dashboard/services/dashboard.service';
import { VendorApiService } from './../vendor-services/vendor-api.service';

import { Router } from '@angular/router';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { VendorInfo } from '../vac/vac.component';
import { VacService } from '../services/vac.service';
import { GoogleAnalyticsService } from '../services/analytics.service';
import { VendorDataModel, VolumeRebateDataModel, VolumeRebateInfo, VolumeRebateVoidRequest } from './model/volume-rebate-info.model';
import { map, startWith } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VolumeRebateSearchComponent } from './volume-rebate-search/volume-rebate-search.component';
import { BuyerInfo } from './model/vac-buyer.model';
import { RestService } from '../services/rest.service';

@Component({
  selector: 'app-volume-rebate',
  templateUrl: './volume-rebate.component.html',
  styleUrls: ['./volume-rebate.component.css']
})
export class VolumeRebateComponent implements OnInit {
  @ViewChild('modalMessage') modalMessage;
  @ViewChild('modalSearch') modalSearch;
  volRebateForm: FormGroup;
  vendorNumberData: any[] = [];
  allVendors:any;
  currentUser = new User();
  rebateVendorInfo: VolumeRebateInfo= {
    
    VendorData: null,
    VolumeRebate:null,
  }
  selectedVendor = {
    'VendorID': '',
    'vendorName': '',
    'vendorType': '',
    'childVendors': '',
    'contactFirstName': '',
    'contactLastName': '',
    'contactEmail': '',
    'contactPhone': '',
    'contactFax': '',
    'buyerID': '',
    'buyerName': ''
  };
  today = new Date(Date.now()).toISOString().split('T')[0];
  isLoading = false;
  rebateerr = [];
  initialRebateLevel = '0';
  modalContent = {
    'header':'',
    'body':'',
    'footer':''
  };
  isSubmitting = false;
  // new changes 
  public selectedVendorID='';
  public vendorIsValid = true;
  public vendorMsg = '';
  //public allowedVendorTypes = ['Dropship','Petsense','Domestic','PTSDistributor','PTSManufacturer','Import'];
  public allowedVendorTypes = ['Domestic','Import'];
  public sourcingType = ['All', 'Domestic', 'Import'];
  public buyerCompany = 'TSC';
  //public sourcingType: 
  public showRPLevel2:boolean = false;
  public showRPLevel3:boolean = false;
  public showRPLevel4:boolean = false;
  public removeBtnInactive: boolean = true;
  public addBtnInactive: boolean = true;
  public effectiveYearDisable: boolean = true;
  years:number[] =[];
  filteredYears: Observable<number[]>;
  public vacBuyers:BuyerInfo[]=[];
  public selectedBuyerID = '';
  public selectedBuyer;
  public existingVolumeRebate:VolumeRebateDataModel[]=[];
  public searchMsg = 'This vendor already has volume rebates in effect for the selected criteria.<br />Please select a volume rebate below to void before creating a new one.'
  public searchClass = 'alert-danger';
  public showSearchMsg = false;
  public isSearching = false;
  public searchResults = [];
  public resultsMsg = '';
  public voidReason = '';
  public otherReason = '';
  public showOtherReason = false;
  public showVoidWarning = false;
  public isVendorIdDisabled = true;
  public showSubmitErrorMsgforInProgressVR = false;
  public voidVendorID ='';
  public searchType = 'Vendor';
  public isClosing = false;


  @ViewChild('buyerSelection') buyerSelectionInput:ElementRef;
  constructor(
                private user: UserService,
                private api: VendorApiService,
                private fb: FormBuilder,
                private router: Router,
                public vac: VacService,
                private ga: GoogleAnalyticsService,
                // private dash: DashboardService,
                private restApi: RestService,
                private currencyPipe: CurrencyPipe,
                private matDialog: MatDialog,
                public componentFactoryResolver:ComponentFactoryResolver
  ) { }

  ngOnInit() {
    
    this.currentUser = this.user.loggedInUser || this.user.getUser();
    console.log('logged in user', this.currentUser);
    console.log("VAC BUYER=>", this.vac.vacBuyer);
    if(this.user.getUserRole() === 'TSC_Buyer'){
      this.buyerCompany='TSC';
      
    }else if(this.user.getUserRole() === 'PTS_Buyer'){
      this.buyerCompany='PETSENSE';
      
    }
    this.createForm(); // initialize form
    /*
    this.vacBuyers = this.vac.vacBuyer;
    if(this.vac.vacBuyer.length >0){
      this.vacBuyers = this.vac.vacBuyer
    }else {
      this.vac.initializeVAC();
      this.vacBuyers = this.vac.vacBuyer;
      console.log("New vac buyers:", this.vacBuyers);
    }
    */
   this.vac.getBuyerInfo().subscribe((buyersList) => {
    console.log("Buyers list:",buyersList);
    if(buyersList.length >0){
      for(let buyer of buyersList){
        let dup = this.vacBuyers.find(b => b.Email === buyer.Email && b.BuyerID === buyer.BuyerID);
        if(!dup){
        if (
          (this.user.getUserRole() === 'PTS_Buyer' && buyer.VendorType.indexOf('PTS') > -1) ||
          (this.user.getUserRole() !== 'PTS_Buyer' && buyer.VendorType.indexOf('PTS') === -1)
          ) {
              this.vacBuyers.push(buyer);
            }

          }

      }
    }

   })

    //  this.vac.getBuyerInfo().subscribe( (buyerData) =>{
    //   console.log('username:', this.user.loggedInUser);
    //     console.log('buyerlist', buyerData);
    //     this.vacBuyers = buyerData;

    //  })
    // we'll probably use the sc contact instead... if we have that info
   
    // this.setAddVendorTypeForUser(); // quick request to populate dropdown

    // getonboarding data with children
    // spinner for select vendor
    // temporary comment
    /*
    this.isLoading = true;

     const parentReq = {
      'Email': this.currentUser.userName,
      'VendorInfo': 'Y',
     };
     let childReq = {
        'VendorID': '',
        'VendorInfo': 'Y',
        'ChildVendor': 'Y'
     };

      this.api.requestData(parentReq).subscribe(
      (data: any) => {
        // console.log('returned vendor data', data);
        this.allVendors = data.Data;
          // now get child info for each parent
          for (let vendor of this.allVendors) {
            vendor.ContactEmail = parentReq.Email;
            vendor.CompanyName = this.getFieldByKey(vendor, 'COMPANY_NAME');
            vendor.VendorType = this.getFieldByKey(vendor, 'VENDOR_TYPE');
            vendor.BuyerID = this.getFieldByKey(vendor, 'BUYERID');
            vendor.BuyerName = this.getFieldByKey(vendor, 'BUYER_FIRST_NAME') + ' ' + this.getFieldByKey(vendor, 'BUYER_LAST_NAME');
            childReq.VendorID = vendor.VendorID;
            console.log('updated vendor record', vendor);
            this.getChildren(childReq);
          }

      }, error => {
        console.log(error);
        this.isLoading = false;
      }
    );
    */
       

   

    // meeting notes
    // set flag for child vendor? allow child, get from child call same as dashboard
    // on submit, modal for success, get buyer alignment message from business
    // getonboardingdata api, child vendors Y
    // buyer info in Corporate Info tab
    // DMM handled in BW service
    // prepopulate all fields from onboarding data
    // send flag if percentage is reduced only, compare to original. RebatePercentageReduced Y or N
    // is recurrring, Y or show year field on N
    // estimated volume rebate is read only, add estimated receipts * percentage
    // 

    

   this.formValueChanges();

   this.setValidYears();


  }

  formValueChanges(){
    /*
    this.volRebateForm.valueChanges.subscribe( val =>{
      console.log("FORM value:", val);
      //if(val.VALID_YEAR)
    })
    */
    // this.volRebateForm.get('VALID_YEAR').valueChanges.subscribe( val =>{
    //   if(val.length== 4){
    //     const currentYear = new Date().getFullYear();
    //   if(val<=currentYear){
    //     alert("Value should be greate then current year..");
    //   }
    //   }
      
    // });

    
  }

  private setValidYears(){
    this.years =[];
    const year = (new Date().getFullYear());
    for(let i=1; i<=5; i++){
         this.years.push(year+i);
    }
    this.filteredYears =this.volRebateForm.get('VALID_YEAR').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    
  }

  private _filter(value:number):number[]{
    return this.years.filter(year => {
     // console.log(year.toString().includes(value.toString()), year);
       // return year===value
     return   year.toString().includes(value.toString())
    });
   
  }
  
  // new changes starts 
  checkVendor(id) {
    console.log('check vendor ' + id,this.selectedBuyerID);
    if(!this.selectedBuyerID || this.selectedBuyerID=== ''){
     this.buyerSelectionInput.nativeElement.focus();
    }
    else if(id.length !== 6) {
      this.vendorIsValid = false;
      this.vendorMsg = 'Please enter a valid vendor ID';
      this.rebateVendorInfo.VendorData ={};
      this.rebateVendorInfo.VolumeRebate ={};
      this.selectedVendorID='';
    } else {
      // check vendor info
      this.selectedVendorID = id;
      this.vac.retrieveVendorInformation(id).subscribe(
        vendor => {
          console.log("vendor info response:",vendor);
          // Look for any existing Volume Rebate 
          if(vendor && vendor.VolumeRebate){
            if(vendor.VolumeRebate.VRData && vendor.VolumeRebate.VRData.length >0){
              this.rebateVendorInfo.VolumeRebate =vendor.VolumeRebate;
              this.existingVolumeRebate = this.rebateVendorInfo.VolumeRebate.VRData;
              
             // this.openVolumeRebatePopup();
            }else {
              this.rebateVendorInfo.VolumeRebate = {};
              this.existingVolumeRebate = [];
            }
            
          }
         
          if(vendor && vendor.VendorData && Object.keys(vendor.VendorData).length > 0){
           // let v = vendor.VendorData[0];
           let v = vendor.VendorData;
           console.log("V=>",v);
            if (v && v.VENDOR_ID === id && this.isAllowedVendor(v)) { 
            
              this.vendorIsValid = true;
              this.vendorMsg = v.CompanyName ; //vendor name
              //this.selectedVendor = v;
              this.changeSearchType('Vendor');
              this.rebateVendorInfo.VendorData = vendor.VendorData;
             // this.rebateVendorInfo.VendorData.VENDOR_CONTACT = this.rebateVendorInfo.VendorData.SC_EMAIL;
              Object.keys(this.volRebateForm.controls).forEach(key => {
               // let val = this.getFieldByKey(vendorTabs,key);
                 // console.log('assign to form', key);
                if(key !== 'VOLUME_REBATE_PERCENT'){
                let val = this.getFormValueByKey(this.rebateVendorInfo.VendorData,key);
                if (val && val !== '') {
                  // console.log("Updating form value for ",key , val);
                  this.volRebateForm.controls[key].patchValue(val);
                }
                }
                
              });
             /*
              if(this.volRebateForm.get('VOLUME_REBATE_PERCENT') && this.volRebateForm.get('VOLUME_REBATE_PERCENT').value !=='') {
                this.initialRebateLevel = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
              }
              */
      
              // handle agreement term
              // if (this.volRebateForm.get('IS_RECURRING') && this.volRebateForm.get('IS_RECURRING').value === 'Y') {
              //   this.volRebateForm.controls['agreementTerm'].patchValue('Recurring');
              // }

              this.ga.sendEvent('Volume Rebate', 'Vendor Data loaded successfully', 'Vendor ' + id + ' for Buyer ' + this.selectedBuyerID , {'time_stamp':Date.now(), 'record_id':id});

              // reset VR search
              this.showSubmitErrorMsgforInProgressVR = false;
              this.showSearchMsg = false;

              console.log(" Vendor information:", this.rebateVendorInfo);
             // this.showPromoTypes(this.allPromos);

            } else {
              this.vendorIsValid = false;
              this.vendorMsg = 'Please enter a valid vendor ID';
              this.vendorIsValid = true;
             // this.selectedVendor = new VendorInfo();
             this.rebateVendorInfo.VendorData ={};
             this.rebateVendorInfo.VolumeRebate ={};
             this.ga.sendEvent('Volume Rebate', 'Invalid Vendor ID', 'Vendor ' + id + ' for Buyer ' + this.selectedBuyerID , {'time_stamp':Date.now(), 'record_id':id});
            // this.volRebateForm.reset();
            }
          } else {
              this.vendorIsValid = false;
              this.vendorMsg = vendor.APIResponse=='Record not found'?'Please enter a valid vendor ID':vendor.APIResponse;
             // this.selectedVendor = new VendorInfo();
             this.rebateVendorInfo.VendorData ={};
             this.rebateVendorInfo.VolumeRebate ={};
             this.ga.sendEvent('Volume Rebate', 'Vendor Data not found', 'Vendor ' + id + ' for Buyer ' + this.selectedBuyerID , {'time_stamp':Date.now(),'record_id':id});
            // this.volRebateForm.reset();
          }
        }, error => {
          console.log(error);
          this.vendorIsValid = false;
          this.vendorMsg = 'Please enter a valid vendor ID';
          this.ga.sendEvent('Volume Rebate', 'Error requesting Vendor data', 'Vendor ' + id + ' for Buyer ' + this.selectedBuyerID , {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':id});
          
          //this.volRebateForm.reset();
          //this.selectedVendor = new VendorInfo();
        }
      );


    }
  }
  onRebateLevelChangeAction(value){
    // console.log("ADDORREMOVE", value);
    if(value === 'add'){
      // show level2 or level 3
      if(!this.showRPLevel2){
        this.showRPLevel2 = true;
        const level1To = this.volRebateForm.get('MLP_TIER_1_TO').value.replace(/[^0-9\.]+/g, "");
        console.log("level1To=",level1To);
        const formattedLevel2From = (Number(level1To)+1).toLocaleString(undefined, {maximumFractionDigits:0});
        // this.volRebateForm.get('MLP_TIER_2_FROM').patchValue(Number(level1To)+1);
        this.volRebateForm.get('MLP_TIER_2_FROM').patchValue(formattedLevel2From);
      }else if(!this.showRPLevel3){
        this.showRPLevel3 = true;
        const level2To = this.volRebateForm.get('MLP_TIER_2_TO').value.replace(/[^0-9\.]+/g, "");
        console.log("level2To=",level2To);
        const formattedLevel3From = (Number(level2To)+1).toLocaleString(undefined, {maximumFractionDigits:0});
        this.volRebateForm.get('MLP_TIER_3_FROM').patchValue(formattedLevel3From);
      }else{
        this.showRPLevel4 = true;
        const level3To = this.volRebateForm.get('MLP_TIER_3_TO').value.replace(/[^0-9\.]+/g, "");
        console.log("level3To=",level3To);
        const formattedLevel4From = (Number(level3To)+1).toLocaleString(undefined, {maximumFractionDigits:0});
        this.volRebateForm.get('MLP_TIER_4_FROM').patchValue(formattedLevel4From);
      }
      this.addBtnInactive = true;
    }
    if(value==='remove'){
      // hide either level 2 or level 3 or level 4
      if(this.showRPLevel4 === true){
        this.rebateerr[4]="";
        this.volRebateForm.controls['MLP_TIER_4_FROM'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_4_TO'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_4_PERCENTAGE'].patchValue('');

        this.showRPLevel4 = false;
      }
      else if(this.showRPLevel3 === true){
        this.rebateerr[3]="";
        this.volRebateForm.controls['MLP_TIER_3_FROM'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_3_TO'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_3_PERCENTAGE'].patchValue('');
        this.showRPLevel3 = false;
        
      }else if(this.showRPLevel2 === true){
        this.rebateerr[2]="";
        this.volRebateForm.controls['MLP_TIER_2_FROM'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_2_TO'].patchValue('');
        this.volRebateForm.controls['MLP_TIER_2_PERCENTAGE'].patchValue('');
        this.showRPLevel2 = false;
      }
      const errorDoesNotExist:boolean = this.rebateerr.every( value => value ===''||value ===null);
      console.log("Error not exist during remove button:",errorDoesNotExist, this.rebateerr,+this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value, Number(this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value).toFixed(2));
       this.addBtnInactive = errorDoesNotExist?false: true;
    }
    /*
    if(this.showRPLevel2 && this.showRPLevel3){
      this.removeBtnInactive = false;
    }else if(this.showRPLevel2 || this.showRPLevel3) {
      this.removeBtnInactive = false;
    }else {
      this.removeBtnInactive = true;
    }
    */
    this.removeBtnInactive = !(this.showRPLevel2 || this.showRPLevel3 || this.showRPLevel4);
   // this.addBtnInactive = this.showRPLevel4? true:false;
  }
  rebatePercentageChange(level:number, $event?:any){
    
    switch(level){
      case 1: 
      if(this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value){
        const formatedValue = Number(this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value).toFixed(2);
        this.volRebateForm.controls['MLP_TIER_1_PERCENTAGE'].patchValue(formatedValue);
      }
      
      if(+this.volRebateForm.get('MLP_TIER_1_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value > 0){
         if(+this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value < +this.volRebateForm.get('VOLUME_REBATE_PERCENT').value){
          this.rebateerr[level]='Level ' + level + " rebate % Can't be lower than main volume rebate %";
          this.volRebateForm.controls['MLP_TIER_'+level+"_PERCENTAGE"].setErrors({'invalid':true});
         }else {
            if(this.rebateerr[level].indexOf('lower than main volume') > -1){
              this.rebateerr[level]='';
            }
         }
         const errorDoesNotExist:boolean = this.rebateerr.every( value => value ===''||value ===null);
         this.addBtnInactive = errorDoesNotExist?false: true;
      }
      break;
      case 2: 
      if(this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value){
        const formatedValue = Number(this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value).toFixed(2);
        this.volRebateForm.controls['MLP_TIER_2_PERCENTAGE'].patchValue(formatedValue);
      }
      if( +this.volRebateForm.get('MLP_TIER_2_TO').value.replace(/[^0-9\.]+/g, "") > 0 && +this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value > 0){
        if(+this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_1_PERCENTAGE').value){
          this.rebateerr[level]='Level ' + level + " rebate % Can't be lower than the level 1 rebate %";
          this.volRebateForm.controls['MLP_TIER_'+level+"_PERCENTAGE"].setErrors({'invalid':true});
         } else {
          if(this.rebateerr[level].indexOf('lower than the level 1') > -1){
            this.rebateerr[level]='';
          }
          
         }
         const errorDoesNotExist:boolean = this.rebateerr.every( value => value ===''||value ===null);
         this.addBtnInactive = errorDoesNotExist?false: true;
      }
      break;
      case 3: 
      if(this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value){
        const formatedValue = Number(this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value).toFixed(2);
        this.volRebateForm.controls['MLP_TIER_3_PERCENTAGE'].patchValue(formatedValue);
      }
      if(+this.volRebateForm.get('MLP_TIER_3_TO').value.replace(/[^0-9\.]+/g, "") >0 && +this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value > 0){
        if(+this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_2_PERCENTAGE').value){
          this.rebateerr[level]='Level ' + level + " rebate % Can't be lower than level 2 rebate %";
          this.volRebateForm.controls['MLP_TIER_'+level+"_PERCENTAGE"].setErrors({'invalid':true});
         } else {
          if(this.rebateerr[level].indexOf('lower than level 2') > -1){
            this.rebateerr[level]='';
          }
         }
         const errorDoesNotExist:boolean = this.rebateerr.every( value => value ===''||value ===null);
        this.addBtnInactive = errorDoesNotExist?false: true;
      }
      break;
      case 4: 
      if(this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value){
        const formatedValue = Number(this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value).toFixed(2);
        this.volRebateForm.controls['MLP_TIER_4_PERCENTAGE'].patchValue(formatedValue);
      }
      if(+this.volRebateForm.get('MLP_TIER_4_TO').value.replace(/[^0-9\.]+/g, "") >0 && +this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value > 0){
        if(+this.volRebateForm.get('MLP_TIER_4_PERCENTAGE').value < +this.volRebateForm.get('MLP_TIER_3_PERCENTAGE').value){
          this.rebateerr[level]='Level ' + level + "  rebate % Can't be lower than level 3 rebate %";
          this.volRebateForm.controls['MLP_TIER_'+level+"_PERCENTAGE"].setErrors({'invalid':true});
         } else {
          if(this.rebateerr[level].indexOf('lower than level 3') > -1){
            this.rebateerr[level]='';
          }
         }
         const errorDoesNotExist:boolean = this.rebateerr.every( value => value ===''||value ===null);
        this.addBtnInactive = errorDoesNotExist?false: true;
      }
      break;
      default:
        this.addBtnInactive = true;
             
            
             
    }
    // each row, previous to must not be blank if rebate is present
    if (+level > 1) {
      console.log(level, this.volRebateForm.controls['MLP_TIER_'+(+level-1)+'_TO'], this.volRebateForm.controls['MLP_TIER_'+(+level)+'_PERCENTAGE'] );
      if (this.volRebateForm.controls['MLP_TIER_'+(+level-1)+'_TO'].value == '' && 
      this.volRebateForm.controls['MLP_TIER_'+(+level)+'_PERCENTAGE'].value != '') {
        this.rebateerr[level]='Level ' + (+level-1) + " to can't be empty, add a value or remove row " + level;
        this.volRebateForm.controls['MLP_TIER_'+(+level-1)+'_TO'].setErrors({'invalid':true});
      }
    }

  }
  isAllowedVendor(vendor) {
    if (
      vendor.IS_VENDOR_ACTIVE!=='N' &&
      this.allowedVendorTypes.indexOf(vendor.VENDOR_TYPE) > -1
    ) 
    {
      return true;
    } else {
      return false;
    }
  }

  createForm(){
     // build form to match contract
     const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$";
     this.volRebateForm = this.fb.group({
      // SC_EMAIL: [this.currentUser.userName,[ Validators.email ]],
      // SC_FIRST_NAME: this.currentUser.firstName,
      // SC_LAST_NAME: this.currentUser.lastName,
      SC_EMAIL: ['',[ Validators.email ]],
      SC_FIRST_NAME: '',
      SC_LAST_NAME: '',
      BUYERID: ['',[Validators.required]],
      VENDOR_ID: '',
      PARENTID:'',
      VENDOR_TYPE:'',
      SOURCING_TYPE: ['All'],
      COMPANY_NAME: '',
      //VENDOR_CONTACT:['',[ Validators.email ]],
      agreementTerm: 'Recurring',
      IS_RECURRING: '',
      VALID_YEAR: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      VOLUME_REBATE_PERCENT: this.setDefaultPercent(2),
      VOLUME_REBATE_THRESHOLD:['',[Validators.required]],
      // SPECIAL_INSTRUCTIONS: '',
      MLP_TIER_1_FROM:'1',
      MLP_TIER_2_FROM:'',
      MLP_TIER_3_FROM:'',
      MLP_TIER_4_FROM:'',
      MLP_TIER_1_TO:['',[Validators.required]],
      MLP_TIER_2_TO:'',
      MLP_TIER_3_TO:'',
      MLP_TIER_4_TO:'',
      MLP_TIER_1_PERCENTAGE: ['',[Validators.required]],
      MLP_TIER_2_PERCENTAGE: '',
      MLP_TIER_3_PERCENTAGE: '',
      MLP_TIER_4_PERCENTAGE: '',
     // MULTI_LEVEL_PAYOUT1: '',
     // MULTI_LEVEL_PAYOUT2: '',
     // MULTI_LEVEL_PAYOUT3: '',
     });
     this.setAgreementTerm();
     this.volRebateForm.controls['VENDOR_ID'].disable();
     this.isVendorIdDisabled = true;

     /*
     this.volRebateForm = this.fb.group({
      SC_EMAIL: this.currentUser.userName,
      firstname: this.currentUser.firstName,
      lastname: this.currentUser.lastName,
      VENDOR_ID: '',
      VENDOR_TYPE: '',
      COMPANY_NAME: '',
      SC_PHONE: '',
      faxnumber: '',
      BUYER_ID: '',
      EFFECTIVEDATE: '',
      agreementTerm: '',
      IS_RECURRING: '',
      VALID_YEAR: '',
      PROJECTED_RECEIPTS: '',
      VOL_REBATE_ACCURALPERC: '',
      ESTIMATED_VOLUMEREBATE: '',
      VOLUME_REBATE_PERCENT: '',
      EFFECTIVE_RECEIPT_LEVEL: '',
      REBATE_PERCENT_1: '',
      REBATE_PERCENT_2: '',
      REBATE_PERCENT_3: '',
      MULTI_LEVEL_PAYOUT1: '',
      MULTI_LEVEL_PAYOUT2: '',
      MULTI_LEVEL_PAYOUT3: '',
      from1:'',
      from2:'',
      from3:'',
      to1:'',
      to2:'',
      to3:'',
      REBATE_PERCENT_REDUCED:'N',



    });
    */

  }
  setDefaultPercent(value:number){
    return value.toFixed(2)
  }

  openVolumeRebatePopup(): void{
    const dialogRef = this.matDialog.open(VolumeRebateSearchComponent, {
      data:{vendorId:this.selectedVendorID, existingVRData:this.existingVolumeRebate}
    });
    dialogRef.afterClosed().subscribe(result =>{
      console.log("Dialog closed", result);
    })

  }

  formatCurrencyValue(event){
   // const input = event.target.value.replace('/[\.|$]/g','');
   const input = event.target.value.replace(/[^a-zA-Z 0-9]+/g,'');
    console.log("Event value:", input);
    //const returnvalue = this.currencyPipe.transform(input,'USD','$','1:0');
    const returnvalue = this.currencyPipe.transform(input,'USD','$','1.0');
    console.log("Formatted value:",returnvalue);
    this.volRebateForm.get('VOLUME_REBATE_THRESHOLD').patchValue(returnvalue);
    this.formatRange(1); // to validate Level1 value must be > than Receipt level
  }
  setSelectedBuyer(event:any){
    if(event.target.value != ''){
      const buyerInfo = event.target.value;
      // split the value
      this.selectedBuyerID = buyerInfo.split('|')[0];
      const fname = buyerInfo.split('|')[1].split(':')[0];
      const lname = buyerInfo.split('|')[1].split(':')[1];
      const buyer = this.vacBuyers.find(b => b.BuyerID == this.selectedBuyerID && b.FirstName == fname && b.LastName == lname);
      if (buyer) {
        this.selectedBuyer = buyer;
      }
      console.log("Selected Buyer id:",event.target.value, this.selectedBuyerID, this.selectedBuyer);
      // this.selectedBuyerID = event.target.value;
      this.isVendorIdDisabled = false;
      this.volRebateForm.controls['VENDOR_ID'].enable();
    }
  }

  selectVendor(vendorNumber) {
    this.createForm(); // initialize form
    Object.keys(this.selectedVendor).forEach(v => this.selectedVendor[v] = ''); // initialize selected vendor
    this.searchResults = [];
    let foundVendor = this.allVendors.find(v => v.VendorID === vendorNumber);
    if (foundVendor) {
      this.currentUser = this.user.loggedInUser || this.user.getUser();
      // console.log('selected vendor', foundVendor, this.currentUser);

      // set selectedVendor
      this.selectedVendor.VendorID = vendorNumber;
      this.selectedVendor.vendorName = foundVendor.CompanyName;
      this.selectedVendor.vendorType = foundVendor.VendorType;
      this.selectedVendor.childVendors = this.findChildren(vendorNumber) ? this.findChildren(vendorNumber).join(', ') : '';
      this.selectedVendor.contactEmail = foundVendor.ContactEmail; 
      this.selectedVendor.contactFirstName = this.currentUser.firstName; // this.getFieldByKey(foundVendor,'SC_FIRST_NAME'); 
      this.selectedVendor.contactLastName = this.currentUser.lastName; //this.getFieldByKey(foundVendor,'SC_LAST_NAME'); 
      this.selectedVendor.buyerID = foundVendor.BuyerID;
      this.selectedVendor.buyerName = foundVendor.BuyerName;
      this.selectedVendor.contactEmail = foundVendor.ContactEmail;

      //console.log(this.selectedVendor.contactEmail, this.currentUser.userName);
      
      console.log('From Vendor API', this.allVendors, this.selectedVendor);

      let vendorTabs = this.allVendors.find(v => v.VendorID === vendorNumber);
        // set form fields
        Object.keys(this.volRebateForm.controls).forEach(key => {
          let val = this.getFieldByKey(vendorTabs,key);
          // console.log('assign to form', key, val);
          if (val && val !== '') {
            this.volRebateForm.controls[key].patchValue(val);
          }
        });

        if(this.volRebateForm.get('VOLUME_REBATE_PERCENT') && this.volRebateForm.get('VOLUME_REBATE_PERCENT').value !=='') {
          this.initialRebateLevel = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
        }

        // handle agreement term
        if (this.volRebateForm.get('IS_RECURRING') && this.volRebateForm.get('IS_RECURRING').value === 'Y') {
          this.volRebateForm.controls['agreementTerm'].patchValue('Recurring');
        }


      
      
    }
  }

  getChildren(request){
    this.isLoading = true;
    this.api.requestData(request).subscribe(
      data => {
        // console.log(this.user.loggedInUser.userName);
        console.log('requested child vendor data from api', data);
        if(data && data['Data']) {
          for(let v of data['Data']){
            let foundVendor = this.allVendors.find(r => r.VendorID === v.VendorID);
            // console.log('Child Vendor, add to list', v, foundVendor);
            if(!foundVendor && (!v.OnboardingStatus || v.OnboardingStatus === 'Onboarding Completed')){
              v.ParentVendorNumber = request.VendorID;
              v.CompanyName = this.getFieldByKey(v, 'COMPANY_NAME');
              v.VendorType = this.getFieldByKey(v, 'VENDOR_TYPE');
              v.BuyerID = this.getFieldByKey(v, 'BUYER_ID');
              v.BuyerName = this.getFieldByKey(v, 'BUYER_FIRST_NAME') + ' ' + this.getFieldByKey(v, 'BUYER_LAST_NAME');
              // v.ContactEmail = this.getFieldByKey(v, 'SC_EMAIL');
              // console.log(v);
              this.allVendors.push(v);
            }
          }
        }
        this.isLoading = false;
        // update dropdown
        this.populateVendorSelection();
        console.log('vendor info (allvendors)', this.vendorNumberData, this.allVendors);

      }, error => {
        console.log(error);
        // update dropdown
        this.populateVendorSelection(); //this will just have parents, child request failed
        this.ga.sendEvent('Volume Rebate', 'Error requesting child vendors', 'for' + request.VendorID, {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':request.VendorID});
        this.isLoading = false;
      }
    );
  }

  

  setAddVendorTypeForUser(){
    this.vendorNumberData = [];
      this.getVendorTypeForUser().subscribe((data: any) => {
        
        this.vendorNumberData = data.VendorData;
        console.log('Vendor data (small req)', this.vendorNumberData);
      });
  }

  formatRange(index, frmControlName?){
    let form = this.volRebateForm;

    // initialize error handling
    this.rebateerr[0] = '';
    for (let x=1;x<5;x++){
      this.rebateerr[x] = '';
      //form.controls['from'+x].setErrors(null);
      //form.controls['to'+x].setErrors(null);
      form.controls['MLP_TIER_'+x+'_FROM'].setErrors(null);
      form.controls['MLP_TIER_'+x+'_TO'].setErrors(null);
      
    }

    if(index){
      //let fieldname = 'MULTI_LEVEL_PAYOUT' + index;
      let range = form.controls['MLP_TIER_'+index+'_FROM'].value + '-' + form.controls['MLP_TIER_'+index+"_TO"].value;
      
      if(frmControlName){
        const frmControlValue = form.controls[frmControlName].value.toString().replace(/[^0-9\.]+/g, "");
        if(frmControlValue){
          let formattedValue =   new Intl.NumberFormat('en-US', {
             minimumFractionDigits: 0,
           }).format(Number(form.controls[frmControlName].value.toString().replace(/[^0-9\.]+/g, "")));
            console.log("formattedValue currency value:", formattedValue);
            form.controls[frmControlName].patchValue(formattedValue);

        }
        

      }
      

      // validation, don't overlap
      if(index== 1){
        console.log("XXXX",+form.controls['MLP_TIER_'+index+"_TO"].value.replace(/[^0-9\.]+/g, ""),+form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""));
        console.log("CONDITION:",+form.controls['MLP_TIER_'+index+"_TO"].value.replace(/[^0-9\.]+/g, "")< +form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""));
        if(form.controls['MLP_TIER_'+index+"_TO"] && form.controls['MLP_TIER_'+index+"_TO"].value &&
         (+form.controls['MLP_TIER_'+index+"_TO"].value.replace(/[^0-9\.]+/g, "")< +form.controls['VOLUME_REBATE_THRESHOLD'].value.replace(/[^0-9\.]+/g, ""))){
          this.rebateerr[index]='Level ' + index + " To: Can't be lower than Receipt level that must be reached before rebate is effective value";
          form.controls['MLP_TIER_'+index+"_TO"].setErrors({'invalid':true});
        }
        
      }
      for (let x=1;x<5;x++){
        // each row, to must be greater than from
        if(form.controls['MLP_TIER_'+x+'_FROM'].value && form.controls['MLP_TIER_'+x+"_TO"].value !='' &&
           +form.controls['MLP_TIER_'+x+'_FROM'].value.toString().replace(/[^0-9\.]+/g, "") >= +form.controls['MLP_TIER_'+x+"_TO"].value.toString().replace(/[^0-9\.]+/g, "") ) {
         // ( +(form.controls['MLP_TIER_'+x+'_FROM'].value.replace(/[^0-9\.]+/g, "")) >= +(form.controls['MLP_TIER_'+x+"_TO"].value.replace(/[^0-9\.]+/g, "")))) {
          // mark invalid
          form.controls['MLP_TIER_'+x+"_TO"].setErrors({'invalid':true});
          // to must be greater than from
              this.rebateerr[x]='Level ' + x + ': Value of "To" must be greater than value of "From"';
          } else {

          }
          // each row, from must be greater than previous to
          if (x > 1) {
            if(form.controls['MLP_TIER_'+(+x-1)+"_TO"].value && form.controls['MLP_TIER_'+x+'_FROM'].value &&
              +form.controls['MLP_TIER_'+(+x-1)+"_TO"].value.toString().replace(/[^0-9\.]+/g, "") != +form.controls['MLP_TIER_'+x+'_FROM'].value.toString().replace(/[^0-9\.]+/g, "")-1 ) {
              // mark invalid
              // form.controls['MLP_TIER_'+x+'_FROM'].setErrors({'invalid':true});
              // from must be greater than previous to
              // this.rebateerr[x]='Level ' + x + ': Value of "From" must be greater than value of "To" in Level ' + (+x-1);

              // update from value instead
              const toValue = +form.controls['MLP_TIER_'+(+x-1)+"_TO"].value.toString().replace(/[^0-9\.]+/g, "");
              const newValue = (toValue+1).toLocaleString(undefined,{maximumFractionDigits:0});
              form.controls['MLP_TIER_'+x+'_FROM'].patchValue(newValue);
              console.log('updated From row ' + x);
            
            }
          }

          // each row, previous to must not be blank if rebate is present
          if (x > 1) {
            if (form.controls['MLP_TIER_'+(+x-1)+'_TO'].value == '' && 
                form.controls['MLP_TIER_'+(+x)+'_PERCENTAGE'].value != '') {
              this.rebateerr[x]='Level ' + (+x-1) + " to can't be empty, add a value or remove row " + x;
              form.controls['MLP_TIER_'+(+x-1)+'_TO'].setErrors({'invalid':true});
            }
          }
        

      }
      

      //this.volRebateForm.controls[fieldname].patchValue(range);
      this.rebatePercentageChange(index);// calling this method to either activate or deactivate add button
      // console.log(this.volRebateForm.get(fieldname));
    }
  }

  calculateRebate(){
    //const receipts = this.volRebateForm.controls['PROJECTED_RECEIPTS'].value;
    //const percent = this.volRebateForm.controls['VOL_REBATE_ACCURALPERC'].value;
    const percent = +this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
    // perform math
   // const total = Math.round((+percent/100)); // round to whole number
   //const formattedValue = +(Math.round(+percent * 100) / 100).toFixed(2);
   const formattedValue = percent.toFixed(2);
     console.log("Formatted value:",formattedValue, +formattedValue);
    // apply to form
    if (!isNaN(+formattedValue)){
      this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].patchValue(formattedValue);
      this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].setErrors(null);
    } else {
      this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].setErrors({'invalid': true});
    }

  }
  roundN(num:number,n:number){
    //const ss = Math.round(2);
   // return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
  }

  populateVendorSelection(){
    for (let v of this.allVendors) {
      console.log('populating vendor', v);
      if(!v.parentVendorNumber) {
        let fId = this.vendorNumberData.find(vnd => vnd.VendorID === v.VendorID);
        if(
          !fId &&
          (!v.ParentVendorNumber || v.ParentVendorNumber === '') &&
          v.isVendorActive !== 'N' &&
          v.VendorType !== 'Dropship' && // dropship not allowed
          v.VendorType.indexOf('Placeholder') < 0 && // placeholder vendors not allowed
          (!v.OnboardingStatus || v.OnboardingStatus === 'Onboarding Completed')
          ){
          let vInfo = {'VendorID': v.VendorID, 'CompanyName': v.CompanyName, 'buyerId': v.BuyerID, 'buyerName': v.BuyerName, 'vendorType': v.VendorType, 'status':v.OnboardingStatus};
          this.vendorNumberData.push(vInfo);
        }
      }
    }
    // add children
    let children = this.allVendors.filter(c => c.ParentVendorNumber && c.ParentVendorNumber !=='');
    if(children && children.length >0){
      for (let child of children) {
        console.log('child', child);
        // loop through parents
        for (let i in this.vendorNumberData) {
          if (this.vendorNumberData[+i].VendorID === child.ParentVendorNumber) {
            // add child after parent in list
            let childInfo = {'VendorID': child.VendorID, 'CompanyName': child.CompanyName, 'buyerId': child.BuyerID, 'buyerName': child.BuyerName, 'vendorType': child.VendorType, 'ParentVendorNumber':child.ParentVendorNumber}
            this.vendorNumberData.splice(+i,0,childInfo);
          }
        }
      }
    }
  }

  setAgreementTerm(){
    // handle agreement term
    // console.log(this.volRebateForm.get('agreementTerm').value);
    if (this.volRebateForm.get('agreementTerm')) {
      if (this.volRebateForm.get('agreementTerm').value === 'Year') {
        this.volRebateForm.controls['IS_RECURRING'].patchValue('N');
       // const year = new Date(this.volRebateForm.get('EFFECTIVEDATE').value).getFullYear();
        // if(year){
        //   this.volRebateForm.controls['VALID_YEAR'].patchValue(year);
        // }
        console.log("VALID_YEAR:",this.volRebateForm.controls['VALID_YEAR'].value);
        
      } else {
        this.volRebateForm.controls['IS_RECURRING'].patchValue('Y');
        //this.volRebateForm.controls['VALID_YEAR'].patchValue('');
      }
    }

    console.log('changed Valid Year to ' + this.volRebateForm.controls['VALID_YEAR'].value);
    console.log('changed Is Recurring to ' + this.volRebateForm.controls['IS_RECURRING'].value);
    this.showVolumeRebateSearch();
  }


  requestVolumeRebate(){
    console.log("Inside requestVolumeRebate");
    event.preventDefault();
   
    this.setAgreementTerm(); // finalize
    

    // add SC_EMAIL
    //this.volRebateForm.controls['SC_EMAIL'].patchValue(this.currentUser.userName);

    // format effective date
    console.log("Inside requestVolumeRebate123...");

    // filter to send only contract fields (not agreementTerm, etc.)
    let sendContract = {};
    sendContract['ONBOARDING_STATUS']='Submitted';
    sendContract['SUBMITTED_BY'] = this.currentUser.userName;
    // const buyerChosen:BuyerInfo[]  = this.vacBuyers.filter( (prop:BuyerInfo) => +prop.BuyerID== +this.selectedBuyerID);
    //   if(buyerChosen){
    //    sendContract['BUYEREMAIL'] = buyerChosen[0].Email;
    //    sendContract['BUYER_FIRST_NAME'] = buyerChosen[0].FirstName;
    //    sendContract['BUYER_LAST_NAME'] = buyerChosen[0].LastName;
    //    sendContract['BUYERCOMPANY'] = buyerChosen[0].CompanyName;
    // }
    if (this.selectedBuyer) {
        sendContract['BUYEREMAIL'] = this.selectedBuyer.Email;
        sendContract['BUYER_FIRST_NAME'] = this.selectedBuyer.FirstName;
        sendContract['BUYER_LAST_NAME'] = this.selectedBuyer.LastName;
        sendContract['BUYERCOMPANY'] = this.selectedBuyer.CompanyName;
        sendContract['BUYERID'] = this.selectedBuyer.BuyerID;
    }
      sendContract['PARENTVENDORNAME']= this.rebateVendorInfo.VendorData.COMPANY_NAME;
      
      sendContract['SC_PHONE'] = this.rebateVendorInfo.VendorData.SC_PHONE;
      sendContract['REBATE_PERCENT_REDUCED'] = 'N';
    // set rebate reduced flag
    const currentVRPercent = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
    const currentVRSourcingType:string = this.volRebateForm.controls['SOURCING_TYPE'].value;
    const currentValidYear = this.volRebateForm.controls['VALID_YEAR'].value;
    console.log("Existing volume rebate:",this.existingVolumeRebate);
    if(this.volRebateForm.get('agreementTerm').value === 'Year' && this.existingVolumeRebate && this.existingVolumeRebate.length > 0){
      if(currentVRSourcingType.toLowerCase() === 'all'){
        const filterdData = this.existingVolumeRebate.filter(data => data.SOURCING_TYPE.toLowerCase() === 'all' 
        || data.SOURCING_TYPE.toLowerCase() === 'domestic'
        || data.SOURCING_TYPE.toLowerCase() === 'import' );
        if(filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT){
          sendContract['REBATE_PERCENT_REDUCED'] ='Y';
        }
      }else if(currentVRSourcingType.toLowerCase() === 'domestic') {
        const filterdData = this.existingVolumeRebate.filter(data => (data.SOURCING_TYPE.toLowerCase() === 'domestic' || 
        data.SOURCING_TYPE.toLowerCase() === 'all') );
        if(filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT){
          sendContract['REBATE_PERCENT_REDUCED'] ='Y';
        }
      }else if(currentVRSourcingType.toLowerCase() === 'import'){
        const filterdData = this.existingVolumeRebate.filter(data => (data.SOURCING_TYPE.toLowerCase() === 'import' ||
        data.SOURCING_TYPE.toLowerCase() === 'all') );
        if(filterdData[0] && +currentVRPercent < +filterdData[0].VOLUME_REBATE_PERCENT){
          sendContract['REBATE_PERCENT_REDUCED'] ='Y';
        }
      }
      
    }

   // this.volRebateForm.controls['REBATE_PERCENT_REDUCED'].patchValue('N');
    /*
    if(this.volRebateForm.controls['VOLUME_REBATE_PERCENT'] && this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value){
      const newPercent = this.volRebateForm.controls['VOLUME_REBATE_PERCENT'].value;
      if (+newPercent < +this.initialRebateLevel) {
        this.volRebateForm.controls['REBATE_PERCENT_REDUCED'].patchValue('Y');        
      }
    }
    */

    for (let key of Object.keys(this.volRebateForm.controls)){
      // console.log(key);
      if(this.isUpperCase(key.substring(0,1)) && this.volRebateForm.controls[key].value && this.volRebateForm.controls[key].value!==''){
        /*
        if(key ==='VOLUME_REBATE_THRESHOLD'){
          sendContract[key] = this.volRebateForm.controls[key].value.replace('$','');
        }else{
          sendContract[key] = this.volRebateForm.controls[key].value
        }
        */
       if(key === 'VOLUME_REBATE_THRESHOLD' || key.startsWith('MLP_TIER_') ){
        sendContract[key] = this.volRebateForm.controls[key].value.toString().replace(/[^0-9\.]+/g, "");
       } else if (key === 'BUYERID') {
        sendContract[key] = this.selectedBuyerID || sendContract[key] ;
       }
       else {
        sendContract[key] = this.volRebateForm.controls[key].value;
       }

       
        
        
      }
    }

    console.log(sendContract, JSON.stringify(sendContract));
    this.isSubmitting = true;
    this.submitVolRebateForm(sendContract).subscribe(
      data => {
        console.log('submitted form', sendContract, data);
        // prevent submitting again
        this.isSubmitting = false;
        
        // handle various "success" responses
        if (data && data.toString() === 'Submitted') {
          // let currVendor = this.allVendors.find(v => v.VendorID === this.selectedVendor.VendorID);
          // currVendor.OnboardingStatus = "Submitted - In-progress";
          //this.populateVendorSelection();

          this.modalContent.header = 'Success';
          this.modalContent.body = 'Your request has been successfully submitted and has entered the DocuSign approval process.';
          this.modalMessage.show();
          this.clearFormFields();
          this.ga.sendEvent('Volume Rebate', 'Volume Rebate request successful for' + sendContract['PARENTID'], JSON.stringify(sendContract), {'time_stamp':Date.now(), 'record_id':sendContract['PARENTID']});

        } else {
          console.log('failed request', data);
          // show error, it didn't work
          this.modalContent.header = 'Error';
          this.modalContent.body = 'There was a problem processing your request, please try again or contact your representative.';
          this.modalMessage.show();
          this.ga.sendEvent('Volume Rebate', 'Volume Rebate Request Failed for Vendor ' + sendContract['PARENTID'], JSON.stringify(sendContract), {'time_stamp':Date.now(), 'error_msg':data.toString(), 'record_id':sendContract['PARENTID']});
        }
        
      }, error => {
        console.log(error);
        this.isSubmitting = false;
        this.modalContent.header = 'Error';
        this.modalContent.body = 'There was a problem processing your request, please try again or contact your representative.';
        this.modalMessage.show();
        this.ga.sendEvent('Volume Rebate', 'Volume Rebate Request Error for Vendor ' + sendContract['PARENTID'], JSON.stringify(sendContract), {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':sendContract['PARENTID']});
      }
    );
    

  }

  submitVolRebateForm(contract){
    // convert to json
    let json = contract; // JSON.stringify(contract);

    // convert fields to numbers
    /*
    for (let i=1;i<5;i++) {
      if (json['MLP_TIER_' + i + '_TO']) {
        const formattedTo = this.formatNumber(json['MLP_TIER_' + i + '_TO']);
        json['MLP_TIER_' + i + '_TO'] = formattedTo;
      }
      if (json['MLP_TIER_' + i + '_FROM']) {
        const formattedFrom = this.formatNumber(json['MLP_TIER_' + i + '_FROM']);
        json['MLP_TIER_' + i + '_FROM'] = formattedFrom;
      }
    }

    if (json.EFFECTIVE_RECEIPT_LEVEL) {
      const formattedNumber = this.formatNumber(json.EFFECTIVE_RECEIPT_LEVEL);
      json.EFFECTIVE_RECEIPT_LEVEL = formattedNumber;
    }

    if (json.VOLUME_REBATE_THRESHOLD) {
      const formattedNumber = this.formatNumber(json.VOLUME_REBATE_THRESHOLD);
      json.VOLUME_REBATE_THRESHOLD = formattedNumber;
    }
    */

    // submit form api: sendvolumerebatetodocusign
   // return this.api.apiReq('sendvolumerebatetodocusign',json);
    return this.restApi.bwReqVRSubmit('sendvolumerebatetodocusign', json);
    
   
    // return of('tested',contract, json);
  }
  clearFormFields(){
    this.createForm();
    this.rebateVendorInfo.VendorData={};
    this.rebateVendorInfo.VolumeRebate={};
    this.searchResults = [];
    // reset visible volume rebate rows
    this.showRPLevel2 = false;
    this.showRPLevel3 = false;
    this.showRPLevel4 = false;
  }

  getVendorTypeForUser(){
    const json = {
      'Email': this.currentUser.userName,
    }
    return this.api.requestAddVendor(json);
  }
  
  getFormValueByKey(vrVendorInfo:VendorDataModel , key:string): string{
    let value = '';
    if(Object.keys(vrVendorInfo).length> 0){
      if(key in vrVendorInfo){
         value = vrVendorInfo[key];

      }
    }
   return value;
  }
 
  getFieldByKey(vendor, key) {
    let value = '';
    if (vendor.tabs) {
      for (let tab of vendor.tabs) {
        let foundField = tab.fields.find(f => f.key === key);
        if (foundField && foundField.value) {
          value = foundField.value;
          // console.log('found ', key, value, tab.title);
          return value;
        }
      }
    }
    return value;
  }

  findChildren(id) {
    let children = [];
    for (let vendor of this.allVendors) {
      if (vendor.ParentVendorNumber === id) {
        children.push(vendor.VendorID);
      }
    }
    return children;
  }

  isOnboarding(vendor){
    if (!vendor.OnboardingStatus || vendor.OnboardingStatus !== 'Onboarding Completed') {
      return false;
    }
    return true;
  }

  isUpperCase(string) {
    return /^[A-Z]*$/.test(string);
  }

  goToPage(path) {
    this.router.navigate([path]);
  }


  // Volume Rebate Search
  searchVR(modal?) {
    if (this.selectedVendorID && (!this.voidVendorID || this.selectedVendorID != this.voidVendorID )) {
      if(modal){
        // this.voidVendorID = this.selectedVendorID; // if modal is not true , keep voidVendorId from input search text value
        this.voidVendorID = this.rebateVendorInfo.VendorData.PARENTID || this.selectedVendorID;
      }
      
    }
    if(modal) {
      modal.show();
    }
    if (this.voidVendorID) {
      console.log('searching for ' + this.voidVendorID);
      this.resultsMsg = '';
      this.searchResults = [];
      this.isSearching = true;
          
    // subscribe to search service
    const url = 'getvrinfo';
    let req = {};
    // const req = {'VRIDs': {'VRID': []},'VENDOR_ID': ''};
    
    if (this.searchType === 'Buyer') {
      req['BUYER_ID'] = this.voidVendorID;
    } else {
      req['VENDOR_ID'] = this.voidVendorID;
    }

      this.restApi.bwReqVRSubmit(url,req).subscribe(
        results => {
          // response
          // console.log('Volume Rebates for ' + req[0], results);
          if (results && results.VRInfo && results.VRInfo.length > 0) {
            this.resultsMsg = '';
            this.searchResults = results.VRInfo;
            this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + this.searchType + ' Search', this.searchResults.length + ' results found for ' + this.voidVendorID, {'time_stamp':Date.now(), 'record_id':this.voidVendorID});
          } else {
            this.resultsMsg = req[0] ? 'No Volume Rebates found for ' + req[0] : 'No Volume Rebates found.'
            this.searchResults = [];
            this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + this.searchType + ' Search', 'No results found for ' + this.voidVendorID, {'time_stamp':Date.now(), 'record_id':this.voidVendorID});
          }
          this.isSearching = false;
        }, error => {
          console.log(error);
          this.resultsMsg = 'The search was not completed due to an error.';
          this.isSearching = false;
          this.ga.sendEvent('Volume Rebate', 'Volume Rebate ' + this.searchType + ' Search Error ', error.message, {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':this.voidVendorID});    
        }
      );
    }

  }

  searchNewVR(){
    this.showSearchMsg = false;
    this.searchVR();
  }

  changeSearchType(value) {
    console.log('searching by '+ value);
    this.searchType = value;
  }

  closeVR(modal) {
    modal.hide();
    this.selectedVendorID = this.volRebateForm.get('PARENTID').value;
    this.isClosing = false;
    return false;
  }

  showBuyerName(buyerId, email) {
    let result = '';
    let buyer = this.vacBuyers.find(b => b.BuyerID === buyerId && b.Email === email);
    if (buyer) {
      result = buyerId + ' - ' + buyer.FirstName + ' ' + buyer.LastName;
    }

    return result;
  }

  requestVoid(vr, isRecall?) {
    // get void info
    if (vr) {
      // create VOID Request
      let voidObject = new VolumeRebateVoidRequest();
      let voidReq = Object.assign(voidObject, {'VRID': vr.VRID});

      // add VOID Fields
      voidReq.PARENTID = vr.PARENTID;
      voidReq.PARENTVENDORNAME = vr.PARENTVENDORNAME;
      voidReq.COMPANY_NAME = vr.COMPANY_NAME;
      voidReq.SC_EMAIL = vr.SC_EMAIL;
      voidReq.VENDOR_TYPE = vr.VENDOR_TYPE;
      
        // Volume Rebate Recall
        if (isRecall) {
            voidReq.ONBOARDING_STATUS = 'Reject';
        } else {
            voidReq.ONBOARDING_STATUS = 'Void';
            voidReq.VOID_REASON = this.getVoidReason();
        }

      voidReq.VOID_BY = this.user.loggedInUser.userName;
      
      voidReq.BUYEREMAIL = vr.BUYEREMAIL;
      voidReq.BUYER_FIRST_NAME = vr.BUYER_FIRST_NAME;

      console.log('final VOID request', voidReq);
      // make void request and handle response
  
      this.isSearching = true;
      this.submitVolRebateForm(voidReq).subscribe(
        data => {
          console.log(data);
          this.isSearching = false;
          this.searchResults.length = 0;
          this.resultsMsg =  (isRecall ? 'Recall' : 'Void') + ' request has been sent.';
          this.showSearchMsg = false;
          this.showVoidWarning = false;
          this.ga.sendEvent('Volume Rebate', (isRecall ? 'Recall' : 'Void') + ' request successful', 'VRID ' + vr.VRID , {'time_stamp':Date.now(), 'record_id':vr.VRID});
        }, error => {
          this.isSearching = false;
          console.log(error);
          this.resultsMsg = 'There was an error sending the ' + (isRecall ? 'Recall' : 'Void') +  + ' request.';
          this.showVoidWarning = false;
          this.ga.sendEvent('Volume Rebate', 'Error requesting ' + (isRecall ? 'Recall' : 'Void'), 'VRID ' + vr.VRID , {'time_stamp':Date.now(), 'error_msg':error.message, 'record_id':vr.VRID});
        }
      );   

    }
  }

  selectYear(e) {
    let selectedYear = e.option.value;
    this.volRebateForm.controls['VALID_YEAR'].patchValue(selectedYear);
    console.log('autocomplete selected year ', selectedYear, this.volRebateForm.get('VALID_YEAR').value );
    this.showVolumeRebateSearch(this.volRebateForm);
  } 

  showVolumeRebateSearch(form?) {
    console.log('closing modal?', this.isClosing);
    if (this.isClosing) {
      this.isClosing = false;
      return;
    }
    if (!form) {
      form = this.volRebateForm;
    }
    const currentYear = new Date().getFullYear();
    if( form.get('VALID_YEAR').value && +form.get('VALID_YEAR').value < currentYear){
      form.controls['VALID_YEAR'].setErrors({'invalid':true});
      return;
    }
    // Current Volume Rebate Request Details
    const effectiveDate = form.get('VALID_YEAR').value;
    const sourcingType = form.get('SOURCING_TYPE').value;
    const isRecurring = form.get('IS_RECURRING').value;

    // compare to each volume rebate for vendor
    let showSearch = false;
    // only check if all necessary fields are populated and there are existing volume rebates
    if (this.existingVolumeRebate && this.existingVolumeRebate.length > 0 && effectiveDate && sourcingType && isRecurring) {
      // check each existing active or in-progress volume rebate
      for (let vr of this.existingVolumeRebate) {
        if (vr.IS_VR_ACTIVE === 'Y' ||  vr.ONBOARDING_STATUS.toUpperCase().indexOf('DOCUSIGN') > -1){
          console.log(vr);
          if (vr.IS_RECURRING === 'Y' && (vr.VALID_YEAR < effectiveDate || (vr.VALID_YEAR >= effectiveDate && isRecurring == 'Y'))) {
            switch(sourcingType.toUpperCase()) {
              case 'ALL':
                showSearch = true;
              break;
              case 'IMPORT':
                if (vr.SOURCING_TYPE.toUpperCase() !== 'DOMESTIC') showSearch = true;
              break;
              case 'DOMESTIC':
                if (vr.SOURCING_TYPE.toUpperCase() !== 'IMPORT') showSearch = true;
              break;
            }
            console.log('Show search if is recurring and sourcing types match', showSearch, sourcingType, vr.SOURCING_TYPE);
          } else {
            // existing vol rebate not recurring
            console.log('not recurring');
            if (vr.VALID_YEAR == effectiveDate) {
              switch(sourcingType.toUpperCase()) {
                case 'ALL':
                  showSearch = true;
                break;
                case 'IMPORT':
                  if (vr.SOURCING_TYPE.toUpperCase() !== 'DOMESTIC') showSearch = true;
                break;
                case 'DOMESTIC':
                  if (vr.SOURCING_TYPE.toUpperCase() !== 'IMPORT') showSearch = true;
                break;
              }
              console.log('Show search if date matches and sourcing types match', showSearch, sourcingType, vr.SOURCING_TYPE);
            }
          }
        }
      }
    }
    

    if (showSearch) {
      // trigger the search function and modal
      this.searchVR(this.modalSearch);
      this.showSearchMsg = true;
      this.showSubmitErrorMsgforInProgressVR = true;
    }else {
      this.showSubmitErrorMsgforInProgressVR = false;
    }

    console.log('Show Existing VR? ', showSearch, effectiveDate, sourcingType, isRecurring, this.existingVolumeRebate);

  }


  isVRactive(id) {
    let active = this.existingVolumeRebate.find(a => a.VRID === id && a.IS_VR_ACTIVE === 'Y');
    if (active) {
      return true;
    } else {
      let active = this.searchResults.find(a => a.VRID === id && a.DELETE_INDICATOR !== 'Y' && a.ONBOARDING_STATUS.indexOf('Docusign') == -1);
      if (active) {
        return true;
      }
    }
    return false;
  }

  isVRInProgress(id) {
    let inprogress = this.existingVolumeRebate.find(a => a.VRID === id && (a.ONBOARDING_STATUS.toUpperCase()== 'DATA SENT TO DOCUSIGN' || a.ONBOARDING_STATUS.toUpperCase()== 'IN PROGRESS')); // find in-progress statuses
    if (inprogress) {
      return true;
    } else {
      let inprogress = this.searchResults.find(a => a.VRID === id && (a.ONBOARDING_STATUS.toUpperCase()== 'DATA SENT TO DOCUSIGN' || a.ONBOARDING_STATUS.toUpperCase()== 'IN PROGRESS'));
      if (inprogress) {
        return true;
      }
    }
    return false;

  }

  getVoidReason() {
    if (this.voidReason === 'Other') {
      return this.otherReason;
    }
    return this.voidReason;
  }

  selectVoidReason(){
    console.log(this.voidReason + ' selected');
    if (this.voidReason === 'Other') {
      this.showOtherReason = true;
    } else {
      this.otherReason = '';
      this.showOtherReason = false;
    }
  }
  
  confirmVoid() {
    this.showVoidWarning = true;
  }

  resetVoid() {
    this.showOtherReason = false;
    this.showVoidWarning = false;
    this.otherReason = '';
    this.voidReason = '';
  }

  formatNumber(string) {
    // format as 2 decimal places with no comma
    if (string && string !=='') {
      string = string.replaceAll(',',''); // remove commas
      let num = parseInt(string);
      string = isNaN(num) ? string : num.toFixed(2);
    }
    return string;
  }

}
