

<h4>This vendor already has volume rebates in effect for the selected creteria.
  Please select a volume rebate below to void before creating new one. </h4>
<div mat-dialog-content>

  <div class="row">
    <div class="col-sm-9">
      <h2>Look up a Vendor Rebate Confirmation (VRC)</h2>

      <div class="form-row">
        <label class="col-sm-1 col-form-label text-right">
          Search
        </label>
        <div class="form-group col-sm-6">
          <input class="form-control" type="text" [(ngModel)]="searchQuery">
        </div>
        <div class="form-group col-sm-2">
          <button class="search-btn" [disabled]="isSearching" (click)="searchVAC()"><span class="material-icons">search</span></button>
        </div>  

      </div>

    </div>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
  
</div>