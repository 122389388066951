/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./dialog.component";
import * as i9 from "@angular/platform-browser";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-dialog-content", [["class", "card-body mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["tabindex", "1"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _co.confirmButtonText; _ck(_v, 7, 0, currVal_4); }); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.DialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef, i9.DomSanitizer], null, null)], null, null); }
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i8.DialogComponent, View_DialogComponent_Host_0, {}, {}, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
