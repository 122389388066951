import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { UserService } from './user.service';

import { map, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class AnnouncementService {

  public announcementList: Announcement[] = [];
  public announcementsLoaded = false;
  constructor(
    private rest: RestService,
    private users: UserService,
  ) { }

  // retrieve announcements
  retrieveAnnouncements(){
    // form http request for events from API
    return this.rest.getReq('getAnnouncements', this.users.getHeader()).pipe(map(
      res => {
        console.log('announcement request', res);
        this.announcementList = res.json();
        this.announcementsLoaded = true;
        if(this.announcementList && this.announcementList.length > 0){
          this.announcementList.sort((a,b)=>new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()).reverse();
        }

        // parse role list
        for (let a of this.announcementList){
          let rolesArr = [];
          if(a.roleName && a.roleName !== ''){

            a.roleName = a.roleName.replace('null', 'all'); // prevent null values

            if (a.roleName.indexOf(',') > -1) {
              // multiple roles
              rolesArr = a.roleName.split(',');
            } else {
              // single role
              rolesArr.push(a.roleName);
            }
          }
          a.rolesArr = rolesArr;

          // parse buyerId and lob and vendor id
          a.buyerArr = [];
          a.lobArr =[];
          a.vendorIds = [];
          a.vendorAttrArr = [];
          if(a.buyerId && a.buyerId !==''){
            if(a.buyerId.indexOf(',')>-1){
              a.buyerArr = a.buyerId.split(',');
            } else {
              a.buyerArr.push(a.buyerId);
            }
          }
          if(a.lob && a.lob !==''){
            if(a.lob.indexOf(',')>-1){
              a.lobArr = a.lob.split(',');
            } else {
              a.lobArr.push(a.lob);
            }
          }
          if(a.vendor && a.vendor!==''){
            if(a.vendor.indexOf(',')>-1){
              a.vendorIds = a.vendor.split(',');
            } else {
              a.vendorIds.push(a.vendor);
            }
          }
          if (a.vendorAttr && a.vendorAttr !=='') {
            if(a.vendorAttr.indexOf(',')>-1){
              a.vendorAttrArr = a.vendorAttr.split(',');
            } else {
              a.vendorAttrArr.push(a.vendorAttr);
            }
          }
        }
        return this.announcementList;
      }
    ));
  }

  

}

export class Announcement {
  public id: String = '';
  public title: String = '';
  public bodyText: String = '';
  public publishDate: Date = null;
  public expiryDate: Date = null;
  public createDate: Date = null;
  public modifyDate: Date = null;
  public authorName: String = '';
  public roleName: String = 'all';
  public groupName: String = '';
  public lastModifiedBy: String = '';
  public thumbNail: String = '';
  public announceLink: String = '';
  
  public filterType: String = '';
  public buyerId: String = '';
  public lob: String = '';
  public vendor: String = '';
  public vendorType: String = '';
  public vendorAttr: String = '';

  public vendorIds: String[] = [];
  public rolesArr: String[] = [];
  public buyerArr: String[] = [];
  public lobArr: String[] = [];
  public vendorTypeArr: String[] = [];
  public contactType: String[] = [];
  public vendorAttrArr: String[] = [];

  public docPath: String = '';
  public fileName: String = '';
  public file: File;

  public emailOnly: boolean = false;
}
