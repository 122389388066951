import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';
import { of, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressService } from './../services/address.service';
import { Address } from './../vendor-dashboard/address-section/address';
import * as states from './../../assets/statelist.json';
import * as countries from './../../assets/countrylist.json';





@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  allStates = (states as any).default;
  allCountries = (countries as any).default;
  public isValidRTV = false;
  public RTVselectionCount = 0;
  public returnsApplyToAll;

  constructor(private addr: AddressService,){}

    // rule functions

    // future year
    isFutureYear(input){
        const thisYear = new Date().getFullYear();
        // console.log(thisYear, input);
        if (+input > thisYear){
            return true;
        } else {
            return false;
        }
    }

    radioReturnedGoods(form, key, value) {
        let fieldList = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE']; // 01-09 except 03
        let applyToAllSkus = form.controls['RETURNS_APPLY_TO_ALL'] ? form.controls['RETURNS_APPLY_TO_ALL'] : form.controls['ONLINE_RETURN_EXCEPTIONS'];

        let selectedField = fieldList.find(x => x === key); // check that current field is in the list
        console.log('filter returned goods', applyToAllSkus, selectedField);
        // if apply to all skus = Y, allow only one 01-09 to be Y

        if (applyToAllSkus && applyToAllSkus.value === 'Y' && selectedField) {
          // set all others to N
          for (let f of fieldList) {
              if (f === key) {
                console.log('select clicked', f);
                form.controls[f].setValue(value, { emitEvent: false });
                form.controls[f].markAsDirty();
              } else {
                console.log('update magically', f);
                if(form.controls[f]){
                  form.controls[f].setValue('N', { emitEvent: false });
                  form.controls[f].markAsDirty();
                }
            }
          }
        } else {
          // if clicked on applyToAllSkus
          if ((key === 'RETURNS_APPLY_TO_ALL' || key === 'ONLINE_RETURN_EXCEPTIONS') && value === 'Y') {
            // set all to N
            let firstOneSet = false;
            for (let f of fieldList) {
              let currControl = form.controls[f];
              console.log(f,currControl);
              if(currControl) {
                if (currControl.value === 'Y' && !firstOneSet) {
                  // don't change the value, but update firstOneSet
                  firstOneSet = true;
                } else {
                  currControl.setValue('N', { emitEvent: false });
                  currControl.markAsDirty();
                }
              }
            }
          } else if (selectedField) {

              // auto select all N, make N read-only?
              // if all are N, change first to Y

            // applyToAllSkus is N or not present
            console.log('apply to all?', applyToAllSkus.value, applyToAllSkus);
            // require more than 1 Y, do not auto edit
            // set validation for at least 2 Y in list
            let selectedRTV = [];
            for (let r of fieldList) {
              const fieldValue = form.get(r).value;
              // if(form.controls[r] && form.controls[r].value) {selectedRTV[r] = form.controls[r].value;}
              if(fieldValue) {selectedRTV[r] = fieldValue}
            }
            let rtvSelections = selectedRTV.filter(f => f === 'Y');
            console.log('selected RTV', rtvSelections);
            if (rtvSelections && rtvSelections.length > 1) {
              // valid
              // this.isValidRTV = true;
            } else {
              // invalid
              console.log('must select at least 2 RTV options', selectedRTV);
              // this.isValidRTV = false;
            }


          }
        }
        // validation
        form.valueChanges.subscribe(
          val => {
          this.validateRTV(form, val);
          }
        );
    }

    validateRTV(form, changes?) {
      let fieldList = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE']; 
      let val = form.controls;
      if (changes) {val = changes;}

          this.isValidRTV = false;
          this.returnsApplyToAll = val['RETURNS_APPLY_TO_ALL'] ? val['RETURNS_APPLY_TO_ALL'] : val['ONLINE_RETURN_EXCEPTIONS'];
          if(!changes) {
            this.returnsApplyToAll = val['RETURNS_APPLY_TO_ALL'].value ? val['RETURNS_APPLY_TO_ALL'].value : val['ONLINE_RETURN_EXCEPTIONS'].value
          }
                    
          let rtv = [];
          for(let x of fieldList) {
            if(form.controls[x] && form.controls[x].value) {
              rtv[x] = form.get(x).value;
            }
          }
          console.log(this.returnsApplyToAll=='Y' ? '1 and only 1 Y is required and allowed':'at least 2 Y are required');

          let notAllN = Object.keys(rtv).filter((key)=>rtv[key]=='Y');
          this.RTVselectionCount = notAllN.length;

          if (!notAllN) {
            this.isValidRTV = false;
          }
          if (this.returnsApplyToAll == 'Y' && notAllN.length==1) {
            this.isValidRTV = true;
          }
          if (this.returnsApplyToAll == 'N' && notAllN.length > 1) {
            this.isValidRTV = true;
          } 

          console.log('Valid?', this.isValidRTV, rtv);

    }

    isRTV(key) {
      let rtv = ['ISPRODUCT_DESTROYED', 'ISPRODUCT_RETURNED', 'ISTSC_RECOVERABLE', 'ISALLOWANCE_NEGOTIATED', 'ISREPAIRABLE_BYLOCALCENTRE', 'IS_NOALLOWANCEPAYABLE', 'IS_CONTACTBUYER', 'IS_PERISHABLE'];
      if (rtv.indexOf(key) == -1) {
        return false;
      }
      return true;
    }
  
    radioDeductionLocation(form, section, key, value) {
      // group deduction location fields on each tab they appear
      // allow only one Y, set the rest to N
      // console.log(tabs);
  
      let fieldList = [];
      //const currentTab = tabs.find(t => t.fields.find(f => f.key === key));
      const currentTab = section;
      console.log('current tab', currentTab);
          for (let field of currentTab.fields) {
            if (form.get(field.key) && field.subtitle === 'Deduction Location') {
              fieldList.push(field.key);
            }
          }
        // console.log('deduction fields', fieldList);
        if (fieldList && fieldList.length > 0) {
        for (let f of fieldList) {
          if (f === key) {
            console.log('select clicked', f);
            form.controls[f].setValue(value, { emitEvent: false });
            form.controls[f].markAsDirty();
          } else {
            // console.log('update magically', f);
            if(form.controls[f]){
              form.controls[f].setValue('N', { emitEvent: false });
              form.controls[f].markAsDirty();
            }
          }
        }
      }
    }

    radioAccountsPayable(form, key, value){
      // only one must be Y
      let fieldList = ['IS_OPENACCOUNT','IS_LINE_OF_CREDIT'];
      let selectedField = fieldList.find(x => x === key); // check that current field is in the list
        if (selectedField) {
          // set all others to N
          for (let f of fieldList) {
              if (f === key) {
                console.log('select clicked', f);
                form.controls[f].setValue(value, { emitEvent: false });
                form.controls[f].markAsDirty();
              } else {
                // console.log('update magically', f);
                if(form.controls[f]){
                  if(value === 'Y') {
                    form.controls[f].setValue('N', { emitEvent: false });
                    form.controls[f].markAsDirty();
                  } else {
                    form.controls[f].setValue('Y', { emitEvent: false });
                    form.controls[f].markAsDirty();
                  }
                }
            }
          }
        }
  
  
    }

    updateFreightFormFields(form, key, list?) {
      // console.log('key is ', key, key.substring(0,3));
      const abbr = key.substring(0,3);
      if (abbr.includes('FOB')) {
        if(form.controls['TSC_RECEIVING'].value != 2){
          console.log("Before changing TSC_RECEIVING value in FOB",form.controls['TSC_RECEIVING'].value);
          form.controls['TSC_RECEIVING'].setValue(2);
          form.controls['TSC_RECEIVING'].markAsDirty();
          form.controls['TSC_RECEIVING'].disable();
        }
        if(form.controls['TIME_TO_DESTINATION'].value != 45){
          console.log("Before changing TIME_TO_DESTINATION value in FOB",form.controls['TIME_TO_DESTINATION'].value);
          form.controls['TIME_TO_DESTINATION'].setValue(45);
          form.controls['TIME_TO_DESTINATION'].markAsDirty();
          form.controls['TIME_TO_DESTINATION'].disable();

        }
          /*
          form.controls['TSC_RECEIVING'].setValue(2);
          form.controls['TIME_TO_DESTINATION'].setValue(45);
          form.controls['TSC_RECEIVING'].markAsDirty();
          form.controls['TIME_TO_DESTINATION'].markAsDirty();
          form.controls['TSC_RECEIVING'].disable();
          form.controls['TIME_TO_DESTINATION'].disable();
          */
        } else if (abbr.includes('FPV')) {
          /*
          form.controls['TSC_RECEIVING'].setValue(2);
          form.controls['TSC_RECEIVING'].markAsDirty();
          form.controls['TSC_RECEIVING'].disable();
          form.controls['LEAD_TIME_SHIP'].enable();
          form.controls['TIME_TO_DESTINATION'].enable();
          */
          if(form.controls['TSC_RECEIVING'].value != 2){
            console.log("Before changing TSC_RECEIVING value in FPV",form.controls['TSC_RECEIVING'].value);
            form.controls['TSC_RECEIVING'].setValue(2);
            form.controls['TSC_RECEIVING'].markAsDirty();
            form.controls['TSC_RECEIVING'].disable();
          }
          form.controls['LEAD_TIME_SHIP'].enable();
          form.controls['TIME_TO_DESTINATION'].enable();
        } else if (abbr.includes('FCT')) {
          /*
          form.controls['TSC_RECEIVING'].setValue(2);
          form.controls['TIME_TO_DESTINATION'].setValue(2);
          form.controls['TSC_RECEIVING'].markAsDirty();
          form.controls['TIME_TO_DESTINATION'].markAsDirty();
          form.controls['TIME_TO_DESTINATION'].disable();
          form.controls['TSC_RECEIVING'].disable();
          */
          if(form.controls['TSC_RECEIVING'].value != 2){
            console.log("Before changing TSC_RECEIVING value in FCT",form.controls['TSC_RECEIVING'].value);
            form.controls['TSC_RECEIVING'].setValue(2);
            form.controls['TSC_RECEIVING'].markAsDirty();
            form.controls['TSC_RECEIVING'].disable();
          }
          if(form.controls['TIME_TO_DESTINATION'].value != 2){
            console.log("Before changing TIME_TO_DESTINATION value in FCT",form.controls['TIME_TO_DESTINATION'].value);
            form.controls['TIME_TO_DESTINATION'].setValue(2);
            form.controls['TIME_TO_DESTINATION'].markAsDirty();
            form.controls['TIME_TO_DESTINATION'].disable();

          }

        }
        
        if(list){
          this.validateFreightTermsAndCalculatedDeliveryTime(form, list);
        }
        else {
          this.validateFreightTermsAndCalculatedDeliveryTime(form);
        }
        form.controls['TOTAL_DELIVERY_TIME'].disable();
    }
  
    validateFreightTermsAndCalculatedDeliveryTime(form, list?) {
      let isInputValidNumber = true;
      if (Number.isNaN(form.controls['LEAD_TIME_SHIP'].value)) {
         form.controls['LEAD_TIME_SHIP'].setErrors({'incorrect': true});
         isInputValidNumber = false;
      } else if (Number.isNaN(form.controls['TIME_TO_DESTINATION'].value)) {
         form.controls['TIME_TO_DESTINATION'].setErrors({'incorrect': true});
         isInputValidNumber = false;
      } else if (Number.isNaN(form.controls['TSC_RECEIVING'].value)) {
         form.controls['TSC_RECEIVING'].setErrors({'incorrect': true});
         isInputValidNumber = false;
      }

   if (isInputValidNumber) {
      let calculatedDeliveryTime = parseInt(form.controls['LEAD_TIME_SHIP'].value) + parseInt(form.controls['TIME_TO_DESTINATION'].value) + parseInt(form.controls['TSC_RECEIVING'].value);
      if(form.controls['TOTAL_DELIVERY_TIME'].value != calculatedDeliveryTime){
        form.controls['TOTAL_DELIVERY_TIME'].setValue(calculatedDeliveryTime);
        form.controls['TOTAL_DELIVERY_TIME'].markAsDirty();
      }

      //form.controls['TOTAL_DELIVERY_TIME'].setValue(calculatedDeliveryTime);
      //form.controls['TOTAL_DELIVERY_TIME'].markAsDirty();

      if(list){
        // find the fields and update
        let fieldList = ['LEAD_TIME_SHIP', 'TIME_TO_DESTINATION', 'TSC_RECEIVING', 'TOTAL_DELIVERY_TIME'];
        for (let field of fieldList) {
          let found = list.fields.find(f => f.key === field);
          if(found){
            found.value = form.controls[field].value;
            console.log('updated ' + field + ' to ' + found.value);
          }
        }
      }
   }
}
calculateFreightTermsDeliveryTime(form, key, list?) {
  // console.log(key);
  if (key === 'LEAD_TIME_SHIP' || key === 'LEAD_TIME_STORE' || key === 'TSC_RECEIVING'
      || key === 'TOTAL_DELIVERY_TIME' || key === 'TIME_TO_DESTINATION') {
      const freightFormFields = form.get('FRT_TERMS_LEAD_TIME');
        if (freightFormFields != null) {
          // console.log('freightFormFields', freightFormFields);
          this.updateFreightFormFields(form, freightFormFields.value, list);
        }
  } else if (key === "VA_ADDRESS_LINE1" || key ==="VA_ADDRESS_LINE2" || key === "VA_CITY" || key  === "VA_STATE" || key === "VA_COUNTRY" || key === "VA_ZIP") {
            form.get('VA_ADDRESS_LINE1').setErrors(null);
            form.get('VA_ADDRESS_LINE2').setErrors(null);
            form.get('VA_CITY').setErrors(null);
            form.get('VA_STATE').setErrors(null);
            form.get('VA_COUNTRY').setErrors(null);
            form.get('VA_ZIP').setErrors(null);
  } else if (key === "SA_ADDRESS_LINE1" || key === 'SA_ADDRESS_LINE2' || key === 'SA_CITY' || key === 'SA_STATE' || key === 'SA_COUNTRY' || key === 'SA_ZIP'){
            form.get('SA_ADDRESS_LINE1').setErrors(null);
            form.get('SA_ADDRESS_LINE2').setErrors(null);
            form.get('SA_CITY').setErrors(null);
            form.get('SA_STATE').setErrors(null);
            form.get('SA_COUNTRY').setErrors(null);
            form.get('SA_ZIP').setErrors(null);

  } else if (key === "RA_ADDRESS_LINE1" || key ==="RA_ADDRESS_LINE2" || key === "RA_CITY" || key  === "RA_STATE" || key === "RA_COUNTRY" || key === "RA_ZIP") {
             form.get('RA_ADDRESS_LINE1').setErrors(null);
             form.get('RA_ADDRESS_LINE2').setErrors(null);
             form.get('RA_CITY').setErrors(null);
             form.get('RA_STATE').setErrors(null);
             form.get('RA_COUNTRY').setErrors(null);
             form.get('RA_ZIP').setErrors(null);
               }
  //this.form.setErrors(null);
  //console.log("form state " + this.form.valid);
}

// TIN validation


    // validations for Dashboard/Maintenance

    // 



    // address validation
    
    validateAddresses(addresses, form?) {
      let addressList = [];
      // get unique address types
    let roles = [];
    for (let a of addresses) {
      let found = roles.find(r => r === a.subtitle);
      if(!found){
        roles.push(a.subtitle);
      }
    }

    // now, format the addressList request to validation service
    for (let r of roles){
      let currentAddress = addresses.filter(c => (c.subtitle && c.subtitle === r));
                if (currentAddress && currentAddress.length > 0){
                  let address = {} as Address;
                  for (let field of currentAddress) {
                    // this.vendorAddressesFields.push(field);
                    address.title = field.subtitle;
                    // console.log(field, field.label);
                    switch (field.label) {
                      case 'Address1' :
                      address.address1 = field.value;
                      break;
                      case 'Address2' :
                      address.address2 = field.value;
                      break;
                      case 'State':
                      address.state = field.value;
                      break;
                      case 'City':
                      address.city = field.value;
                      break;
                      case 'Country':
                      address.country = field.value;
                      break;
                      case 'ZIP Code':
                        address.zipcode = field.value;
                      break;
                      case 'ZIP':
                      address.zipcode = field.value;
                      break;
                      case 'Phone No':
                      address.phoneNo = field.value;
                      break;
                      case 'Fax':
                      address.fax = field.value;
                      break;
                      case 'Website':
                      address.website = field.value;
                      break;
                      default:
                      address[field.label] = field.value;
                    }
                  }
                  addressList.push(address);
                }
    }

    console.log('send to validation', addressList);

      let validateList = [];
      // look for changes
      for (let add of addressList) {
        // console.log('address:', add);
        // only validate US addresses
        if (add && (add.country === 'US' || add.country === 'USA')) {
          validateList.push(this.addr.verifyAddress(add).catch(e => of(e)));
          console.log(add, validateList);
        } else {
          validateList.push(of('skip'));
        }
      }

      // make all requests, then return results to modal
      let modalDisplay = [];
      if (validateList && validateList.length > 0){
      return forkJoin(validateList).pipe(map(
          data => {
            console.log(data);
            if(data && data.length>0){
              for (let i=0;i<data.length;i++) {
                let valid = true;
                console.log(addressList[i], validateList[i], data[i]);
                if(data[i] !== 'skip'){
                  Object.keys(addressList[i]).forEach(k => {
                    console.log(k, addressList[i][k]);
                  //  Object.keys(validateList[i]).forEach(k => {
                  //  console.log(k, validateList[i][k]);
                    if(data[i] && k !== 'title') {
                      console.log(k, data[i][k], addressList[i][k]);
                      if(addressList[i][k] !== data[i][k]) {
                        valid = false;
                      }
                    } else if(!data[i] || data[i] === null || (data[i].value && data[i].value.status)){
                      // address rejected!
                      console.log(i + ': data is null');
                      let nullAddr:Address = {};
                      nullAddr.address1 = 'No match found.';
                      data[i] = nullAddr;
                      valid = false;
                    }
                  });
                } else {
                  // skip this one
                  let skipAddr:Address = {};
                  skipAddr.address1 = 'skipped validation';
                  data[i] = skipAddr;
                  valid = true;
                }
                console.log(i, valid, data[i]);
                // if (!valid) {
                  let row = [addressList[i], data[i]];
                  // let row = [validateList[i], data[i]];
                  modalDisplay.push(row);
                // }
              }
            }
  
            // return array for modal to address section, pass to vendor-dashboard
            return modalDisplay;
      }));
        }
    }


    formatState(st, co) {
      // retrieve full state name from abbreviation
  
      // assume and validate country abbreviation
      let abbr = this.allCountries.find(c => c.ABBR === co);
      if (!abbr) {
        // try by name
        abbr = this.allCountries.find(c => c.NAME === co);
      }
      if (abbr && abbr.ABBR) {
        co = abbr.ABBR;
      }
  
      let state = this.allStates.find(s => s.COUNTRY === co && s.ABBR == st);
      
      if (state) {
        st = state.NAME; // full state name
      }
  
      return st;
      
    }
  
    formatCountry(abbr) {
      // retrieve name from abbreviation
      let country = this.allCountries.find(c => c.ABBR === abbr);
      if (!country) {
        country = this.allCountries.find(c => c.NAME === abbr);
      }
      if (country && country.NAME) {
        abbr = country.NAME;
      }
  
      return abbr;
    }

}
