import { Component, OnInit, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { RestService } from '../services/rest.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsService } from './../services/analytics.service';

import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';


@Component({
  selector: 'app-campaign-performance',
  templateUrl: './campaign-performance.component.html',
  styleUrls: ['./campaign-performance.component.css']
})
export class PowerBiComponent implements OnInit {
  @ViewChild('powerBIembed') embedNode;

  // public powerBI = this.clean.bypassSecurityTrustResourceUrl('https://localhost');
  public embedded = false; 
  public initLoad = true;
  public pageContent:any;
  public config = {
    'clientID': '5e675eab-f4d3-4b65-84e8-12888921b442',
    'workspaceID':'fc7fdce2-9263-4848-9da4-a1efac669086', // '0b401f99-9964-4c9b-ade9-e80e6c7ad3e7', // BARN // 'e31039ae-68e4-4b6e-bc5b-af5a548300e6', // MON
    'reportID':  '5328db0c-8ecc-49c5-8965-8e56151eaf5', // dynamic now, remove // '14219d7e-9816-4074-a044-088606221c61', // BARN // 'c5f8ce2e-d191-431d-8165-ebb614365e20', // MON 
  }
  public APIpath = '';
  public svcResp = '';
  private portalsvc = environment.api; // local is '/tsc-api/'; // 

  // test bw api http://t1ratbw502.ssc.tsc:8181/sendvendorreports with proxy tsc-api; replace with portalsvc

  // private tokenUrl = 'https://dev-api.tractorsupply.com/getOAuthToken';
  // private listUrl = this.portalsvc + 'getvendorreports?workspaceId=' + this.config.workspaceID; // BW // 'https://dev-api.tractorsupply.com/getVendorReports?workspaceId=' + this.config.workspaceID;
  private listUrl = this.portalsvc + 'getPowerBIReports/' + this.config.workspaceID;  // Java
  private reportUrl = this.portalsvc + 'getBIReportData'; // JAVA
  // private reportUrl = this.portalsvc + 'sendvendorreports'; // BW // 'https://dev-api.tractorsupply.com/postVendorReports';

  public PBIconfig = { type: 'report',
                        id: undefined,
                        reportName: undefined,
                        embedUrl: undefined,
                        accessToken: undefined,
                        tokenType: models.TokenType.Embed,
                        hostname: 'https://app.powerbi.com',
                        permissions: models.Permissions.All,
                        settings: {
                          panes: {
                              filters: {
                                  expanded: false,
                                  visible: false
                              }
                          },
                          background: models.BackgroundType.Transparent,
                      } 
  }

  public reportsList = [
    {
      reportId: '0',
      reportName: 'Loading reports...'
    }
  ];

  public selectedReport = {'reportName':'Loading reports...','reportId':null};

  constructor(
    public rest: RestService,
    public http: Http,
    public ga: GoogleAnalyticsService,
    private clean: DomSanitizer,
    public users: UserService,
  ) { }

  ngOnInit() {
    this.getReportsList();
  }


  isSuccess(){
    return this.embedded;
  }

  changeReport(e) {
    
    let selectedReport = this.reportsList.find(r => r.reportId === e.target.value);
    if (selectedReport) {
      console.log('Change Report: ', selectedReport);
      this.selectedReport = selectedReport;
      this.showReport(selectedReport);

    }
  }

  getReportsList() {

        this.http.get(this.listUrl).subscribe(
          reports => {
            const list = reports.json();
            this.reportsList = [];
            for (let l of list.reports){
              this.reportsList.push(l);
            }
            console.log('got list of reports', this.reportsList);
            this.showReport(this.reportsList[0]);
          }, error => {
            console.log('reports error', error);
            this.svcResp = error.status + ':' + error.statusText;
            this.embedded = false;
            this.selectedReport = {
              reportId: '0',
              reportName: 'Error: Unable to load reports'
            };

          }
        );

  }

  showReport(report) {
    // report object will have name and id

    this.selectedReport = report;

    let identity = {
      'roles': [this.users.loggedInUser.vendorNumber],
      'username': this.users.loggedInUser.userName
    }

    let json = {
      'workspaceId': this.config.workspaceID,
      'reportId': report.reportId, // this.config.reportID,
      'vendorId': identity.username, // 'mtanweer@tractorsupply.com', // BARN :'str7004@petsensellc.com' 
    }

    var hdr = new Headers({
      "Content-Type": "application/json",
      "Accept": "application/json"
  });


    this.http.post(this.reportUrl, json, {headers:hdr}).subscribe(
      resp => {
        this.svcResp = resp.status + ':' + resp.statusText;
        // return embed code
        this.embedded = true;
        this.initLoad = false;
        const data = resp.json();
        console.log(data);
        if (data.embedData && data.embedData[0] && data.embedData[0].embedUrl) {
          const embedCode = data.embedData[0].embedUrl
          // console.log('EMBED URL', data.embedData[0].embedUrl, data);
          // console.log('ACCESS TOKEN', data.embedData[0].embedToken, data);


          // POWERBI EMBED MODULE

          this.PBIconfig = { 
                      type: 'report',
                      id: data.embedData[0].reportId,
                      reportName: data.embedData[0].reportName,
                      embedUrl: data.embedData[0].embedUrl,
                      accessToken: data.embedData[0].embedToken,
                      tokenType: models.TokenType.Embed,
                      hostname: 'https://app.powerbi.com',
                      permissions: models.Permissions.All,
                      settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: true
                            }
                        },
                        background: models.BackgroundType.Transparent,
                    }
                  }
          // console.log(this.PBIconfig);


        } else {
          // show error
          this.embedded = false;
          this.svcResp = data.Errormessage ? data.ErrorMessage.error.message : 'Error loading data from PowerBI.';
        }
      }, error => {
        // handle error
        console.log('SERVICE ERROR:',error);
        this.initLoad = false;
        this.svcResp = error.status + ':' + error.statusText;
        this.embedded = false;
      }
    );

  }

}
