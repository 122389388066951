export interface VolumeRebateInfo {
    /*
    CompanyName?:string;
    Email?:string;
    FirstName?:string;
    LastName?:string;
    VendorID?:string;
    VendorType?:string;
    ParentVendorID?: string;
    Phone?: string;
    VolumeRebatePercent?: string;
   */
   
    VendorData?: VendorDataModel;
    VolumeRebate?: VolumeRebateModel;


}
export interface VendorDataModel{
    COMPANY_NAME?: string;
    SC_EMAIL?: string;
    SC_FIRST_NAME?: string;
    SC_LAST_NAME?: string;
    VENDOR_ID?: string;
    PARENTID?: string;
    VENDOR_TYPE?: string;
    VENDOR_CONTACT?: string;
    SC_PHONE?: string;
    VOLUME_REBATE_PERCENT?: string;
    IS_VENDOR_ACTIVE?: string;

}
export interface VolumeRebateModel{
    VRData?:VolumeRebateDataModel[];

}
export interface VolumeRebateDataModel{
    VRID?: string;
    VALID_YEAR?: string;
    SOURCING_TYPE?: string;
    IS_RECURRING?: string;
    ONBOARDING_STATUS?: string;
    VOLUME_REBATE_PERCENT?: string;
    IS_VR_ACTIVE?: string;
}

export class VolumeRebateVoidRequest {
    VRID: string;
    ONBOARDING_STATUS: string;
    VOID_BY: string;
    VOID_REASON: string;
    PARENTID: string;
    PARENTVENDORNAME?: string;
    BUYER_FIRST_NAME?: string;
    BUYER_LAST_NAME?: string;
    BUYEREMAIL: string;
    VENDOR_TYPE?: string;
    COMPANY_NAME?: string;
    SC_EMAIL?: string;

}