/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./campaign-performance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../node_modules/powerbi-client-angular/powerbi-client-angular.ngfactory";
import * as i5 from "powerbi-client-angular";
import * as i6 from "./campaign-performance.component";
import * as i7 from "../services/rest.service";
import * as i8 from "@angular/http";
import * as i9 from "../services/analytics.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../services/user.service";
var styles_PowerBiComponent = [i0.styles];
var RenderType_PowerBiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PowerBiComponent, data: {} });
export { RenderType_PowerBiComponent as RenderType_PowerBiComponent };
function View_PowerBiComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.reportId; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.reportId; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.reportId === _co.selectedReport.reportId); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.reportName; _ck(_v, 3, 0, currVal_3); }); }
function View_PowerBiComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "select", [["class", "form-control form-control-sm"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeReport($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(3, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Select a report..."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PowerBiComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = null; _ck(_v, 2, 0, currVal_0); var currVal_1 = null; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.reportsList; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PowerBiComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-sm-12 embed-report"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "powerbi-report", [["style", "display:block;width:100%;height:80vh;"]], null, null, null, i4.View_PowerBIReportEmbedComponent_0, i4.RenderType_PowerBIReportEmbedComponent)), i1.ɵdid(2, 4833280, null, 0, i5.PowerBIReportEmbedComponent, [], { embedConfig: [0, "embedConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.PBIconfig; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PowerBiComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There was a problem loading data from PowerBI."]))], null, null); }
export function View_PowerBiComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { embedNode: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-sm-6 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PowerBiComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "col-sm-12 bgimg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PowerBiComponent_3)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PowerBiComponent_4)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.reportsList && (_co.reportsList.length > 1)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.embedded; _ck(_v, 12, 0, currVal_2); var currVal_3 = (!_co.embedded && !_co.initLoad); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedReport.reportName; _ck(_v, 5, 0, currVal_0); }); }
export function View_PowerBiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-campaign-performance", [], null, null, null, View_PowerBiComponent_0, RenderType_PowerBiComponent)), i1.ɵdid(1, 114688, null, 0, i6.PowerBiComponent, [i7.RestService, i8.Http, i9.GoogleAnalyticsService, i10.DomSanitizer, i11.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PowerBiComponentNgFactory = i1.ɵccf("app-campaign-performance", i6.PowerBiComponent, View_PowerBiComponent_Host_0, {}, {}, []);
export { PowerBiComponentNgFactory as PowerBiComponentNgFactory };
