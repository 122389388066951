import { Injectable } from '@angular/core';
import {VendorInvite} from "../modal/vendor.invite";
import {environment} from '../../environments/environment';
import { RestService } from './rest.service';
import {map} from 'rxjs/operators';
import { UserService } from './user.service';


@Injectable()
export class InviteService {

  private api = environment.merchapi;
  constructor(
    private rest:RestService,
    private users: UserService,
  ) { }

  postVendorInviteForm(vendorInvite: VendorInvite) {
    const auth = this.users.getHeader();
    return this.rest.postInviteReq(this.api +'createguest',vendorInvite,auth).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

  postAdditionalVendorForm(jsonValue: String) {
    const auth = this.users.getHeader();
    return this.rest.postInviteReq(this.api +'createguest',jsonValue,auth).pipe(map(
      data => {
        return data;
      }, error => {
        return error;
      }
    ));
  }

}
