import { RestService } from './rest.service';
import { VendorApiService } from './../vendor-services/vendor-api.service';
import { UserService } from './../services/user.service';
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "../vendor-services/vendor-api.service";
import * as i3 from "./user.service";
import * as i4 from "@angular/router";
function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}
var VacService = /** @class */ (function () {
    function VacService(rest, api, user, router) {
        this.rest = rest;
        this.api = api;
        this.user = user;
        this.router = router;
        // TIBCO-3533
        this.vacBuyer = [];
        this.sheetData = new BehaviorSubject('');
        this.isBuyerAdmin = false;
        this.currBuyer = []; // hold the list of buyers
        // edit VAC functionality - TIBCO-3618
        this.isRevision = false;
        this.selectedVAC = new VendorAllowanceConfirmation();
    }
    VacService.prototype.submitVACrequest = function (json) {
        var url = 'sendvacinfotodocusign';
        return this.api.vacReq(url, json).pipe(map(function (data) {
            return data;
        }, function (error) {
            return error;
        }));
    };
    VacService.prototype.getBuyerInfo = function () {
        var _this = this;
        return this.api.getBuyerDetails().pipe(map(function (data) {
            // get unique
            console.log('Buyers', data.Data);
            if (data && data.Data) {
                var _loop_1 = function (d) {
                    // let dup = this.vacBuyer.find(b => b.Email === d.Email);
                    // console.log(d.Email + ' ' + d.BuyerID);
                    var dup = _this.vacBuyer.find(function (b) { return b.Email === d.Email && b.BuyerID === d.BuyerID; });
                    // console.log('duplicate', dup);
                    if (!dup) {
                        // console.log('add to list', d);
                        // check for buyer and vendor types match
                        if ((_this.user.getUserRole() === 'PTS_Buyer' && d.VendorType.indexOf('PTS') > -1) ||
                            (_this.user.getUserRole() !== 'PTS_Buyer' && d.VendorType.indexOf('PTS') === -1)) {
                            _this.vacBuyer.push(d);
                        }
                    }
                };
                for (var _i = 0, _a = data.Data; _i < _a.length; _i++) {
                    var d = _a[_i];
                    _loop_1(d);
                }
            }
            // this.vacBuyer = data.Data;
            console.log(_this.vacBuyer);
            return data.Data;
        }));
    };
    VacService.prototype.getVendorInfo = function (vendor, email) {
        var _this = this;
        // let url = this.api + 'queryvendorinfo';
        // format request from vendor info
        console.log(vendor);
        var json = {};
        if (email) {
            json['Email'] = vendor; //this.users.loggedInUser.userName
        }
        else {
            json['VendorID'] = vendor;
        }
        console.log(json);
        // return this.api.apiReq(url, json).pipe(map(
        return this.api.requestAddVendor(json).pipe(map(function (data) {
            _this.vacVendor = data;
            return _this.vacVendor;
        }));
    };
    VacService.prototype.retrieveVendorInformation = function (vendorId) {
        var _this = this;
        console.log("Vendor ID:", vendorId);
        var json = {};
        json['VENDOR_ID'] = vendorId;
        var authToken = this.user.getHeader();
        return this.rest.bwReqVendorInfo('getvendorinfo', json, authToken).pipe(map(function (data) {
            console.log("FINAL RESPONSE:", data);
            _this.vacVendor = data;
            return _this.vacVendor;
        }, function (err) {
            return err;
        }));
    };
    VacService.prototype.initializeVAC = function (vendor) {
        var _this = this;
        console.log(this.vacBuyer);
        if (!this.vacBuyer || this.vacBuyer.length === 0) {
            console.log('load buyer list', this.vacBuyer);
            this.getBuyerInfo().subscribe(function (buyerdata) {
                // match with current user
                // if match, allow access to only buyer's VAC (add filter in search or filter results if needed)
                // may be more than one buyer ID
                // otherwise, treat as buyer admin and allow access to all domestic vacs (not pts)
                console.log('username:', _this.user.loggedInUser);
                console.log('buyerlist', buyerdata);
                if (_this.user.loggedInUser.groupName.indexOf('Buyer') > -1 && _this.user.loggedInUser.userName) {
                    _this.currBuyer = buyerdata.filter(function (b) { return b.Email === _this.user.loggedInUser.userName; });
                    if (!_this.currBuyer || _this.currBuyer.length === 0) {
                        // admin
                        console.log('current logged in buyer is admin');
                        _this.isBuyerAdmin = true;
                        _this.currBuyer = [];
                    }
                    else {
                        // assign buyer ids
                        _this.isBuyerAdmin = false;
                        console.log('current logged in buyer', _this.currBuyer);
                    }
                }
            });
        }
        if (vendor && !this.vacVendor) {
            this.getVendorInfo(vendor);
        }
        console.log('initialize Buyer and Vendor', this.vacBuyer, this.vacVendor);
    };
    VacService.prototype.getPromoDetails = function (company) {
        if (!company) {
            company = 'TSC';
        }
        if (company === 'PETSENSE') {
            company = 'PTS';
        }
        var json = { 'Buyer': company };
        var url = 'getpromodetails';
        return this.api.vacReq(url, json).pipe(map(function (data) {
            return data;
        }, function (error) {
            return error;
        }));
    };
    VacService.prototype.getVACinfo = function (request) {
        var url = 'getvacinfofrommdm';
        return this.api.vacReq(url, request).pipe(map(function (data) {
            return data;
        }, function (error) {
            return error;
        }));
    };
    VacService.prototype.getSheetData = function () {
        return this.sheetData.asObservable();
    };
    VacService.prototype.importSheet = function (evt, existingData) {
        var _this = this;
        var startTime = Date.now();
        var loadTime = null;
        var arrayContent = [];
        var xlsxData = [];
        var tempData = [];
        var _self = this;
        console.log('import event', evt);
        /* wire up file reader */
        var target = (evt.target);
        if (target.files.length !== 1)
            throw new Error('Cannot use multiple files');
        var reader = new FileReader();
        reader.onload = function (e) {
            /* read workbook */
            var bstr = e.target.result;
            var wb = XLSX.read(bstr, { type: 'binary' });
            /* grab first sheet */
            var wsname = wb.SheetNames[0];
            var ws = wb.Sheets[wsname];
            /* save data */
            xlsxData = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
            arrayContent = xlsxData;
            // console.log(JSON.stringify(this.arrayContent));
            var rowCount = arrayContent.length;
            // if(rowCount && rowCount <= 1001){
            console.log('loading ' + (rowCount - 1) + ' rows from worksheet');
            var objArray = [];
            for (var r = 1; r < rowCount; r++) {
                // add header as key for each column
                objArray[r - 1] = {};
                for (var k = 0; k < arrayContent[0].length && k < arrayContent[r].length; k++) {
                    var key = arrayContent[0][k];
                    if (key) {
                        objArray[r - 1][key] = _this.replaceNull(arrayContent[r][k]);
                    }
                }
                if (r % 100 === 0) {
                    console.log('loading row ' + r + ' of ' + rowCount);
                }
            }
            console.log('import spreadsheet', objArray);
            // console.log('all attributes', this.fam.attrList);
            loadTime = Date.now();
            console.log('spreadsheet to object in ' + (loadTime - startTime) + 'ms');
            // copy sheetData
            // tempData = JSON.parse(JSON.stringify(this.sheetData));
            tempData = JSON.parse(JSON.stringify(objArray));
            // console.log(tempData);     
            var parseTime = Date.now();
            console.log('previous sheet', existingData);
            console.log('updated sheet in ' + (parseTime - loadTime) + 'ms', tempData);
            _this.sheetData.next(_this.copyArrayObject(tempData));
        };
        reader.readAsBinaryString(target.files[0]);
    };
    VacService.prototype.exportSheet = function (hot) {
        var exportFileName = '';
        var headers = hot.getColHeader();
        //console.log(description)
        var sheet = hot.getData();
        var fname = 'VAC Scandown Promo Data';
        // add headers to top row
        sheet.unshift(headers);
        //sheet.push();
        // remove last column in each row
        for (var _i = 0, sheet_1 = sheet; _i < sheet_1.length; _i++) {
            var row = sheet_1[_i];
            var btns = row.pop();
            var fcst = row.pop(); // remove forecast also
        }
        // prepare csv export
        this.downloadCSV(sheet, fname);
    };
    VacService.prototype.downloadCSV = function (sheet, filename) {
        var values = [];
        // convert number format to string
        for (var _i = 0, sheet_2 = sheet; _i < sheet_2.length; _i++) {
            var x = sheet_2[_i];
            var row = x.map(String);
            for (var col in row) {
                if (!row[col] || row[col] === null || row[col] === 'null') {
                    row[col] = '';
                }
            }
            values.push(row);
        }
        console.log('CSV sheet', values);
        /* generate worksheet */
        var ws = XLSX.utils.aoa_to_sheet(values);
        /* save to file */
        var wbout = XLSX.utils.sheet_to_csv(ws);
        saveAs(new Blob([s2ab(wbout)]), filename + '.csv');
    };
    VacService.prototype.copyArrayObject = function (obj) {
        return obj.map(function (x) { return Object.assign({}, x); });
    };
    VacService.prototype.replaceNull = function (val) {
        if (!val || val === null || val === 'null') {
            console.log('replace null here', val);
            val = '';
        }
        console.log('replaced', val);
        return val;
    };
    // edit VAC functionality - TIBCO-3618
    VacService.prototype.getRevisionStatus = function () {
        return this.isRevision;
    };
    VacService.prototype.setRevisionStatus = function (status) {
        if (status === true) {
            this.isRevision = true;
        }
        else {
            this.isRevision = false;
        }
    };
    VacService.prototype.selectVAC = function (vac) {
        this.selectedVAC = vac;
        this.setRevisionStatus(true);
    };
    VacService.prototype.getSelectedVAC = function () {
        return this.selectedVAC;
    };
    VacService.prototype.resetSelectedVAC = function () {
        this.selectedVAC = null;
        this.setRevisionStatus(false);
        console.log('VAC reset', this.getSelectedVAC(), this.getRevisionStatus());
    };
    VacService.prototype.goToEditVAC = function () {
        this.router.navigate(['/vendorAllowance']);
    };
    VacService.ngInjectableDef = i0.defineInjectable({ factory: function VacService_Factory() { return new VacService(i0.inject(i1.RestService), i0.inject(i2.VendorApiService), i0.inject(i3.UserService), i0.inject(i4.Router)); }, token: VacService, providedIn: "root" });
    return VacService;
}());
export { VacService };
var VacUpdateRequest = /** @class */ (function () {
    function VacUpdateRequest() {
    }
    return VacUpdateRequest;
}());
export { VacUpdateRequest };
var VendorAllowanceConfirmation = /** @class */ (function () {
    function VendorAllowanceConfirmation() {
        this.ScandownFlag = 'Y';
        this.VACID = '';
        this.PromoType = '';
        this.PromoAbbreviation = '';
        this.PromoDescription = '';
        this.IsManufacturer = 'N';
        this.EndDate = ''; // format 19-JUL-2021,
        this.BeginDate = ''; // format 19-JUL-2021,
        // CompletionDate:string = null; // format 19-JUL-2021,
        this.MfrName = '';
        this.MfrEmail = '';
        this.SubmittedBy = '';
    }
    return VendorAllowanceConfirmation;
}());
export { VendorAllowanceConfirmation };
var VacDocData = /** @class */ (function () {
    function VacDocData() {
        this.OnboardingStatus = 'Submitted';
        this.VoidBy = '';
        this.VoidReason = '';
        this.RejectedBy = '';
        this.RejectedReason = '';
    }
    return VacDocData;
}());
export { VacDocData };
var VacPromoDetails = /** @class */ (function () {
    function VacPromoDetails() {
        this.FlatDolAmt = '';
        this.FlatDollarSkuList = '';
        this.SpecialInstructions = '';
        this.EstimatedUnits = '';
        this.PaymentDate = ''; // format 19-JUL-2021
        this.Rainchecks = '';
        this.TotalSKUCount = '';
        this.PaymentType = '';
        this.EstimatedAmt = '';
        this.Frequency = '';
        this.NoOfPayments = '';
    }
    return VacPromoDetails;
}());
export { VacPromoDetails };
var VacScanDown = /** @class */ (function () {
    function VacScanDown() {
        this.Forecast = '';
        this.SKUDescription = '';
        this.SKU = '';
        this.ScandownAmt = null;
        this.Units = null;
    }
    return VacScanDown;
}());
export { VacScanDown };
var VacScanDownPercent = /** @class */ (function () {
    function VacScanDownPercent() {
        this.SKU = '';
        this.SKUDescription = '';
        this.ScandownAmtPercent = null;
        this.Sales$ = null;
        this.Result = '';
    }
    return VacScanDownPercent;
}());
export { VacScanDownPercent };
var VacCompanyContact = /** @class */ (function () {
    function VacCompanyContact() {
    }
    return VacCompanyContact;
}());
export { VacCompanyContact };
var VacCorpInfo = /** @class */ (function () {
    function VacCorpInfo() {
    }
    return VacCorpInfo;
}());
export { VacCorpInfo };
var VacSearchResult = /** @class */ (function () {
    function VacSearchResult() {
        this.VACID = '';
        this.BuyerID = '';
        this.BuyerEmail = '';
        this.VendorID = '';
        this.VendorType = '';
        this.IsVACActive = '';
        this.OnboardingStatus = '';
        this.PromoType = '';
        this.PromoAbbreviation = '';
        this.PromoDescription = '';
        this.BeginDate = ''; // format 19-JUL-2021,
        this.EndDate = ''; // format 19-JUL-2021,
    }
    return VacSearchResult;
}());
export { VacSearchResult };
