import { Component, Inject, OnInit, HostListener, SecurityContext } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent  {

  message: any = '';
  confirmButtonText = 'Accept';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    private dom: DomSanitizer) {
    if(data){
      this.message = this.dom.bypassSecurityTrustHtml(data.message) || this.message; // this.dom.sanitize(SecurityContext.HTML, data.message) // data.message
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}

//   onConfirmClick(): void {
//     this.dialogRef.close(true);
//   }
// }

// ---------
// @Component({
//   selector: 'app-dialog',
//   templateUrl: './dialog.component.html',
//   styleUrls: ['./dialog.component.css']
// })
// export class DialogComponent  {

//   message: string = ""
//   confirmButtonText = "Accept"
//   constructor(
//     @Inject(MAT_DIALOG_DATA) private data: any,
//     private dialogRef: MatDialogRef<DialogComponent>) {
//     if(data){
//       this.message = data.message || this.message;
//       if (data.buttonText) {
//         this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
//       }
//     }
//   }

//   onConfirmClick(): void {
//     this.dialogRef.close(true);
//   }


// }
